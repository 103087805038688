<template>
  <div>
    <WCard class="table-card">
      <WlanTable
        :items="wlans"
        :totalItems="totalWlansCount"
        :perPage="limit"
        :selectedWlanIds="selectedWlanIds"
        :activeRowWlanId="''"
        :sortState="sortParams"
        :isDataLoading="false"
        :isDisabled="isLoading"
        :isOneColMode="false"
        :isSelectionRowsModeEnabled="true"
        @on-cell-click="handleCellClick"
        @on-selected-rows-change="handleSelectRow"
        @on-sort-change="handleSortChange"
        @on-page-change="handlePageChange"
        @on-per-page-change="handlePerPageChange"
      >
        <template v-slot:table-menu>
          <div class="control-panel">
            <div class="control-panel__container left">
              <WButton info
                :disabled="isLoading"
                @click="openCreateWlanModal"
              >
                {{ $t('general.add') }}
              </WButton>

              <WButton info
                :disabled="isLoading || selectedWlanIds.length !== 1"
                @click="openCloneWlanModal"
              >
                {{ $t('general.clone') }}
              </WButton>
              <WButton info
                :disabled="isLoading || !selectedWlanIds.length"
                @click="openDeleteWlanModal"
              >
                {{ $t('general.delete') }}
              </WButton>

              <WButton info
                :disabled="isLoading || !selectedWlanIds.length"
                @click="handleEnableWlans"
              >
                {{ $t('general.enable') }}
              </WButton>
              <WButton info
                :disabled="isLoading || !selectedWlanIds.length"
                @click="handleDisableWlans"
              >
                {{ $t('general.disable') }}
              </WButton>
            </div>
            <div class="control-panel__container right">
            </div>
          </div>
        </template>
      </WlanTable>
    </WCard>

    <CreateWlanModal
      :forbiddenWlanNames="forbiddenWlanNames"
      :forbiddenWlanIds="forbiddenWlanIds"
      :suggestedMabRadiusProfiles="suggestedMabRadiusProfiles"
      :suggestedWebAuthRedirectNames="suggestedWebAuthRedirectNames"
      :suggestedRadius8021xProfileNames="suggestedRadius8021xProfileNames"
      :suggestedRadiusAccountingProfileNames="suggestedRadiusAccountingProfileNames"
      :isOpen="isCreateWlanModalOpen"
      @submit="createWlan"
      @close="closeCreateWlanModal"
    />
    <ChangeWlanModal
      :wlanForChanging="selectedWlanForChanging"
      :forbiddenWlanNames="forbiddenWlanNames"
      :forbiddenWlanIds="forbiddenWlanIds"
      :suggestedMabRadiusProfiles="suggestedMabRadiusProfiles"
      :suggestedWebAuthRedirectNames="suggestedWebAuthRedirectNames"
      :suggestedRadius8021xProfileNames="suggestedRadius8021xProfileNames"
      :suggestedRadiusAccountingProfileNames="suggestedRadiusAccountingProfileNames"
      :isOpen="isChangeWlanModalOpen"
      @submit="changeWlan"
      @close="closeChangeWlanModal"
    />
    <CloneWlanModal
      :wlanForCloning="selectedWlanForCloning"
      :forbiddenWlanNames="forbiddenWlanNames"
      :forbiddenWlanIds="forbiddenWlanIds"
      :suggestedMabRadiusProfiles="suggestedMabRadiusProfiles"
      :suggestedWebAuthRedirectNames="suggestedWebAuthRedirectNames"
      :suggestedRadius8021xProfileNames="suggestedRadius8021xProfileNames"
      :suggestedRadiusAccountingProfileNames="suggestedRadiusAccountingProfileNames"
      :isOpen="isCloneWlanModal"
      @submit="createWlan"
      @close="closeCloneWlanModal"
    />
    <DeleteWlanModal
      :wlanIdsToDelete="selectedWlanIds"
      :isOpen="isDeleteWlanModalOpen"
      @close="closeDeleteWlanModal"
      @confirm="handleDeleteWlans"
    />
  </div>
</template>

<script>
/**
 * компонент страницы Wlan
 * https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/218562646/FT23.7.1+Front
 * @component
 */

import {
  WlanTable,
  CreateWlanModal,
  ChangeWlanModal,
  CloneWlanModal,
  DeleteWlanModal
} from './components';

import wlanApi from './api';

/* eslint-disable camelcase */

export default {
  name: 'WlanPage',
  components: {
    WlanTable,
    CreateWlanModal,
    ChangeWlanModal,
    CloneWlanModal,
    DeleteWlanModal
  },
  props: {
  },
  data() {
    return {
      wlans: [],
      suggestedMabRadiusProfiles: [],
      suggestedWebAuthRedirectNames: [],
      suggestedRadius8021xProfileNames: [],
      suggestedRadiusAccountingProfileNames: [],
      // айдишки для контролирования стейта при выборе через чекбокс
      selectedWlanIds: [],
      selectedWlanForChanging: {},
      selectedWlanForCloning: {},
      totalWlansCount: 0,
      offset: 0,
      limit: 10,
      sortParams: {
        field: 'name',
        type: 'asc'
      },
      isLoading: false,
      isCreateWlanModalOpen: false,
      isChangeWlanModalOpen: false,
      isCloneWlanModal: false,
      isDeleteWlanModalOpen: false
    };
  },
  computed: {
    forbiddenWlanNames() {
      const wlanNames = this.wlans.map(wlan => wlan.name);
      return wlanNames;
    },
    forbiddenWlanIds() {
      const wlanIds = this.wlans.map(wlan => wlan.wlan_id);
      return wlanIds;
    }
  },
  methods: {
    handleCellClick(rowData) {
      const { nativeData: { id } } = rowData;

      const [selectedWlanForChanging] = this.wlans
        .filter(wlan => wlan.id === id);

      this.selectedWlanForChanging = selectedWlanForChanging;

      this.openChangeWlanModal();
    },
    handleSelectRow(selectedRowData) {
      const selectedWlanIds = selectedRowData.map(rowData => rowData.nativeData.wlan_id);

      if (selectedWlanIds.length === 1) {
        const [selectedWlanForCloning] = this.wlans
          .filter(wlan => wlan.wlan_id === selectedWlanIds[0]);

        this.selectedWlanForCloning = selectedWlanForCloning;
      }

      if (selectedWlanIds.length !== 1) {
        this.selectedWlanForCloning = {};
      }

      this.selectedWlanIds = selectedWlanIds;
    },
    handleSortChange(sortChangeData) {
      const { params } = sortChangeData;
      const { field, type } = params[0];

      this.sortParams = { field, type };

      this.getWlans();
    },
    handlePageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalWlansCount) {
        this.offset = 0;
      }

      this.getWlans();
    },
    handlePerPageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalWlansCount) {
        this.offset = 0;
      }

      this.getWlans();
    },
    handleEnableWlans() {
      const idsToDelete = this.wlans
        .filter(wlan => this.selectedWlanIds.includes(wlan.wlan_id))
        .map(wlan => wlan.id);

      wlanApi.enableWlans({
        ids: idsToDelete
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.getWlans();
        },
        onError: (error) => {
          this.isLoading = false;
        },
        successMessage: this.$t('wlan.responseMessage.enableWlans.success'),
        errorMessage: this.$t('wlan.responseMessage.enableWlans.error')
      });
    },
    handleDisableWlans() {
      const idsToDelete = this.wlans
        .filter(wlan => this.selectedWlanIds.includes(wlan.wlan_id))
        .map(wlan => wlan.id);

      wlanApi.disableWlans({
        ids: idsToDelete
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.getWlans();
        },
        onError: (error) => {
          this.isLoading = false;
        },
        successMessage: this.$t('wlan.responseMessage.disableWlans.success'),
        errorMessage: this.$t('wlan.responseMessage.disableWlans.error')
      });
    },
    handleDeleteWlans() {
      const idsToDelete = this.wlans
        .filter(wlan => this.selectedWlanIds.includes(wlan.wlan_id))
        .map(wlan => wlan.id);

      wlanApi.deleteWlans({
        ids: idsToDelete
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeDeleteWlanModal();
          this.getWlans();
        },
        onError: (error) => {
          this.isLoading = false;
        },
        successMessage: this.$t('wlan.responseMessage.deleteWlans.success'),
        errorMessage: this.$t('wlan.responseMessage.deleteWlans.error')
      });
    },
    changeWlan(formValues) {
      const { id } = this.selectedWlanForChanging;

      wlanApi.updateWlan({
        id,
        name: formValues['general.name'],
        wlan_id: formValues['general.wlan_id'],
        ssid: formValues['general.ssid'],
        broadcast_ssid_enabled: formValues['general.broadcast_ssid_enabled'],
        status_24ghz_enabled: formValues['general.status_24ghz_enabled'],
        status_5ghz_enabled: formValues['general.status_5ghz_enabled'],
        status_6ghz_enabled: formValues['general.status_6ghz_enabled'],
        status_ssid_enabled: formValues['general.status_ssid_enabled'],
        security_type: formValues['security.layer2.security_type'],
        encryption_type: formValues['security.layer2.encryption_type'],
        support_pmf: formValues['security.layer2.support_pmf'],
        param_80211w_query_attempts: formValues['security.layer2.param_80211w_query_attempts'],
        param_80211w_retry_timeout: formValues['security.layer2.param_80211w_retry_timeout'],
        fast_roaming_enabled: formValues['security.layer2.fast_roaming_enabled'],
        ft_over_ds_enabled: formValues['security.layer2.ft_over_ds_enabled'],
        param_80211r_reassociation_timeout: formValues['security.layer2.param_80211r_reassociation_timeout'],
        mab_radius_profile: formValues['security.layer2.mab_radius_profile'],
        psk_enabled: formValues['security.layer2.psk_enabled'],
        psk_256_enabled: formValues['security.layer2.psk_256_enabled'],
        param_8021x_enabled: formValues['security.layer2.param_8021x_enabled'],
        param_8021x_256_enabled: formValues['security.layer2.param_8021x_256_enabled'],
        sae_enabled: formValues['security.layer2.sae_enabled'],
        ft_sae_enabled: formValues['security.layer2.ft_sae_enabled'],
        ft_8021x_enabled: formValues['security.layer2.ft_8021x_enabled'],
        ft_psk_enabled: formValues['security.layer2.ft_psk_enabled'],
        suite_b_192_enabled: formValues['security.layer2.suite_b_192_enabled'],
        owe_enabled: formValues['security.layer2.owe_enabled'],
        psk_format: formValues['security.layer2.psk_format'],
        psk_type: formValues['security.layer2.psk_type'],
        psk_key: formValues['security.layer2.psk_key'],
        owe_wlan_id: formValues['security.layer2.owe_wlan_id'],
        sae_anti_clogging_threshold: formValues['security.layer2.sae_anti_clogging_threshold'],
        sae_max_retries: formValues['security.layer2.sae_max_retries'],
        sae_password_element: formValues['security.layer2.sae_password_element'],
        web_auth_profile: formValues['security.layer3.web_auth_profile'],
        radius_8021x_profile: formValues['security.aaa.radius_8021x_profile'],
        radius_accounting_profile: formValues['security.aaa.radius_accounting_profile'],
        advertise_ap_name_enabled: formValues['advanced.advertise_ap_name_enabled'],
        p2p_block_action: formValues['advanced.p2p_block_action'],
        deny_laa_rcm_enabled: formValues['advanced.deny_laa_rcm_enabled'],
        wmm_policy: formValues['advanced.wmm_policy'],
        dtim_interval_24ghz: formValues['advanced.dtim_interval_24ghz'],
        dtim_interval_5ghz: formValues['advanced.dtim_interval_5ghz'],
        max_connection_radio_wlan: formValues['advanced.max_connection_radio_wlan'],
        ofdma_downlink_enabled: formValues['advanced.ofdma_downlink_enabled'],
        ofdma_uplink_enabled: formValues['advanced.ofdma_uplink_enabled'],
        mumimo_downlink_enabled: formValues['advanced.mumimo_downlink_enabled'],
        mumimo_uplink_enabled: formValues['advanced.mumimo_uplink_enabled'],
        bss_twt_enabled: formValues['advanced.bss_twt_enabled'],
        okc_enabled: formValues['advanced.okc_enabled'],
        param_80211ax_enabled: formValues['advanced.param_80211ax_enabled'],
        neighbor_report_enabled: formValues['advanced.neighbor_report_enabled'],
        bss_fast_transition_enabled: formValues['advanced.bss_fast_transition_enabled']
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeChangeWlanModal();
          this.getWlans();
        },
        onError: () => {
          this.isLoading = false;
        },
        successMessage: this.$t('wlan.responseMessage.updateWlan.success'),
        errorMessage: this.$t('wlan.responseMessage.updateWlan.error')
      });
    },
    createWlan(formValues) {
      wlanApi.createWlan({
        name: formValues['general.name'],
        wlan_id: formValues['general.wlan_id'],
        ssid: formValues['general.ssid'],
        broadcast_ssid_enabled: formValues['general.broadcast_ssid_enabled'],
        status_24ghz_enabled: formValues['general.status_24ghz_enabled'],
        status_5ghz_enabled: formValues['general.status_5ghz_enabled'],
        status_6ghz_enabled: formValues['general.status_6ghz_enabled'],
        status_ssid_enabled: formValues['general.status_ssid_enabled'],
        security_type: formValues['security.layer2.security_type'],
        encryption_type: formValues['security.layer2.encryption_type'],
        support_pmf: formValues['security.layer2.support_pmf'],
        param_80211w_query_attempts: formValues['security.layer2.param_80211w_query_attempts'],
        param_80211w_retry_timeout: formValues['security.layer2.param_80211w_retry_timeout'],
        fast_roaming_enabled: formValues['security.layer2.fast_roaming_enabled'],
        ft_over_ds_enabled: formValues['security.layer2.ft_over_ds_enabled'],
        param_80211r_reassociation_timeout: formValues['security.layer2.param_80211r_reassociation_timeout'],
        mab_radius_profile: formValues['security.layer2.mab_radius_profile'],
        psk_enabled: formValues['security.layer2.psk_enabled'],
        psk_256_enabled: formValues['security.layer2.psk_256_enabled'],
        param_8021x_enabled: formValues['security.layer2.param_8021x_enabled'],
        param_8021x_256_enabled: formValues['security.layer2.param_8021x_256_enabled'],
        sae_enabled: formValues['security.layer2.sae_enabled'],
        ft_sae_enabled: formValues['security.layer2.ft_sae_enabled'],
        ft_8021x_enabled: formValues['security.layer2.ft_8021x_enabled'],
        ft_psk_enabled: formValues['security.layer2.ft_psk_enabled'],
        suite_b_192_enabled: formValues['security.layer2.suite_b_192_enabled'],
        owe_enabled: formValues['security.layer2.owe_enabled'],
        psk_format: formValues['security.layer2.psk_format'],
        psk_type: formValues['security.layer2.psk_type'],
        psk_key: formValues['security.layer2.psk_key'],
        owe_wlan_id: formValues['security.layer2.owe_wlan_id'],
        sae_anti_clogging_threshold: formValues['security.layer2.sae_anti_clogging_threshold'],
        sae_max_retries: formValues['security.layer2.sae_max_retries'],
        sae_password_element: formValues['security.layer2.sae_password_element'],
        web_auth_profile: formValues['security.layer3.web_auth_profile'],
        radius_8021x_profile: formValues['security.aaa.radius_8021x_profile'],
        radius_accounting_profile: formValues['security.aaa.radius_accounting_profile'],
        advertise_ap_name_enabled: formValues['advanced.advertise_ap_name_enabled'],
        p2p_block_action: formValues['advanced.p2p_block_action'],
        deny_laa_rcm_enabled: formValues['advanced.deny_laa_rcm_enabled'],
        wmm_policy: formValues['advanced.wmm_policy'],
        dtim_interval_24ghz: formValues['advanced.dtim_interval_24ghz'],
        dtim_interval_5ghz: formValues['advanced.dtim_interval_5ghz'],
        max_connection_radio_wlan: formValues['advanced.max_connection_radio_wlan'],
        ofdma_downlink_enabled: formValues['advanced.ofdma_downlink_enabled'],
        ofdma_uplink_enabled: formValues['advanced.ofdma_uplink_enabled'],
        mumimo_downlink_enabled: formValues['advanced.mumimo_downlink_enabled'],
        mumimo_uplink_enabled: formValues['advanced.mumimo_uplink_enabled'],
        bss_twt_enabled: formValues['advanced.bss_twt_enabled'],
        okc_enabled: formValues['advanced.okc_enabled'],
        param_80211ax_enabled: formValues['advanced.param_80211ax_enabled'],
        neighbor_report_enabled: formValues['advanced.neighbor_report_enabled'],
        bss_fast_transition_enabled: formValues['advanced.bss_fast_transition_enabled']
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeCreateWlanModal();
          this.closeCloneWlanModal();
          this.getWlans();
        },
        onError: () => {
          this.isLoading = false;
        },
        successMessage: this.$t('wlan.responseMessage.createWlan.success'),
        errorMessage: this.$t('wlan.responseMessage.createWlan.error')
      });
    },
    getWlans() {
      wlanApi.getWlans({
        limit: this.limit,
        offset: this.offset,
        // // если надо будет реализовать фильтрацию, то использовать filterByName, filterById
        // filterByName: undefined,
        // filterById: undefined,
        sortOrderBy: this.sortParams.type,
        sortValueBy: this.sortParams.field
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;

          const {
            total,
            limit,
            offset,
            wlan_profiles
          } = data;

          this.wlans = wlan_profiles;
          this.totalWlansCount = total;
          this.offset = offset;
          this.limit = limit;

          this.isLoading = false;

          this.selectedWlanIds = [];
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    getRadiusGroups() {
      wlanApi.getRadiusGroups({
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (radiusGroups) => {
          this.isLoading = false;

          const radiusNames = radiusGroups.map(radiusGroup => radiusGroup.name);

          this.suggestedMabRadiusProfiles = radiusNames;
          this.suggestedRadius8021xProfileNames = radiusNames;
          this.suggestedRadiusAccountingProfileNames = radiusNames;
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    getRedirectRules() {
      wlanApi.getRedirectRules({
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (redirectRules) => {
          this.isLoading = false;
          this.suggestedWebAuthRedirectNames = redirectRules.map(redirectRule => redirectRule.name);
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    openCreateWlanModal() {
      this.isCreateWlanModalOpen = true;
    },
    closeCreateWlanModal() {
      this.isCreateWlanModalOpen = false;
    },
    openChangeWlanModal() {
      this.isChangeWlanModalOpen = true;
    },
    closeChangeWlanModal() {
      this.isChangeWlanModalOpen = false;
      this.selectedWlanForChanging = {};
    },
    openCloneWlanModal() {
      this.isCloneWlanModal = true;
    },
    closeCloneWlanModal() {
      this.isCloneWlanModal = false;
    },
    openDeleteWlanModal() {
      this.isDeleteWlanModalOpen = true;
    },
    closeDeleteWlanModal() {
      this.isDeleteWlanModalOpen = false;
    }
  },
  mounted() {
    this.getWlans();
    this.getRadiusGroups();
    this.getRedirectRules();
  }
};
</script>

<style lang="css" scoped>
.table-card {
  background-color: var(--table-accent);
}

.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}
</style>
