<template>
  <section>
    <h2 class="mb-1">
      {{ $t('recommendations.heading') }}
      <span
        @click="moveRecommendationsBottom"
        v-if="dashboardRecommendationsOnTheDashboardTop"
        role="button"
        class="recomendation-move-block"
      >
        <i class="fa fa-long-arrow-down"></i>
        {{ $t('recommendations.moveRecommendationsBottom') }}
        <i class="fa fa-long-arrow-down"></i>
      </span>
      <span
        @click="moveRecommendationsTop"
        v-if="!dashboardRecommendationsOnTheDashboardTop"
        role="button"
        class="recomendation-move-block"
      >
        <i class="fa fa-long-arrow-up"></i>
        {{ $t('recommendations.moveRecommendationsTop') }}
        <i class="fa fa-long-arrow-up"></i>
      </span>
    </h2>
    <masonry :cols="{ default: 4, 1440: 3, 1140: 2, 540: 1 }" :gutter="30">
      <recommendation-card v-if="allGood || showAll" locale-key="allGood" color="success">
        <template v-slot:icon>
          <span class="icon">
            <i class="fa fa-check" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>

      <recommendation-card
        v-if="(!activeAds.length && isContentPortalEnabled) || showAll"
        locale-key="addAdds"
        link="/portal/ads"
      >
        <template v-slot:icon>
          <span class="icon">
            <i class="fa fa-bullhorn" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>

      <recommendation-card v-if="(!hasBanner && isContentPortalEnabled) || showAll" locale-key="addBanners" link="/portal/ads">
        <template v-slot:icon>
          <span class="icon">
            <i class="fa fa-sticky-note" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>

      <recommendation-card v-if="(!hasVideo && isContentPortalEnabled) || showAll" locale-key="addVideo" link="/portal/ads">
        <template v-slot:icon>
          <span class="icon">
            <i class="fa fa-video-camera" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>

      <recommendation-card v-if="(!hasPoll && isContentPortalEnabled) || showAll" locale-key="addPolls" link="/portal/ads">
        <template v-slot:icon>
          <span class="icon">
            <i class="fa fa-list" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>

      <recommendation-card
        v-if="(!hasPollQuality && isContentPortalEnabled) || showAll"
        locale-key="addPollsQuality"
        link="/portal/ads"
      >
        <template v-slot:icon>
          <small class="icon">
            <i class="fa fa-star" aria-hidden="true"></i>
          </small>
        </template>
      </recommendation-card>

      <recommendation-card
        v-if="(!hasRadarExport && isContentAnalyticsEnabled) || showAll"
        locale-key="addRadarExport"
        color="info"
        link="/radar"
      >
        <template v-slot:icon>
          <span class="icon">
            <i class="fa fa-compass" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>

      <recommendation-card
        v-if="(activeAds.length > 0 && isContentPortalEnabled) || showAll"
        locale-key="showAdsStats"
        color="success"
        link="/portal/ads"
      >
        <template v-slot:icon>
          <span class="icon">
            <i class="fa fa-bar-chart" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>

      <recommendation-card v-if="hasHighLoad || showAll" locale-key="highCPULoad" color="danger">
        <template v-slot:icon>
          <span class="icon">
            <i class="fa fa-bolt" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>
      <recommendation-card
        v-if="$can('read', { route: 'Aps' }) && (hasDistressedCPEs || showAll)"
        locale-key="distressedCPEs"
        color="danger"
        :link="{ name: 'Aps', params: { showDistressed: true } }"
      >
        <template v-slot:icon>
          <span class="icon">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>
<!--      <recommendation-card-->
<!--        locale-key="easyInstallWizard"-->
<!--        color="success"-->
<!--        link="easy-install-wizard"-->
<!--        v-if="this.$store.state.userData.role === 'admin'"-->
<!--      >-->
<!--        <template v-slot:icon>-->
<!--          <span class="icon">-->
<!--            <i class="glyphicons glyphicons-road" aria-hidden="true"></i>-->
<!--          </span>-->
<!--        </template>-->
<!--      </recommendation-card>-->
      <recommendation-card
        locale-key="easyInstallWizard"
        color="success"
        link="easy-install-wizard"
        v-if="showEasyInstallWizardRecommendationCard || showAll"
      >
        <template v-slot:icon>
          <span class="icon">
            <i class="glyphicons glyphicons-road" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>
      <recommendation-card v-if="showKnowledgeBaseRecommendationCard || showAll" locale-key="knowledgeBase" color="success" link="support">
        <template v-slot:icon>
          <span class="icon">
            <i class="glyphicons glyphicons-question-sign" aria-hidden="true"></i>
          </span>
        </template>
      </recommendation-card>
    </masonry>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import RecommendationCard from './recommendation-card.vue';
import portalAdsService from '../../services/portalAdsService';
import dashboardService from '../../services/dashboardService';
import radarService from '../../services/radarService';

const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'DashboardRecommendations',
  components: { RecommendationCard },
  data() {
    return {
      startOfDay: moment().startOf('day').unix(),
      hasDistressedCPEs: false,
      window,
      showAll: false
    };
  },
  props: {
    dashboardRecommendationsOnTheDashboardTop: {
      type: Boolean
    }
  },
  computed: {
    ...featureFlagsMapGetters(['isContentPortalEnabled', 'isContentAnalyticsEnabled', 'isExperimentalModeEnabled']),
    ...WLCProductMapGetters(['isWLCProduct']),
    showKnowledgeBaseRecommendationCard() {
      return !this.isWLCProduct;
    },
    showEasyInstallWizardRecommendationCard() {
      // https://wimark.kaiten.ru/space/321807/card/43764636 - убрать isExperimentalModeEnabled после исправления и из computed
      return this.$store.state.userData.role === 'admin' && this.isContentPortalEnabled && !this.isWLCProduct && this.isExperimentalModeEnabled;
    },
    activeAds() {
      if (!this.$store.state.portalAdsList) {
        return [];
      }
      return JSON.parse(JSON.stringify(this.$store.state.portalAdsList)).filter(
        (ad) => ad.schedule.stop >= this.startOfDay
      );
    },
    hasBanner() {
      return !!this.activeAds.find((item) => item.data.type === 'image');
    },
    hasPoll() {
      return !!this.activeAds.find((item) => item.data.type === 'poll');
    },
    hasPollQuality() {
      return !!this.activeAds.find((item) => item.data.type === 'poll_quality');
    },
    hasVideo() {
      return !!this.activeAds.find((item) => item.data.type === 'video');
    },
    hasHighLoad() {
      const { systemInfo } = this.$store.state;
      if (!systemInfo || !systemInfo.load || !systemInfo.load.load_core) {
        return false;
      }
      const coreLoad = systemInfo.load.load_core;
      const highLoad = 0.9;
      return coreLoad >= highLoad;
    },
    hasRadarExport() {
      return this.$store.state.radarExportList.length > 0;
    },
    // hasDistressedCPEs() {
    //   return dashboardService.checkDistressedCPEs(this);
    // },
    allGood() {
      /* Has all recommended ads or does not have Portal */
      return (
        ((this.isContentPortalEnabled && this.hasBanner) || !this.isContentPortalEnabled) &&
        ((this.isContentPortalEnabled && this.hasPoll) || !this.isContentPortalEnabled) &&
        ((this.isContentPortalEnabled && this.hasPollQuality) || !this.isContentPortalEnabled) &&
        ((this.isContentPortalEnabled && this.hasVideo) || !this.isContentPortalEnabled) &&
        /* No technical problems */
        !this.hasDistressedCPEs &&
        !this.hasHighLoad &&
        /* Ether has Radar export or does not have Radar */
        ((this.isContentAnalyticsEnabled && this.hasRadarExport) || !this.isContentAnalyticsEnabled)
      );
    }
  },
  created() {
    if (this.isContentPortalEnabled) {
      // portalAdsService.getPortalAds(this);
      portalAdsService.getPortalAdsWithNoPagination(this);
    }
    dashboardService.checkDistressedCPEs(this);
    if (this.isContentAnalyticsEnabled) {
      radarService.getExports(this);
    }
  },
  methods: {
    moveRecommendationsBottom() {
      this.$emit('moveRecommendationsBottom');
    },
    moveRecommendationsTop() {
      this.$emit('moveRecommendationsTop');
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  white-space: nowrap;
  float: right;
  font-size: 32px;
}

.recomendation-move-block {
  font-size: 0.37em;
  margin-left: 0.5em;
}
</style>
