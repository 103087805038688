<template>
  <div class="cpe-status">
    <span v-if="isBusy(props.row.nativeData.id, props.row.nativeData.config_status)"
      class="badge badge-primary"
    >
      {{ cpesIdsOperations.includes(props.row.nativeData.id)
      ? $t('aps.updating')
      : $t(`aps.${props.row.nativeData.config_status}`)
      }}
    </span>
    <span v-else-if="props.row.nativeData.config_status === 'ok'"
      class="badge badge-success"
    >
      {{ $t('aps.ok') }}
    </span>
    <span v-else-if="props.row.nativeData.config_status === 'offline'"
      class="badge badge-dark"
    >
      {{ $t('aps.offline') }}
    </span>
    <span v-else-if="props.row.nativeData.config_status === 'empty'"
      class="badge badge-light"
    >
      {{ $t('aps.empty') }}
    </span>
    <span
      v-else-if="props.row.nativeData.config_status === 'join'"
      class="badge badge-info text-sm"
    >
      {{ $t('aps.join') }}
    </span>
    <span v-else
      class="badge badge-light"
    >
      {{ props.formattedRow[props.column.field] }}
    </span>
    <LastErrorTooltip
      v-if="props.row.nativeData.last_error.description || props.row.nativeData.last_error.type"
      :errorObj="props.row.nativeData.last_error"
      @on-show-more-events="handleShowMoreEvents"
    />
  </div>
</template>

<script>
/**
 * компонент - ячейка status.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

import helpers from '@/helpers';
import LastErrorTooltip from '../LastErrorTooltip/LastErrorTooltip.vue';

export default {
  name: 'StatusCell',
  components: {
    LastErrorTooltip
  },
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    cpesIdsOperations() {
      return helpers.combineOperationIdsArrayFromObjects(this.$store.state.cpeOperations);
    }
  },
  methods: {
    isBusy(id, status) {
      return (
        this.cpesIdsOperations.includes(id)
        || status === 'updating'
        || status === 'upgrading'
        || status === 'rebooting'
        || status === 'resetting'
      );
    },
    handleShowMoreEvents() {
      this.$emit('on-show-more-events');
    }
  }
};
</script>

<style lang="css" scoped>
.cpe-status {
  display: flex;
  align-items: center;
  gap: 8px;
}

.info {
  color: var(--brand-info);
}

.success {
  color: var(--brand-success);
}

.muted {
  color: var(--gray-light);
}
</style>
