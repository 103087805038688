<template>
  <div
    v-if="currentSecurityType !== 'open'"
    class="wlan-form-tab-input-group auth-key-management"
  >
    <h3 v-if="config.label" class="wlan-form-tab-input-group-heading">
      {{ config.label }}
    </h3>

    <!-- ! информационные блоки -->
    <div v-if="isWpa2WarningCaptionVisible"
      class="wlan-form-warning-caption alert alert-warning"
    >
      <p class="caption-heading">{{ $t('wlan.tabText.warningCaption.authentication-key-management.wpa2.1') }}</p>
      <ul class="caption-list">
        <li v-for="(caption, index) in sectionWarningCaptionWPA2"
          :key="index"
        >
          {{ caption }}
        </li>
      </ul>
    </div>
    <div v-if="isWpa2Wpa3WarningCaptionVisible"
      class="wlan-form-warning-caption alert alert-warning"
    >
      <p class="caption-heading">{{ $t('wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.1') }}</p>
      <p class="caption-heading">{{ $t('wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.2') }}</p>
      <ul class="caption-list">
        <li v-for="(caption, index) in sectionWarningCaptionWPA2WPA3.firstBlock"
          :key="index"
        >
          {{ caption }}
        </li>
      </ul>
      <p class="caption-heading">{{ $t('wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.6') }}</p>
      <ul class="caption-list">
        <li v-for="(caption, index) in sectionWarningCaptionWPA2WPA3.secondBlock"
          :key="index"
        >
          {{ caption }}
        </li>
      </ul>
    </div>
    <div v-if="isWpa3WarningCaptionVisible"
      class="wlan-form-warning-caption alert alert-warning"
    >
      <p class="caption-heading">{{ $t('wlan.tabText.warningCaption.authentication-key-management.wpa3.1') }}</p>
      <ul class="caption-list">
        <li v-for="(caption, index) in sectionWarningCaptionWPA3"
          :key="index"
        >
          {{ caption }}
        </li>
      </ul>
    </div>

    <!-- ! ошибки чекбоксов -->
    <span v-if="!isCheckboxCombinationsErrorMessageVisible && isCheckboxesErrors"
      class="authentication-key-management__checkbox-error"
    >
      {{ $t('wlan.inputError.authKeyManagementCheckbox.required') }}
    </span>
    <span v-if="isCheckboxCombinationsErrorMessageVisible && isCheckboxesErrors"
      class="authentication-key-management__checkbox-error"
    >
      {{ $t('wlan.inputError.authKeyManagementCheckbox.combination') }}
    </span>

    <template v-for="input in config.inputs"
      class="wlan-form-tab-input-group"

    >
      <template v-if="input.inputType === 'checkbox'">
        <template v-if="input.name === 'security.layer2.psk_enabled'">
          <WCheckbox v-if="isPskCheckboxVisible"
            data-vv-scope="authKeyManagementCheckboxes"
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.name"
            :checked="formState[input.name]"
            @change="handleCheckbox(input.name, $event)"
            :disabled="input.isDisabled"
            v-validate="pskCheckboxValidationRules"
            data-vv-validate-on="change"
            :data-vv-as="input.label"
            :errorText="''"
          />
        </template>
        <template v-else-if="input.name === 'security.layer2.psk_256_enabled'">
          <WCheckbox v-if="isPsk256CheckboxVisible"
            data-vv-scope="authKeyManagementCheckboxes"
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.name"
            :checked="formState[input.name]"
            @change="handleCheckbox(input.name, $event)"
            :disabled="input.isDisabled"
            v-validate="psk256CheckboxValidationRules"
            data-vv-validate-on="change"
            :data-vv-as="input.label"
            :errorText="''"
          />
        </template>
        <template v-else-if="input.name === 'security.layer2.param_8021x_enabled'">
          <WCheckbox v-if="isParam8021xCheckboxVisible"
            data-vv-scope="authKeyManagementCheckboxes"
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.name"
            :checked="formState[input.name]"
            @change="handleCheckbox(input.name, $event)"
            :disabled="input.isDisabled"
            v-validate="param8021xCheckboxValidationRules"
            data-vv-validate-on="change"
            :data-vv-as="input.label"
            :errorText="''"
          />
        </template>
        <template v-else-if="input.name === 'security.layer2.param_8021x_256_enabled'">
          <WCheckbox v-if="isParam8021x256CheckboxVisible"
            data-vv-scope="authKeyManagementCheckboxes"
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.name"
            :checked="formState[input.name]"
            @change="handleCheckbox(input.name, $event)"
            :disabled="input.isDisabled"
            v-validate="param8021x256CheckboxValidationRules"
            data-vv-validate-on="change"
            :data-vv-as="input.label"
            :errorText="''"
          />
        </template>
        <template v-else-if="input.name === 'security.layer2.sae_enabled'">
          <WCheckbox v-if="isSaeCheckboxVisible"
            data-vv-scope="authKeyManagementCheckboxes"
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.name"
            :checked="formState[input.name]"
            @change="handleCheckbox(input.name, $event)"
            :disabled="input.isDisabled"
            v-validate="saeCheckboxValidationRules"
            data-vv-validate-on="change"
            :data-vv-as="input.label"
            :errorText="''"
          />
        </template>
        <template v-else-if="input.name === 'security.layer2.ft_sae_enabled'">
          <WCheckbox v-if="isFtSaeCheckboxVisible"
            data-vv-scope="authKeyManagementCheckboxes"
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.name"
            :checked="formState[input.name]"
            @change="handleCheckbox(input.name, $event)"
            :disabled="input.isDisabled"
            v-validate="ftSaeCheckboxValidationRules"
            data-vv-validate-on="change"
            :data-vv-as="input.label"
            :errorText="''"
          />
        </template>
        <template v-else-if="input.name === 'security.layer2.ft_8021x_enabled'">
          <WCheckbox v-if="isFt8021xCheckboxVisible"
            data-vv-scope="authKeyManagementCheckboxes"
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.name"
            :checked="formState[input.name]"
            @change="handleCheckbox(input.name, $event)"
            :disabled="input.isDisabled"
            v-validate="ft8021xCheckboxValidationRules"
            data-vv-validate-on="change"
            :data-vv-as="input.label"
            :errorText="''"
          />
        </template>
        <template v-else-if="input.name === 'security.layer2.ft_psk_enabled'">
          <WCheckbox v-if="isFtPskCheckboxVisible"
            data-vv-scope="authKeyManagementCheckboxes"
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.name"
            :checked="formState[input.name]"
            @change="handleCheckbox(input.name, $event)"
            :disabled="input.isDisabled"
            v-validate="ftPskCheckboxValidationRules"
            data-vv-validate-on="change"
            :data-vv-as="input.label"
            :errorText="''"
          />
        </template>
        <template v-else-if="input.name === 'security.layer2.suite_b_192_enabled'">
          <WCheckbox v-if="isSuiteb192CheckboxVisible"
            data-vv-scope="authKeyManagementCheckboxes"
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.name"
            :checked="formState[input.name]"
            @change="handleCheckbox(input.name, $event)"
            :disabled="input.isDisabled"
            v-validate="suiteb192CheckboxValidationRules"
            data-vv-validate-on="change"
            :data-vv-as="input.label"
            :errorText="''"
          />
        </template>
        <template v-else-if="input.name === 'security.layer2.owe_enabled'">
          <WCheckbox v-if="isOweCheckboxVisible"
            data-vv-scope="authKeyManagementCheckboxes"
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.name"
            :checked="formState[input.name]"
            @change="handleCheckbox(input.name, $event)"
            :disabled="input.isDisabled"
            v-validate="oweCheckboxValidationRules"
            data-vv-validate-on="change"
            :data-vv-as="input.label"
            :errorText="''"
          />
        </template>
        <WCheckbox v-else
          :label="input.label"
          :key="input.id"
          :name="input.name"
          :id="input.name"
          :checked="formState[input.name]"
          @change="handleCheckbox(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="change"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else-if="input.name === 'security.layer2.psk_format'">
        <WSelect
          v-if="isPskFormatVisible"
          data-vv-scope="psk_format"
          :key="input.id"
          withInitialOption
          :options="input.selectOptions"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          @input="handleSelect(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else-if="input.name === 'security.layer2.psk_type'">
        <WSelect
          v-if="isPskTypeVisible"
          data-vv-scope="psk_type"
          :key="input.id"
          withInitialOption
          :options="input.selectOptions"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          @input="handleSelect(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else-if="input.name === 'security.layer2.psk_key'">
        <WInput
          v-if="isPskKeyVisible"
          data-vv-scope="psk_key"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules ? input.validateRules + pskKeyValidationRules : pskKeyValidationRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
      <template v-else-if="input.name === 'security.layer2.owe_wlan_id'">
        <WInput
          v-if="isOweWlanIdVisible"
          data-vv-scope="owe_wlan_id"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        >
          <template #icon-right>
            <v-popover
              v-if="currentSecurityType === 'wpa3'"
              trigger="hover"
              placement="top-center"
              popoverClass="wlan-form-popover"
              :popperOptions="{
                modifiers: {
                  preventOverflow: {
                    boundariesElement: 'window'
                  }
                }
              }">
              <i class="fa fa-info-circle wlan-form-info-icon" />
              <template slot="popover">
                <span>{{ $t('wlan.inputInfo.owe_wlan_id.wpa3') }}</span>
              </template>
            </v-popover>
          </template>
        </WInput>
      </template>
      <template v-else-if="input.name === 'security.layer2.sae_anti_clogging_threshold'">
        <WInput
          v-if="isSaeAntiCloggingThresholdVisible"
          data-vv-scope="sae_anti_clogging_threshold"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
      <template v-else-if="input.name === 'security.layer2.sae_max_retries'">
        <WInput
          v-if="isSaeMaxRetriesVisible"
          data-vv-scope="sae_max_retries"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
      <template v-else-if="input.name === 'security.layer2.sae_password_element'">
        <WSelect
          v-if="isSaePasswordElementVisible"
          data-vv-scope="sae_password_element"
          :key="input.id"
          withInitialOption
          :options="input.selectOptions"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          @input="handleSelect(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else>
        <WInput
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
    </template>
  </div>
  <!-- При выборе Тип безопасности Открытая, должен отображаться только OWE и без заголовка -->
  <div
    v-else
    class="wlan-form-tab-input-group auth-key-management open"
  >
    <template v-for="input in config.inputs"
      class="wlan-form-tab-input-group"
    >
      <template v-if="input.name === 'security.layer2.owe_wlan_id'">
        <WInput
          v-if="isOweWlanIdVisible_open"
          data-vv-scope="owe_wlan_id"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        >
          <template #icon-right>
            <v-popover
              v-if="currentSecurityType === 'open'"
              trigger="hover"
              placement="top-center"
              popoverClass="wlan-form-popover"
              :popperOptions="{
                modifiers: {
                  preventOverflow: {
                    boundariesElement: 'window'
                  }
                }
              }">
              <i class="fa fa-info-circle wlan-form-info-icon" />
              <template slot="popover">
                <span>{{ $t('wlan.inputInfo.owe_wlan_id.open') }}</span>
              </template>
            </v-popover>
          </template>
        </WInput>
      </template>
    </template>
  </div>
</template>

<script>
/**
 *
 * компонент секции таба security - Authentication Key Management.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных секций(групп) инпутов в соответствии с конфигом
 * @component
 *
 */

import { authKeyManagementCheckboxesMixin } from './mixins';

/* eslint-disable camelcase */

export default {
  name: 'AuthenticationKeyManagementSection',
  inject: ['$validator'],
  components: {
  },
  mixins: [
    authKeyManagementCheckboxesMixin
  ],
  props: {
    config: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    isPskFormatVisible() {
      // Параметр должен быть доступен к настройке,
      // если активны следующие переключатели: PSK или PSK - SHA256 или FT + PSK или SAE, в противном случае должен быть скрыт.

      return this.arePskCheckboxesChecked;
    },
    isPskTypeVisible() {
      // Параметр должен быть доступен к настройке,
      // если активны следующие переключатели: PSK или PSK - SHA256 или FT + PSK или SAE, в противном случае должен быть скрыт.

      return this.arePskCheckboxesChecked;
    },
    isPskKeyVisible() {
      // Параметр должен быть доступен к настройке,
      // если активны следующие переключатели: PSK или PSK- SHA256 или FT + PSK или SAE, в противном случае должен быть скрыт.

      return this.arePskCheckboxesChecked;
    },
    isOweWlanIdVisible() {
      if (this.currentSecurityType === 'wpa3' && this.formState['security.layer2.owe_enabled']) {
        return true;
      }
      return false;
    },
    // для open
    isOweWlanIdVisible_open() {
      if (this.currentSecurityType === 'open') {
        return true;
      }
      return false;
    },
    isSaeAntiCloggingThresholdVisible() {
      // Параметр должнен быть доступен к настройке,
      // если активны следующие переключатели: SAE или SAE + FT, в противном случае должен быть скрыт.

      return this.areSaeCheckboxesChecked;
    },
    isSaeMaxRetriesVisible() {
      // Параметр должнен быть доступен к настройке,
      // если если активны следующие переключатели: SAE или SAE + FT, в противном случае должен быть скрыт.
      return this.areSaeCheckboxesChecked;
    },
    isSaePasswordElementVisible() {
      // Параметр должнен быть доступен к настройке,
      // если если активны следующие переключатели: SAE или SAE + FT, в противном случае должен быть скрыт.
      return this.areSaeCheckboxesChecked;
    },
    currentSecurityType() {
      return this.formState['security.layer2.security_type'];
    },
    isFastRoaming80211rEnabled() {
      return this.formState['security.layer2.fast_roaming_enabled'];
    },
    currentPskTypeValue() {
      return this.formState['security.layer2.psk_type'];
    },
    currentPskFormatValue() {
      return this.formState['security.layer2.psk_format'];
    },
    /*
    * 1. Если Формат PSK выбрано ASCII и Тип PSK выбрано Открытый,
    *   то поле для заполнения принимает от 8 до 63 знаков(символы от 0 до 9, от a до z и от A до Z)
    * 2. Если Формат PSK выбрано HEX и Тип PSK выбрано Открытый,
    *   то в поле для заполнения необходимо ввести 64 символа в формате HEX(символы от 0 до 9, от a до f, от A до F)
    * 3. Если Тип PSK выбрано Зашифрованный,
    *   то в поле для ввода необходимо ввести любую комбинацию
    *   ранее было: в поле для ввода необходимо внести зашифрованную последовательность, которая должна начинаться с символов $6$
    *   и применялся валидатор pskKeyEncryptedFormatMask
    */
    pskKeyValidationRules() {
      if (this.currentPskFormatValue === 'ascii' && this.currentPskTypeValue === 'open') {
        return 'pskKeyASCIIFormatMask';
      }

      if (this.currentPskFormatValue === 'hex' && this.currentPskTypeValue === 'open') {
        return 'pskKeyHEXFormatMask';
      }

      if (this.currentPskTypeValue === 'encrypted') {
        return '';
      }

      return '';
    },
    isWpa2WarningCaptionVisible() {
      return this.currentSecurityType === 'wpa2' && !this.isCheckboxCombinationValid('wpa2');
    },
    isWpa2Wpa3WarningCaptionVisible() {
      return this.currentSecurityType === 'wpa2+wpa3' && !this.isCheckboxCombinationValid('wpa2+wpa3');
    },
    isWpa3WarningCaptionVisible() {
      return this.currentSecurityType === 'wpa3' && !this.isCheckboxCombinationValid('wpa3');
    },
    sectionWarningCaptionWPA2() {
      return [
        this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa2.2'),
        this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa2.3'),
        this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa2.4')
      ];
    },
    sectionWarningCaptionWPA2WPA3() {
      return {
        firstBlock: [
          this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.3'),
          this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.4'),
          this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.5')
        ],
        secondBlock: [
          this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.7'),
          this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.8'),
          this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.9')
        ]
      };
    },
    sectionWarningCaptionWPA3() {
      return [
        this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa3.2'),
        this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa3.3'),
        this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa3.4'),
        this.$t('wlan.tabText.warningCaption.authentication-key-management.wpa3.5')
      ];
    }
  },
  watch: {
    formState: {
      handler(newVal, oldVal) {
        const new_psk_type = newVal['security.layer2.psk_type'];
        const old_psk_type = oldVal['security.layer2.psk_type'];

        const new_psk_format = newVal['security.layer2.psk_format'];
        const old_psk_format = oldVal['security.layer2.psk_format'];

        const isNew_psk_type = new_psk_type !== old_psk_type;
        const isNew_psk_format = new_psk_format !== old_psk_format;

        if (isNew_psk_type || isNew_psk_format) {
          this.handleResetPsk_key();
        }
      }
    },
    isPskFormatVisible(newVal) {
      // при отключении psk_format - сброс значений и ошибок валидации в дефолтное значение
      if (newVal === false) {
        this.handleResetPsk_format();
      }
    },
    isPskTypeVisible(newVal) {
      // при отключении psk_type - сброс значений и ошибок валидации в дефолтное значение
      if (newVal === false) {
        this.handleResetPsk_type();
      }
    },
    isPskKeyVisible(newVal) {
      // при отключении psk_key - сброс значений и ошибок валидации в дефолтное значение
      if (newVal === false) {
        this.handleResetPsk_key();
      }
    },
    isSaeAntiCloggingThresholdVisible(newVal) {
      // при отключении sae_anti_clogging_threshold - сброс значений и ошибок валидации в дефолтное значение
      if (newVal === false) {
        this.handleResetSae_anti_clogging_threshold();
      }
    },
    isSaeMaxRetriesVisible(newVal) {
      // при отключении sae_max_retries - сброс значений и ошибок валидации в дефолтное значение
      if (newVal === false) {
        this.handleResetSae_max_retries();
      }
    },
    isSaePasswordElementVisible(newVal) {
      // при отключении sae_password_element - сброс значений и ошибок валидации в дефолтное значение
      if (newVal === false) {
        this.handleResetSae_password_element();
      }
    },
    isOweWlanIdVisible(newVal) {
      // при отключении owe_wlan_id - сброс значений и ошибок валидации в дефолтное значение
      if (newVal === false) {
        this.handleResetOweWlanId();
      }
    },
    isOweWlanIdVisible_open(newVal) {
      // при security_type === 'open' при отключении owe_wlan_id - сброс значений и ошибок валидации в дефолтное значение
      if (newVal === false) {
        this.handleResetOweWlanId();
      }
    }
  },
  methods: {
    handleFormSectionInputValue({ inputName, value }) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleCheckbox(inputName, event) {
      const isChecked = event.target.checked;
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleSelect(inputName, value) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleResetPsk_format() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.psk_format',
        value: 'ascii'
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'psk_format',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetPsk_type() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.psk_type',
        value: 'open'
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'psk_type',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetPsk_key() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.psk_key',
        value: ''
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'psk_key',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetSae_anti_clogging_threshold() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.sae_anti_clogging_threshold',
        value: 1500
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'sae_anti_clogging_threshold',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetSae_max_retries() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.sae_max_retries',
        value: 5
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'sae_max_retries',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetSae_password_element() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.sae_password_element',
        value: 'hnp'
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'sae_password_element',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetOweWlanId() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.owe_wlan_id',
        value: 0
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'owe_wlan_id',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    }
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.wlan-form-tab-input-group.auth-key-management {
  grid-area: authKeys;
}

.wlan-form-tab-input-group.auth-key-management.open {
  grid-area: p80211w;
}

.authentication-key-management__checkbox-error {
  color: var(--brand-danger);
  border-color: var(--brand-danger);
  display: block;
  font-size: 0.85rem;
}

.caption-heading {
  margin: 8px 0;
}

.caption-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: decimal;
  padding-left: 16px;
  margin: 0;
}
</style>
