// Этот миксин используется в компонентах, где нужен список таймзон
// предоставляет метод для их загрузки из api
// и computed с самим списком для использования

import { createNamespacedHelpers } from 'vuex';
import { PREFFERED_TIMEZONES_LIST_ORDER, reorderTimeZonesListWithPrefferedList } from './utils';
import timeZonesListService from '@/services/timeZonesListService';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');
const timeZonesListMixin = {
  data() {
    return {
      timeZonesListFromAPI: []
    };
  },
  methods: {
    /**
     * Запрашивает список таймзон с бэка
     *
     * @param {Object} [options] - Дополнительные опции запроса.
     * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
     * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
     * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
     */
    async getTimeZonesFromAPI(options) {
      const fetchedtTimeZonesListFromAPI = await timeZonesListService.fetchTimeZones(options);
      if (fetchedtTimeZonesListFromAPI && Array.isArray(fetchedtTimeZonesListFromAPI)) {
        this.timeZonesListFromAPI = fetchedtTimeZonesListFromAPI;
      } else {
        // в случае если произошла какая-то ошибка при подгрузке данных и в fetchedtTimeZonesListFromAPI undefined
        // для того чтобы не ломался computed timeZonesList
        this.timeZonesListFromAPI = [];
      }
    },
    /**
     * Возвращает строчку для отображения таймзоны в списке с учетом локализации
     *
     * Для англиского языка перводов нет - показываем что прилетает с бэка чтобы показывать самые актуальные значения и не блыо хардкода для отображения
     * Для русского пытаемся пытаемся перевести, но берем надписи типа (UTC+01:00) из оригинала, прилетающего с бэка. Остальную часть строки переводим.
     * Иначе возможен кейс - поменяли на бэке в списке таймзон вот эту часть "UTC+01:00" на другую,
     * а в русском переводе она останется как есть и опять буду по сути захарждкоженые значеничя
     * (Владивосток кажется уже 2 или 3 раза меняли :)
     *
     * @param {object} timeZoneObject объект таймзоны
     * @returns {String} строка для отображения таймзоны в списке

     */
    getTimeZoneCaptionForDisplayingWith18N(timeZoneObject) {
      // Если русская локаль и есть русский текст (на всякий случай)
      if (this.isRuLocale && timeZoneObject.textru) {
        return timeZoneObject.textru;
      }
      // Для английской локали или если нет русского текста используем основной текст
      return timeZoneObject.text;
    }
  },
  computed: {
    ...localeMapGetters(['isRuLocale']),
    /**
     * Список таймзон для использования в различных местах
     * Модифицируется для единообразия отображения элементов списка таким же образом как и в визраде контроллера
     *
     * @returns {[]} массив с объектами таймзон
     */
    timeZonesList() {
      // фильтруем список так, чтобы в начале были российские и СНГ, потом все остальные таймзоны
      // (вязто из кода визарда контроллера для единообраия отображаемых элементов списков тут и в визарде)
      const reorderdTimeZonesList = reorderTimeZonesListWithPrefferedList(
        this.timeZonesListFromAPI,
        PREFFERED_TIMEZONES_LIST_ORDER
      );
      const reorderdFiltredAndModifiedTimeZonesList = reorderdTimeZonesList
        .filter((timezone) => {
          // эта фильтрация перенесена из визарда для единообраия отображаемых элементов списов тут и в визарде
          return Array.isArray(timezone.utc) && timezone.utc.length !== 0;
        })
        .map((timezone) => {
          // В ранее используемом  файле с таймзонами оффсет был в виде чисел (в том числе и дробных)
          // Преобразуем и тут чтобы нигде не поломалась логика при сравнениях оффсетов с использованием оператора строго равенства
          const offsetAsFloat = parseFloat(timezone.offset);
          // добавляем переводы для отображения таймзона в UI
          const nameWithI18NForDisplay = this.getTimeZoneCaptionForDisplayingWith18N(timezone);
          return { ...timezone, offset: offsetAsFloat, text: nameWithI18NForDisplay };
        });

      return reorderdFiltredAndModifiedTimeZonesList;
    }
  }
};

export default timeZonesListMixin;
