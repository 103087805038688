<template>
  <div class="wlan-form-tab">
    <div class="wlan-form-tab-content">
      <template v-for="(content, index) in tabContent">
        <!-- ! вкладка general -->
        <template v-if="content.name === 'general.ghzStatuses'">
          <GhzStatusesSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <!-- ! вкладка security -->
        <template v-else-if="content.name === 'security.layer2.security_type'">
          <SecurityTypeSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <template v-else-if="content.name === 'security.layer2.encryption_type'">
          <EncryptionTypeSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <template v-else-if="content.name === 'security.layer2.mab'">
          <MabSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <template v-else-if="content.name === 'security.layer2.80211r'">
          <Params80211rSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <template v-else-if="content.name === 'security.layer2.80211w'">
          <Params80211wSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <template v-else-if="content.name === 'security.layer2.authenticationKeyManagement'">
          <AuthenticationKeyManagementSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <template v-else-if="content.name === 'security.layer3.webAuthRule'">
          <WebAuthRuleSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <template v-else-if="content.name === 'security.aaa.radius'">
          <RadiusSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <!-- ! остальные инпуты без выделения в отдельные компоненты секций -->
        <template v-else>
          <div
            :class="['wlan-form-tab-input-group', content.withBorder && 'with-border']"
            :key="index"
          >
            <h3 v-if="content.label"
              class="wlan-form-tab-input-group-heading"
            >
              {{ content.label }}
            </h3>
            <template v-for="input in content.inputs">
              <template v-if="input.inputType === 'checkbox'">
                <WCheckbox
                  :key="input.id"
                  :label="input.label"
                  :name="input.name"
                  :id="input.name"
                  :checked="formState[input.name]"
                  @change="handleCheckbox(input.name, $event)"
                  :disabled="input.isDisabled"
                  v-validate="input.validateRules"
                  data-vv-validate-on="change"
                  :data-vv-as="input.label"
                  :errorText="errors.first(input.name)"
                />
              </template>
              <template v-else-if="input.inputType === 'radio'">
                <WRadio
                  :key="input.id"
                  :label="input.label"
                  :name="input.name"
                  :id="input.id"
                  :value="input.radioValue"
                  @input="handleRadio(input, $event)"
                  :checked="formState[input.name] === input.radioValue"
                />
              </template>
              <template v-else-if="input.inputType === 'select'">
                <WSelect
                  :key="input.id"
                  :options="input.selectOptions"
                  :label="input.label"
                  :name="input.name"
                  :id="input.name"
                  :value="formState[input.name]"
                  @input="handleSelect(input.name, $event)"
                  :disabled="input.isDisabled"
                  v-validate="input.validateRules"
                  data-vv-validate-on="input"
                  :data-vv-as="input.label"
                  :errorText="errors.first(input.name)"
                />
              </template>
              <template v-else>
                <WInput
                  data-vv-delay="500"
                  :key="input.id"
                  :type="input.inputType"
                  :label="input.label"
                  :value="formState[input.name]"
                  @input="handleInput(input, $event)"
                  :id="input.name"
                  :name="input.name"
                  :placeholder="input.placeholder"
                  :disabled="input.isDisabled"
                  v-validate="input.validateRules"
                  data-vv-validate-on="input"
                  :data-vv-as="input.label"
                  :errorText="errors.first(input.name)"
                  autocomplete="one-time-code"
                />
              </template>
            </template>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент таба WlanForm.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных инпутов в соответствии с конфигом
 * @component
 *
 */

import {
  GhzStatusesSection
} from './tabs/general';

import {
  SecurityTypeSection,
  EncryptionTypeSection,
  MabSection,
  Params80211rSection,
  Params80211wSection,
  AuthenticationKeyManagementSection
} from './tabs/security/layer2';

import {
  WebAuthRuleSection
} from './tabs/security/layer3';

import {
  RadiusSection
} from './tabs/security/aaa';

export default {
  name: 'WlanFormTab',
  inject: ['$validator'],
  components: {
    GhzStatusesSection,
    SecurityTypeSection,
    EncryptionTypeSection,
    MabSection,
    Params80211rSection,
    Params80211wSection,
    AuthenticationKeyManagementSection,
    WebAuthRuleSection,
    RadiusSection
  },
  props: {
    tabContent: {
      type: Array,
      required: true
    },
    formState: {
      type: Object,
      required: true
    },
    currentTabName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleCheckbox(inputName, event) {
      const isChecked = event.target.checked;
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleSelect(inputName, value) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleRadio(inputObj, value) {
      const { name } = inputObj;

      this.$emit('update:inputValue', { inputName: name, value });
    },
    handleFormSectionInputValue({ inputName, value }) {
      this.$emit('update:inputValue', { inputName, value });
    }
  }
};
</script>

<style lang="css" scoped>
.wlan-form-tab {
  height: 100%;
  max-height: 450px;
  display: flex;
  gap: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
}

/* стили для скроллбара и трека */
.wlan-form-tab::-webkit-scrollbar {
  width: 5px;
}

.wlan-form-tab::-webkit-scrollbar-track:vertical {
  border-radius: 8px;
  background-color: var(--gray-lighter);
}

.wlan-form-tab::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 3px;
  background: var(--brand-info);
}

.wlan-form-tab-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.wlan-form-tab-content.layer2{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px 32px;
  grid-template-areas:
    "security encryption"
    "mab mab"
    "p80211w p80211w"
    "p80211r p80211r"
    "authKeys authKeys";
}

.wlan-form-tab-content.advanced {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px 32px;
  grid-template-areas:
    "nasid .";
}

p {
  margin: 0;
}

</style>

<style lang="css">
.wlan-form-tab-input-group-heading {
  margin: 0;
  font-size: 16px;
}

.wlan-form-tab-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wlan-form-tab-input-group.with-border {
  border-left: 2px solid var(--brand-info);
  padding: 12px;
  border-radius: var(--border-radius);
}

.wlan-form-tab-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wlan-form-popover {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.wlan-form-info-icon {
  color: var(--brand-info);
  transition: var(--transition);
  cursor: pointer;
}

.wlan-form-info-icon:hover {
  color: var(--brand-primary);
}

.wlan-form-danger-icon {
  color: var(--brand-danger);
  transition: var(--transition);
  cursor: help;
}

.wlan-form-danger-icon:hover {
  opacity: .8;
}

.wlan-form-warning-caption {
  font-weight: 700;
}
</style>
