<template>
  <div class="row cpes-page">
    <div class="col-lg-12 d-flex justify-content-end mb-h">
      <div class="pl-1 filters-add">
        <WButton info
          v-if="!isOperator"
          @click="openCpeWizard"
        >
          {{ $t('aps.addCpe') }}
        </WButton>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="p-0 filter-block filters-wrap w-100">
        <div class="w-100">
          <div class="filters w-100">
            <div class="filters__search w-100">
              <div class="input-group input-group-sm filters__input">
                <span class="input-group-addon">
                  <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>
                  <i class="fa fa-search" v-else></i>
                </span>
                <input
                  id="search"
                  name="search"
                  :placeholder="$t('general.search')"
                  class="form-control"
                  v-model="searchValue"
                />
                <span class="clear-input text-muted">
                  <i class="fa fa-remove" v-if="searchValue && !searchSpinner" @click="resetFilterQuery"></i>
                </span>
              </div>



              <div class="input-group input-group-sm filters__input ml-1" v-if="areLocationsVisible">
                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>
                </span>
                <select
                  class="form-control form-control-sm filters__field"
                  ref="locationsSelect"
                  v-model="filters.company"
                  @change="changeCompany"
                  :disabled="muteCpeFilters || isSelectedDefaultLocationForRequests"
                >
                  <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
                  <option v-for="company in companys" :value="company" :key="company.name">
                    {{ company.name }}
                  </option>
                </select>
              </div>

<!--              <div-->
<!--                class="input-group input-group-sm filters__input ml-1"-->
<!--                v-if="this.filters.company === 'All companys'"-->
<!--              >-->
<!--                <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker" :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                <select-->
<!--                  class="form-control form-control-sm filters__field"-->
<!--                  ref="locationsSelect"-->
<!--                  v-model="filters.location"-->
<!--                  @change="this.refreshCpesListQuery"-->
<!--                  :disabled="muteCpeFilters"-->
<!--                >-->
<!--                  <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
<!--                  <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
<!--                    {{ location.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="input-group input-group-sm filters__input ml-1" v-else>-->
<!--                <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker" :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                <select-->
<!--                  class="form-control form-control-sm filters__field"-->
<!--                  ref="locationsSelect"-->
<!--                  v-model="filters.location"-->
<!--                  @change="this.refreshCpesListQuery"-->
<!--                  :disabled="muteCpeFilters"-->
<!--                >-->
<!--                  <option-->
<!--                    v-for="location in locationsObjbyIds(filters.company.locations)"-->
<!--                    :value="location.id"-->
<!--                    :key="location.id"-->
<!--                  >-->
<!--                    {{ location.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->


              <div
                v-if="this.filters.company === 'All companys'"
                v-show="areLocationsVisible"
                class="input-group-for-location-select-in-cpe--filters   ml-1">
                                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                <SelectComponentV3ForUseInLocationSelectors
                  :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                  :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                  v-if="showLocationFilter"
                  :class="{
            'select-location-in-cpe--filters': true
          }"
                  v-model="locationInFiltersData"
                  track-by="name"
                  option-id-name="id"
                  :no-options-found="$t('general.noResultForSearch')"
                  :async-function="requestLocations.bind(this)"
                  :placeholder="$t('portalStats.selectLocation')"
                  @select="selectLocationInFilters"
                >
                  <template #dropdown-start>
                    <!--            <span class="select__option select__option_clickable" @click="toggleWithChilds">-->
                    <!--              <Checkbox-component-->
                    <!--                class="w-100"-->
                    <!--                :disabled="isDisabledWithChilds"-->
                    <!--                name="with_childs"-->
                    <!--                :label="$t('portalStats.selectLocationWithChild')"-->
                    <!--                id="with_childs"-->
                    <!--                :checked="value.location.with_childs === true"-->
                    <!--                @click="toggleWithChilds"-->
                    <!--              />-->
                    <!--            </span>-->
                    <div v-if="showPreviouslySelectedLocationsInFilterBlock">
                      <span class="ml-h text-muted">{{$t('general.previouslySelected')}}</span>
                      <span
                        :key="location.id"
                        @click.stop.prevent="selectInPreviouslySelectedLocationsInFilter(location)"
                        v-for="location in previouslySelectedLocationsInFilter"
                        class="select__option select__option_clickable">
                        {{location.name}}
                      </span>
                      <div style="border-bottom: 0.1px solid lightgray" class="m-0"></div>
                      <span class="ml-h text-muted">{{$t('general.locations')}}</span>
                    </div>
                    <div class="ml-h mt-h"
                         :class="{ 'mt-1': !showPreviouslySelectedLocationsInFilterBlock }">
                      <Switch-component
                         v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                        @input="changeWithChildsInFilters"
                        :label="$t('general.withChild')"
                        v-model="filters.with_childs"
                        class=""
                      />
                    </div>
                  </template>
                </SelectComponentV3ForUseInLocationSelectors>
              </div>
              <div v-show="areLocationsVisible" class="input-group input-group-sm filters__input ml-1" v-else>
                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                <select
                  class="form-control form-control-sm filters__field"
                  ref="locationsSelect"
                  v-model="filters.location"
                  @change="this.refreshCpesListQuery"
                  :disabled="muteCpeFilters"
                >
                  <option
                    v-for="location in locationsObjbyIds(filters.company.locations)"
                    :value="location.id"
                    :key="location.id"
                  >
                    {{ location.name }}
                  </option>
                </select>
              </div>







              <!--              <div class="input-group input-group-sm filters__input ml-1">-->
              <!--                <span class="input-group-addon filters__field-icon">-->
              <!--                  <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>-->
              <!--                </span>-->
              <!--                <select-->
              <!--                  class="form-control form-control-sm filters__field"-->
              <!--                  ref="locationsSelect"-->
              <!--                  v-model="filters.company"-->
              <!--                  @change="this.refreshCpesListQuery"-->
              <!--                  :disabled="muteCpeFilters"-->
              <!--                >-->
              <!--                  <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>-->
              <!--                  <option v-for="company in companyList" :value="company" :key="company.name">-->
              <!--                    {{ company.name}}-->
              <!--                  </option>-->
              <!--                </select>-->
              <!--              </div>-->

              <div class="input-group input-group-sm filters__location ml-1">
                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-sitemap" :class="{ 'text-primary': filters.group !== 'No RRM filter' }"></i>
                </span>
                <select
                  class="form-control form-control-sm filters__field"
                  v-model="filters.group"
                  @change="refreshCpesListQuery"
                  :disabled="muteCpeFilters"
                >
                  <option :selected="true" :value="'No RRM filter'">{{ $t('RRM.allGroups') }}</option>
                  <option v-for="rrm in rrmList" :value="rrm.id" :key="rrm.id">{{ rrm.name }}</option>
                </select>
              </div>

              <div class="input-group input-group-sm filters__location ml-1">
                <span class="input-group-addon filters__field-icon">
                  <img
                    v-if="filters.models === 'All models'"
                    class="img img-responsive cpe-icon"
                    src="/static/img/router-icon.svg"
                    alt
                  />
                  <img v-else class="img img-responsive cpe-icon" src="/static/img/router-icon-blue.svg" alt />
                </span>
                <select
                  class="form-control form-control-sm filters__field"
                  v-model="filters.models"
                  @change="this.refreshCpesListQuery"
                  :disabled="muteCpeFilters"
                >
                  <option :selected="true" :value="'All models'">{{ $t('general.allModels') }}</option>
                  <option v-for="cpeModel in cpeModelsList" :value="cpeModel.id" :key="cpeModel.id">
                    {{ cpeModel.description }}
                  </option>
                </select>
              </div>

              <div class="input-group input-group-sm filters__location ml-1">
                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-tag" :class="{ 'text-primary': filters.tag !== 'All tags' }"></i>
                </span>
                <select
                  class="form-control form-control-sm filters__field"
                  v-model="filters.tag"
                  @change="refreshCpesListQuery"
                  :disabled="muteCpeFilters"
                >
                  <option :selected="true" :value="'All tags'">{{ $t('general.allTags') }}</option>
                  <option :selected="true" v-if="cpesTagsCurrentList.length" :value="''">
                    {{ $t('general.noTag') }}
                  </option>
                  <option v-for="tag in cpesTagsCurrentList" :value="tag" :key="tag">{{ tag }}</option>
                </select>
              </div>
              <!--              <div class="conn-disconn-sort-in-aps">-->
              <!--                <div class="input-group input-group-sm filters__location ml-1">-->
              <!--              <small>{{ $t('aps.sortByConnectionnOrDisconnectionTime') }}</small>-->
              <!--              </div>-->
              <!--              <div class="input-group input-group-sm filters__location ml-1">-->
              <!--                <span class="input-group-addon filters__field-icon">-->
              <!--                  <i-->
              <!--                    class="fa fa-sort-amount-desc text-primary icon-sort"-->
              <!--                    v-if="filters.connectionOrDisconnectionTimeFilterOrder === 'desc'"-->
              <!--                    @click="toggleSortModeForconnectionOrDisconnectionTimeFilter()"-->
              <!--                  ></i>-->
              <!--                  <i-->
              <!--                    class="fa fa-sort-amount-asc text-primary icon-sort"-->
              <!--                    v-if="filters.connectionOrDisconnectionTimeFilterOrder === 'asc'"-->
              <!--                    @click="toggleSortModeForconnectionOrDisconnectionTimeFilter()"-->
              <!--                  ></i>-->
              <!--                </span>-->
              <!--                  <select-->
              <!--                    class="form-control form-control-sm filters__field"-->
              <!--                    v-model="filters.connectionOrDisconnectionTimeFilter"-->
              <!--                    @change="refreshCpesListQuery"-->
              <!--                    :disabled="muteCpeFilters"-->
              <!--                  >-->
              <!--                    <option :selected="true" :value="'No Connection Or Disconnection Filter'">{{-->
              <!--                      $t('aps.sortByConnectionnOrDisconnectionTimeNoSort') }}-->
              <!--                    </option>-->
              <!--                    <option :value="'sortByFirstConnection'">{{ $t('aps.sortByFirstConnection') }}-->
              <!--                    </option>-->
              <!--                    <option :value="'sortByLastConnection'">{{ $t('aps.sortByLastConnection') }}-->
              <!--                    </option>-->
              <!--                    <option :value="'sortByLastDisconnection'">{{ $t('aps.sortByLastDisconnection')-->
              <!--                      }}-->
              <!--                    </option>-->
              <!--                  </select>-->
              <!--              </div>-->
              <!--            </div>-->
              <div>
                <!--                <div class="pl-1 filters-add">-->
                <!--                <button class="btn btn-info btn-md" @click="openCpeWizard" v-if="!isOperator">{{-->
                <!--                  $t('aps.addCpe') }}-->
                <!--                </button>-->
                <!--              </div>-->
              </div>
            </div>

            <!-- filters -->
<!--            <div class="filters__multiple filters__multiple-in-aps">-->
<!--              <div class="btn-group btn-group-sm float-left" data-toggle="button">-->
<!--                <label class="btn btn btn-outline-primary" :class="{ active: filters.connectedFilter === true }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="connectedFilter"-->
<!--                    @click="setFilter('connectedFilter', true)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.connected') }}-->
<!--                </label>-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.connectedFilter === false }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="connectedFilter"-->
<!--                    @click="setFilter('connectedFilter', false)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.notConnected') }}-->
<!--                </label>-->
<!--              </div>-->
<!--              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.activeFilter === true }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="activeFilter"-->
<!--                    @click="setFilter('activeFilter', true)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.active') }}-->
<!--                </label>-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.activeFilter === false }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="activeFilter"-->
<!--                    @click="setFilter('activeFilter', false)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.notActive') }}-->
<!--                </label>-->
<!--              </div>-->
<!--              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 1 }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="interfacesFilter"-->
<!--                    @click="setFilter('interfacesFilter', 1)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.oneBand') }}-->
<!--                </label>-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 2 }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="interfacesFilter"-->
<!--                    @click="setFilter('interfacesFilter', 2)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.dualBand') }}-->
<!--                </label>-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 3 }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="interfacesFilter"-->
<!--                    @click="setFilter('interfacesFilter', 3)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.wired') }}-->
<!--                </label>-->
<!--              </div>-->
<!--              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.radarFilter }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="interfacesFilter"-->
<!--                    @click="setFilter('radarFilter', true)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.radar') }}-->
<!--                </label>-->
<!--              </div>-->
<!--              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">-->
<!--                <label class="btn btn-outline-primary"-->
<!--                       :id="filters.DHCPCaptureFilter ? 'DHCPCaptureFilter-button-when-filter-enabled' : ''"-->
<!--                       :class="{ active: filters.DHCPCaptureFilter }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="DHCPCaptureFilter"-->
<!--                    @click="toggleDHCPCaptureFilter"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.DHCPCaptureFilter') }}-->
<!--                </label>-->
<!--                <transition name="dhcpcapturefiltertransitioninaps">-->
<!--                  <div class="btn-group btn-group-sm m-0" data-toggle="button"-->
<!--                       v-if="filters.DHCPCaptureFilter">-->
<!--                    <label class="btn btn-outline-primary"-->
<!--                           id="DHCPCaptureFilter-on-button"-->
<!--                           :class="{ active: filters.DHCPCaptureFilter === 'enable' }">-->
<!--                      <input-->
<!--                        type="radio"-->
<!--                        name="DHCPCaptureFilterEnabled"-->
<!--                        @click="setDHCPCaptureFilter('enable')"-->
<!--                        :disabled="muteCpeFilters"-->
<!--                      />-->
<!--                      {{ $t('aps.DHCPCaptureFilterEnabledShort') }}-->
<!--                    </label>-->
<!--                    <label class="btn btn-outline-primary"-->
<!--                           id="DHCPCaptureFilter-off-button"-->
<!--                           :class="{ active: filters.DHCPCaptureFilter === 'disable' }">-->
<!--                      <input-->
<!--                        type="radio"-->
<!--                        name="DHCPCaptureFilterTidsabled"-->
<!--                        @click="setDHCPCaptureFilter('disable')"-->
<!--                        :disabled="muteCpeFilters"-->
<!--                      />-->
<!--                      {{ $t('aps.DHCPCaptureFilterDisabledShort') }}-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </transition>-->
<!--              </div>-->


<!--              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.lastError === true }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="interfacesFilter"-->
<!--                    @click="setFilter('lastError', true)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.lastError') }}-->
<!--                </label>-->
<!--              </div>-->
<!--              <div class="btn-group btn-group-sm float-left" data-toggle="button">-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.updatingStatusCPEs === true }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="updatingFilter"-->
<!--                    @click="setFilter('updatingStatusCPEs', true)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.filterByUpdatingStatus') }}-->
<!--                </label>-->
<!--              </div>-->
<!--              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">-->
<!--                <label-->
<!--                  class="btn btn-outline-primary"-->
<!--                  :class="{ active: showingConnectionnOrDisconnectionFiltersBlock === true }"-->
<!--                >-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="connectionnOrDisconnectionFiltersBlock"-->
<!--                    @click="toggleConnectionnOrDisconnectionFiltersBlockShowing"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.sortByConnectionnOrDisconnectionTimeButton') }}-->
<!--                </label>-->
<!--              </div>-->
<!--            </div>-->

            <div class="filters__multiple filters__multiple-in-aps">
              <div class="btn-group btn-group-sm float-left" data-toggle="button">
                <label class="btn btn btn-outline-primary" :class="{ active: filters.connectedFilter === true }">
                  <input
                    type="radio"
                    name="connectedFilter"
                    @click="setFilter('connectedFilter', true)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.connected') }}
                </label>
                <label class="btn btn-outline-primary" :class="{ active: filters.connectedFilter === false }">
                  <input
                    type="radio"
                    name="connectedFilter"
                    @click="setFilter('connectedFilter', false)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.notConnected') }}
                </label>
              </div>
              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">
                <label class="btn btn-outline-primary" :class="{ active: filters.activeFilter === true }">
                  <input
                    type="radio"
                    name="activeFilter"
                    @click="setFilter('activeFilter', true)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.active') }}
                </label>
                <label class="btn btn-outline-primary" :class="{ active: filters.activeFilter === false }">
                  <input
                    type="radio"
                    name="activeFilter"
                    @click="setFilter('activeFilter', false)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.notActive') }}
                </label>
              </div>
              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">
                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 1 }">
                  <input
                    type="radio"
                    name="interfacesFilter"
                    @click="setFilter('interfacesFilter', 1)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.oneBand') }}
                </label>
                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 2 }">
                  <input
                    type="radio"
                    name="interfacesFilter"
                    @click="setFilter('interfacesFilter', 2)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.dualBand') }}
                </label>
                <label v-if="showWiredFilter" class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 3 }">
                  <input
                    type="radio"
                    name="interfacesFilter"
                    @click="setFilter('interfacesFilter', 3)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.wired') }}
                </label>
              </div>
              <div v-if="showWiFiTargetFilter" class="btn-group btn-group-sm float-left ml-1" data-toggle="button">
                <label class="btn btn-outline-primary" :class="{ active: filters.radarFilter }">
                  <input
                    type="radio"
                    name="interfacesFilter"
                    @click="setFilter('radarFilter', true)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.radar') }}
                </label>
              </div>
              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">
                <label class="btn btn-outline-primary"
                       :id="filters.DHCPCaptureFilter ? 'DHCPCaptureFilter-button-when-filter-enabled' : ''"
                       :class="{ active: filters.DHCPCaptureFilter }">
                  <input
                    type="radio"
                    name="DHCPCaptureFilter"
                    @click="toggleDHCPCaptureFilter"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.DHCPCaptureFilter') }}
                </label>
                <transition name="dhcpcapturefiltertransitioninaps">
                  <div class="btn-group btn-group-sm m-0" data-toggle="button"
                       v-if="filters.DHCPCaptureFilter">
                    <label class="btn btn-outline-primary"
                           id="DHCPCaptureFilter-on-button"
                           :class="{ active: filters.DHCPCaptureFilter === 'enable' }">
                      <input
                        type="radio"
                        name="DHCPCaptureFilterEnabled"
                        @click="setDHCPCaptureFilter('enable')"
                        :disabled="muteCpeFilters"
                      />
                      {{ $t('aps.DHCPCaptureFilterEnabledShort') }}
                    </label>
                    <label class="btn btn-outline-primary"
                           id="DHCPCaptureFilter-off-button"
                           :class="{ active: filters.DHCPCaptureFilter === 'disable' }">
                      <input
                        type="radio"
                        name="DHCPCaptureFilterTidsabled"
                        @click="setDHCPCaptureFilter('disable')"
                        :disabled="muteCpeFilters"
                      />
                      {{ $t('aps.DHCPCaptureFilterDisabledShort') }}
                    </label>
                  </div>
                </transition>
              </div>


              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">
                <label class="btn btn-outline-primary" :class="{ active: filters.lastError === true }">
                  <input
                    type="radio"
                    name="interfacesFilter"
                    @click="setFilter('lastError', true)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.lastError') }}
                </label>
              </div>
              <div class="btn-group btn-group-sm float-left" data-toggle="button">
                <label class="btn btn-outline-primary" :class="{ active: filters.updatingStatusCPEs === true }">
                  <input
                    type="radio"
                    name="updatingFilter"
                    @click="setFilter('updatingStatusCPEs', true)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.filterByUpdatingStatus') }}
                </label>
              </div>
              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">
                <label
                  class="btn btn-outline-primary"
                  :class="{ active: showingConnectionnOrDisconnectionFiltersBlock === true }"
                >
                  <input
                    type="radio"
                    name="connectionnOrDisconnectionFiltersBlock"
                    @click="toggleConnectionnOrDisconnectionFiltersBlockShowing"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.sortByConnectionnOrDisconnectionTimeButton') }}
                </label>
              </div>
            </div>

            <transition name="fadeinconndisconnfiltersinaps">
              <div v-if="showingConnectionnOrDisconnectionFiltersBlock" class="filters__search filters-in-aps">
                <div class="conn-disconn-sort-in-aps">
                  <div class="input-group input-group-sm filters__location">
                    <small>{{ $t('aps.sortByConnectionnOrDisconnectionTime') }}</small>
                  </div>
                  <div class="input-group input-group-sm filters__location">
                    <span class="input-group-addon filters__field-icon">
                      <i
                        class="fa fa-sort-amount-desc text-primary icon-sort"
                        v-if="filters.connectionOrDisconnectionTimeFilterOrder === 'desc'"
                        @click="toggleSortModeForconnectionOrDisconnectionTimeFilter()"
                      ></i>
                      <i
                        class="fa fa-sort-amount-asc text-primary icon-sort"
                        v-if="filters.connectionOrDisconnectionTimeFilterOrder === 'asc'"
                        @click="toggleSortModeForconnectionOrDisconnectionTimeFilter()"
                      ></i>
                    </span>
                    <select
                      class="form-control form-control-sm filters__field"
                      v-model="filters.connectionOrDisconnectionTimeFilter"
                      @change="connectionOrDisconnectionTimeFilterSelectorChanged"
                      :disabled="muteCpeFilters"
                    >
                      <option :selected="true" :value="'No Connection Or Disconnection Filter'">
                        {{ $t('aps.sortByConnectionnOrDisconnectionTimeNoSort') }}
                      </option>
                      <option :value="'sortByFirstConnection'">{{ $t('aps.sortByFirstConnection') }}</option>
                      <option :value="'sortByLastConnection'">{{ $t('aps.sortByLastConnection') }}</option>
                      <option :value="'sortByLastDisconnection'">{{ $t('aps.sortByLastDisconnection') }}</option>
                    </select>
                  </div>
                </div>
                <transition name="fadeinconndisconnfiltersinaps">
                  <div
                    class="conn-disconn-sort-in-aps"
                    v-if="showingConnectionnOrDisconnectionTimeDateRangeFilterButton"
                  >
                    <div class="input-group input-group-sm filters__location ml-1">
                      <small>{{ $t('aps.sortByConnectionnOrDisconnectionTimeDateRangeFilterCaption') }}</small>
                    </div>
                    <div class="ml-1 d-flex align-items-end justify-content-center">
                      <Switch-component
                        v-model="filters.connectionOrDisconnectionTimeFilterByDateRange"
                        :disabled="muteCpeFilters"
                        class="switcher-conn-disconn-date-range-filter-in-aps"
                        @change="toggleConnectionnOrDisconnectionFilterByDateRange"
                      />
                    </div>
                    <!--                  <div class="btn-group btn-group-sm  ml-1" data-toggle="button">-->
                    <!--                    <label class="btn btn-outline-primary mb-0"-->
                    <!--                           :class="{ active: filters.connectionOrDisconnectionTimeFilterByDateRange === true }">-->
                    <!--                      <input-->
                    <!--                        type="radio"-->
                    <!--                        name="connectionnOrDisconnectionFiltersBlock"-->
                    <!--                        @click="toggleConnectionnOrDisconnectionFilterByDateRange"-->
                    <!--                        :disabled="muteCpeFilters"-->
                    <!--                      />-->
                    <!--                      {{$t('aps.sortByConnectionnOrDisconnectionTimeDateRangeFilterButton')}}-->
                    <!--                    </label>-->
                    <!--                  </div>-->
                  </div>
                </transition>
                <transition name="fadeinconndisconnfiltersinaps">
                  <div class="conn-disconn-sort-in-aps" v-if="filters.connectionOrDisconnectionTimeFilterByDateRange">
                    <div class="input-group input-group-sm filters__location ml-1">
                      <small>{{ $t('aps.sortByConnectionnOrDisconnectionTimeDateRangeCaption') }}</small>
                    </div>
                    <div
                      class="btn-group btn-group-sm ml-1 datepicker-conn-disconn-date-range-filter-in-aps"
                      data-toggle="button"
                    >
                      <date-picker
                        v-model="filters.connectionOrDisconnectionTimeFilterDateRange"
                        class="float-right"
                        :onChange="handleDateChangeForConnectionDisconnectionFilter"
                      />
                    </div>
                  </div>
                </transition>
              </div>
            </transition>
            <div v-if="isFiltersActive" class="mb-1">
              <WButton secondary outline sm @click="resetAllFilters">
                <i class="fa fa-close"></i>
                {{ $t('general.resetAllFilters') }}
              </WButton>
            </div>
          </div>
        </div>

<!--        <div class="pl-1 filters-add">-->
<!--          <button class="btn btn-info btn-md" @click="openCpeWizard" v-if="!isOperator">{{ $t('aps.addCpe') }}</button>-->
<!--        </div>-->
      </div>
    </div>
    <div v-if="showGoBackToCpeStatisticButton" class=" col-lg-12 mt-1 mb-1 pr-q pl-q">
          <div class="col-lg-12">
            <div class="float-right">
              <WButton primary @click="goBackToCpeStatistic">
                {{ $t('aps.goBackToCpeStatistic') }}
              </WButton>
            </div>
          </div>
        </div>
        <div v-if="showGoBackToDashboardButton" class=" col-lg-12 mt-1 mb-1 pr-q pl-q">
          <div class="col-lg-12">
            <div class="float-right">
              <WButton primary @click="goBackToDashboard">
                {{ $t('dashboard.goBackToDashboard') }}
              </WButton>
            </div>
          </div>
        </div>
    <div v-if="showGoBackToClientsButton" class=" col-lg-12 mt-1 mb-1 pr-q pl-q">
          <div class="col-lg-12">
            <div class="float-right">
              <WButton primary @click="goBackToClients">
                {{ $t('aps.goBackToClients') }}
              </WButton>
            </div>
          </div>
        </div>
    <div class="col-lg-12">
      <div
        class="cpes"
        :class="{ 'edit-visible': editVisible || selectedCpesId.length, 'interface-visible': isCpeHasIntefacesView }"
      >
        <div class="cpes-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <!--                  <div class="loader loader-backdrop loader-backdrop-aps" v-if="isCpesLoading"></div>-->
                  <div v-if="isCpesLoading"><Loader-spinner /></div>
                  <div v-if="(showGoBackToCpeStatisticButton || showGoBackToDashboardButton || showGoBackToClientsButton) && isLoadingAll"><Loader-spinner /></div>
                  <div class="card-block">
                    <ApsTable
                      :items="cpesList"
                      :selectedRowApIds="selectedCpesId"
                      :activeRowApId="cpeSelectedId"
                      :isDataLoading="false"
                      :isDisabled="isUpdating"
                      :isOneColMode="Boolean(editVisible)"
                      :isSelectionRowsModeEnabled="!Boolean(isOperator)"
                      @on-selected-rows-change="handleSelectAPRow"
                      @on-cell-click="openEditAPPanel"
                      @go-to-events="goToEvents"
                      @on-tag-click="searchTag"
                      @on-delete-ap="deleteAP"
                    >
                      <template v-slot:table-menu>
                        <div class="d-flex align-items-center justify-content-between">
                          <div>
                            <span class="text-muted" v-if="!isCpesLoading">
                              {{ $t('aps.tableView') }} {{ filterItems(cpesList).length }} {{ $t('aps.tableItemsOf') }}
                              {{ totalResult }}
                              <span v-if="selectedCpesId.length">
                                ,&nbsp;{{$t('general.selectedForTables')}}:&nbsp;{{ selectedCpesId.length }}
                              </span>
                            </span>
                          </div>
                          <div class="d-flex align-items-center">
                            <div>
                              <WButton secondary outline v-if="!isDisable" @click="showSetCpeConfigModal"
                                v-tooltip.top-center="$t('aps.cpeConfigSetButtonTooltipText')">
                                {{ $t('aps.cpeConfigSetButtonText') }}
                              </WButton>
                            </div>
                            <TableDataDownloader
                              v-if="cpesList.length && showDownloadButton"
                              class="ml-h"
                              :data="formatToCSV(cpesList)"
                              :data-for-excel="convertToNewExcelExportModuleFormat(formatToCSV(cpesList), $t('aps.cpesList'))"
                              :filename="getFileNameForDownloadFiles($t('downloadFilesNames.cpes'))"
                              :availableReports="{
                                csv: true,
                                xlsx: {
                                  enabled: true,
                                  autoColumnsWidth: true
                                },
                                pdf: {
                                  enabled: true,
                                  columnsWidth: downloadPdfColumnWidths(),
                                  pageSize: downloadPdfColumnWidths() !== undefined && 'A3'
                                }
                              }" />
                          </div>
                        </div>
                      </template>
                      <template v-slot:table-actions-bottom>
                        <div v-show="canLoadMore && !isCpesLoading && cpesList.length && !Boolean(editVisible)"
                          class="card-block text-center p-0"
                        >
                          <WButton link @click="loadMore">
                            {{ $t('aps.tableLoad') }}
                            <span v-if="limit < totalResult - cpesList.length">
                              {{ limit }}
                            </span>
                            <span v-else>
                              {{ totalResult - cpesList.length }}
                            </span>
                            {{ $t('aps.tableOutOf') }} {{ totalResult - cpesList.length }}
                          </WButton>
                        </div>
                      </template>
                    </ApsTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cpes-edit" v-if="editVisible && cpeEditCurrent && selectedCpesId.length == 0">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card mb-0">
                <!--                <div class="loader loader-backdrop loader-backdrop-aps" v-if="isCpesLoading"></div>-->
                <div v-if="isCpesLoading"><Loader-spinner /></div>
                <div v-if="(showGoBackToCpeStatisticButton || showGoBackToDashboardButton || showGoBackToClientsButton) && isLoadingAll"><Loader-spinner /></div>
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="edit-header">
                        <div class="edit-header__info mb-1 mt-1">
                          <div class="edit-header__name">
                            <span
                              class="badge-cpe badge-status badge-status--cpe ml-h h4"
                              :class="{
                                'badge-status__connected': cpeEditCurrent.connected,
                                'badge-status__disconnected': !cpeEditCurrent.connected
                              }"
                            >
                              <span v-if="cpeEditCurrent.name">{{ cpeEditCurrent.name }}</span>
                              <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                            </span>

                            <span class="ml-1 mb-0 cpe-edit-name">
                              <span
                                v-if="isBusy(cpeEditCurrent.id, cpeEditCurrent.config_status)"
                                class="badge badge-info text-sm"
                              >
                                {{
                                  cpesIdsOperations.includes(cpeEditCurrent.id)
                                    ? $t('aps.updating')
                                    : $t(`aps.${cpeEditCurrent.config_status}`)
                                }}
                              </span>

                              <span
                                v-else-if="cpeEditCurrent.config_status === 'ok'"
                                class="badge badge-success text-sm"
                              >
                                {{ $t('aps.ok') }}
                              </span>
                              <span
                                v-else-if="cpeEditCurrent.config_status === 'offline'"
                                class="badge badge-default text-sm"
                              >
                                {{ $t('aps.offline') }}
                              </span>
                              <span
                                v-else-if="cpeEditCurrent.config_status === 'empty'"
                                class="badge badge-default text-sm"
                              >
                                {{ $t('aps.empty') }}
                              </span>
                              <span
                                v-else-if="cpeEditCurrent.config_status === 'join'"
                                class="badge badge-info text-sm"
                              >
                                {{ $t('aps.join') }}
                              </span>

                              <span v-else class="badge badge-default text-sm">{{ cpeEditCurrent.config_status }}</span>
                              <span style="position: absolute; bottom: 1px"
                                    class="badge badge-success text-sm ml-1"
                                    v-if="checkCPEWPA3Capability(cpeEditCurrent)">
                                      {{$t('aps.WPA3Capable')}}
                              </span>
                            </span>
                          </div>
                          <div class="edit-header__tags">
                            <span
                              v-for="(tag, index) in updatedCpe.tags"
                              v-if="updatedCpe.tags.length"
                              :key="tag"
                              :class="{ 'tag--disable': isDisable }"
                              @click.prevent="removeTag"
                              :data-index="index"
                              class="tag tag--item"
                            >
                              <i class="fa fa-tag"></i>
                              <i class="fa fa-remove"></i>
                              {{ tag }}
                            </span>
                            <span class="tag tag--default" v-if="!updatedCpe.tags.length">
                              <i class="fa fa-tag"></i>
                              {{ $t('aps.noTags') }}
                            </span>
                          </div>
                        </div>

                        <div class="edit-header__buttons edit-header__buttons-in-cpes">
                          <div>
                            <WButton danger outline rounded sm
                              v-if="cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')"
                              @click="openRebootCpeAgentModal"
                              v-tooltip.top-center="{ content: $t('aps.restartCpeAgent') }"
                            >
                              <i class="fa fa-refresh"></i>
                            </WButton>
                            <WButton danger outline rounded sm
                              v-if="cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')"
                              @click="openRebootCpeRedundantModal"
                              v-tooltip.top-center="{ content: $t('aps.rebootCpeRedundant') }"
                            >
                              <i class="fa fa-history"></i>
                            </WButton>
                            <WButton danger outline rounded sm
                              v-if="cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating') && !isWLCProduct"
                              @click="openIPsecModal"
                              v-tooltip.top-center="{ content: $t('aps.IPsec') }"
                            >
                              <i class="fa fa-shield"></i>
                            </WButton>
                            <WButton danger outline rounded sm
                              v-if="cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')"
                              @click="openConfigModal"
                              v-tooltip.top-center="{ content: $t('aps.sendConfigCmd') }"
                            >
                              <i class="fa fa-code"></i>
                            </WButton>
                            <WButton danger outline rounded sm
                              v-if="cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')"
                              @click="openSetTimeUsingNTPModal"
                              v-tooltip.top-center="{ content: $t('aps.setTimeUsingNTP') }"
                            >
                              <i class="fa fa fa-clock-o"></i>
                            </WButton>
                            <!-- <WButton info outline rounded sm
                            customClass="mr-1"
                            v-if="cpeEditCurrent.connected && !isDisable"
                            @click="openRebootCpeModal"
                            v-tooltip.top-center="{ content: $t('aps.rebootCpe') }"
                          >
                            <i class="fa fa-refresh"></i>
                          </WButton> -->
                            <WButton info outline rounded sm
                              v-if="cpeEditCurrent.connected && !isDisable"
                              @click="openResetCpeModal"
                              v-tooltip.top-center="{ content: $t('aps.resetCpe') }"
                            >
                              <i class="fa fa-power-off"></i>
                            </WButton>
                            <!-- <WButton info outline rounded sm
                            customClass="mr-1"
                            v-if="cpeEditCurrent.connected && !isDisable && isCpeEditCurrentIpsecTunnelType === true"
                            @click="openFWUpgradeDialog"
                            v-tooltip.top-center="{ content: $t('aps.fwUpgrade') }"
                          >
                            <i class="fa fa-download"></i>
                            &lt;!&ndash;<span v-if="updatedCpe.state.firmware.has_update" class="badge badge-danger badge-pill badge-upgrade">!</span>&ndash;&gt;
                          </WButton> -->
                            <WButton info outline rounded sm
                              v-if="cpeEditCurrent.connected && !isDisable"
                              @click.stop.prevent="openFWUpgradeDialog"
                              v-tooltip.top-center="{ content: $t('aps.fwUpgrade') }"
                            >
                              <i class="fa fa-download"></i>
                              <!--<span v-if="updatedCpe.state.firmware.has_update" class="badge badge-danger badge-pill badge-upgrade">!</span>-->
                            </WButton>
                            <!--<button
                            type="button"
                            v-tooltip.top-center="{content: 'Get web and ssh access'}"
                            v-if="cpeEditCurrent.connected && !isDisable"
                            class="btn btn-outline-info btn-sm"
                            @click="getWebAccess"
                          >
                            <span :class="{ invisible: WebAccess }"> <i
                              class="fa fa-terminal"
                              @click="getWebAccess"
                            ></i></span>
                            <span
                              v-if="WebAccess"
                              class="loader loader&#45;&#45;mini"
                            ></span>
                          </button>-->
                            <WButton info outline rounded sm
                              v-if="cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')"
                              @click="openSyncTimeModal"
                              v-tooltip.top-center="{ content: $t('aps.syncTime') }"
                            >
                              <i class="fa fa fa-clock-o"></i>
                            </WButton>
                            <WButton success outline rounded sm
                              v-if="cpeEditCurrent.connected &&
                                cpeEditCurrent.config.wifi &&
                                cpeEditCurrent.config.wifi.length &&
                                cpeModelData.caps &&
                                cpeModelData.caps.wifi &&
                                Object.keys(cpeModelData.caps.wifi).length &&
                                Object.keys(cpeEditCurrent.config.wifi).length != 0 &&
                                !isDisable
                                "
                              @click="setTemplateModal = true"
                              v-tooltip.top-center="{ content: $t('aps.assignTemplate') }"
                            >
                              <i class="fa fa-paste"></i>
                            </WButton>
                            <WButton success outline rounded sm
                              v-if="cpeEditCurrent.connected &&
                                cpeEditCurrent.config.wifi &&
                                cpeEditCurrent.config.wifi.length &&
                                cpeModelData.caps &&
                                cpeModelData.caps.wifi &&
                                Object.keys(cpeModelData.caps.wifi).length &&
                                Object.keys(cpeEditCurrent.config.wifi).length != 0 &&
                                !isDisable
                                "
                              @click="showNewTemplate"
                              v-tooltip.top-center="{ content: $t('aps.createTemplateFromCpe') }"
                            >
                              <i class="fa fa-copy"></i>
                            </WButton>
                            <WButton success outline rounded sm
                              v-if="!isDisable"
                              @click="cpeTagsModal = true"
                              v-tooltip.top-center="{ content: $t('aps.editTags') }"
                            >
                              <i class="fa fa-tag"></i>
                            </WButton>
                            <WButton success outline rounded sm
                              v-if="!isDisable"
                              @click="showShowCpeConfigModal"
                              v-tooltip.top-center="{ content: $t('aps.cpeConfigShowTooltipForButtonInCpe') }"
                            >
                              <i class="fa fa-arrow-circle-down"></i>
                            </WButton>
                            <WButton success outline rounded sm
                              v-if="cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')"
                              @click="openGetLogFromCPEModal"
                              v-tooltip.top-center="{ content: $t('aps.getLogsFromCpe') }"
                            >
                              <i class="fa fa-file-text"></i>
                            </WButton>
                            <WButton danger outline rounded sm
                              v-if="isCpeHasSomeErrors"
                              @click="discardErrorsForOneCPE"
                              :disabled="areCPEErrorsDiscarding"
                              v-tooltip.top-center="{ content: $t('aps.discardErrorsButton') }"
                            >
                              <i class="fa fa-eraser"></i>
                            </WButton>
                          </div>
                          <div>
                            <WButton secondary outline rounded sm
                              @click="disableSingleEditMode"
                              v-tooltip.top-center="{ content: $t('general.close') }"
                            >
                              <i class="fa fa-close"></i>
                            </WButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-block pt-0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row mt-1">
                        <div class="col-lg-7">
                          <ul class="list-unstyled cpe-info">
                            <li>
                              <strong>{{ $t('aps.cpeInfo') }}</strong>
                            </li>
                            <li>
                              {{ $t('aps.model') }}:
                              <span class="text-muted">
                                {{ cpeEditCurrent.model.name }} {{ isCisco ? '(Cisco)' : '' }}
                              </span>
                            </li>
                            <li>
                              {{$t('aps.serialNumber')}}:
                              <span class="text-muted">
                                {{ cpeEditCurrentSerialNumber ? cpeEditCurrentSerialNumber : '-' }}
                              </span>
                            </li>
                            <li v-if="cpeEditCurrent.state.network && cpeEditCurrent.state.network.macaddr">
                              {{ $t('aps.macaddr') }}:
                              <span class="text-muted">{{ cpeEditCurrent.state.network.macaddr }}</span>
                            </li>
                            <li>
                              {{ $t('aps.cpeId') }}:
                              <span class="text-muted">{{ cpeEditCurrent.id }}</span>
                            </li>
                            <li>
                              {{ $t('aps.FWversion') }}:
                              <a
                                href="#"
                                @click.prevent="openFWUpgradeDialog"
                                v-if="
                                  cpeEditCurrent.connected && !isDisable && updatedCpe.state.firmware.version.version
                                "
                              >
                                {{ updatedCpe.state.firmware.version.version }}
                              </a>
                              <span class="text-muted" v-else>{{ updatedCpe.state.firmware.version.version }}</span>
                            </li>
                            <li>
<!--                              <router-link :to="{ name: 'Events', query: { id: cpeEditCurrent.id } }">-->
<!--                                {{ $t('aps.cpeEvents') }}-->
<!--                              </router-link>-->
                              <a
                                href="#"
                                @click.prevent="goToEvents(cpeEditCurrent)"
                              >
                                 {{ $t('aps.cpeEvents') }}
                              </a>
                            </li>
<!--                            <li>-->
<!--                              <router-link-->
<!--                                :to="{-->
<!--                                  name: 'Clients',-->
<!--                                  params: {-->
<!--                                    cpeId: cpeEditCurrent.id,-->
<!--                                    cpeName: cpeEditCurrent.name,-->
<!--                                    locationId: cpeEditCurrent.base_location-->
<!--                                  }-->
<!--                                }"-->
<!--                              >-->
<!--                                {{ $t('aps.showCurrentClients') }}-->
<!--                              </router-link>-->
<!--                            </li>-->
                            <li>
                              <a
                                href="#"
                                @click.prevent="goToClients"

                              >
                                {{ $t('aps.showCurrentClients') }}
                              </a>
                            </li>
                            <li
                              v-if="
                                cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')
                              "
                            >
                              <a
                                href="#"
                                @click.prevent="
                                  openCPEClientsListFromCpeCommandModal(
                                    selectedCpesId.length ? selectedCpesId : [cpeSelectedId]
                                  )
                                "
                              >
                                {{ $t('aps.cpeClientsListFromCpeCommandLink') }}
                              </a>
                              <info class="" :content="$t('aps.cpeClientsListFromCpeClientsInfoCaption')" />
                            </li>
                            <li>
                              <a
                                href="#"
                                @click.prevent="openCurrentCpeStatsModal(cpeEditCurrent.id, cpeEditCurrent.name)"
                              >
                                {{ $t('aps.showCurrentStats') }}
                              </a>
                            </li>
                            <!--                            <li v-if="cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')">-->
                            <!--                              <a-->
                            <!--                                href="#"-->
                            <!--                                @click.prevent="openCPEClientsListFromCpeCommandModal(selectedCpesId.length ? selectedCpesId : [cpeSelectedId])"-->
                            <!--                              >-->
                            <!--                                {{ $t('aps.cpeClientsListFromCpeCommandLink') }}-->
                            <!--                              </a>-->
                            <!--                            </li>-->
                            <br />
                            <li v-if="cpeEditCurrentLastErrorDescriptionAndRecommendation.description !== ''">
                              <span class="font-weight-bold text-danger">
                                {{ $t('aps.errorCaptionInCpeEditPanel') }}:
                              </span>
                              <span class="text-danger">
                                {{ cpeEditCurrentLastErrorDescriptionAndRecommendation.description }}
                                <i
                                  v-tooltip="{ content: $t('aps.errorCopyToClipBoardCaption') }"
                                  @click.stop="
                                    copyToClipboard(cpeEditCurrentLastErrorDescriptionAndRecommendation.description)
                                  "
                                  class="fa fa-clipboard icon-clipboard"
                                ></i>
                              </span>
                            </li>
                            <li
                              class="mt-q"
                              v-if="cpeEditCurrentLastErrorDescriptionAndRecommendation.description !== ''"
                            >
                              <span class="font-weight-bold text-danger">
                                {{ $t('aps.recommendationCaptionInCpeEditPanel') }}:
                              </span>
                              <span
                                v-if="cpeEditCurrentLastErrorDescriptionAndRecommendation.recommendation !== ''"
                                class="text-danger"
                              >
                                {{ cpeEditCurrentLastErrorDescriptionAndRecommendation.recommendation }}
                                <i
                                  v-tooltip="{ content: $t('aps.errorCopyToClipBoardCaption') }"
                                  @click.stop="
                                    copyToClipboard(cpeEditCurrentLastErrorDescriptionAndRecommendation.recommendation)
                                  "
                                  class="fa fa-clipboard icon-clipboard"
                                ></i>
                              </span>
                              <span
                                v-if="cpeEditCurrentLastErrorDescriptionAndRecommendation.recommendation === ''"
                                class="text-danger"
                              >
                                {{ $t('general.no').toLowerCase() }}
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div class="col-lg-5">
                          <ul class="list-unstyled cpe-info">
                            <li>
                              <strong>
                                <span v-if="cpeEditCurrent.connected">{{ $t('aps.connectionInfo') }}</span>
                                <span v-else>{{ $t('aps.connectionInfo') }}</span>
                              </strong>
                            </li>

                            <li>
                              {{ $t('aps.interfaceType') }}:
                              <span
                                v-if="
                                  cpeEditCurrent.state.wan &&
                                  cpeEditCurrent.state.wan.proto &&
                                  cpeEditCurrent.state.wan.proto === `qmi`
                                "
                                class="text-muted"
                              >
                                Cellular modem
                              </span>

                              <span v-else class="text-muted">Ethernet</span>
                            </li>

                            <li
                              v-if="
                                cpeEditCurrent.state &&
                                cpeEditCurrent.state.l2tp_state &&
                                cpeEditCurrent.state.l2tp_state.tunnel_type &&
                                cpeEditCurrent.state.l2tp_state.tunnel_type !== `none`
                              "
                            >
                              {{ $t('aps.type') }}:
                              <span class="text-muted">{{ cpeEditCurrent.state.l2tp_state.tunnel_type }}</span>
                            </li>

                            <li
                              v-if="
                                cpeEditCurrent.state &&
                                cpeEditCurrent.state.network &&
                                cpeEditCurrent.state.network.ipaddrs &&
                                cpeEditCurrent.state.network.ipaddrs.length
                              "
                            >
                              {{ $t('aps.ipaddr') }}:
                              <span
                                v-for="(ip, index) in cpeEditCurrent.state.network.ipaddrs"
                                :key="ip.ipaddr"
                                class="text-muted"
                              >
                                <a :href="'http://' + ip.ipaddr" v-if="cpeEditCurrent.connected && !isDisable">
                                  {{ ip.ipaddr }}
                                </a>
                                <span v-else>{{ ip.ipaddr }}</span>
                                <span v-if="index !== cpeEditCurrent.state.network.ipaddrs.length - 1">,</span>
                              </span>
                            </li>

                            <li v-if="cpeEditCurrent.state.l2tp_state && cpeEditCurrent.state.l2tp_state.local_addr">
                              {{ $t('aps.localAddr') }}:
                              <span class="text-muted">{{ cpeEditCurrent.state.l2tp_state.local_addr }}</span>
                            </li>

                            <li v-if="cpeEditCurrent.state.l2tp_state && cpeEditCurrent.state.l2tp_state.host_addr">
                              {{ $t('aps.hostAddr') }}:
                              <span class="text-muted">{{ cpeEditCurrent.state.l2tp_state.host_addr }}</span>
                            </li>
                            <li v-if="cpeEditCurrent.state.l2tp_state && cpeEditCurrent.state.l2tp_state.host">
                              {{ $t('aps.brokerHost') }}:
                              <span class="text-muted">
                                {{
                                  cpeEditCurrent.state.l2tp_state.host === 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA'
                                    ? 'default'
                                    : cpeEditCurrent.state.l2tp_state.host
                                }}
                              </span>
                            </li>
                            <li
                              v-if="
                                cpeEditCurrent.hasOwnProperty('first_connection') &&
                                cpeEditCurrent.first_connection !== 0
                              "
                            >
                              {{ $t('aps.tableTab_firstConnection') }}:
                              <span class="text-muted">
                                {{ covertTimestampToDate(cpeEditCurrent.first_connection) }}
                              </span>
                            </li>
                            <li
                              v-if="
                                cpeEditCurrent.hasOwnProperty('last_connection') && cpeEditCurrent.last_connection !== 0
                              "
                            >
                              {{ $t('aps.tableTab_lastConnection') }}:
                              <span class="text-muted">
                                {{ covertTimestampToDate(cpeEditCurrent.last_connection) }}
                              </span>
                            </li>
                            <li
                              v-if="
                                cpeEditCurrent.hasOwnProperty('last_disconnection') &&
                                cpeEditCurrent.last_disconnection !== 0
                              "
                            >
                              {{ $t('aps.tableTab_lastDisconnection') }}:
                              <span class="text-muted">
                                {{ covertTimestampToDate(cpeEditCurrent.last_disconnection) }}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-12">
                          <ul class="list-unstyled cpe-info cpe-info--stats">
                            <li>
                              <strong>RRM</strong>
                              <info class="info-tooltip-in-aps" :content="$t('aps.rrmInfoTooltip')" />
                            </li>
                            <li>
                              {{ $t('RRM.group') }}:
                              <span class="text-muted" v-if="updatedCpe.rrm_group && updatedCpe.rrm_group.rrm_group">
                                {{ commonService.getRrmById(updatedCpe.rrm_group.rrm_group) }}
                              </span>
                              <span class="text-muted" v-else>{{ $t('RRM.noGroup') }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-12">
                          <ul class="list-unstyled cpe-info cpe-info--stats">
                            <li>
                              <strong v-if="isWifiTargetBlockShowingInApsSettingsDependingOnTheBuild">{{ $t('aps.statAndRadarCaptionInCpeInfoBlock') }}</strong>
                              <strong v-else>{{ $t('aps.statAndRadarCaptionInCpeInfoBlockWithoutWifiTarget') }}</strong>
                            </li>

                            <li>
                              {{ $t('aps.Stats') }}:
                              <span class="text-muted">
                                {{ updatedCpe.config.stats_config.enabled ? $t('general.On') : $t('general.Off') }}
                              </span>
                              <span
                                class="text-muted"
                                v-if="
                                  updatedCpe.config.stats_config.enabled && updatedCpe.config.stats_config.reportperiod
                                "
                              >
                                ( {{ $t('aps.reportPeriod') }}: {{ updatedCpe.config.stats_config.reportperiod }} )
                              </span>
                            </li>

                            <li v-if="isWifiTargetBlockShowingInApsSettingsDependingOnTheBuild">
                              {{ $t('aps.lbs') }}:
                              <span class="text-muted">
                                {{ updatedCpe.config.lbs_config.enabled ? $t('general.On') : $t('general.Off') }}
                              </span>
                              <span
                                class="text-muted"
                                v-if="updatedCpe.config.lbs_config.enabled && updatedCpe.config.lbs_config.reportperiod"
                              >
                                ( {{ $t('aps.reportPeriod') }}: {{ updatedCpe.config.lbs_config.reportperiod }} )
                              </span>
                            </li>
                            <!--<li>-->
                            <!--{{$t('aps.LBSFilterMode')}}:-->
                            <!--<span class="text-muted">{{ updatedCpe.config.lbs_config.filtermode}}</span>-->
                            <!--</li>-->
                            <li>
                              {{ $t('aps.log') }}:
                              <span class="text-muted">
                                {{ updatedCpe.config.log_config.enabled ? $t('general.On') : $t('general.Off') }}
                              </span>
                            </li>
                            <li>
                              {{ $t('aps.dhcpPacketCapture') }}:
                              <span class="text-muted">
                                {{ updatedCpe.config.dhcpcap_config.enabled ? $t('general.On') : $t('general.Off') }}
                              </span>
                            </li>
                            <li v-if="updatedCpe.config.lbs_config.enabled">
                              {{ $t('aps.emptyWatcher') }}:
                              <span class="text-muted">
                                {{ updatedCpe.config.lbs_config.emptywatcher ? $t('general.On') : $t('general.Off') }}
                              </span>
                            </li>
                            <!-- snmp -->
                            <li
                              v-if="
                                updatedCpe.config.hasOwnProperty('wmsnmpd') &&
                                updatedCpe.config.wmsnmpd.hasOwnProperty('default') &&
                                updatedCpe.config.wmsnmpd.default.hasOwnProperty('enabled')
                              "
                            >
                              {{ $t('aps.snmp') }}:
                              <span class="text-muted">
                                {{ updatedCpe.config.wmsnmpd.default.enabled ? $t('general.On') : $t('general.Off') }}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
<!--                      tunnels block-->
                      <div class="row">
                        <div class="col-lg-12">
                          <ul class="list-unstyled cpe-info cpe-info--stats mb-0 mb-1">
                            <li>
                              <span role="button"
                                    @click.stop.prevent="toggleShowTunnelsBlockInEditPanel">
                                <i v-if="showTunnelsBlockInEditPanel" class="icon-arrow-down"></i>
                              <i v-else class="icon-arrow-right"></i>
                              <strong
                                class="ml-q">{{ $t('aps.tunnels.captionInCpeModal') }}</strong>
                              </span>

                            </li>
                          </ul>
                          <transition name="fade">
                            <div class="ml-1" v-if="showTunnelsBlockInEditPanel">
                              <Tunnels :class-for-wrapper-element="'cpe-info'" :cpeTunnels="currentCPEStateTunnels"></Tunnels>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <div class="h5 mt-1 mb-1">{{ $t('aps.general') }}</div>
                        <label for="name">{{ $t('aps.name') }}</label>
                        <input
                          type="text"
                          id="name"
                          :placeholder="$t('aps.cpeEditPanelNamePlaceHolder')"
                          name="name"
                          v-validate="'max:30'"
                          class="form-control"
                          :disabled="isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')"
                          :class="{ input: true, 'is-danger': errors.has('name') }"
                          v-model="updatedCpe.name"
                        />
                        <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
                      </div>

                      <div class="form-group">
                        <label for="description">{{ $t('aps.description') }}</label>
                        <input
                          type="text"
                          id="description"
                          :placeholder="$t('aps.cpeEditPanelDescriptionPlaceHolder')"
                          name="description"
                          v-validate="'max:200'"
                          class="form-control"
                          :class="{ input: true, 'is-danger': errors.has('description') }"
                          :disabled="isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')"
                          v-model="updatedCpe.description"
                        />
                        <span v-show="errors.has('description')" class="help is-danger">
                          {{ errors.first('description') }}
                        </span>
                      </div>

                      <div
                        class="form-group mt-1"
                        v-if="isBeelineBranding && updatedCpe.config && updatedCpe.config.beeline_config"
                      >
                        <label for="beeline-nas-ip">{{ $t('aps.nasIp') }}</label>
                        <input
                          type="text"
                          id="beeline-nas-ip"
                          placeholder="0.0.0.0"
                          name="beeline-nas-ip"
                          v-validate="'ipv4'"
                          class="form-control"
                          :class="{ input: true, 'is-danger': errors.has('beeline-nas-ip') }"
                          v-model="updatedCpe.config.beeline_config.nas_ip"
                        />
                        <span v-show="errors.has('beeline-nas-ip')" class="help is-danger">
                          {{ errors.first('beeline-nas-ip') }}
                        </span>
                      </div>
                      <div
                        class="form-group mt-1"
                        v-if="updatedCpe.config && updatedCpe.config.hasOwnProperty('gre_peer_addr')"
                      >
                        <label for="peerAddress">
                          {{ $t('wlans.peerAddress') }}
                        </label>
                        <input
                          key="peerAddress"
                          type="text"
                          :data-vv-as="$t('wlans.peerAddress')"
                          id="peerAddress"
                          name="peerAddress"
                          :class="{ input: true, 'is-danger': errors.has('peerAddress') }"
                          v-validate="'addressForEoGREServer'"
                          :placeholder="$t('wlans.peerAddressPlaceholder')"
                          data-vv-validate-on="change"
                          class="form-control"
                          v-model="updatedCpe.config.gre_peer_addr"
                          :disabled="isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')"
                        />
                        <span v-show="errors.has('peerAddress')" class="help is-danger">
                          {{ errors.first('peerAddress') }}
                        </span>
                      </div>

                      <div class="form-group" v-if="areLocationsVisible">
                        <label for="location">{{ $t('aps.location') }}</label>
                        <select
                          @change="changeLocationForCpeHandler"
                          v-model="updatedCpe.base_location"
                          name="location"
                          id="location"
                          :class="{
                            'select-disabled':
                              isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                          }"
                          class="form-control"
                          :disabled="isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')"
                        >
                          <option v-for="location in locationsList" :key="location.id" :value="location.id">
                            {{ location.name }}
                          </option>
                        </select>
                        <span class="small text-muted">{{$t('aps.locationChangeHint')}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div v-if="isCpeHasIntefaces">
                        <div class="h5 mb-1">{{ $t('aps.interfaces') }}</div>

                        <div
                          v-if="
                            updatedCpe.config.hasOwnProperty('wifi_lock') &&
                            updatedCpe.config.wifi &&
                            !!updatedCpe.config.wifi.length
                          "
                          class="mb-h"
                        >
                          <Switch-component
                            v-model="updatedCpe.config.wifi_lock"
                            :disabled="isDisable"
                            :label="$t('aps.wifiLock')"
                            :id="'wifi-lock'"
                          />
                        </div>

                        <div
                          class="form-group interface-block mb-1"
                          v-for="cpeInterface in sortedInterfaces"
                          :data-id="cpeInterface.id"
                          :class="{ 'multiselect-disabled-field': isDisable || updatedCpe.config.wifi_lock }"
                        >
                          <div class="interface-block-header mb-1" @click.prevent="showId">
                            <div class="mb-0">
                              <span class="h6">
                                <img class="interface-block-icon" src="/static/img/radio.svg" />
                                <strong>{{ cpeInterface.id }}</strong>
                                <span class="badge badge-success ml-1 frequency-title">
                                  <span
                                    v-if="
                                      updatedCpe.state.wifi[cpeInterface.id].frequency === '2.4' ||
                                      updatedCpe.state.wifi[cpeInterface.id].frequency === '5'
                                    "
                                  >
                                    {{ updatedCpe.state.wifi[cpeInterface.id].frequency }}{{ $t('aps.ghz') }}
                                  </span>
                                </span>
                              </span>
<!--                              <div-->
<!--                                v-if="-->
<!--                                  cpeModelData &&-->
<!--                                  cpeModelData.caps.wifi &&-->
<!--                                  updatedCpe.state.wifi &&-->
<!--                                  updatedCpe.state.wifi[cpeInterface.id] &&-->
<!--                                  cpeModelData.caps.wifi[cpeInterface.id] &&-->
<!--                                  cpeModelData.caps.wifi[cpeInterface.id].frequency !== 'None'-->
<!--                                "-->
<!--                                class="float-right"-->
<!--                              >-->
<!--                                <button class="btn btn-link" v-if="!interfaceSettings[cpeInterface.id]">-->
<!--                                  <i class="icon-arrow-left mr-1"></i>-->
<!--                                  <i class="icon-settings"></i>-->
<!--                                </button>-->
<!--                                <button-->
<!--                                  class="btn btn-link btn-link__disabled float-right"-->
<!--                                  v-if="interfaceSettings[cpeInterface.id]"-->
<!--                                >-->
<!--                                  <i class="icon-arrow-down mr-1"></i>-->
<!--                                  <i class="icon-settings"></i>-->
<!--                                </button>-->
<!--                              </div>-->
<!--                              <div v-else>-->
<!--                                <div class="mb-h"><small class="text-danger">{{ $t('aps.errorMessageNoAvailableCapabilities') }}</small></div>-->
<!--                                <div><small class="text-danger">{{$t('aps.errorMessageNoAvailableCapabilitiesNote')}}</small></div>-->
<!--                              </div>-->
                              <div
                                v-if="
                                  cpeModelData &&
                                  cpeModelData.caps.wifi &&
                                  updatedCpe.state.wifi &&
                                  updatedCpe.state.wifi[cpeInterface.id] &&
                                  cpeModelData.caps.wifi[cpeInterface.id] &&
                                  cpeModelData.caps.wifi[cpeInterface.id].frequency !== 'None'
                                "
                                class="float-right"
                              >
                                <WButton link v-if="!interfaceSettings[cpeInterface.id]">
                                  <i class="icon-arrow-left mr-1"></i>
                                  <i class="icon-settings"></i>
                                </WButton>
                                <WButton link
                                  v-if="interfaceSettings[cpeInterface.id]"
                                  customClass="btn-link__disabled float-right"
                                >
                                  <i class="icon-arrow-down mr-1"></i>
                                  <i class="icon-settings"></i>
                                </WButton>
                              </div>
                              <div v-else class="errorMessageNoAvailableCapabilities-wrapper">
                                <div class="d-flex justify-content-start">
                                  <div class="d-flex flex-column">
                                    <div class="mb-h"><small class="text-danger">{{
                                      $t('aps.errorMessageNoAvailableCapabilities') }}</small></div>
                                    <div><small class="text-danger">{{$t('aps.errorMessageNoAvailableCapabilitiesNote')}}</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class>
                              <small
                                class
                                v-if="updatedCpe.connected && updatedCpe.state.wifi[cpeInterface.id].enabled"
                              >
                                <b>{{ $t('aps.currentState') }}</b>
                              </small>
                              <small class="text-muted" v-else>{{ $t('aps.interfaceDisabled') }}</small>
                              <br />
                              <small
                                class="text-xs"
                                v-if="updatedCpe.connected && updatedCpe.state.wifi[cpeInterface.id].enabled"
                              >
                                {{ $t('aps.mode') }}:
                                <span class="radio-data">{{ cpeInterface.bandmode }},</span>
                                {{ $t('aps.width') }}:
                                <span class="radio-data">{{ updatedCpe.state.wifi[cpeInterface.id].bandwidth }},</span>
                                {{ $t('aps.channel') }}:
                                <span class="radio-data">
                                  <span v-if="updatedCpe.state.wifi[cpeInterface.id].channel">
                                    {{ updatedCpe.state.wifi[cpeInterface.id].channel }}
                                  </span>
                                </span>
                                {{ $t('aps.txPower') }}:
                                <span
                                  class="radio-data"
                                  v-if="
                                    !updatedCpe.state.wifi[cpeInterface.id].txpower ||
                                    updatedCpe.state.wifi[cpeInterface.id].txpower == '0'
                                  "
                                >
                                  {{ $t('aps.auto') }}
                                </span>
                                <span class="radio-data" v-else>
                                  {{ updatedCpe.state.wifi[cpeInterface.id].txpower }} {{ $t('aps.dbm') }}
                                </span>
                                <span v-if="cpeInterface.country">{{ $t('aps.country') }}:</span>
                                <span class="radio-data" v-if="cpeInterface.country">{{ cpeInterface.country }},</span>
                                {{ $t('aps.rateControl') }}:
                                <span class="radio-data">{{ $t(`general.${cpeInterface.require_mode}`) }},</span>
                                {{ $t('aps.maxClients') }}:
                                <span
                                  class="radio-data"
                                  v-if="
                                    updatedCpe.clients &&
                                    updatedCpe.clients[cpeInterface.id] &&
                                    updatedCpe.clients[cpeInterface.id].total
                                  "
                                >
                                  {{ updatedCpe.clients[cpeInterface.id].total }}
                                </span>
                                <span class="radio-data" v-else>0</span>
                                {{ $t('aps.cpeEditCaptionOf') }}
                                <span class="radio-dataChannel" v-if="cpeInterface.maxclients">
                                  <span v-if="cpeInterface.maxclients == 0 || cpeInterface.maxclients === ''">
                                    {{ $t('aps.cpeEditCaptionUnlimited') }}
                                  </span>
                                  <span v-else>{{ cpeInterface.maxclients }}</span>
                                </span>
                                <span class="radio-data" v-else>{{ $t('aps.cpeEditCaptionUnlimited') }}</span>
                              </small>
                            </div>
                          </div>
                          <transition name="interface">
                            <div class v-if="interfaceSettings[cpeInterface.id]">
                              <!-- <label for="channel">{{interfaceCapabilities[cpeInterface.id].selected.name}}</label> -->
                              <span class="h5">{{ $t('aps.cpeEditRadioSettings') }}</span>
                              <div class="text-danger select-channels-less-or-more-in-interface-settings" v-if="isCpeModelYuncoreXd6800">
                                <div v-if="cpeInterface.id === 'radio0'">{{$t('aps.selectChannelsMoreThanForCorrectWork')}}&nbsp;100</div>
                                <div v-if="cpeInterface.id === 'radio2'">{{$t('aps.selectChannelsLessThanForCorrectWork')}}&nbsp;100</div>
                              </div>
                              <div
                                class="form-group mt-1"
                                v-if="
                                  cpeModelData.caps.wifi && cpeModelData.caps.wifi[cpeInterface.id].frequency === `Both`
                                "
                              >
                                <label for="frequency">Frequency</label>
                                <select
                                  id="frequency"
                                  class="form-control"
                                  :disabled="isDisable || updatedCpe.config.wifi_lock"
                                  :class="{ 'select-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                  @change="
                                    handleFrequencyChange(
                                      interfaceCapabilities[cpeInterface.id].selected,
                                      updatedCpe.state.wifi[cpeInterface.id],
                                      interfaceCapabilities[cpeInterface.id].capabilities,
                                      cpeInterface.id
                                    )
                                  "
                                  v-model="interfaceCapabilities[cpeInterface.id].selected.frequency"
                                >
                                  <option>2.4</option>
                                  <option>5</option>
                                </select>
                              </div>

                              <div class="form-group mt-1">
                                <label for="bandmode">{{ $t('aps.bandMode') }}</label>
                                <select
                                  id="bandmode"
                                  class="form-control"
                                  :disabled="isDisable || updatedCpe.config.wifi_lock"
                                  :class="{ 'select-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                  @change="
                                    handleModeChange(
                                      interfaceCapabilities[cpeInterface.id].selected,
                                      updatedCpe.state.wifi[cpeInterface.id],
                                      interfaceCapabilities[cpeInterface.id].capabilities,
                                      cpeInterface.id
                                    )
                                  "
                                  v-model="interfaceCapabilities[cpeInterface.id].selected.bandmode"
                                >
                                  <option
                                    v-for="(key, value) in interfaceCapabilities[cpeInterface.id].capabilities
                                      .hwmodelist"
                                    :selected="
                                      interfaceCapabilities[cpeInterface.id].selected.bandmode === '11' + value
                                    "
                                    :value="'11' + value"
                                    v-if="
                                      isModeAllowed(
                                        interfaceCapabilities[cpeInterface.id].selected.frequency,
                                        value,
                                        interfaceCapabilities[cpeInterface.id].capabilities.hwmodelist
                                      )
                                    "
                                  >
                                    {{ '11' + value }}
                                  </option>
                                </select>
                              </div>

                              <div class="form-group" v-if="computedCapabilities[cpeInterface.id]">
                                <label for="bandwidth">{{ $t('aps.bandwidth') }}</label>
                                <select
                                  id="bandwidth"
                                  class="form-control"
                                  :disabled="isDisable || updatedCpe.config.wifi_lock"
                                  :class="{ 'select-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                  v-model="interfaceCapabilities[cpeInterface.id].selected.bandwidth"
                                  @change="clearChanList(cpeInterface.id)"
                                >
                                  <option
                                    v-for="bandwidth in calculatedCapabilities[cpeInterface.id].bandwidth"
                                    :selected="interfaceCapabilities[cpeInterface.id].selected.bandwidth == bandwidth"
                                    :value="bandwidth"
                                  >
                                    <span
                                      v-if="
                                        bandwidth === 'HT40' &&
                                        interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'
                                      "
                                    >
                                      HT40+
                                    </span>
                                    <span v-else>{{ bandwidth }}</span>
                                  </option>
                                </select>
                              </div>

                              <label for="channel">{{ $t('aps.channel') }}</label>
                              <!--For 'Both'-->
                              <!--<div class="form-group" v-if="computedCapabilities[cpeInterface.id] && interfaceCapabilities[cpeInterface.id].selected.frequency === 'Both'">-->
                              <!--<strong>Auto</strong>-->
                              <!--</div>-->
                              <div
                                class="form-group"
                                v-if="
                                  computedCapabilities[cpeInterface.id] &&
                                  interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'
                                "
                              >
<!--                                <div class="channel-list">-->
<!--                                  <div-->
<!--                                    class="channel-list-item interface-channel-trapezoid"-->
<!--                                    v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                                    :class="{-->
<!--                                      disabled: isDisable || updatedCpe.config.wifi_lock,-->
<!--                                      active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel)-->
<!--                                    }"-->
<!--                                    v-if="channel != 'auto'"-->
<!--                                  >-->
<!--                                    <input-->
<!--                                      type="checkbox"-->
<!--                                      class="interface-channel-checkbox"-->
<!--                                      :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                                      :value="channel"-->
<!--                                      :id="`${cpeInterface.id}-${channel}`"-->
<!--                                      @change="-->
<!--                                        handlerChannels(-->
<!--                                          isCisco,-->
<!--                                          interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                                          channel,-->
<!--                                          interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),-->
<!--                                          cpeInterface.id-->
<!--                                        )-->
<!--                                      "-->
<!--                                      v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                    />-->
<!--                                    <label-->
<!--                                      class-->
<!--                                      :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                                      :for="`${cpeInterface.id}-${channel}`"-->
<!--                                    >-->
<!--                                      {{ channel }}-->
<!--                                    </label>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->

<!--                              <div-->
<!--                                class="form-group"-->
<!--                                v-if="-->
<!--                                  computedCapabilities[cpeInterface.id] &&-->
<!--                                  interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                                  (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT20' ||-->
<!--                                    interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT20' ||-->
<!--                                    interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'auto')-->
<!--                                "-->
<!--                              >-->
<!--                                <div class="channel-list">-->
<!--                                  <div-->
<!--                                    class="channel-list-item interface-channel-trapezoid"-->
<!--                                    v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                                    :class="{-->
<!--                                      disabled: isDisable || updatedCpe.config.wifi_lock,-->
<!--                                      active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel)-->
<!--                                    }"-->
<!--                                    v-if="channel != 'auto'"-->
<!--                                  >-->
<!--                                    <input-->
<!--                                      type="checkbox"-->
<!--                                      class="interface-channel-checkbox"-->
<!--                                      :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                                      :value="channel"-->
<!--                                      :id="`${cpeInterface.id}-${channel}`"-->
<!--                                      @change="-->
<!--                                        handlerChannels(-->
<!--                                          isCisco,-->
<!--                                          interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                                          channel,-->
<!--                                          interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),-->
<!--                                          cpeInterface.id-->
<!--                                        )-->
<!--                                      "-->
<!--                                      v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                    />-->
<!--                                    <label-->
<!--                                      class-->
<!--                                      :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                                      :for="`${cpeInterface.id}-${channel}`"-->
<!--                                    >-->
<!--                                      {{ channel }}-->
<!--                                    </label>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->

<!--                              <div-->
<!--                                class="form-group"-->
<!--                                v-if="-->
<!--                                  computedCapabilities[cpeInterface.id] &&-->
<!--                                  interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                                  (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT40' ||-->
<!--                                    interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT40')-->
<!--                                "-->
<!--                              >-->
<!--                                <div class="channel-list">-->
<!--                                  <div-->
<!--                                    class="channel-list-item interface-channel-trapezoid__ht40"-->
<!--                                    v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                                    :class="{-->
<!--                                      disabled: isDisable || updatedCpe.config.wifi_lock,-->
<!--                                      active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel)-->
<!--                                    }"-->
<!--                                    v-if="channel != 'auto'"-->
<!--                                  >-->
<!--                                    <input-->
<!--                                      type="checkbox"-->
<!--                                      class="interface-channel-checkbox"-->
<!--                                      :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                                      :value="channel"-->
<!--                                      :id="`${cpeInterface.id}-${channel}`"-->
<!--                                      @change="-->
<!--                                        handlerChannels(-->
<!--                                          isCisco,-->
<!--                                          interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                                          channel,-->
<!--                                          interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),-->
<!--                                          cpeInterface.id-->
<!--                                        )-->
<!--                                      "-->
<!--                                      v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                    />-->
<!--                                    <label-->
<!--                                      class-->
<!--                                      :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                                      :for="`${cpeInterface.id}-${channel}`"-->
<!--                                    >-->
<!--                                      {{ channel }}-->
<!--                                      {{ channel + 4 }}-->
<!--                                    </label>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->

<!--                              <div-->
<!--                                class="form-group"-->
<!--                                v-if="-->
<!--                                  computedCapabilities[cpeInterface.id] &&-->
<!--                                  interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80'-->
<!--                                "-->
<!--                              >-->
<!--                                <div class="channel-list">-->
<!--                                  <div-->
<!--                                    class="channel-list-item interface-channel-trapezoid__ht80"-->
<!--                                    v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                                    :class="{-->
<!--                                      disabled: isDisable || updatedCpe.config.wifi_lock,-->
<!--                                      active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel)-->
<!--                                    }"-->
<!--                                    v-if="channel != 'auto'"-->
<!--                                  >-->
<!--                                    <input-->
<!--                                      type="checkbox"-->
<!--                                      class="interface-channel-checkbox"-->
<!--                                      :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                                      :value="channel"-->
<!--                                      :id="`${cpeInterface.id}-${channel}`"-->
<!--                                      @change="-->
<!--                                        handlerChannels(-->
<!--                                          isCisco,-->
<!--                                          interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                                          channel,-->
<!--                                          interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),-->
<!--                                          cpeInterface.id-->
<!--                                        )-->
<!--                                      "-->
<!--                                      v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                    />-->
<!--                                    <label-->
<!--                                      class-->
<!--                                      :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                                      :for="`${cpeInterface.id}-${channel}`"-->
<!--                                    >-->
<!--                                      {{ channel }}-->
<!--                                      {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }}-->
<!--                                    </label>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->


                                                                <div class="channel-list">
                                  <div
                                    class="channel-list-item interface-channel-trapezoid"
                                    v-for="channel in computedCapabilities[cpeInterface.id].channels"
                                    :class="{
                                      disabled: isDisable || updatedCpe.config.wifi_lock,
                                      active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel)
                                    }"
                                    v-if="channel != 'auto'"
                                  >
                                    <input
                                      type="checkbox"
                                      class="interface-channel-checkbox"
                                      :disabled="isDisable || updatedCpe.config.wifi_lock"
                                      :value="channel"
                                      :id="`${cpeInterface.id}-${channel}`"
                                      @change="
                                        handlerChannels(
                                          isCisco,
                                          interfaceCapabilities[cpeInterface.id].selected.channels,
                                          channel,
                                          interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                                          cpeInterface.id
                                        )
                                      "
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                    />
                                    <label
                                      class
                                      :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                      :for="`${cpeInterface.id}-${channel}`"
                                    >
                                      {{ channel }}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="form-group"
                                v-if="
                                  computedCapabilities[cpeInterface.id] &&
                                  interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                  (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT20' ||
                                    interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT20' ||
                                    interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE20' ||
                                    interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'auto')
                                "
                              >
                                <div class="channel-list">
                                  <div
                                    class="channel-list-item interface-channel-trapezoid"
                                    v-for="channel in computedCapabilities[cpeInterface.id].channels"
                                    :class="{
                                      disabled: isDisable || updatedCpe.config.wifi_lock,
                                      active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel)
                                    }"
                                    v-if="channel != 'auto'"
                                  >
                                    <input
                                      type="checkbox"
                                      class="interface-channel-checkbox"
                                      :disabled="isDisable || updatedCpe.config.wifi_lock"
                                      :value="channel"
                                      :id="`${cpeInterface.id}-${channel}`"
                                      @change="
                                        handlerChannels(
                                          isCisco,
                                          interfaceCapabilities[cpeInterface.id].selected.channels,
                                          channel,
                                          interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                                          cpeInterface.id
                                        )
                                      "
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                    />
                                    <label
                                      class
                                      :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                      :for="`${cpeInterface.id}-${channel}`"
                                    >
                                      {{ channel }}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="form-group"
                                v-if="
                                  computedCapabilities[cpeInterface.id] &&
                                  interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                  (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT40' ||
                                    interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT40' ||
                                    interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE40')
                                "
                              >
                                <div class="channel-list">
                                  <div
                                    class="channel-list-item interface-channel-trapezoid__ht40"
                                    v-for="channel in computedCapabilities[cpeInterface.id].channels"
                                    :class="{
                                      disabled: isDisable || updatedCpe.config.wifi_lock,
                                      active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel)
                                    }"
                                    v-if="channel != 'auto'"
                                  >
                                    <input
                                      type="checkbox"
                                      class="interface-channel-checkbox"
                                      :disabled="isDisable || updatedCpe.config.wifi_lock"
                                      :value="channel"
                                      :id="`${cpeInterface.id}-${channel}`"
                                      @change="
                                        handlerChannels(
                                          isCisco,
                                          interfaceCapabilities[cpeInterface.id].selected.channels,
                                          channel,
                                          interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                                          cpeInterface.id
                                        )
                                      "
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                    />
                                    <label
                                      class
                                      :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                      :for="`${cpeInterface.id}-${channel}`"
                                    >
                                      {{ channel }}
                                      {{ channel + 4 }}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="form-group"
                                v-if="
                                  computedCapabilities[cpeInterface.id] &&
                                  interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                  (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE80')
                                "
                              >
                                <div class="channel-list">
                                  <div
                                    class="channel-list-item interface-channel-trapezoid__ht80"
                                    v-for="channel in computedCapabilities[cpeInterface.id].channels"
                                    :class="{
                                      disabled: isDisable || updatedCpe.config.wifi_lock,
                                      active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel)
                                    }"
                                    v-if="channel != 'auto'"
                                  >
                                    <input
                                      type="checkbox"
                                      class="interface-channel-checkbox"
                                      :disabled="isDisable || updatedCpe.config.wifi_lock"
                                      :value="channel"
                                      :id="`${cpeInterface.id}-${channel}`"
                                      @change="
                                        handlerChannels(
                                          isCisco,
                                          interfaceCapabilities[cpeInterface.id].selected.channels,
                                          channel,
                                          interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                                          cpeInterface.id
                                        )
                                      "
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                    />
                                    <label
                                      class
                                      :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                      :for="`${cpeInterface.id}-${channel}`"
                                    >
                                      {{ channel }}
                                      {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }}
                                    </label>
                                  </div>
                                </div>
                              </div>


                              <div
                                class="form-group"
                                v-if="
                                  computedCapabilities[cpeInterface.id] &&
                                  interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                  ( interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80+80' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT160' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE80+80' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE160')
                                "
                              >
                                <div class="channel-list">
                                  <div
                                    class="channel-list-item interface-channel-trapezoid__ht160"
                                    v-for="channel in computedCapabilities[cpeInterface.id].channels"
                                    :class="{
                                      disabled: isDisable || updatedCpe.config.wifi_lock,
                                      active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel)
                                    }"
                                    v-if="channel != 'auto'"
                                  >
                                    <input
                                      type="checkbox"
                                      class="interface-channel-checkbox"
                                      :disabled="isDisable || updatedCpe.config.wifi_lock"
                                      :value="channel"
                                      :id="`${cpeInterface.id}-${channel}`"
                                      @change="
                                        handlerChannels(
                                          isCisco,
                                          interfaceCapabilities[cpeInterface.id].selected.channels,
                                          channel,
                                          interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                                          cpeInterface.id
                                        )
                                      "
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                    />
                                    <label
                                      class
                                      :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                      :for="`${cpeInterface.id}-${channel}`"
                                    >
                                      {{ channel }}
                                      {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }} {{ channel + 16 }} {{ channel + 20 }} {{ channel + 24 }} {{ channel + 28 }}
                                    </label>
                                  </div>
                                </div>
                              </div>



                              <div
                                class="form-group mb-2"
                                v-if="
                                  interfaceCapabilities[cpeInterface.id].selected.power &&
                                  interfaceCapabilities[cpeInterface.id].selected.power.range
                                "
                              >
                                <span class="m-0 mt-2">{{ $t('aps.txPowerAdjust') }}</span>
                                <Switch-component
                                  v-model="interfaceCapabilities[cpeInterface.id].selected.power.auto"
                                  :disabled="isDisable || updatedCpe.config.wifi_lock"
                                  :label="$t('general.Auto')"
                                  class="float-right"
                                  :id="`power-auto-${cpeInterface.id}`"
                                  :key="`power-auto-${cpeInterface.id}`"
                                />
<!--                                // изменения для задачи WNE-3314 (необходимо сделать минимальным значением txPower 1 dbm)-->

<!--                                <Vue-slider-->
<!--                                  ref="slider"-->
<!--                                  v-if="-->
<!--                                    !isDisable &&-->
<!--                                    !updatedCpe.config.wifi_lock &&-->
<!--                                    !interfaceCapabilities[cpeInterface.id].selected.power.auto-->
<!--                                  "-->
<!--                                  :disabled="isCisco"-->
<!--                                  v-bind="TXPower"-->
<!--                                  :max="computedCapabilities[cpeInterface.id].txPower"-->
<!--                                  :min="computedCapabilities[cpeInterface.id].minTxPower || 0"-->
<!--                                  tooltipDir="bottom"-->
<!--                                  tooltip="always"-->
<!--                                  formatter="{value} dbm"-->
<!--                                  v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"-->
<!--                                ></Vue-slider>-->
<!--                                <Vue-slider-->
<!--                                  ref="slider"-->
<!--                                  v-else-->
<!--                                  :disabled="true"-->
<!--                                  v-bind="TXPower"-->
<!--                                  :max="computedCapabilities[cpeInterface.id].txPower"-->
<!--                                  :min="computedCapabilities[cpeInterface.id].minTxPower || 0"-->
<!--                                  tooltipDir="bottom"-->
<!--                                  tooltip="always"-->
<!--                                  formatter="{value} dbm"-->
<!--                                  v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"-->
<!--                                ></Vue-slider>-->
                                <Vue-slider
                                  ref="slider"
                                  v-if="
                                    !isDisable &&
                                    !updatedCpe.config.wifi_lock &&
                                    !interfaceCapabilities[cpeInterface.id].selected.power.auto
                                  "
                                  :disabled="isCisco"
                                  v-bind="TXPower"
                                  :max="computedCapabilities[cpeInterface.id].txPower"
                                  :min="computedCapabilities[cpeInterface.id].minTxPower || 1"
                                  tooltipDir="bottom"
                                  tooltip="always"
                                  formatter="{value} dbm"
                                  v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"
                                ></Vue-slider>
                                <Vue-slider
                                  ref="slider"
                                  v-else
                                  :disabled="true"
                                  v-bind="TXPower"
                                  :max="computedCapabilities[cpeInterface.id].txPower"
                                  :min="computedCapabilities[cpeInterface.id].minTxPower || 1"
                                  tooltipDir="bottom"
                                  tooltip="always"
                                  formatter="{value} dbm"
                                  v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"
                                ></Vue-slider>
<!--                                // конец изменений по задаче WNE-3314 -->
                              </div>
                              <div class="text-danger mb-2 mt-2" v-else>Incorrect TX Power data</div>

<!--&lt;!&ndash;                              // todo убрать этот старый input (который теперь заменен select'ом) если не потребуется его восстановить (11.12.2023) &ndash;&gt;-->
<!--                              <div class="form-group" v-if="!isCisco">-->
<!--                                <label for="country">{{ $t('aps.country') }}</label>-->
<!--                                <input-->
<!--                                  @input="changeCountryHandler(cpeInterface.id)"-->
<!--                                  id="country"-->
<!--                                  class="form-control"-->
<!--                                  :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                                  :class="{ 'select-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                                  v-model="interfaceCapabilities[cpeInterface.id].selected.country"-->
<!--                                />-->
<!--                              </div>-->

                              <div class="form-group" v-if="!isCisco">
                                <WSelect
                                  @input="changeCountryHandler(cpeInterface.id)"
                                  v-model="interfaceCapabilities[cpeInterface.id].selected.country"
                                  :options="countriesCodesOptionsForSelectorInInterfaceRadioSettings"
                                  :label="$t('aps.country')"
                                  :disabled="isDisable || updatedCpe.config.wifi_lock"
                                  customClass="form-control"
                                  id="country"
                                  />
                              </div>


                              <hr class="hr-settings mb-2" />

                              <div v-if="!isCisco">
                                <span class="h5">{{ $t('aps.advancedSettings') }}</span>

                                <div
                                  class="form-group mt-2 mb-2"
                                  v-if="rateControl[interfaceCapabilities[cpeInterface.id].selected.bandmode]"
                                >
                                  <label for="rate_control">
                                    <b>{{ $t('aps.rateControl') }}</b>
                                  </label>
                                  <Vue-slider
                                    :disabled="
                                      isDisable ||
                                      updatedCpe.config.wifi_lock ||
                                      (interfaceCapabilities[cpeInterface.id].selected.bandmode != '11n' &&
                                        interfaceCapabilities[cpeInterface.id].selected.bandmode != '11ac')
                                    "
                                    v-bind="rateControl[interfaceCapabilities[cpeInterface.id].selected.bandmode]"
                                    tooltipDir="bottom"
                                    tooltip="hover"
                                    formatter="{value}"
                                    id="rate_control"
                                    v-model="interfaceCapabilities[cpeInterface.id].selected.require_mode"
                                  ></Vue-slider>
                                </div>

                                <div class="form-group mb-0 mt-2">
                                  <Switch-component
                                    v-model="interfaceCapabilities[cpeInterface.id].selected.scanningconfig.enabled"
                                    :disabled="
                                      isDisable ||
                                      updatedCpe.config.wifi_lock ||
                                      (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                                    "
                                    :label="$t('aps.backgroundScaning')"
                                    id="background-scanning"
                                  />
                                </div>
                                <div class="form-group mt-1 mb-0">
                                  <Switch-component
                                    v-model="advancedSettings[cpeInterface.id].maxClientsEnabled"
                                    :disabled="
                                      isDisable ||
                                      updatedCpe.config.wifi_lock ||
                                      (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                                    "
                                    :label="$t('aps.maxClients')"
                                    id="max-clients"
                                    @change="
                                      resetMaxClients(
                                        advancedSettings[cpeInterface.id].maxClientsEnabled,
                                        cpeInterface.id
                                      )
                                    "
                                  />
                                </div>
                                <transition name="interface">
                                  <div
                                    class="form-group mb-2"
                                    v-if="advancedSettings[cpeInterface.id].maxClientsEnabled"
                                  >
                                    <label for="maxclients">{{ $t('aps.limitperWlan') }}</label>
                                    <input
                                      id="maxclients"
                                      class="form-control"
                                      :disabled="
                                        isDisable ||
                                        updatedCpe.config.wifi_lock ||
                                        !advancedSettings[cpeInterface.id].maxClientsEnabled
                                      "
                                      name="maxclients"
                                      v-validate="'numeric|between:0,2007'"
                                      :class="{
                                        'select-disabled': isDisable || updatedCpe.config.wifi_lock,
                                        input: true,
                                        'is-danger': errors.has('description')
                                      }"
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.maxclients"
                                    />
                                    <span v-show="errors.has('maxclients')" class="help is-danger">
                                      {{ errors.first('maxclients') }}
                                    </span>
                                  </div>
                                </transition>

                                <div class="form-group mt-1 mb-0">
                                  <!-- FIXME: true-value and false-value don't work-->
                                  <label
                                    class="switch switch-text switch-bee switch-success mr-1 float-right"
                                    :class="{
                                      'switch-disabled':
                                        isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                                    }"
                                  >
                                    <input
                                      type="checkbox"
                                      name="legacyRates"
                                      class="switch-input"
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.legacy_rates"
                                      true-value="0"
                                      false-value="1"
                                      :disabled="
                                        isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                                      "
                                    />
                                    <span class="switch-label"></span>
                                    <span class="switch-handle"></span>
                                  </label>
                                  <label for="legacyRates">
                                    <b>{{ $t('aps.legacyRates') }}</b>
                                  </label>
                                </div>

                                <div class="form-group mt-1 mb-0">
                                  <!-- FIXME: true-value and false-value don't work -->
                                  <label
                                    class="switch switch-text switch-bee switch-success mr-1 float-right"
                                    :class="{
                                      'switch-disabled':
                                        isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                                    }"
                                  >
                                    <input
                                      type="checkbox"
                                      name="logLevel"
                                      class="switch-input"
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.log_level"
                                      true-value="0"
                                      false-value="2"
                                      :disabled="
                                        isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                                      "
                                    />
                                    <span class="switch-label"></span>
                                    <span class="switch-handle"></span>
                                  </label>
                                  <label for="logLevel">
                                    <b>{{ $t('aps.logLevel') }}</b>
                                  </label>
                                </div>
                                <small class="text-muted">{{ $t('aps.logLevelMode') }}</small>
                                <!--                                max_inactivity-->
                                <div class="form-group mt-1">
                                  <label for="maxinactivity">
                                    {{ $t('aps.maxinactivityTitle') }}
                                    <info
                                      @clicked="showMaxinactivityModalInfoDetails"
                                      :content="$t('aps.maxinactivityTooltip')"
                                    />
                                  </label>
                                  <input
                                    id="maxinactivity"
                                    class="form-control"
                                    :disabled="isDisable || updatedCpe.config.wifi_lock"
                                    name="maxinactivity"
                                    :data-vv-as="$t('aps.maxinactivityTitle')"
                                    :placeholder="$t('aps.maxinactivityPlaceholder')"
                                    v-validate="'min_value:0|numeric'"
                                    :class="{
                                      input: true,
                                      'is-danger': errors.has('description')
                                    }"
                                    v-model.number.trim="interfaceCapabilities[cpeInterface.id].selected.max_inactivity"
                                  />
                                  <span v-show="errors.has('maxinactivity')" class="help is-danger">
                                    {{ errors.first('maxinactivity') }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </transition>
                          <hr class="hr-settings" />

                          <div class="wlans-title mt-2">
                            <label class>
                              <strong>{{ $t('aps.wlansCaption') }}</strong>
                            </label>
                            <a href="#" class="ml-q pull-right" @click.prevent="isWlanDescription = !isWlanDescription">
                              <span v-if="!isWlanDescription">{{ $t('aps.showDescription') }}</span>
                              <span v-else>{{ $t('aps.hideDescription') }}</span>
                            </a>
                          </div>
<!--                          <Multiselect-->
<!--                            v-model="interfaceSelectedWlans[cpeInterface.id]"-->
<!--                            :multiple="true"-->
<!--                            :options="wlansList || []"-->
<!--                            :max="8 - interfaceHiddenWlans[cpeInterface.id].length"-->
<!--                            label="ssid"-->
<!--                            :id="[cpeInterface.id]"-->
<!--                            trackBy="ssid"-->
<!--                            :closeOnSelect="false"-->
<!--                            :customLabel="ssidWithDescription"-->
<!--                            :hideSelected="true"-->
<!--                            @input="updateCpeInterface"-->
<!--                            :placeholder="$t('aps.wlansSelectorPlaceHolder')"-->
<!--                            :selectLabel="$t('aps.wlansSelectorTip')"-->
<!--                            :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                            :class="{ 'multiselect-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                            :loading = "loadingDataForWlanList[cpeInterface.id]"-->
<!--                            @search-change = "asyncSearchWlan"-->
<!--                          ></Multiselect>-->
<!--                          <Multiselect-->
<!--                            v-model="interfaceSelectedWlans[cpeInterface.id]"-->
<!--                            :multiple="true"-->
<!--                            :options="wlansListForWlansSearch[cpeInterface.id] || []"-->
<!--                            :max="8 - interfaceHiddenWlans[cpeInterface.id].length"-->
<!--                            label="ssid"-->
<!--                            :id="[cpeInterface.id]"-->
<!--                            trackBy="ssid"-->
<!--                            :closeOnSelect="false"-->
<!--                            :customLabel="ssidWithDescription"-->
<!--                            :hideSelected="true"-->
<!--                            @input="updateCpeInterface"-->
<!--                            :internalSearch = "false"-->
<!--                            :placeholder="$t('aps.wlansSelectorPlaceHolder')"-->
<!--                            :selectLabel="$t('aps.wlansSelectorTip')"-->
<!--                            :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                            :class="{ 'multiselect-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                            :loading = "loadingDataForWlanList[cpeInterface.id]"-->
<!--                            @search-change = "asyncSearchWlanWhenSearchChange"-->
<!--                            @open="asyncSearchWlanWhenOpenSelectorList"-->
<!--                          >-->
<!--                            <template v-slot:noOptions>-->
<!--                              {{ $t('general.noOptionsForSearch') }}-->
<!--                            </template>-->
<!--                            <template v-slot:noResult>-->
<!--                              {{ $t('general.noResultForSearch') }}-->
<!--                            </template>-->
<!--                          </Multiselect>-->
<!--                          <Multiselect-->
<!--                            v-model="interfaceSelectedWlans[cpeInterface.id]"-->
<!--                            :multiple="true"-->
<!--                            :options="wlansListForWlansSearch[cpeInterface.id] || []"-->
<!--                            :max="8 - interfaceHiddenWlans[cpeInterface.id].length"-->
<!--                            label="ssid"-->
<!--                            :id="[cpeInterface.id]"-->
<!--                            trackBy="ssid"-->
<!--                            :closeOnSelect="false"-->
<!--                            :customLabel="ssidWithDescription"-->
<!--                            :hideSelected="true"-->
<!--                            @input="updateCpeInterface"-->
<!--                            :internalSearch="false"-->
<!--                            :placeholder="$t('aps.wlansSelectorPlaceHolder')"-->
<!--                            :selectLabel="$t('aps.wlansSelectorTip')"-->
<!--                            :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                            :class="{ 'multiselect-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                            :loading="loadingDataForWlanList[cpeInterface.id]"-->
<!--                            @search-change="asyncSearchWlanWhenSearchChange"-->
<!--                            @open="asyncSearchWlanWhenOpenSelectorList"-->
<!--                          >-->
<!--                            <template v-slot:noOptions>-->
<!--                              {{ $t('general.noOptionsForSearch') }}-->
<!--                            </template>-->
<!--                            <template v-slot:noResult>-->
<!--                              {{ $t('general.noResultForSearch') }}-->
<!--                            </template>-->
<!--                          </Multiselect>-->
                          <Multiselect
                            v-model="interfaceSelectedWlans[cpeInterface.id]"
                            :multiple="true"
                            :options="wlansListForWlansSearch[cpeInterface.id] || []"
                            :max="8 - interfaceHiddenWlans[cpeInterface.id].length"
                            label="ssid"
                            :id="cpeInterface.id"
                            trackBy="ssid"
                            :closeOnSelect="false"
                            :customLabel="ssidWithDescription"
                            :hideSelected="true"
                            @input="updateCpeInterface"
                            :internalSearch="false"
                            :placeholder="$t('aps.wlansSelectorPlaceHolder')"
                            :selectLabel="$t('aps.wlansSelectorTip')"
                            :disabled="isDisable || updatedCpe.config.wifi_lock"
                            :class="{ 'multiselect-disabled': isDisable || updatedCpe.config.wifi_lock }"
                            :loading="loadingDataForWlanList[cpeInterface.id]"
                            @search-change="asyncSearchWlanWhenSearchChange"
                            @open="asyncSearchWlanWhenOpenSelectorList"
                          >
                            <template v-slot:noOptions>
                              {{ $t('general.noOptionsForSearch') }}
                            </template>
                            <template v-slot:noResult>
                              {{ $t('general.noResultForSearch') }}
                            </template>
                          </Multiselect>
                          <div v-if="!isWLCProduct">
                            <span class="small text-muted">{{$t('aps.wlansSelectorHint')}}</span>
                          </div>
                          <div v-if="cpeInterface.wlans" class="mb-1">
                            <small class="mr-1" v-if="cpeInterface.wlans && cpeInterface.wlans.length">
                              {{ $t('aps.WLANsstatus') }}:
                            </small>
                            <small
                              class
                              v-for="wlanId in cpeInterface.wlans"
                              v-if="
                                updatedCpe.state.wifi[cpeInterface.id] &&
                                updatedCpe.state.wifi[cpeInterface.id].wlanstates
                              "
                            >
                              <span
                                class="text-success interface-wlan-state"
                                :class="{ 'mr-1': !showExpandedInfoAboutWLAN(wlanId) }"
                                v-if="
                                  updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId] &&
                                  updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId].state === 'ENABLED'
                                "
                              >
                                <span v-if="wlanObj(wlanId)">
                                  <i class="fa fa-check"></i>
                                  {{ wlanObj(wlanId).ssid }}
                                </span>
                              </span>
                              <span
                                class="text-danger interface-wlan-state"
                                :class="{ 'mr-1': !showExpandedInfoAboutWLAN(wlanId) }"
                                v-if="
                                  !updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId] ||
                                  (updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId] &&
                                    updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId].state === 'DISABLED')
                                "
                              >
                                <span v-if="wlanObj(wlanId)">
                                  <i class="fa fa-stop-circle-o"></i>
                                  {{ wlanObj(wlanId).ssid }}
                                </span>
                              </span>
                              <span
                                class="text-info interface-wlan-state"
                                :class="{ 'mr-1': !showExpandedInfoAboutWLAN(wlanId) }"
                                v-if="updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId] && (updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId].state != 'ENABLED' && updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId].state != 'DISABLED')"
                              >
                                <span v-if="wlanObj(wlanId)">
                                  <i class="fa fa-refresh"></i>
                                  {{ wlanObj(wlanId).ssid }}
                                </span>
                              </span>
                              <span class="mr-1" v-if="showExpandedInfoAboutWLAN(wlanId) === true">
                                <span>
                                  ( {{ $t('aps.tunnelTypeForWLANInfo') }}:
                                  {{ wlansFullObjectsForSeletedCpe[wlanId].proto }},
                                </span>
                                <span v-if="wlansFullObjectsForSeletedCpe[wlanId].proto === 'gretap'">
                                  {{ $t('wlans.peerAddress') }}:
                                  {{ wlansFullObjectsForSeletedCpe[wlanId].peer_address }},
                                </span>
                                <span>VLAN: {{ wlansFullObjectsForSeletedCpe[wlanId].vlan }}</span>
                                <span>)</span>
                              </span>
                            </small>
                            <p class="p-0 mb-h mt-q small text-" v-if="interfaceHiddenWlans[cpeInterface.id].length">
                              Service hidden WLANs: {{ interfaceHiddenWlans[cpeInterface.id].length }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div v-if="cpeModelData && cpeModelData.caps.wired">
                        <!-- Wired component -->
                        <Wired-config
                          v-if="cpeModelData.caps.wired && updatedCpe"
                          :redirectsList="redirectsList"
                          :cpeModelData="cpeModelData"
                          :objWiredState="updatedCpe.state.wired"
                          :objWiredConfig="updatedCpe.config.wired"
                          :isRootUser="isRootUser"
                          :isDisable="isDisable"
                          :isOperator="isOperator"
                          :hostsList="hostsList"
                          :wlansList="wlansList"
                        ></Wired-config>
                      </div>
                    </div>
                  </div>

                  <div class="advanced-block" v-if="!isCisco && showAdvancedSettingsBlocksInEditPanel">
                    <div class="h5 mt-1 mb-1">{{ $t('aps.Advanced') }}</div>

                    <!--showLogStatLbs-->
                    <statLogLbsConfig
                      v-if="updatedCpe"
                      :updatedCpeConfig="updatedCpe.config"
                      :currentCpeConfig="cpeEditCurrent.config"
                      :isEditShow="statLogLbsmodal"
                      :isRootUser="isRootUser"
                      :lbsFilterList="configFilterList"
                      :isDisable="isDisable"
                      :isOperator="isOperator"
                      :updatedCpe="updatedCpe"
                      :currentCpe="cpeEditCurrent"
                    ></statLogLbsConfig>

                    <Access-control
                      v-if="updatedCpe"
                      :updatedCpeConfig="updatedCpe.config"
                      :isConnected="updatedCpe.connected"
                      :firewallL3List="firewallL3List"
                      :currentCpeConfig="cpeEditCurrent.config"
                      :isEditShow="isFirewallShow"
                      :isRootUser="isRootUser"
                      :firewallsList="firewallsList"
                      :isDisable="isDisable"
                      :isOperator="isOperator"
                    ></Access-control>

                    <div class="interface-block mb-1" v-if="isCpeHasIntefaces && isExperimentalModeEnabled">
                      <div class="setting-block-header" @click.prevent="showFWUpgrade">
                        <div class="setting-block-status">
                          <p class="h6 mb-h pr-h setting-block-title">{{ $t('aps.FWAutoUpgrade') }}</p>
                        </div>
                        <div class="setting-block-buttons">
                          <WButton link v-if="!isFWUpgradeShow">
                            <i class="icon-arrow-left"></i>
                          </WButton>
                          <WButton link v-if="isFWUpgradeShow">
                            <i class="icon-arrow-down"></i>
                          </WButton>
                        </div>
                      </div>

                      <transition name="interface">
                        <div class="mt-1" v-if="isFWUpgradeShow">
                          <div class="form-group">
                            <label for="mode">{{ $t('general.Mode') }}</label>
                            <select
                              v-model="updatedCpe.config.firmware.mode"
                              name="location"
                              id="mode"
                              :class="{
                                'select-disabled':
                                  isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                              }"
                              class="form-control"
                              :disabled="isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')"
                            >
                              <option value="off">{{ $t('aps.firmwareModeOff') }}</option>
                              <option value="on">{{ $t('aps.firmwareModeOn') }}</option>
                              <option value="check">{{ $t('aps.firmwareModeCheck') }}</option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label for="fw_timeout">{{ $t('general.Timeout') }} ({{ $t('general.insec') }})</label>
                            <input
                              type="text"
                              id="fw_timeout"
                              placeholder="Enter timeout"
                              name="fw_timeout"
                              data-vv-as="Timeout"
                              class="form-control"
                              v-validate="'required|between:600,86400'"
                              :class="{ input: true, 'is-danger': errors.has('description') }"
                              :disabled="
                                isDisable ||
                                (!updatedCpe.connected && updatedCpe.config_status == 'empty') ||
                                updatedCpe.config.firmware.mode === 'off'
                              "
                              v-model.number="updatedCpe.config.firmware.timeout"
                            />
                            <span v-show="errors.has('fw_timeout')" class="help is-danger">
                              {{ errors.first('fw_timeout') }}
                            </span>
                          </div>
                        </div>
                      </transition>
                    </div>

                    <!--                    position coordinates-->
                    <div class="interface-block mb-1" v-if="isShowMapsAndLatitudeLongitudeSettings">
                      <div class="setting-block-header" @click.prevent="showLatitudeLongitudeBlock">
                        <div class="setting-block-status">
                          <p class="h6 mb-h pr-h setting-block-title">{{ $t('aps.positionBlockHeader') }}</p>
                          <div class="mb-h">
                            <span v-if="cpeEditCurrent.hasOwnProperty('latitude')" class="badge mr-q badge-success">
                              {{ $t('aps.positionBlockCoordsLatFull') }} {{ cpeEditCurrent.latitude }}
                            </span>
                            <span v-if="cpeEditCurrent.hasOwnProperty('longitude')" class="badge mr-q badge-success">
                              {{ $t('aps.positionBlockCoordsLngFull') }} {{ cpeEditCurrent.longitude }}
                            </span>
                          </div>
                        </div>
                        <div class="setting-block-buttons">
                          <WButton link v-if="!isLatitudeLongitudeBlockShow">
                            <i class="icon-arrow-left"></i>
                          </WButton>
                          <WButton link v-if="isLatitudeLongitudeBlockShow">
                            <i class="icon-arrow-down"></i>
                          </WButton>
                        </div>
                      </div>

                      <transition name="interface">
                        <div class="mt-0" v-if="isLatitudeLongitudeBlockShow">
                          <!--                          <div class="form-group">-->
                          <!--                            <label for="fw_timeout">{{ $t('general.Timeout') }} ({{ $t('general.insec') }})</label>-->
                          <!--                            <input-->
                          <!--                              type="text"-->
                          <!--                              id="fw_timeout"-->
                          <!--                              placeholder="Enter timeout"-->
                          <!--                              name="fw_timeout"-->
                          <!--                              data-vv-as="Timeout"-->
                          <!--                              class="form-control"-->
                          <!--                              v-validate="'required|between:600,86400'"-->
                          <!--                              :class="{ input: true, 'is-danger': errors.has('description') }"-->
                          <!--                              :disabled="-->
                          <!--                                isDisable ||-->
                          <!--                                (!updatedCpe.connected && updatedCpe.config_status == 'empty') ||-->
                          <!--                                updatedCpe.config.firmware.mode === 'off'-->
                          <!--                              "-->
                          <!--                              v-model.number="updatedCpe.config.firmware.timeout"-->
                          <!--                            />-->
                          <!--                            <span v-show="errors.has('fw_timeout')" class="help is-danger">-->
                          <!--                              {{ errors.first('fw_timeout') }}-->
                          <!--                            </span>-->
                          <!--                          </div>-->
                          <div class="form-group" v-if="areLocationsVisible">
                            <label>
                              <span>{{ $t('aps.positionBlockCopyFromLocationCaption') }}</span>
                              <info :content="$t('aps.positionBlockCopyFromSelectedLocationButtonInfo')" />
                            </label>
                            <!--                            <div>-->
                            <!--                              <button :disabled="isDisable" type="button" class="btn btn-info btn-sm"-->
                            <!--                                      @click="copyCoordinatesFromLocation">-->
                            <!--                                <span>{{ $t('aps.positionBlockCopyFromLocationButtonCaption') }} </span>-->
                            <!--                                <span>{{getLocationNameFromLocationId(updatedCpe.base_location)}}</span>-->
                            <!--                              </button>-->
                            <!--                            </div>-->

                            <div>
                              <select
                                v-model="locationForEditPosition"
                                class="form-control"
                                ref="locationsSelect"
                                :disabled="isDisable"
                              >
                                <option :selected="true" :value="'Select Location'">
                                  {{ $t('aps.positionBlockSelectLocation') }}
                                </option>
                                <option v-for="location in locationsList" :value="location.id" :key="location.id">
                                  {{ location.name }}
                                </option>
                              </select>
                            </div>
                            <div class="mt-h">
                              <WButton info sm
                                @click="copyCoordinatesFromLocation"
                                :disabled="isDisable"
                              >
                                {{ $t('aps.positionBlockCopyFromSelectedLocationButtonCaption') }}
                              </WButton>
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="addrSearchSelectorInCpeSingleEdit">
                              <span>{{ $t('aps.positionBlockSearchAddressCaption') }}</span>
                            </label>
                            <SelectComponentForCompanyAndAddressSearch
                              v-if="isLatitudeLongitudeBlockShow && addressSelectorShowing"
                              id="addrSearchSelectorInCpeSingleEdit"
                              v-model="selectedAddressInSelector"
                              :disabled="isDisable"
                              class="w-100"
                              option-id-name="place_id"
                              track-by="display_name"
                              :placeholder="$t('aps.positionBlockEnterAddressToSearch')"
                              :async-function="requestCoordsByAddress.bind(this)"
                              @select="selectAddressInSelector"
                              :noOptionsFound="$t('location.addressNotFound')"
                            />
                          </div>

                          <div style="position: relative;">
                            <loader-spinner-small v-if="showingSpinerOverCoordinates"></loader-spinner-small>

                            <div class="form-group">
                              <label for="latSingleEdit">{{ $t('aps.positionBlockCoordsLatFull') }}</label>
                              <input
                                :data-vv-as="$t('aps.positionBlockCoordsLatFull')"
                                :disabled="isDisable"
                                id="latSingleEdit"
                                name="latSingleEdit"
                                step="0.0000000000001"
                                type="number"
                                class="input form-control"
                                v-model.number="updatedCpe.latitude"
                                v-validate="'between:-90,90'"
                                data-vv-validate-on="input"
                              />
                              <!--                            <span-->
                              <!--                              v-tooltip.top-center="{ content: errors.first('lat') }"-->
                              <!--                              v-show="errors.has('lat')"-->
                              <!--                              class="help is-danger error_tooltip"-->
                              <!--                            >-->
                              <!--                            <i class="fa fa-warning text-error"/>-->
                              <!--                             </span>-->
                              <span v-show="errors.has('latSingleEdit')" class="help is-danger">
                                {{ errors.first('latSingleEdit') }}
                              </span>
                            </div>
                            <div class="form-group">
                              <label for="lngSingleEdit">{{ $t('aps.positionBlockCoordsLngFull') }}</label>
                              <input
                                :data-vv-as="$t('aps.positionBlockCoordsLngFull')"
                                :disabled="isDisable"
                                id="lngSingleEdit"
                                name="lngSingleEdit"
                                step="0.0000000000001"
                                type="number"
                                class="input form-control"
                                v-model.number="updatedCpe.longitude"
                                v-validate="'between:-180,180'"
                                data-vv-validate-on="input"
                              />
                              <!--                            <span-->
                              <!--                              v-tooltip.top-center="{ content: errors.first('lng') }"-->
                              <!--                              v-show="errors.has('lng')"-->
                              <!--                              class="help is-danger error_tooltip"-->
                              <!--                            >-->
                              <!--                            <i class="fa fa-warning text-error"/>-->
                              <!--                           </span>-->
                              <span v-show="errors.has('lngSingleEdit')" class="help is-danger">
                                {{ errors.first('lngSingleEdit') }}
                              </span>
                            </div>

                            <div class="form-group">
                              <Map-cpe
                                @markerDrag="onMarkerDragForSetPositionForSingleEdit"
                                :coords-lat="this.updatedCpe.latitude || 0"
                                :coords-lng="this.updatedCpe.longitude || 0"
                                v-if="isLatitudeLongitudeBlockShow"
                              ></Map-cpe>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
                <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
                  <div>
                    <WButton success outline
                      v-if="(!isDisable && !isUpdating)"
                      @click="updateCpe()"
                      :disabled="!enableSaveChanges || isUpdating || errors.any() || muteEdit"
                    >
                      <span :class="{ invisible: muteEdit || isUpdating }">{{ $t('aps.save') }}</span>
                      <span v-if="muteEdit || isUpdating" class="loader loader--mini"></span>
                    </WButton>
                    <WButton danger outline
                      customClass="ml-1"
                      v-if="isShowDeleteAPButtonForSingleAPEdit"
                      @click="openDeletingCpeDialog(updatedCpe.id)"
                      :disabled="isUpdating || muteEdit"
                    >
                      <span>{{ $t('general.delete') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </WButton>
                  </div>
                  <div>
                    <WButton secondary outline @click="disableSingleEditMode">
                      <span v-if="isDisable">{{ $t('aps.close') }}</span>
                      <span v-else>{{ $t('aps.cancel') }}</span>
                    </WButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cpes-edit" v-if="selectedCpesId.length > 0">
          <div class="row">
            <div class="col-lg-12">
              <div class="card mb-0">
                <div class="card-block mb-0" v-if="!hideCommonEdit">
                  <div class="h4 m-0">{{ $t('aps.multiEdit') }}</div>
                  <small class="text-muted">{{ $t('aps.multiEditMode') }}</small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" success outline fullWidth
                    customClass="mt-2"
                    @click="showShowCpeConfigModal"
                  >
                    {{ $t('aps.cpeConfigShowTooltipForButtonInCpe') }}
                  </WButton>

                  <!--                  <small class="text-danger" v-if="!cpeModelData">{{$t('aps.errorMessageNoCPEsModelData')}}</small>-->
                  <!--                  <small class="text-danger" v-if="!checkCpesModel()"></small>-->

                  <!--                  position coordinates-->
                  <WButton primary outline fullWidth
                    customClass="mt-1"
                    v-if="isShowMapsAndLatitudeLongitudeSettings && !isHiddenMultiEditButtonForWLCBuild"
                    @click="setPositionSettingsShow"
                  >
                    {{ $t('aps.positionBlockForMultiEditButtonCaption') }}
                  </WButton>
                  <!--                  <small class="text-danger" v-if="!cpeModelData">{{$t('aps.errorMessageNoCPEsModelData')}}</small>-->
                  <!--                  <small class="text-danger" v-if="!checkCpesModel()"></small>-->
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" primary outline fullWidth
                    customClass="mt-1"
                    @click="editCpesSettingsShow"
                    :disabled="!checkCpesModel() || !cpeModelData || notCorrectCapabilities"
                  >
                    {{ $t('aps.editSettingsCpes') }}
                  </WButton>
                  <small class="text-danger" v-if="notCorrectCapabilities && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageNoAvailableCapabilities') }}
                  </small>
                  <small class="text-danger" v-else-if="!checkCpesModel() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyForAPsWithEqualModel') }}
                  </small>
                  <small class="text-danger" v-else-if="!cpeModelData && !isHiddenMultiEditButtonForWLCBuild">No model data for current CPE</small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" primary outline fullWidth
                    customClass="mt-1 text-prewrap"
                    @click="LogSettingsShow"
                    :disabled="isCisco"
                  >
                    {{ $t('aps.editLogAndStatSettings') }}
                  </WButton>
                  <small class="text-danger" v-if="isCisco && !isHiddenMultiEditButtonForWLCBuild">Not available for Cisco</small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" primary outline fullWidth
                    customClass="mt-1"
                    @click="acessControlSettingsShow"
                      :disabled="isCisco"
                  >
                    {{ $t('aps.accessControlSettings') }}
                  </WButton>
                  <small class="text-danger" v-if="isCisco && !isHiddenMultiEditButtonForWLCBuild">Not available for Cisco</small>
<!--                  <button-->
<!--                    class="btn btn-outline-primary btn-block"-->
<!--                    :disabled="-->
<!--                      !checkCpesConnectedState() ||-->
<!--                      !checkCpesModel() ||-->
<!--                      !cpeModelData ||-->
<!--                      isCisco ||-->
<!--                      !checkCpesHasIpsecTunnelType() ||-->
<!--                      !checkCpesHasEqualHostAddr()-->
<!--                    "-->
<!--                    @click="fwUpgradeForMultiEditShow"-->
<!--                  >-->
<!--                    {{ $t('aps.fwUpgrade') }}-->
<!--                  </button>-->
<!--                  <small class="text-danger mb-0" v-if="!checkCpesHasEqualHostAddr()">-->
<!--                    {{ $t('aps.errorMessageOnlyForCPEsWithEqualHostAddr') }}-->
<!--                  </small>-->
<!--                  <small class="text-danger" v-if="!checkCpesHasIpsecTunnelType()">-->
<!--                    {{ $t('aps.errorMessageOnlyForIpsecConnectedCPEs') }}-->
<!--                  </small>-->
<!--                  <small class="text-danger" v-if="!checkCpesConnectedState()">-->
<!--                    {{ $t('aps.errorMessageOnlyForConnectedCPEs') }}-->
<!--                  </small>-->
<!--                  <small class="text-danger" v-else-if="!checkCpesModel()">-->
<!--                    {{ $t('aps.errorMessageOnlyForAPsWithEqualModel') }}-->
<!--                  </small>-->
<!--                  <small class="text-danger" v-else-if="isCisco">Not available for Cisco</small>-->
                  <WButton primary outline fullWidth
                    @click="fwUpgradeForMultiEditShow"
                    :disabled="
                      !checkCpesConnectedState() ||
                      !checkCpesModel() ||
                      isCisco
                    "
                  >
                    {{ $t('aps.fwUpgrade') }}
                  </WButton>
<!--                  <small class="text-danger mb-0" v-if="!checkCpesHasEqualHostAddr()">-->
<!--                    {{ $t('aps.errorMessageOnlyForCPEsWithEqualHostAddr') }}-->
<!--                  </small>-->
<!--                  <small class="text-danger" v-if="!checkCpesHasIpsecTunnelType()">-->
<!--                    {{ $t('aps.errorMessageOnlyForIpsecConnectedCPEs') }}-->
<!--                  </small>-->
                  <small class="text-danger" v-if="!checkCpesConnectedState()">
                    {{ $t('aps.errorMessageOnlyForConnectedCPEs') }}
                  </small>
                  <small class="text-danger" v-else-if="!checkCpesModel()">
                    {{ $t('aps.errorMessageOnlyForAPsWithEqualModel') }}
                  </small>
                  <small class="text-danger" v-else-if="isCisco">Not available for Cisco</small>
                  <WButton primary outline fullWidth
                    v-if="areLocationsVisible && !isHiddenMultiEditButtonForWLCBuild"
                    @click="isChangeLocationOpen = true"
                  >
                    {{ $t('general.changeLocations') }}
                  </WButton>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" primary outline fullWidth
                    @click="openSyncTimeModal"
                    :disabled="!checkCpesConnectedState()"
                  >
                    {{ $t('aps.syncTime') }}
                  </WButton>
                  <small class="text-danger" v-if="!checkCpesConnectedState() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyForConnectedCPEs') }}
                  </small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" success outline fullWidth
                    customClass="mt-2"
                    @click="setTemplate"
                    :disabled="!checkCpesModel() || !cpeModelData"
                  >
                    {{ $t('aps.addCommonTemplates') }}
                  </WButton>
                  <small class="text-danger" v-if="!cpeModelData && !isHiddenMultiEditButtonForWLCBuild">{{ $t('aps.errorMessageNoCPEsModelData') }}</small>
                  <small class="text-danger" v-if="!checkCpesModel() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyForAPsWithEqualModel') }}
                  </small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" success outline fullWidth @click="assignCommonWlanShow">
                    {{ $t('aps.addCommonWlans') }}
                  </WButton>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" success outline fullWidth @click="openAddTagsDialog">
                    {{ $t('aps.editTags') }}
                  </WButton>
                  <WButton danger outline fullWidth
                    customClass="mt-2"
                    @click="openRebootCpeModal"
                    :disabled="!checkCpesConnectedState()"
                  >
                    {{ $t('aps.rebootCpe') }}
                  </WButton>
                  <small class="text-danger" v-if="!checkCpesConnectedState()">
                    {{ $t('aps.errorMessageOnlyForConnectedCPEs') }}
                  </small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" danger outline fullWidth
                    @click="openResetCpeModal"
                    :disabled="!checkCpesConnectedState()"
                  >
                    {{ $t('aps.resetCpe') }}
                  </WButton>
                  <small class="text-danger" v-if="!checkCpesConnectedState() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyForConnectedCPEs') }}
                  </small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" danger outline fullWidth
                    @click="openRebootCpeAgentModal"
                    :disabled="!checkCpesConnectedState()"
                  >
                    {{ $t('aps.rebootCpeAgent') }}
                  </WButton>
                  <small class="text-danger" v-if="!checkCpesConnectedState() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyForConnectedCPEs') }}
                  </small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" danger outline fullWidth
                    @click="openRebootCpeRedundantModal"
                    :disabled="!checkCpesConnectedState()"
                  >
                    {{ $t('aps.rebootCpeRedundant') }}
                  </WButton>
                  <small class="text-danger" v-if="!checkCpesConnectedState() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyForConnectedCPEs') }}
                  </small>
                  <WButton  danger outline fullWidth v-if="!isWLCProduct && !isHiddenMultiEditButtonForWLCBuild"
                    @click="openIPsecModal"
                    :disabled="!checkCpesConnectedState()"
                  >
                    {{ $t('aps.IPsec') }}
                  </WButton>
                  <small class="text-danger" v-if="!checkCpesConnectedState() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyForConnectedCPEs') }}
                  </small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" danger outline fullWidth
                    @click="openConfigModal"
                    :disabled="!checkCpesConnectedState()"
                  >
                    {{ $t('aps.configModalTitle') }}
                  </WButton>
                  <small class="text-danger" v-if="!checkCpesConnectedState() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyForConnectedCPEs') }}
                  </small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" danger outline fullWidth
                    @click="openSetTimeUsingNTPModal"
                    :disabled="!checkCpesConnectedState()"
                  >
                    {{ $t('aps.setTimeUsingNTP') }}
                  </WButton>
                  <small class="text-danger" v-if="!checkCpesConnectedState() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyForConnectedCPEs') }}
                  </small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" danger outline fullWidth
                    @click="discardErrorsForMultiEditCPEs"
                    :disabled="!checkCpesHasError() || areCPEErrorsDiscarding"
                  >
                    {{ $t('aps.discardErrorsButton') }}
                  </WButton>
                  <small class="text-danger" v-if="!checkCpesHasError() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyIfLeastOneCPEHaveError') }}
                  </small>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" danger outline fullWidth
                    customClass="mt-2"
                    @click="openCommonDeletingTagsDialog"
                  >
                    {{ $t('aps.deleteAllTags') }}
                  </WButton>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" danger outline fullWidth @click="openCommonDeletingWlansDialog">
                    {{ $t('aps.deleteAllWlans') }}
                  </WButton>
                  <WButton v-if="!isHiddenMultiEditButtonForWLCBuild" danger outline fullWidth
                    :disabled="!isShowDeleteAPButtonForMultiAPsEdit()"
                    @click="openCommonDeletingCpesDialog"
                  >
                    {{ $t('aps.deleteCpes') }}
                  </WButton>
                  <small class="text-danger" v-if="!isShowDeleteAPButtonForMultiAPsEdit() && !isHiddenMultiEditButtonForWLCBuild">
                    {{ $t('aps.errorMessageOnlyForDisconnectedCPEs') }}
                  </small>
                  <WButton  secondary outline fullWidth
                    customClass="mt-2"
                    @click="cancelCommonEdit"
                  >
                    {{ $t('aps.cancel') }}
                  </WButton>
                </div>

                <!--Multiple CPEs upgrade-->
                <div class="card-block" v-if="CpesFwUpgradeMode">
                  <loader-spinner centered v-if="isfirmwareObjectsListFromApiLoading"></loader-spinner>
                  <h4 class="modal-title">{{ $t('aps.fwUpgrade') }}</h4>
                  <div class="mt-2">
                    <div class="mb-2">
                      <p>
                        <strong>{{ $t('aps.model') }}:</strong>
                        {{ updatedCpe.model.name }}
                      </p>
                    </div>
                    <div class>
                      <!--<label for="file">Select FW</label>-->
                      <!--<a href="#" class="small" @click.prevent="changeFwSource">{{ !fwFromLink ? 'from link' : 'from file'}}</a>-->
                      <div class="input-group" v-if="!fwFromLink" style="height: 35px;">
                        <select
                          v-model="forceFWObjects"
                          v-if="cpeModelData.firmwares"
                          id="file"
                          :class="{
                            'select-disabled':
                              isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                          }"
                          class="form-control"
                          :disabled="isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')"
                        >
                          <option disabled v-if="cpeModelData.firmwares.length" :value="false">
                            {{ $t('aps.fwSelectFirmwareCaption') }}
                          </option>
                          <option
                            v-for="(firmware, index) in cpeModelData.firmwares"
                            :key="index"
                            v-if="cpeModelData.firmwares.length"
                            :value="firmware"
                          >
                            {{ firmware.version }}
                          </option>
                          <option v-if="!cpeModelData.firmwares.length" :value="false" disabled>
                            {{ $t('aps.modalNoUpdatesAvailable') }}
                          </option>
                        </select>
                        <span class="input-group-btn">
                          <div class="fileUpload btn btn-info" :disabled="uploadFW" :class="{ disabled: uploadFW }">
                            <i class="fa fa-file-o" :class="{ invisible: uploadFW }"></i>
                            <span :class="{ invisible: uploadFW }">{{ $t('aps.modalUploadFWfile') }}</span>
                            <span v-if="uploadFW" class="loader loader--mini"></span>
                            <input
                              id="fwfile"
                              @change="uploadFWFileToServer"
                              name="files"
                              type="file"
                              class="upload"
                              v-if="!uploadFW"
                            />
                          </div>
                        </span>
                      </div>
                      <div v-if="fwFromLink">
                        <div class="form-group m-0 p-0">
                          <input
                            type="text"
                            placeholder="http://10.10.10.1/file_v42.tar"
                            class="form-control"
                            v-model="forceFWObjects.url"
                          />
                        </div>
                      </div>
                      <div class="firmware-info" v-if="forceFWObjects && !fwFromLink">
                        <p class="m-0">
                          <small>
                            <strong>{{ $t('aps.modalFirmwareFileInfo') }}</strong>
                          </small>
                        </p>
                        <p class="m-0">
                          <small>
                            <strong>MD5:</strong>
                            <span v-if="forceFWObjects.md5sum">{{ forceFWObjects.md5sum }}</span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </small>
                        </p>
                        <p>
                          <small>
                            <strong>{{ $t('aps.modalFirmwareFileInfoSize') }}:</strong>
                            <span v-if="forceFWObjects.filesize">{{ formatBytes(forceFWObjects.filesize) }}</span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </small>
                        </p>
                      </div>
                      <small class="text-danger">
                        {{ $t('aps.modalBeAttention') }}!
                        <br />
                        1) {{ $t('aps.modalBeAttentionFirst') }}
                        <br />
                        2) {{ $t('aps.modalBeAttentionSecond') }}
                        <br />
                        <!--3) Use <u>http</u> protocol in link-->
                      </small>
                    </div>
                  </div>
                  <div class="mt-2"
                       v-if="(forceFWObjects !== false) && !checkCpesHasEqualHostAddr()">
                    <div class="mb-h">
                      <b class="text-danger">{{$t('aps.differentHostAddrMessage')}}</b>
                    </div>
                    <div v-if="Object.keys(getHostAddressesAndCpeNamesList()).length > 0">
                      <div class="mb-1"
                           v-for="(cpes, hostAddr) of getHostAddressesAndCpeNamesList()"
                           :key="hostAddr">
                        <div><b>{{hostAddr}}</b> <a href="#" class="ml-q"
                                                    @click.prevent="setCustomUrlAddressWithSchemaForFWUpgrage('http', hostAddr)">
                          <span>{{ $t('aps.selectHostAddress') }}</span>
                        </a></div>
                        <div>
                          <span class="mr-1"><b>{{$t('aps.cpe')}}:</b></span>
                          <span class="mr-h" v-for="cpeName of cpes"
                                :key="cpeName">{{cpeName}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="forceFWObjects !== false"
                       class="mt-2 form-group d-flex flex-column justify-content-start align-items-start ">
                    <div>
                      <b>{{$t('aps.cpeFwCustomAddressAndSchema')}}</b>
                    </div>
                    <div class="d-flex flex-row justify-content-start align-items-start">
                      <div>
                        <select style="min-width: 70px;" class="form-control"
                                v-model="customUrlAddressWithSchemaForFWUpgrage.schema">
                          <option value="http">http</option>
                          <option value="https">https</option>
                        </select>
                      </div>
                      <div>
                        <input id="custom-url-address-for-fw-upgrage-in-multiedit"
                               name="custom-url-address-for-fw-upgrage-in-multiedit"
                               :data-vv-as="$t('aps.cpeFwCustomAddress')"
                               data-vv-validate-on="input"
                               v-validate="'required|addressForFWUpgrade'" class="form-control"
                               style="max-width: 300px;" type="text"
                               v-model="customUrlAddressWithSchemaForFWUpgrage.url"/>
                        <span v-show="errors.has('custom-url-address-for-fw-upgrage-in-multiedit')"
                              class="help is-danger">
                  {{ errors.first('custom-url-address-for-fw-upgrage') }}
                </span>
                      </div>
                    </div>
                    <div v-if="!checkCpesHasEqualHostAddr()">
                      <a href="#" class="ml-q"
                         @click.prevent="setCustomUrlAddressWithSchemaForFWUpgrage('http', '')">
                        <span>{{ $t('general.clear') }}</span>
                      </a>
                    </div>
                    <div v-else>
                      <a href="#" class="ml-q"
                         @click.prevent="setCustomUrlAddressWithSchemaForFWUpgrage('http', updatedCpe.state.l2tp_state.host_addr)">
                        <span>{{ $t('general.byDefault') }}</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="actions-buttons actions-buttons__multiple"
                    :class="{ 'actions-buttons__operator': isDisable }"
                  >
                  <WButton info
                    @click="upgradeFW"
                    :disabled="!forceFWObjects || !forceFWObjects.url || errors.has('custom-url-address-for-fw-upgrage-in-multiedit')"
                  >
                    {{ $t('aps.buttonsUpgrade') }}
                  </WButton>
                  <WButton secondary outline
                    @click="fwUpgradeForMultiEditHide"
                    :disabled="uploadFW"
                  >
                    {{ $t('aps.buttonsBack') }}
                  </WButton>
                  </div>
                </div>

                <!-- Multiple Radio Settings -->

                <div class="card-block" v-if="editCpesSettingsMode && cpeEditCurrent">
                  <div class="h4 m-0">{{ $t('aps.editSettingsCpes') }}</div>
                  <div v-for="cpeInterface in updatedCpe.config.wifi" v-if="updatedCpe.config.wifi.length">
                    <div class="interface-block mt-2 mb-1">
                      <p class>
                        <strong>{{ cpeInterface.id }}</strong>
                        <span class="badge badge-pill badge-success">
                          <span v-if="updatedCpe.state.wifi[cpeInterface.id].frequency === 'Both'">dualband</span>
                          <span v-else>{{ updatedCpe.state.wifi[cpeInterface.id].frequency }}{{ $t('aps.ghz') }}</span>
                        </span>
                      <div class="text-danger select-channels-less-or-more-in-interface-settings"
                           v-if="isCpeModelYuncoreXd6800">
                        <div v-if="cpeInterface.id === 'radio0'">
                          {{$t('aps.selectChannelsMoreThanForCorrectWork')}}&nbsp;100
                        </div>
                        <div v-if="cpeInterface.id === 'radio2'">
                          {{$t('aps.selectChannelsLessThanForCorrectWork')}}&nbsp;100
                        </div>
                      </div>
                      </p>
                      <!-- <label for="channel">{{interfaceCapabilities[cpeInterface.id].selected.name}}</label> -->
                      <div
                        class="form-group mt-1"
                        v-if="cpeModelData.caps.wifi && cpeModelData.caps.wifi[cpeInterface.id].frequency === `Both`"
                      >
                        <label for="frequency">Frequency</label>
                        <select
                          id="frequency"
                          class="form-control"
                          :disabled="isDisable"
                          :class="{ 'select-disabled': isDisable }"
                          @change="
                            handleFrequencyChange(
                              interfaceCapabilities[cpeInterface.id].selected,
                              updatedCpe.state.wifi[cpeInterface.id],
                              interfaceCapabilities[cpeInterface.id].capabilities,
                              cpeInterface.id
                            )
                          "
                          v-model="interfaceCapabilities[cpeInterface.id].selected.frequency"
                        >
                          <option>2.4</option>
                          <option>5</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="bandmode">{{ $t('aps.bandMode') }}</label>
                        <select
                          id="bandmode"
                          class="form-control"
                          :disabled="isDisable"
                          :class="{ 'select-disabled': isDisable }"
                          @change="
                            handleModeChange(
                              interfaceCapabilities[cpeInterface.id].selected,
                              updatedCpe.state.wifi[cpeInterface.id],
                              interfaceCapabilities[cpeInterface.id].capabilities,
                              cpeInterface.id
                            )
                          "
                          v-model="interfaceCapabilities[cpeInterface.id].selected.bandmode"
                        >
                          <option
                            v-for="(key, value) in interfaceCapabilities[cpeInterface.id].capabilities.hwmodelist"
                            :selected="interfaceCapabilities[cpeInterface.id].selected.bandmode === '11' + value"
                            :value="'11' + value"
                            v-if="
                              isModeAllowed(
                                interfaceCapabilities[cpeInterface.id].selected.frequency,
                                value,
                                interfaceCapabilities[cpeInterface.id].capabilities.hwmodelist
                              )
                            "
                          >
                            {{ '11' + value }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group" v-if="computedCapabilities[cpeInterface.id]">
                        <label for="bandwidth">{{ $t('aps.bandwidth') }}</label>
                        <select
                          id="bandwidth"
                          class="form-control"
                          :disabled="isDisable"
                          :class="{ 'select-disabled': isDisable }"
                          v-model="interfaceCapabilities[cpeInterface.id].selected.bandwidth"
                          @change="clearChanList(cpeInterface.id)"
                        >
                          <option
                            v-for="bandwidth in computedCapabilities[cpeInterface.id].bandwidth"
                            :key="bandwidth"
                            :selected="interfaceCapabilities[cpeInterface.id].selected.bandwidth == bandwidth"
                            :value="bandwidth"
                          >
                            <span
                              v-if="
                                bandwidth === 'HT40' &&
                                interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'
                              "
                            >
                              HT40+
                            </span>
                            <span v-else>{{ bandwidth }}</span>
                          </option>
                        </select>
                      </div>

                      <label for="channel">{{ $t('aps.channel') }}</label>
                      <div
                        class="form-group"
                        v-if="
                          computedCapabilities[cpeInterface.id] &&
                          interfaceCapabilities[cpeInterface.id].selected.frequency === 'Both'
                        "
                      >
                        <strong>Auto</strong>
                      </div>

                      <!--End for Both-->

<!--                      <div-->
<!--                        class="form-group"-->
<!--                        v-if="-->
<!--                          computedCapabilities[cpeInterface.id] &&-->
<!--                          interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'-->
<!--                        "-->
<!--                      >-->
<!--                        <div class="channel-list">-->
<!--                          <div-->
<!--                            class="channel-list-item interface-channel-trapezoid"-->
<!--                            v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                            :class="{-->
<!--                              disabled: isDisable,-->
<!--                              active: activeChannel(interfaceCapabilities[cpeInterface.id].selected.channels, channel)-->
<!--                            }"-->
<!--                            v-if="channel != 'auto'"-->
<!--                            :key="channel"-->
<!--                          >-->
<!--                            <input-->
<!--                              type="checkbox"-->
<!--                              class="interface-channel-checkbox"-->
<!--                              :disabled="isDisable"-->
<!--                              :value="channel"-->
<!--                              :id="`${cpeInterface.id}-${channel}`"-->
<!--                              :checked="interfaceCapabilities[cpeInterface.id].selected.channels === channel"-->
<!--                              v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                            />-->
<!--                            <label-->
<!--                              class-->
<!--                              :class="{ 'label-disabled': isDisable }"-->
<!--                              :for="`${cpeInterface.id}-${channel}`"-->
<!--                            >-->
<!--                              {{ channel }}-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div-->
<!--                        class="form-group"-->
<!--                        v-if="-->
<!--                          computedCapabilities[cpeInterface.id] &&-->
<!--                          interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                          (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT20' ||-->
<!--                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT20' ||-->
<!--                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'auto')-->
<!--                        "-->
<!--                      >-->
<!--                        <div class="channel-list">-->
<!--                          <div-->
<!--                            class="channel-list-item interface-channel-trapezoid"-->
<!--                            v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                            :class="{-->
<!--                              disabled: isDisable,-->
<!--                              active: activeChannel(interfaceCapabilities[cpeInterface.id].selected.channels, channel)-->
<!--                            }"-->
<!--                            v-if="channel != 'auto'"-->
<!--                          >-->
<!--                            <input-->
<!--                              type="checkbox"-->
<!--                              class="interface-channel-checkbox"-->
<!--                              :disabled="isDisable"-->
<!--                              :value="channel"-->
<!--                              :id="`${cpeInterface.id}-${channel}`"-->
<!--                              :checked="interfaceCapabilities[cpeInterface.id].selected.channels === channel"-->
<!--                              v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                            />-->
<!--                            <label-->
<!--                              class-->
<!--                              :class="{ 'label-disabled': isDisable }"-->
<!--                              :for="`${cpeInterface.id}-${channel}`"-->
<!--                            >-->
<!--                              {{ channel }}-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div-->
<!--                        class="form-group"-->
<!--                        v-if="-->
<!--                          computedCapabilities[cpeInterface.id] &&-->
<!--                          interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                          (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT40' ||-->
<!--                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT40')-->
<!--                        "-->
<!--                      >-->
<!--                        <div class="channel-list">-->
<!--                          <div-->
<!--                            class="channel-list-item interface-channel-trapezoid__ht40"-->
<!--                            v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                            :class="{-->
<!--                              disabled: isDisable,-->
<!--                              active: activeChannel(interfaceCapabilities[cpeInterface.id].selected.channels, channel)-->
<!--                            }"-->
<!--                            v-if="channel != 'auto'"-->
<!--                          >-->
<!--                            <input-->
<!--                              type="checkbox"-->
<!--                              class="interface-channel-checkbox"-->
<!--                              :disabled="isDisable"-->
<!--                              :value="channel"-->
<!--                              :id="`${cpeInterface.id}-${channel}`"-->
<!--                              :checked="interfaceCapabilities[cpeInterface.id].selected.channels === channel"-->
<!--                              v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                            />-->
<!--                            <label-->
<!--                              class-->
<!--                              :class="{ 'label-disabled': isDisable }"-->
<!--                              :for="`${cpeInterface.id}-${channel}`"-->
<!--                            >-->
<!--                              {{ channel }}-->
<!--                              {{ channel + 4 }}-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div-->
<!--                        class="form-group"-->
<!--                        v-if="-->
<!--                          computedCapabilities[cpeInterface.id] &&-->
<!--                          interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                          interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80'-->
<!--                        "-->
<!--                      >-->
<!--                        <div class="channel-list">-->
<!--                          <div-->
<!--                            class="channel-list-item interface-channel-trapezoid__ht80"-->
<!--                            v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                            :class="{-->
<!--                              disabled: isDisable,-->
<!--                              active: activeChannel(interfaceCapabilities[cpeInterface.id].selected.channels, channel)-->
<!--                            }"-->
<!--                            v-if="channel != 'auto'"-->
<!--                          >-->
<!--                            <input-->
<!--                              type="checkbox"-->
<!--                              class="interface-channel-checkbox"-->
<!--                              :disabled="isDisable"-->
<!--                              :value="channel"-->
<!--                              :id="`${cpeInterface.id}-${channel}`"-->
<!--                              :checked="interfaceCapabilities[cpeInterface.id].selected.channels === channel"-->
<!--                              v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                            />-->
<!--                            <label-->
<!--                              class-->
<!--                              :class="{ 'label-disabled': isDisable }"-->
<!--                              :for="`${cpeInterface.id}-${channel}`"-->
<!--                            >-->
<!--                              {{ channel }}-->
<!--                              {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }}-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

                      <div
                        class="form-group"
                        v-if="
                          computedCapabilities[cpeInterface.id] &&
                          interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'
                        "
                      >
                        <div class="channel-list">
                          <div
                            class="channel-list-item interface-channel-trapezoid"
                            v-for="channel in computedCapabilities[cpeInterface.id].channels"
                            :class="{
                              disabled: isDisable,
                              active: activeChannel(interfaceCapabilities[cpeInterface.id].selected.channels, channel)
                            }"
                            v-if="channel != 'auto'"
                            :key="channel"
                          >
                            <input
                              type="checkbox"
                              class="interface-channel-checkbox"
                              :disabled="isDisable"
                              :value="channel"
                              :id="`${cpeInterface.id}-${channel}`"
                              :checked="interfaceCapabilities[cpeInterface.id].selected.channels === channel"
                              v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                            />
                            <label
                              class
                              :class="{ 'label-disabled': isDisable }"
                              :for="`${cpeInterface.id}-${channel}`"
                            >
                              {{ channel }}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div
                        class="form-group"
                        v-if="
                          computedCapabilities[cpeInterface.id] &&
                          interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                          (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT20' ||
                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT20' ||
                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE20' ||
                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'auto')
                        "
                      >
                        <div class="channel-list">
                          <div
                            class="channel-list-item interface-channel-trapezoid"
                            v-for="channel in computedCapabilities[cpeInterface.id].channels"
                            :class="{
                              disabled: isDisable,
                              active: activeChannel(interfaceCapabilities[cpeInterface.id].selected.channels, channel)
                            }"
                            v-if="channel != 'auto'"
                          >
                            <input
                              type="checkbox"
                              class="interface-channel-checkbox"
                              :disabled="isDisable"
                              :value="channel"
                              :id="`${cpeInterface.id}-${channel}`"
                              :checked="interfaceCapabilities[cpeInterface.id].selected.channels === channel"
                              v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                            />
                            <label
                              class
                              :class="{ 'label-disabled': isDisable }"
                              :for="`${cpeInterface.id}-${channel}`"
                            >
                              {{ channel }}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div
                        class="form-group"
                        v-if="
                          computedCapabilities[cpeInterface.id] &&
                          interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                          (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT40' ||
                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT40' ||
                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE40')
                        "
                      >
                        <div class="channel-list">
                          <div
                            class="channel-list-item interface-channel-trapezoid__ht40"
                            v-for="channel in computedCapabilities[cpeInterface.id].channels"
                            :class="{
                              disabled: isDisable,
                              active: activeChannel(interfaceCapabilities[cpeInterface.id].selected.channels, channel)
                            }"
                            v-if="channel != 'auto'"
                          >
                            <input
                              type="checkbox"
                              class="interface-channel-checkbox"
                              :disabled="isDisable"
                              :value="channel"
                              :id="`${cpeInterface.id}-${channel}`"
                              :checked="interfaceCapabilities[cpeInterface.id].selected.channels === channel"
                              v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                            />
                            <label
                              class
                              :class="{ 'label-disabled': isDisable }"
                              :for="`${cpeInterface.id}-${channel}`"
                            >
                              {{ channel }}
                              {{ channel + 4 }}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div
                        class="form-group"
                        v-if="
                          computedCapabilities[cpeInterface.id] &&
                          interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                          (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80' ||
                          interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE80')
                        "
                      >
                        <div class="channel-list">
                          <div
                            class="channel-list-item interface-channel-trapezoid__ht80"
                            v-for="channel in computedCapabilities[cpeInterface.id].channels"
                            :class="{
                              disabled: isDisable,
                              active: activeChannel(interfaceCapabilities[cpeInterface.id].selected.channels, channel)
                            }"
                            v-if="channel != 'auto'"
                          >
                            <input
                              type="checkbox"
                              class="interface-channel-checkbox"
                              :disabled="isDisable"
                              :value="channel"
                              :id="`${cpeInterface.id}-${channel}`"
                              :checked="interfaceCapabilities[cpeInterface.id].selected.channels === channel"
                              v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                            />
                            <label
                              class
                              :class="{ 'label-disabled': isDisable }"
                              :for="`${cpeInterface.id}-${channel}`"
                            >
                              {{ channel }}
                              {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div
                        class="form-group"
                        v-if="
                                  computedCapabilities[cpeInterface.id] &&
                                  interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                  (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80+80' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT160' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE80+80' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE160')
                                "
                      >
                        <div class="channel-list">
                          <div
                            class="channel-list-item interface-channel-trapezoid__ht160"
                            v-for="channel in computedCapabilities[cpeInterface.id].channels"
                            :class="{
                                      disabled: isDisable || updatedCpe.config.wifi_lock,
                                      active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel)
                                    }"
                            v-if="channel != 'auto'"
                          >
                            <input
                              type="checkbox"
                              class="interface-channel-checkbox"
                              :disabled="isDisable || updatedCpe.config.wifi_lock"
                              :value="channel"
                              :id="`${cpeInterface.id}-${channel}`"
                              @change="
                                        handlerChannels(
                                          isCisco,
                                          interfaceCapabilities[cpeInterface.id].selected.channels,
                                          channel,
                                          interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                                          cpeInterface.id
                                        )
                                      "
                              v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                            />
                            <label
                              class
                              :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                              :for="`${cpeInterface.id}-${channel}`"
                            >
                              {{ channel }}
                              {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }} {{ channel + 16
                              }} {{ channel + 20 }} {{ channel + 24 }} {{ channel + 28 }}
                            </label>
                          </div>
                        </div>
                      </div>


                      <div
                        class="form-group mb-2"
                        v-if="
                          interfaceCapabilities[cpeInterface.id].selected.power &&
                          interfaceCapabilities[cpeInterface.id].selected.power.range &&
                          Array.isArray(interfaceCapabilities[cpeInterface.id].selected.power.range)
                        "
                      >
                        <span class="m-0 mt-2">{{ $t('aps.txPowerAdjust') }}</span>

                        <Switch-component
                          v-model="interfaceCapabilities[cpeInterface.id].selected.power.auto"
                          :disabled="isDisable"
                          :label="$t('general.Auto')"
                          class="float-right"
                          :id="`power-auto-${cpeInterface.id}`"
                        />
                        <!--                                // изменения для задачи WNE-3314 (необходимо сделать минимальным значением txPower 1 dbm)-->
<!--                        <Vue-slider-->
<!--                          ref="slider"-->
<!--                          v-if="!isDisable && !interfaceCapabilities[cpeInterface.id].selected.power.auto"-->
<!--                          :disabled="isCisco"-->
<!--                          v-bind="TXPower"-->
<!--                          :max="computedCapabilities[cpeInterface.id].txPower"-->
<!--                          :min="computedCapabilities[cpeInterface.id].minTxPower || 0"-->
<!--                          tooltipDir="bottom"-->
<!--                          tooltip="always"-->
<!--                          formatter="{value} dbm"-->
<!--                          v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"-->
<!--                        ></Vue-slider>-->
<!--                        <Vue-slider-->
<!--                          ref="slider"-->
<!--                          v-else-->
<!--                          :disabled="true"-->
<!--                          v-bind="TXPower"-->
<!--                          :max="computedCapabilities[cpeInterface.id].txPower"-->
<!--                          :min="computedCapabilities[cpeInterface.id].minTxPower || 0"-->
<!--                          tooltipDir="bottom"-->
<!--                          tooltip="always"-->
<!--                          formatter="{value} dbm"-->
<!--                          v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"-->
<!--                        ></Vue-slider>-->
                        <Vue-slider
                          ref="slider"
                          v-if="!isDisable && !interfaceCapabilities[cpeInterface.id].selected.power.auto"
                          :disabled="isCisco"
                          v-bind="TXPower"
                          :max="computedCapabilities[cpeInterface.id].txPower"
                          :min="computedCapabilities[cpeInterface.id].minTxPower || 1"
                          tooltipDir="bottom"
                          tooltip="always"
                          formatter="{value} dbm"
                          v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"
                        ></Vue-slider>
                        <Vue-slider
                          ref="slider"
                          v-else
                          :disabled="true"
                          v-bind="TXPower"
                          :max="computedCapabilities[cpeInterface.id].txPower"
                          :min="computedCapabilities[cpeInterface.id].minTxPower || 1"
                          tooltipDir="bottom"
                          tooltip="always"
                          formatter="{value} dbm"
                          v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"
                        ></Vue-slider>
<!--                                // конец изменений по задаче WNE-3314 -->
                      </div>

<!--&lt;!&ndash;                              // todo убрать этот старый input (который теперь заменен select'ом) если не потребуется его восстановить (11.12.2023) &ndash;&gt;-->
<!--                      <div class="form-group">-->
<!--                        <label for="country">{{ $t('aps.country') }}</label>-->
<!--                        <input-->
<!--                          @input="changeCountryHandler(cpeInterface.id)"-->
<!--                          id="country"-->
<!--                          class="form-control"-->
<!--                          :disabled="isDisable"-->
<!--                          :class="{ 'select-disabled': isDisable }"-->
<!--                          v-model="interfaceCapabilities[cpeInterface.id].selected.country"-->
<!--                        />-->
<!--                      </div>-->

                      <div class="form-group" v-if="!isCisco">
                        <WSelect
                          @input="changeCountryHandler(cpeInterface.id)"
                          v-model="interfaceCapabilities[cpeInterface.id].selected.country"
                          :options="countriesCodesOptionsForSelectorInInterfaceRadioSettings"
                          :label="$t('aps.country')"
                          :disabled="isDisable"
                          customClass="form-control"
                          id="country"
                        />
                      </div>


                      <hr class="hr-settings mb-2" />

                      <div v-if="!isCisco">
                        <span class="h5">{{ $t('aps.advancedSettings') }}</span>

                        <div class="form-group mt-2 mb-2">
                          <label for="rate_control">
                            <b>{{ $t('aps.rateControl') }}</b>
                          </label>
                          <Vue-slider
                            v-if="rateControl[interfaceCapabilities[cpeInterface.id].selected.bandmode]"
                            :disabled="
                              isDisable ||
                              (interfaceCapabilities[cpeInterface.id].selected.bandmode != '11n' &&
                                interfaceCapabilities[cpeInterface.id].selected.bandmode != '11ac')
                            "
                            v-bind="rateControl[interfaceCapabilities[cpeInterface.id].selected.bandmode]"
                            tooltipDir="bottom"
                            tooltip="hover"
                            formatter="{value}"
                            id="rate_control"
                            v-model="interfaceCapabilities[cpeInterface.id].selected.require_mode"
                          ></Vue-slider>
                        </div>

                        <div class="form-group mb-0 mt-3">
                          <Switch-component
                            v-model="interfaceCapabilities[cpeInterface.id].selected.scanningconfig.enabled"
                            :disabled="isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')"
                            :label="$t('aps.backgroundScaning')"
                            :id="`background-scanning-${cpeInterface.id}`"
                          />
                        </div>
                        <div class="form-group mt-1 mb-0">
                          <Switch-component
                            v-model="advancedSettings[cpeInterface.id].maxClientsEnabled"
                            :disabled="isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')"
                            :label="$t('aps.maxClients')"
                            :id="`max-clients-${cpeInterface.id}`"
                            @change="
                              resetMaxClients(advancedSettings[cpeInterface.id].maxClientsEnabled, cpeInterface.id)
                            "
                          />
                        </div>
                        <transition name="interface">
                          <div class="form-group mb-2" v-if="advancedSettings[cpeInterface.id].maxClientsEnabled">
                            <label for="maxclients">{{ $t('aps.limitperWlan') }}</label>
                            <input
                              id="maxclients"
                              class="form-control"
                              :disabled="isDisable || !advancedSettings[cpeInterface.id].maxClientsEnabled"
                              name="maxclients"
                              v-validate="'numeric|between:0,2007'"
                              :class="{
                                'select-disabled': isDisable,
                                input: true,
                                'is-danger': errors.has('description')
                              }"
                              v-model="interfaceCapabilities[cpeInterface.id].selected.maxclients"
                            />
                            <span v-show="errors.has('maxclients')" class="help is-danger">
                              {{ errors.first('maxclients') }}
                            </span>
                          </div>
                        </transition>
                        <!--                                max_inactivity-->
                        <div class="form-group mt-1">
                          <label for="maxinactivity">
                            {{ $t('aps.maxinactivityTitle') }}
                            <info
                              @clicked="showMaxinactivityModalInfoDetails"
                              :content="$t('aps.maxinactivityTooltip')"
                            />
                          </label>
                          <input
                            id="maxinactivity"
                            class="form-control"
                            :disabled="isDisable || updatedCpe.config.wifi_lock"
                            name="maxinactivity"
                            :data-vv-as="$t('aps.maxinactivityTitle')"
                            :placeholder="$t('aps.maxinactivityPlaceholder')"
                            v-validate="'min_value:0|numeric'"
                            :class="{
                              input: true,
                              'is-danger': errors.has('description')
                            }"
                            v-model.number.trim="interfaceCapabilities[cpeInterface.id].selected.max_inactivity"
                          />
                          <span v-show="errors.has('maxinactivity')" class="help is-danger">
                            {{ errors.first('maxinactivity') }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="actions-buttons actions-buttons__multiple"
                    :class="{ 'actions-buttons__operator': isDisable }"
                  >
                    <div>
                      <WButton success outline
                        v-if="!isDisable"
                        @click="updateCpesRadioSettings"
                      >
                        <span :class="{ invisible: muteEdit }">{{ $t('aps.save') }}</span>
                        <span v-if="muteEdit" class="loader loader--mini"></span>
                      </WButton>
                    </div>
                    <div>
                      <WButton secondary outline @click="editCpesSettingsMode = false">
                        <span v-if="isDisable">{{ $t('aps.close') }}</span>
                        <span v-else>{{ $t('aps.back') }}</span>
                      </WButton>
                    </div>
                  </div>
                </div>

                <!--END Multiple  Radio Settings -->

                <div class="card-block" v-if="assignCommonWlanMode">
                  <div class="h4 m-0">{{ $t('aps.assignCommonWlans') }}</div>
                  <small class="text-muted">{{ $t('aps.assignCommonWlansHint') }}</small>
                  <p class="text-danger" v-if="checkRfCollision()">{{ $t('aps.assignCommonWlansWarn') }}</p>

                  <form>
                    <div>
                      <label class>
                        <strong>{{ $t('aps.wlansCaption') }}</strong>
                      </label>
                      <a href="#" class="ml-q pull-right" @click.prevent="isWlanDescription = !isWlanDescription">
                        <span v-if="!isWlanDescription">{{ $t('aps.showDescription') }}</span>
                        <span v-else>{{ $t('aps.hideDescription') }}</span>
                      </a>
                    </div>
<!--                    <Multiselect-->
<!--                      class="mb-1"-->
<!--                      v-model="commonSelectedWlans"-->
<!--                      :multiple="true"-->
<!--                      :options="wlansList || []"-->
<!--                      :max="8"-->
<!--                      @input="updateCpeInterface"-->
<!--                      label="ssid"-->
<!--                      :customLabel="ssidWithDescription"-->
<!--                      trackBy="ssid"-->
<!--                      :closeOnSelect="false"-->
<!--                      :hideSelected="true"-->
<!--                      :placeholder="$t('aps.wlansSelectorPlaceHolder')"-->
<!--                    ></Multiselect>-->
<!--                    <Multiselect-->
<!--                      class="mb-1"-->
<!--                      v-model="commonSelectedWlans"-->
<!--                      :multiple="true"-->
<!--                      :options="wlansListForWlansSearchForMultiedit || []"-->
<!--                      :max="8"-->
<!--                      @input="updateCpeInterface"-->
<!--                      label="ssid"-->
<!--                      :customLabel="ssidWithDescription"-->
<!--                      :internalSearch = "false"-->
<!--                      trackBy="ssid"-->
<!--                      :closeOnSelect="false"-->
<!--                      :hideSelected="true"-->
<!--                      :placeholder="$t('aps.wlansSelectorPlaceHolder')"-->
<!--                      :loading = "loadingDataForWlanListForMultiedit"-->
<!--                      @search-change = "asyncSearchWlanWhenSearchChangeForMultiedit"-->
<!--                      @open="asyncSearchWlanWhenOpenSelectorListForMultiedit"-->
<!--                    >-->
<!--                      <template v-slot:noOptions>-->
<!--                        {{ $t('general.noOptionsForSearch') }}-->
<!--                      </template>-->
<!--                      <template v-slot:noResult>-->
<!--                        {{ $t('general.noResultForSearch') }}-->
<!--                      </template>-->
<!--                    </Multiselect>-->
<!--                    <Multiselect-->
<!--                      class="mb-1"-->
<!--                      :id="'multiEditWlanSelector'"-->
<!--                      v-model="commonSelectedWlans"-->
<!--                      :multiple="true"-->
<!--                      :options="wlansListForWlansSearchForMultiedit || []"-->
<!--                      :max="8"-->
<!--                      @input="updateCpeInterface"-->
<!--                      label="ssid"-->
<!--                      :customLabel="ssidWithDescription"-->
<!--                      :internalSearch="false"-->
<!--                      trackBy="ssid"-->
<!--                      :closeOnSelect="false"-->
<!--                      :hideSelected="true"-->
<!--                      :placeholder="$t('aps.wlansSelectorPlaceHolder')"-->
<!--                      :loading="loadingDataForWlanListForMultiedit"-->
<!--                      @search-change="asyncSearchWlanWhenSearchChangeForMultiedit"-->
<!--                      @open="asyncSearchWlanWhenOpenSelectorListForMultiedit"-->
<!--                    >-->
<!--                      <template v-slot:noOptions>-->
<!--                        {{ $t('general.noOptionsForSearch') }}-->
<!--                      </template>-->
<!--                      <template v-slot:noResult>-->
<!--                        {{ $t('general.noResultForSearch') }}-->
<!--                      </template>-->
<!--                    </Multiselect>-->
                    <Multiselect
                      class="mb-1"
                      :id="'multiEditWlanSelector'"
                      v-model="commonSelectedWlans"
                      :multiple="true"
                      :options="wlansListForWlansSearchForMultiedit || []"
                      :max="8"
                      @input="updateCpeInterface"
                      label="ssid"
                      :customLabel="ssidWithDescription"
                      :internalSearch="false"
                      trackBy="ssid"
                      :closeOnSelect="false"
                      :hideSelected="true"
                      :placeholder="$t('aps.wlansSelectorPlaceHolder')"
                      :loading="loadingDataForWlanListForMultiedit"
                      @search-change="asyncSearchWlanWhenSearchChangeForMultiedit"
                      @open="asyncSearchWlanWhenOpenSelectorListForMultiedit"
                    >
                      <template v-slot:noOptions>
                        {{ $t('general.noOptionsForSearch') }}
                      </template>
                      <template v-slot:noResult>
                        {{ $t('general.noResultForSearch') }}
                      </template>
                    </Multiselect>
                    <div
                      class="actions-buttons actions-buttons__multiple"
                      :class="{ 'actions-buttons__operator': isDisable }"
                    >
                      <div>
                        <WButton success outline
                          v-if="!isDisable"
                          @click="assignCommonWlan"
                        >
                          <span :class="{ invisible: muteEdit }">{{ $t('aps.update') }}</span>
                          <span v-if="muteEdit" class="loader loader--mini"></span>
                        </WButton>
                      </div>
                      <div>
                        <WButton secondary outline @click="assignCommonWlanMode = false">
                          <span v-if="isDisable">{{ $t('aps.close') }}</span>
                          <span v-else>{{ $t('aps.back') }}</span>
                        </WButton>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="card-block" v-if="setTemplateMode">
                  <div class="h4 m-0">{{ $t('aps.assignTemplate') }}</div>
                  <small class="text-muted">{{ $t('aps.assignTemplateHint') }}</small>

                  <div class="form-group mt-1">
                    <label for="templates">
                      {{ $t('aps.templates') }}
                      <span v-if="updatedCpe.model.name"></span>
                      for
                      <strong>{{ updatedCpe.model.name }}</strong>
                    </label>
                    <select v-model="setTemplateId" name="templates" id="templates" class="form-control">
                      <option :value="''" disabled checked>{{ $t('aps.modalSelectTemplatePlaceHolder') }}</option>
                      <option v-for="template in templatesList" :value="template.id">
                        {{ commonService.templateNameById(template.id) }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="actions-buttons actions-buttons__multiple"
                    :class="{ 'actions-buttons__operator': isDisable }"
                  >
                    <div>
                      <WButton success outline
                        v-if="!isDisable"
                        @click="setTemplatesToCPEs(setTemplateId, selectedCpesId)"
                        :disabled="!setTemplateId"
                      >
                        <span :class="{ invisible: muteEdit }">{{ $t('aps.update') }}</span>
                        <span v-if="muteEdit" class="loader loader--mini"></span>
                      </WButton>
                    </div>
                    <div>
                      <WButton secondary outline @click="setTemplateMode = false">
                        <span v-if="isDisable">{{ $t('aps.close') }}</span>
                        <span v-else>{{ $t('aps.back') }}</span>
                      </WButton>
                    </div>
                  </div>
                </div>

                <div class="card-block" v-if="addTagsModal">
                  <div class="h4 mb-2">{{ $t('aps.editTags') }}</div>
                  <div class="modal-tags">
                    <p class="h5">{{ $t('tags.currentTags') }}:</p>
                    <span
                      v-for="(tag, index) in newTags"
                      class="tag tag--item mr-q"
                      @click.prevent="removeTagMultiple"
                      :data-index="index"
                    >
                      <i class="fa fa-tag"></i>
                      <i class="fa fa-remove"></i>
                      {{ tag }}
                    </span>
                    <div class="input-group mt-2">
                      <input
                        type="text"
                        name="tags"
                        class="form-control input-tag"
                        :placeholder="$t('aps.modalAddTagPlaceHolder')"
                        :disabled="isDisable"
                        @keyup.13="addTagMultiple"
                        v-validate="'max:15'"
                        data-vv-validate-on="input"
                        v-model="newTag"
                        :class="{ input: true, 'is-danger': errors.has('tags') }"
                      />
                      <span class="input-group-btn">
                        <WButton success
                          customClass="mb-0"
                          @click="addTagMultiple"
                          :disabled="(this.newTag == '')"
                        >
                        {{ $t('general.add') }}
                        </WButton>
                      </span>
                    </div>
                    <span v-show="errors.has('tags')" class="help is-danger is-danger__block">
                      {{ errors.first('tags') }}
                    </span>
                    <div class="tags__availabel mt-1">
                      <p class="h6">{{ $t('tags.availlableTags') }}:</p>
                      <span
                        v-for="tag in cpesMultipleTagsList"
                        class="tag tag--add mr-q"
                        @click="addExistTagMultiple(tag)"
                      >
                        <i class="fa fa-tag"></i>
                        <i class="fa fa-plus"></i>
                        {{ tag }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="actions-buttons actions-buttons__multiple"
                    :class="{ 'actions-buttons__operator': isDisable }"
                  >
                    <div>
                      <WButton success outline
                        v-if="!isDisable"
                        @click="assignTags"
                        :disabled="!newTags.length"
                      >
                        <span :class="{ invisible: muteEdit }">{{ $t('aps.update') }}</span>
                        <span v-if="muteEdit" class="loader loader--mini"></span>
                      </WButton>
                    </div>
                    <div>
                      <WButton secondary outline
                        @click="addTagsModal = false"
                      >
                        <span v-if="isDisable">{{ $t('aps.close') }}</span>
                        <span v-else>{{ $t('aps.back') }}</span>
                      </WButton>
                    </div>
                  </div>
                </div>

                <div class="card-block" v-if="logSettingsMode">
                  <div class="h4 m-0 mb-2">{{ $t('aps.editLogAndStatSettings') }}</div>

                  <statLogLbsConfig
                    v-if="updatedCpe"
                    :updatedCpeConfig="updatedCpe.config"
                    :currentCpeConfig="cpeEditCurrent.config"
                    :isEditShow="statLogLbsmodal"
                    :isRootUser="isRootUser"
                    :lbsFilterList="configFilterList"
                    :isDisable="isDisable"
                    :isOperator="isOperator"
                    :updatedCpe="updatedCpe"
                    :currentCpe="cpeEditCurrent"
                  ></statLogLbsConfig>

                  <div
                    class="actions-buttons actions-buttons__multiple"
                    :class="{ 'actions-buttons__operator': isDisable }"
                  >
                    <div>
                      <WButton success outline
                        v-if="!isDisable"
                        @click="updateLogSettings"
                      >
                        <span :class="{ invisible: muteEdit }">{{ $t('aps.update') }}</span>
                        <span v-if="muteEdit" class="loader loader--mini"></span>
                      </WButton>
                    </div>
                    <div>
                      <WButton secondary outline
                        @click="logSettingsMode = false"
                      >
                        {{ $t('aps.back') }}
                      </WButton>
                    </div>
                  </div>
                </div>

                <!--                position coordinates -->
                <div class="card-block" v-if="setPositionSettingsMode && isShowMapsAndLatitudeLongitudeSettings">
                  <div class="h4 m-0 mb-2">{{ $t('aps.positionBlockHeader') }}</div>
                  <div class="mt-0" v-if="setPositionSettingsMode">
                    <!--                          <div class="form-group">-->
                    <!--                            <label for="fw_timeout">{{ $t('general.Timeout') }} ({{ $t('general.insec') }})</label>-->
                    <!--                            <input-->
                    <!--                              type="text"-->
                    <!--                              id="fw_timeout"-->
                    <!--                              placeholder="Enter timeout"-->
                    <!--                              name="fw_timeout"-->
                    <!--                              data-vv-as="Timeout"-->
                    <!--                              class="form-control"-->
                    <!--                              v-validate="'required|between:600,86400'"-->
                    <!--                              :class="{ input: true, 'is-danger': errors.has('description') }"-->
                    <!--                              :disabled="-->
                    <!--                                isDisable ||-->
                    <!--                                (!updatedCpe.connected && updatedCpe.config_status == 'empty') ||-->
                    <!--                                updatedCpe.config.firmware.mode === 'off'-->
                    <!--                              "-->
                    <!--                              v-model.number="updatedCpe.config.firmware.timeout"-->
                    <!--                            />-->
                    <!--                            <span v-show="errors.has('fw_timeout')" class="help is-danger">-->
                    <!--                              {{ errors.first('fw_timeout') }}-->
                    <!--                            </span>-->
                    <!--                          </div>-->
                    <div class="form-group" v-if="areLocationsVisible">
                      <label>
                        <span>{{ $t('aps.positionBlockCopyFromLocationCaption') }}</span>
                        <info :content="$t('aps.positionBlockCopyFromSelectedLocationButtonInfo')" />
                      </label>
                      <!--                            <div>-->
                      <!--                              <button :disabled="isDisable" type="button" class="btn btn-info btn-sm"-->
                      <!--                                      @click="copyCoordinatesFromLocationForMultiEdit">-->
                      <!--                                <span>{{ $t('aps.positionBlockCopyFromLocationButtonCaption') }} </span>-->
                      <!--                                <span>{{getLocationNameFromLocationId(updatedCpe.base_location)}}</span>-->
                      <!--                              </button>-->
                      <!--                            </div>-->

                      <div>
                        <select
                          v-model="locationForMultiEditPosition"
                          class="form-control"
                          ref="locationsSelect"
                          :disabled="isDisable"
                        >
                          <option :selected="true" :value="'Select Location'">
                            {{ $t('aps.positionBlockSelectLocation') }}
                          </option>
                          <option v-for="location in locationsList" :value="location.id" :key="location.id">
                            {{ location.name }}
                          </option>
                        </select>
                      </div>
                      <div class="mt-h">
                        <WButton info
                          @click="copyCoordinatesFromLocationForMultiEdit"
                          :disabled="isDisable"
                        >
                          {{ $t('aps.positionBlockCopyFromSelectedLocationButtonCaption') }}
                        </WButton>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="addrSearchSelectorInCpeMultiEdit">
                        <span>{{ $t('aps.positionBlockSearchAddressCaption') }}</span>
                      </label>
                      <SelectComponentForCompanyAndAddressSearch
                        v-if="setPositionSettingsMode && addressSelectorShowing"
                        id="addrSearchSelectorInCpeMultiEdit"
                        v-model="selectedAddressInSelector"
                        :disabled="isDisable"
                        class="w-100"
                        option-id-name="place_id"
                        track-by="display_name"
                        :placeholder="$t('aps.positionBlockEnterAddressToSearch')"
                        :async-function="requestCoordsByAddress.bind(this)"
                        @select="selectAddressInSelectorForMultiEdit"
                        :noOptionsFound="$t('location.addressNotFound')"
                      />
                    </div>

                    <div style="position: relative;">
                      <loader-spinner-small v-if="showingSpinerOverCoordinates"></loader-spinner-small>

                      <div class="form-group">
                        <label for="latMultiEdit">{{ $t('aps.positionBlockCoordsLatFull') }}</label>
                        <input
                          :data-vv-as="$t('aps.positionBlockCoordsLatFull')"
                          :disabled="isDisable"
                          id="latMultiEdit"
                          name="latMultiEdit"
                          step="0.0000000000001"
                          type="number"
                          class="input form-control"
                          v-model.number="latitudeForMultiEdit"
                          v-validate="'between:-90,90'"
                          data-vv-validate-on="input"
                        />
                        <!--                            <span-->
                        <!--                              v-tooltip.top-center="{ content: errors.first('lat') }"-->
                        <!--                              v-show="errors.has('lat')"-->
                        <!--                              class="help is-danger error_tooltip"-->
                        <!--                            >-->
                        <!--                            <i class="fa fa-warning text-error"/>-->
                        <!--                             </span>-->
                        <span v-show="errors.has('latMultiEdit')" class="help is-danger">
                          {{ errors.first('latMultiEdit') }}
                        </span>
                      </div>
                      <div class="form-group">
                        <label for="lngMultiEdit">{{ $t('aps.positionBlockCoordsLngFull') }}</label>
                        <input
                          :data-vv-as="$t('aps.positionBlockCoordsLngFull')"
                          :disabled="isDisable"
                          id="lngMultiEdit"
                          name="lngMultiEdit"
                          step="0.0000000000001"
                          type="number"
                          class="input form-control"
                          v-model.number="longitudeForMultiEdit"
                          v-validate="'between:-180,180'"
                          data-vv-validate-on="input"
                        />
                        <!--                            <span-->
                        <!--                              v-tooltip.top-center="{ content: errors.first('lng') }"-->
                        <!--                              v-show="errors.has('lng')"-->
                        <!--                              class="help is-danger error_tooltip"-->
                        <!--                            >-->
                        <!--                            <i class="fa fa-warning text-error"/>-->
                        <!--                           </span>-->
                        <span v-show="errors.has('lngMultiEdit')" class="help is-danger">
                          {{ errors.first('lngMultiEdit') }}
                        </span>
                      </div>
                      <div class="form-group">
                        <Map-cpe
                          @markerDrag="onMarkerDragForSetPositionForMultiEdit"
                          :coords-lat="this.latitudeForMultiEdit || 0"
                          :coords-lng="this.longitudeForMultiEdit || 0"
                          v-if="setPositionSettingsMode"
                        ></Map-cpe>
                      </div>
                    </div>
                  </div>
                  <div
                    class="actions-buttons actions-buttons__multiple"
                    :class="{ 'actions-buttons__operator': isDisable }"
                  >
                    <div>
                      <WButton success outline
                        v-if="!isDisable"
                        @click="setPositionForMultiEdit"
                        :disabled="disabledSaveButtonForPositionSetBlockInMultiEdit"
                      >
                        <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
                        <span v-if="muteEdit" class="loader loader--mini"></span>
                      </WButton>
                    </div>
                    <div>
                      <WButton secondary outline @click="setPositionSettingsHide">
                        {{ $t('aps.back') }}
                      </WButton>
                    </div>
                  </div>
                </div>

                <div class="card-block" v-if="accessControlMode">
                  <div class="h4 m-0 mb-2">{{ $t('aps.accessControlSettings') }}</div>

                  <accessControl
                    v-if="updatedCpe"
                    :updatedCpeConfig="updatedCpe.config"
                    :isConnected="updatedCpe.connected"
                    :firewallL3List="firewallL3List"
                    :currentCpeConfig="cpeEditCurrent.config"
                    :isEditShow="isFirewallShow"
                    :isRootUser="isRootUser"
                    :firewallsList="firewallsList"
                    :isDisable="isDisable"
                    :isOperator="isOperator"
                  ></accessControl>

                  <div
                    class="actions-buttons actions-buttons__multiple"
                    :class="{ 'actions-buttons__operator': isDisable }"
                  >
                    <div>
                      <WButton success outline
                        v-if="!isDisable"
                        @click="updateAccessControl"
                      >
                        <span :class="{ invisible: muteEdit }">{{ $t('aps.update') }}</span>
                        <span v-if="muteEdit" class="loader loader--mini"></span>
                      </WButton>
                    </div>
                    <div>
                      <WButton secondary outline @click="accessControlMode = false">
                        {{ $t('aps.back') }}
                      </WButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      close-btn
      title="Delete Common Wlans"
      class="modal-danger"
      v-model="commonDeletingWlansModal"
      :okText="modalOkTranslation"
      :cancelText="modalCancelTranslation"
      @ok="deleteCommonWlans"
      @cancel="cancelDeleteCommonWlans"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.deleteCommonWlans') }}</h4>
      {{ $t('aps.deleteWlansConfirm') }}
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary @click="cancelDeleteCommonWlans">
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline @click="deleteCommonWlans">
          {{ $t('general.delete') }}
        </WButton>
      </div>
    </Modal>

    <Modal
      close-btn
      title="Delete Common Tags"
      class="modal-danger"
      v-model="commonDeletingTagsModal"
      :okText="modalOkTranslation"
      :cancelText="modalCancelTranslation"
      @ok="deleteCommonTags"
      @cancel="cancelDeleteCommonTags"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.deleteCommonTags') }}</h4>
      {{ $t('aps.deleteTagsConfirm') }}
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary @click="cancelDeleteCommonTags">
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline @click="deleteCommonTags">
          {{ $t('general.delete') }}
        </WButton>
      </div>
    </Modal>

    <Modal
      close-btn
      title="Delete CPEs"
      v-model="commonDeletingCpesModal"
      :okText="modalOkTranslation"
      :cancelText="modalCancelTranslation"
      @ok="deleteCommonCpes"
      class="modal-danger"
      @cancel="cancelDeleteCommonCpes"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.deleteCpes') }}</h4>

      <p>{{ $t('aps.deleteCpesConfirm') }} {{ this.selectedCpesId.length }} {{ $t('aps.cpe') }}?</p>

      <Warning-line class="mb-0">
        <i18n path="aps.warnStatistics" tag="span" for="aps.warnStatisticsLoss">
          <span class="font-weight-bold text-danger">{{ $t('aps.warnStatisticsLoss') }}</span>
        </i18n>
      </Warning-line>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary @click="cancelDeleteCommonCpes">
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteCommonCpes"
          :disabled="muteCpesDelete"
        >
          <span :class="{ invisible: muteCpesDelete }">{{ $t('general.delete') }}</span>
          <span v-if="muteCpesDelete" class="loader loader--mini"></span>
        </WButton>
      </div>
    </Modal>

    <Modal
      close-btn
      title="Delete CPE"
      v-model="deletingCpeModal"
      :okText="modalOkTranslation"
      :cancelText="modalCancelTranslation"
      @ok="deleteCpe"
      class="modal-danger"
      @cancel="cancelDeleteCpe"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.deleteCpe') }}</h4>

      <p>
        {{ $t('aps.deleteCpeConfirm') }}
        <strong v-if="this.cpeForDelete">{{ cpeObj(this.cpeForDelete).name || cpeObj(this.cpeForDelete).id }}</strong>
        ?
      </p>

      <WarningLine class="mb-0">
        <i18n path="aps.warnStatistics" tag="span" for="aps.warnStatisticsLoss">
          <span class="font-weight-bold text-danger">{{ $t('aps.warnStatisticsLoss') }}</span>
        </i18n>
      </WarningLine>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary @click="cancelDeleteCpe">
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline @click="deleteCpe">
          {{ $t('general.delete') }}
        </WButton>
      </div>
    </Modal>

    <Modal close-btn @cancel="rebootCpeAgentModal = false" title="Reboot CPE Agent" v-model="rebootCpeAgentModal" class="modal-info" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.rebootCpeAgent') }}</h4>

      <div>
        <div v-if="selectedCpesId && selectedCpesId.length">
          <p>{{ $t('aps.rebootCpeAgentModalText') }}:</p>
          <ul class="list-unstyled">
            <li v-for="cpeId in selectedCpesId" :key="cpeId">
              <strong>{{ cpeObj(cpeId).name || cpeId }}</strong>
            </li>
          </ul>
        </div>
        <div v-else-if="cpeSelectedId">
          <p>
            {{ $t('aps.rebootCpeAgentModalText') }}:
            <strong v-if="cpeSelectedId">{{ cpeObj(cpeSelectedId).name || cpeSelectedId }}</strong>
          </p>
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary @click="rebootCpeAgentModal = false">
          {{ $t('general.cancel') }}
        </WButton>
        <WButton info outline @click="rebootCpeAgent(selectedCpesId.length ? selectedCpesId : [cpeSelectedId])">
          {{ $t('general.apply') }}
        </WButton>
      </div>
    </Modal>

    <Modal close-btn @cancel="rebootCpeRedundantModal = false" title="Reboot via Redundant Channel" v-model="rebootCpeRedundantModal" class="modal-info" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.rebootCpeRedundant') }}</h4>

      <div>
        <div v-if="selectedCpesId && selectedCpesId.length">
          <p>{{ $t('aps.rebootCpeRedundantModalText') }}:</p>
          <ul class="list-unstyled">
            <li v-for="cpeId in selectedCpesId" :key="cpeId">
              <strong>{{ cpeObj(cpeId).name || cpeId }}</strong>
            </li>
          </ul>
        </div>
        <div v-else-if="cpeSelectedId">
          <p>
            {{ $t('aps.rebootCpeRedundantModalText') }}:
            <strong v-if="cpeSelectedId">{{ cpeObj(cpeSelectedId).name || cpeSelectedId }}</strong>
          </p>
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="rebootCpeRedundantModal = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton info outline
          @click="rebootCpeRedundant(selectedCpesId.length ? selectedCpesId : [cpeSelectedId])"
        >
          {{ $t('general.apply') }}
        </WButton>
      </div>
    </Modal>

    <Modal close-btn @cancel="IPsecModal = false" title="IPsec" v-model="IPsecModal" class="modal-info" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.IPsec') }}</h4>

      <form @submit.prevent="updateCPEIPsec(selectedCpesId.length ? selectedCpesId : [cpeSelectedId], IPsecOption)">
        <h3>{{ $t('aps.IPsecModalLabel') }}:</h3>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              id="IPsec-turnOn"
              name="IPsec"
              v-model="IPsecOption"
              value="turnOn"
              type="radio"
              class="form-check-input"
            />
            <label for="IPsec-turnOn" class="custom-control-label">{{ $t('aps.IPsecTurnOn') }}</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input
              id="IPsec-turnOnUnencrypted"
              name="IPsec"
              v-model="IPsecOption"
              value="turnOnUnencrypted"
              type="radio"
              class="form-check-input"
            />
            <label for="IPsec-turnOnUnencrypted" class="custom-control-label">
              {{ $t('aps.IPsecTurnOnUnencrypted') }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input
              id="IPsec-turnOff"
              name="IPsec"
              v-model="IPsecOption"
              value="turnOff"
              type="radio"
              class="form-check-input"
            />
            <label for="IPsec-turnOff" class="custom-control-label">{{ $t('aps.IPsecTurnOff') }}</label>
          </div>

          <div v-if="selectedCpesId && selectedCpesId.length" class="mt-1">
            <p>{{ $t('aps.forFollowingCPEs') }}:</p>
            <ul class="list-unstyled">
              <li v-for="cpeId in selectedCpesId" :key="cpeId">
                <strong>{{ cpeObj(cpeId).name || cpeId }}</strong>
              </li>
            </ul>
          </div>
          <div v-else-if="cpeSelectedId" class="mt-1">
            <p>
              {{ $t('aps.forFollowingCPEs') }}:
              <strong v-if="cpeSelectedId">{{ cpeObj(cpeSelectedId).name || cpeSelectedId }}</strong>
            </p>
          </div>
        </div>
      </form>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="IPsecModal = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton info outline
          @click="updateCPEIPsec(selectedCpesId.length ? selectedCpesId : [cpeSelectedId], IPsecOption)"
          :disabled="!IPsecOption"
        >
          {{ $t('general.apply') }}
        </WButton>
      </div>
    </Modal>

    <Modal close-btn @cancel="handleCloseConfigScript" title="Send Config Script" v-model="configModal" class="modal-info" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.configModalTitle') }}</h4>

      <form @submit="onSubmitConfigScriptForm" @keyup.13="onEnterKeyUpOnConfigScriptForm">
        <loader-spinner v-if="isSendCPEConfigScriptFromCpesExecuting" centered></loader-spinner>
        <label class="h6" for="configCommand">{{ $t('aps.configModalLabel') }}:</label>
        <input
          id="configCommand"
          name="configCommand"
          v-model.trim="configCommand"
          type="text"
          class="form-control input"
        />

        <div v-if="selectedCpesId && selectedCpesId.length" class="mt-1">
          <p>{{ $t('aps.forFollowingCPEs') }}:</p>
          <ul class="list-unstyled">
            <li v-for="cpeId in selectedCpesId" :key="cpeId">
              <strong>{{ cpeObj(cpeId).name || cpeId }}</strong>
            </li>
          </ul>
        </div>
        <div v-else-if="cpeSelectedId" class="mt-1">
          <p>
            {{ $t('aps.forFollowingCPEs') }}:
            <strong v-if="cpeSelectedId">{{ cpeObj(cpeSelectedId).name || cpeSelectedId }}</strong>
          </p>
        </div>
      </form>
      <template v-if="selectedCpesId.length <= 1 && commandResult">
        <h6 class="mt-1">{{ $t('aps.commandOutput') }}:</h6>
        <Alert class="alert-info overflowY-auto mt-1" style="max-height: 500px;">
          <!--          <template #text>-->
          <!--            <span v-for="(row, id) of commandResult.split('\n')" class="output-row" :key="id">-->
          <!--              {{ row }}-->
          <!--            </span>-->
          <!--          </template>-->
          <!--          <template #text>-->
          <!--            <span v-for="(row, id) of commandResult.split('\n')" class="output-row" :key="id">-->
          <!--             <span v-for="(word, wordIndex) of row.split(' ')" :key="wordIndex">-->
          <!--               <span></span>-->
          <!--               <span v-for="(letter, letterIndex) of word.split('')" :key="letterIndex">-->
          <!--                 <span class="ml-1" v-if="letter === '\t'"></span>-->
          <!--                 <span v-else>{{letter}}</span>-->
          <!--             </span>-->
          <!--            </span>-->
          <!--            </span>-->
          <!--          </template>-->
          <template #text>
            <span v-for="(row, id) of commandResult.split('\n')" class="output-row" :key="id">
              <span v-for="(letter, letterIndex) of row.split('')" :key="letterIndex">
                <span class="command-result-tab-margin" v-if="letter === '\t'"></span>
                <span v-else>{{ letter }}</span>
              </span>
            </span>
          </template>
        </Alert>
      </template>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="handleCloseConfigScript"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton info outline
          @click="issueCPECommand(selectedCpesId.length ? selectedCpesId : [cpeSelectedId], configCommand)"
          :disabled="!configCommand || isSendCPEConfigScriptFromCpesExecuting"
        >
          {{ $t('general.submit') }}
        </WButton>
      </div>
    </Modal>

    <Modal
      close-btn
      @cancel="handleCloseSyncTimeModal"
      v-if="syncTimeModalShowing"
      title="Sync time"
      v-model="syncTimeModalShowing"
      class="modal-info"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.syncTime') }}</h4>
       <loader-spinner v-if="isShowSpinnerOverSyncTimeModal"></loader-spinner>
      <div v-if="cpeSelectedId" class="mt-1">
          <p>
            {{ $t('aps.syncTimeForCPES') }}:
            <strong v-if="cpeSelectedId">{{ cpeObj(cpeSelectedId).name || cpeSelectedId }}</strong>
          </p>
        </div>
      <div v-else-if="selectedCpesId && selectedCpesId.length" class="mt-1">
        <p>{{ $t('aps.forFollowingCPEs') }}:</p>
        <div class="">
          <span v-for="(cpeId, index) in selectedCpesId" :key="cpeId">
            <strong v-if="index !== 0">,&nbsp;</strong>
            <strong>{{ cpeObj(cpeId).name || cpeId }}</strong>
          </span>
        </div>
      </div>
      <div v-if="syncTimeCommandResult !== undefined">
        <Alert class="alert-success overflowY-auto mt-1" style="max-height: 500px;">
          <template #text>
            <div>{{$t('aps.syncTimeSuccessMsg')}}:&nbsp; <span v-if="syncTimeCommandResult !== undefined">{{covertSyncTimeTimestampToDateTimeForShow(syncTimeCommandResult)}}</span></div>
          </template>
        </Alert>
      </div>
      <div v-if="syncTimeCommandError !== undefined">
        <Alert class="alert-danger overflowY-auto mt-1" style="max-height: 500px;">
          <template #text>
            <span>{{syncTimeCommandError}}</span>
          </template>
        </Alert>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="handleCloseSyncTimeModal"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton info outline
          @click="syncTimeButtonHandler(selectedCpesId.length ? selectedCpesId : [cpeSelectedId])"
          :disabled="isBlockButtonsInSyncTimeModal"
        >
          {{ $t('aps.syncTimeButton') }}
        </WButton>
      </div>
    </Modal>

    <Modal
      close-btn
      @cancel="handleCloseSetTimeUsingNTPModal"
      v-if="setTimeUsingNTPModalShowing"
      title="Set Time Using NTP"
      v-model="setTimeUsingNTPModalShowing"
      class="modal-info"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.setTimeUsingNTP') }}</h4>
      <loader-spinner v-if="isSetTimeUsingNTPRequestExecuting" centered></loader-spinner>
      <form @submit="onSubmitSetTimeUsingNTPForm">
        <label class="h6" for="ntpServerAddressForsetTimeUsingNTP">
          {{ $t('aps.setTimeUsingNTPEnterServerAddress') }}:
        </label>
        <input
          id="ntpServerAddressForsetTimeUsingNTP"
          name="ntpServerAddressForsetTimeUsingNTP"
          v-model.trim="ntpServerAddressForsetTimeUsingNTP"
          type="text"
          class="form-control input"
          v-validate.disable="'ipv4orDomainNameForNtpServer'"
          :placeholder="$t('aps.ntpServerAddressPlaceholder')"
          :class="{ input: true, 'is-danger': errors.has('ntpServerAddressForsetTimeUsingNTP') }"
        />
        <span v-show="errors.has('ntpServerAddressForsetTimeUsingNTP')" class="help is-danger">
          {{ errors.first('ntpServerAddressForsetTimeUsingNTP') }}
        </span>

        <div v-if="selectedCpesId && selectedCpesId.length" class="mt-1">
          <p>{{ $t('aps.forFollowingCPEs') }}:</p>
          <ul class="list-unstyled">
            <li v-for="cpeId in selectedCpesId" :key="cpeId">
              <strong>{{ cpeObj(cpeId).name || cpeId }}</strong>
            </li>
          </ul>
        </div>
        <div v-else-if="cpeSelectedId" class="mt-1">
          <p>
            {{ $t('aps.forFollowingCPEs') }}:
            <strong v-if="cpeSelectedId">{{ cpeObj(cpeSelectedId).name || cpeSelectedId }}</strong>
          </p>
        </div>
      </form>
      <template v-if="selectedCpesId.length <= 1 && setTimeUsingNTPResult">
        <h6 class="mt-1">{{ $t('aps.commandOutput') }}:</h6>
        <Alert class="alert-info overflowY-auto mt-1" style="max-height: 500px;">
          <template #text>
            <span v-for="(row, id) of setTimeUsingNTPResult.split('\n')" class="output-row" :key="id">
              {{ row }}
            </span>
          </template>
        </Alert>
      </template>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="handleCloseSetTimeUsingNTPModal"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton info outline
          @click="
            issueSetTimeUsingNTP(
              selectedCpesId.length ? selectedCpesId : [cpeSelectedId],
              ntpServerAddressForsetTimeUsingNTP
            )
          "
          :disabled="!ntpServerAddressForsetTimeUsingNTP || isSetTimeUsingNTPRequestExecuting"
        >
          {{ $t('general.submit') }}
        </WButton>
      </div>
    </Modal>

    <!--    Clietns List From CPE command output-->
    <Modal
      close-btn
      @cancel="closeCPEClientsListFromCpeCommandModal"
      xxxlarge
      v-if="isCPEClientsListFromCpeCommandModalOpened"
      title="Clietns List From CPE command output"
      v-model="isCPEClientsListFromCpeCommandModalOpened"
      class="modal-info"
      effect="fade/zoom"
    >
      <loader-spinner v-if="isShowSpinnerOverCPEClientsListFromCpeCommandModal" centered></loader-spinner>
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.cpeClientsListFromCpeCommandModalHeader') }}</h4>
      <!--      <template v-if="getCPEClientsListFromCpeCommandResult !== ''">-->
      <!--        <h6 class="mt-1">{{ $t('aps.commandOutput') }}:</h6>-->
      <!--        <Alert class="alert-info overflowY-auto mt-1" style="max-height: 500px;">-->
      <!--          <template #text>-->
      <!--&lt;!&ndash;            <span v-for="(row, id) of getCPEClientsListFromCpeCommandResult.split('\n')" class="output-row" :key="id">&ndash;&gt;-->
      <!--&lt;!&ndash;              {{ row }}&ndash;&gt;-->
      <!--&lt;!&ndash;            </span>&ndash;&gt;-->

      <!--            <span v-for="(row, id) of getCPEWLANSInfoFromCpeCommandResult.split('\n')" class="output-row" :key="id">-->
      <!--             <span v-for="(letter, letterIndex) of row.split('')" :key="letterIndex">-->
      <!--               <span class="command-result-tab-margin" v-if="letter === '\t'"></span>-->
      <!--               <span v-else>{{letter}}</span>-->
      <!--             </span>-->
      <!--            </span>-->

      <!--          </template>-->

      <!--        </Alert>-->
      <!--      </template>-->
      <div class="mb-3" v-for="(wlanObj, wlanName) in getCPEClientsListFromCpeCommandResultParsed" :key="wlanName">
        <div class="mt-1" v-if="getWlanInfoForClientsListFromCpeCommand(wlanName)">
          <h5 class="">
            <!--          <small class="mb-1" v-if="getWlanInfoForClientsListFromCpeCommand(wlanName)">{{getWlanInfoForClientsListFromCpeCommand(wlanName)}}</small>-->
            <!--            <span class="wlan-ssid-in-CPEClientsListFromCpeCommandModal"-->
            <!--                  v-tooltip.right="{ content: tooltipForWlanInfoForClientsListFromCpeCommand(getWlanInfoForClientsListFromCpeCommand(wlanName)) }">SSID: {{getWlanInfoForClientsListFromCpeCommand(wlanName).essid}} ({{wlanName}})</span>-->
            <span class="">SSID: {{ getWlanInfoForClientsListFromCpeCommand(wlanName).essid }} ({{ wlanName }})</span>
            <i
              class="fa fa-question-circle-o icon-button-hover ml-q"
              v-tooltip.right="{
                content: tooltipForWlanInfoForClientsListFromCpeCommand(
                  getWlanInfoForClientsListFromCpeCommand(wlanName)
                )
              }"
            />
          </h5>
          <h6 v-if="getWlanInfoForClientsListFromCpeCommand(wlanName).Channel">
            {{ $t('aps.cpeClientsListFromCpeChannellCaption') }}:
            {{ getWlanInfoForClientsListFromCpeCommand(wlanName).Channel }}
          </h6>
        </div>
        <div class="mt-1" v-else>
          <h5>{{ wlanName }}</h5>
        </div>

        <!--        <small  class="mb-1" v-if="getWlanInfoForClientsListFromCpeCommand(wlanName)" >{{getWlanInfoForClientsListFromCpeCommand(wlanName)}}</small>-->

        <!--        <div class="ml-2 mt-2"-->
        <!--             v-if="Object.keys(getCPEClientsListFromCpeCommandResultParsed[wlanName]).length === 0">-->
        <!--          <span>{{$t('aps.cpeClientsListFromCpeNoClients')}}</span>-->
        <!--        </div>-->
        <!--        <div-->
        <!--          class="ml-2"-->
        <!--          v-else-->
        <!--          v-for="(clientObj, clientMac) in getCPEClientsListFromCpeCommandResultParsed[wlanName]"-->
        <!--          :key="clientMac">-->
        <!--          <span><b>{{clientMac}}</b></span>-->
        <!--          <p>{{clientObj}}</p>-->
        <!--        </div>-->

        <div class="mt-2 card-block card-block__table table-responsive">
          <h6 class="mb-h">
            {{ $t('aps.cpeClientsListFromCpeClientsTableHeader') }} ({{
              $t('aps.cpeClientsListFromCpeClientsConnectedCaption')
            }}: {{ Object.keys(getCPEClientsListFromCpeCommandResultParsed[wlanName]).length }})
          </h6>
          <!--          <table class="table table-hover table-with-clients-from-cpe-command">-->
          <!--            <thead>-->
          <!--            <tr>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsMac')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsInactiveTime')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsRxBytes')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsRxPackets')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsTxBytes')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsTxPackets')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsTxRetries')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsTxFailed')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsRxDropMisc')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsSignal')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsSignalAvg')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsTxBitrate')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsRxBitrate')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsRxDuration')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsAuthorized')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsAuthenticated')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsAssociated')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsPreamble')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsWMMWME')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsMFP')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsTDLSPeer')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsDTIMPeriod')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsBeaconInterval')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsShortSlotTime')}}</th>-->
          <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsConnectedTime')}}</th>-->

          <!--            </tr>-->
          <!--            </thead>-->
          <!--            <tbody>-->

          <!--            <tr-->
          <!--              v-for="(clientObj, clientMac) in getCPEClientsListFromCpeCommandResultParsed[wlanName]"-->
          <!--              :key="clientMac"-->
          <!--            >-->
          <!--              <td class="text-left">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'mac') && clientObj['mac'] !== ''">-->
          <!--                  {{clientObj['mac']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'inactive time') && clientObj['inactive time'] !== ''">-->
          <!--                  {{clientObj['inactive time']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'rx bytes') && clientObj['rx bytes'] !== ''">-->
          <!--                  {{clientObj['rx bytes']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'rx packets') && clientObj['rx packets'] !== ''">-->
          <!--                  {{clientObj['rx packets']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'tx bytes') && clientObj['tx bytes'] !== ''">-->
          <!--                  {{clientObj['tx bytes']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'tx packets') && clientObj['tx packets'] !== ''">-->
          <!--                  {{clientObj['tx packets']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'tx retries') && clientObj['tx retries'] !== ''">-->
          <!--                  {{clientObj['tx retries']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'tx failed') && clientObj['tx failed'] !== ''">-->
          <!--                  {{clientObj['tx failed']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'rx drop misc') && clientObj['rx drop misc'] !== ''">-->
          <!--                  {{clientObj['rx drop misc']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'signal') && clientObj['signal'] !== ''">-->
          <!--                  {{clientObj['signal']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'signal avg') && clientObj['signal avg'] !== ''">-->
          <!--                  {{clientObj['signal avg']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'tx bitrate') && clientObj['tx bitrate'] !== ''">-->
          <!--                  {{clientObj['tx bitrate']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'rx bitrate') && clientObj['rx bitrate'] !== ''">-->
          <!--                  {{clientObj['rx bitrate']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'rx duration') && clientObj['rx duration'] !== ''">-->
          <!--                  {{clientObj['rx duration']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'authorized') && clientObj['authorized'] !== ''">-->
          <!--                  {{clientObj['authorized']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'authenticated') && clientObj['authenticated'] !== ''">-->
          <!--                  {{clientObj['authenticated']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'associated') && clientObj['associated'] !== ''">-->
          <!--                  {{clientObj['associated']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'preamble') && clientObj['preamble'] !== ''">-->
          <!--                  {{clientObj['preamble']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'WMM/WME') && clientObj['WMM/WME'] !== ''">-->
          <!--                  {{clientObj['WMM/WME']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'MFP') && clientObj['MFP'] !== ''">-->
          <!--                  {{clientObj['MFP']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'TDLS peer') && clientObj['TDLS peer'] !== ''">-->
          <!--                  {{clientObj['TDLS peer']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'DTIM period') && clientObj['DTIM period'] !== ''">-->
          <!--                  {{clientObj['DTIM period']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'beacon interval') && clientObj['beacon interval'] !== ''">-->
          <!--                  {{clientObj['beacon interval']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'short slot time') && clientObj['short slot time'] !== ''">-->
          <!--                  {{clientObj['short slot time']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--              <td class="text-left responsive-row">-->
          <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'connected time') && clientObj['connected time'] !== ''">-->
          <!--                  {{clientObj['connected time']}}-->
          <!--                </span>-->
          <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
          <!--              </td>-->

          <!--            </tr>-->
          <!--            <tr-->
          <!--              v-if="Object.keys(getCPEClientsListFromCpeCommandResultParsed[wlanName]).length === 0">-->
          <!--              <td colspan="20" class="no-data-row">-->
          <!--                <span class="text-muted pt-1">{{ $t('aps.cpeClientsListFromCpeNoClients') }}</span>-->
          <!--              </td>-->
          <!--            </tr>-->
          <!--            </tbody>-->
          <!--          </table>-->
          <div class="card-block card-block__table table-responsive">
            <table class="table table-hover table-with-clients-from-cpe-command-with-combined-columns">
              <thead v-if="Object.keys(getCPEClientsListFromCpeCommandResultParsed[wlanName]).length !== 0">
                <tr>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsMac') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsConnectedTime') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsInactiveTime') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsRX') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsTX') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsCombinedSignal') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsBitrate') }}</th>
                  <th class="text-left align-text-top">
                    {{ $t('aps.cpeClientsListFromCpeClientsAuthorizedAuthenticatedAssociated') }}
                  </th>
                  <!--              <th class="text-left align-text-top">{{$t('aps.cpeClientsListFromCpeClientsPreamble')}}</th>-->
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsWMMWME') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsMFP') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsTDLSPeer') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsDTIMPeriod') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsBeaconInterval') }}</th>
                  <th class="text-left align-text-top">{{ $t('aps.cpeClientsListFromCpeClientsShortSlotTime') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(clientObj, clientMac) in getCPEClientsListFromCpeCommandResultParsed[wlanName]"
                  :key="clientMac"
                >
                  <td class="text-left">
                    <span v-if="Object.prototype.hasOwnProperty.call(clientObj, 'mac') && clientObj['mac'] !== ''">
                      {{ makeMACForDisplayInClientsListFromCpeCommandTable(clientObj['mac']) }}
                    </span>
                    <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                  </td>

                  <td class="text-left table-with-clients-from-cpe-command-with-combined-columns-nowrap-column">
                    <span
                      v-if="
                        Object.prototype.hasOwnProperty.call(clientObj, 'connected time') &&
                        clientObj['connected time'] !== ''
                      "
                    >
                      {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getConnectedTimeStringWithTranslation($i18n, clientObj['connected time'])}}
                    </span>
                    <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                  </td>

                  <td
                    class="text-left responsive-row table-with-clients-from-cpe-command-with-combined-columns-nowrap-column"
                  >
                    <span
                      v-if="
                        Object.prototype.hasOwnProperty.call(clientObj, 'inactive time') &&
                        clientObj['inactive time'] !== ''
                      "
                    >
                      {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getInactiveTimeStringWithTranslation($i18n, clientObj['inactive time'])}}
                    </span>
                    <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                  </td>

                  <td class="text-left table-with-clients-from-cpe-command-with-combined-columns-nowrap-column">
                    <!--                rx-->
                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsRxBytes') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'rx bytes') && clientObj['rx bytes'] !== ''
                        "
                      >
                        {{ clientObj['rx bytes'] }}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>

                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsRxPackets') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'rx packets') &&
                          clientObj['rx packets'] !== ''
                        "
                      >
                        {{ clientObj['rx packets'] }}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>

                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsRxDropMisc') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'rx drop misc') &&
                          clientObj['rx drop misc'] !== ''
                        "
                      >
                        {{ clientObj['rx drop misc'] }}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>

                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsRxDuration') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'rx duration') &&
                          clientObj['rx duration'] !== ''
                        "
                      >
                        {{ clientObj['rx duration'] }}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>
                  </td>

                  <td class="text-left table-with-clients-from-cpe-command-with-combined-columns-nowrap-column">
                    <!--                tx-->
                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsTxBytes') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'tx bytes') && clientObj['tx bytes'] !== ''
                        "
                      >
                        {{ clientObj['tx bytes'] }}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>

                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsTxPackets') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'tx packets') &&
                          clientObj['tx packets'] !== ''
                        "
                      >
                        {{ clientObj['tx packets'] }}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>

                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsTxRetries') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'tx retries') &&
                          clientObj['tx retries'] !== ''
                        "
                      >
                        {{ clientObj['tx retries'] }}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>

                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsTxFailed') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'tx failed') && clientObj['tx failed'] !== ''
                        "
                      >
                        {{ clientObj['tx failed'] }}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>
                  </td>

                  <td class="text-left table-with-clients-from-cpe-command-with-combined-columns-nowrap-column">
                    <!--                signal-->
                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsSignal') }}:</b>
                      </span>
                      <span
                        v-if="Object.prototype.hasOwnProperty.call(clientObj, 'signal') && clientObj['signal'] !== ''"
                      >
                        {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getCombinedSignalStringWithTranslation($i18n, clientObj['signal'] )}}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>

                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsSignalAvg') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'signal avg') &&
                          clientObj['signal avg'] !== ''
                        "
                      >
                        {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getCombinedSignalStringWithTranslation($i18n, clientObj['signal avg'])}}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>
                  </td>

                  <td class="text-left table-with-clients-from-cpe-command-with-combined-columns-nowrap-column">
                    <!--                bitrate-->
                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsRxBitrate') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'rx bitrate') &&
                          clientObj['rx bitrate'] !== ''
                        "
                      >
                        {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getClientsBitrateStringWithTranslation($i18n, clientObj['rx bitrate'] )}}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>

                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsTxBitrate') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'tx bitrate') &&
                          clientObj['tx bitrate'] !== ''
                        "
                      >

                        {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getClientsBitrateStringWithTranslation($i18n, clientObj['tx bitrate'] )}}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>
                  </td>

                  <td class="text-left responsive-row">
                    <!--                Authorized, Authenticated, Associated-->
                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsAuthorized') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'authorized') &&
                          clientObj['authorized'] !== ''
                        "
                      >
                        {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getYesOrNoStringWithTranslation($i18n, clientObj['authorized'])}}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>

                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsAuthenticated') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'authenticated') &&
                          clientObj['authenticated'] !== ''
                        "
                      >
                        {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getYesOrNoStringWithTranslation($i18n, clientObj['authenticated'])}}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>

                    <div>
                      <span>
                        <b>{{ $t('aps.cpeClientsListFromCpeClientsAssociated') }}:</b>
                      </span>
                      <span
                        v-if="
                          Object.prototype.hasOwnProperty.call(clientObj, 'associated') &&
                          clientObj['associated'] !== ''
                        "
                      >

                        {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getYesOrNoStringWithTranslation($i18n, clientObj['associated'])}}
                      </span>
                      <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                    </div>
                  </td>

                  <!--              <td class="text-left responsive-row">-->
                  <!--                <span v-if = "Object.prototype.hasOwnProperty.call(clientObj, 'preamble') && clientObj['preamble'] !== ''">-->
                  <!--                  {{clientObj['preamble']}}-->
                  <!--                </span>-->
                  <!--                <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
                  <!--              </td>-->

                  <td class="text-left responsive-row">
                    <span
                      v-if="Object.prototype.hasOwnProperty.call(clientObj, 'WMM/WME') && clientObj['WMM/WME'] !== ''"
                    >
                      {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getYesOrNoStringWithTranslation($i18n, clientObj['WMM/WME'])}}
                    </span>
                    <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                  </td>

                  <td class="text-left responsive-row">
                    <span v-if="Object.prototype.hasOwnProperty.call(clientObj, 'MFP') && clientObj['MFP'] !== ''">
                      {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getYesOrNoStringWithTranslation($i18n, clientObj['MFP'])}}
                    </span>
                    <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                  </td>

                  <td class="text-left responsive-row">
                    <span
                      v-if="
                        Object.prototype.hasOwnProperty.call(clientObj, 'TDLS peer') && clientObj['TDLS peer'] !== ''
                      "
                    >
                      {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getYesOrNoStringWithTranslation($i18n, clientObj['TDLS peer'])}}
                    </span>
                    <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                  </td>

                  <td class="text-left responsive-row">
                    <span
                      v-if="
                        Object.prototype.hasOwnProperty.call(clientObj, 'DTIM period') &&
                        clientObj['DTIM period'] !== ''
                      "
                    >
                      {{ clientObj['DTIM period'] }}
                    </span>
                    <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                  </td>

                  <td class="text-left responsive-row">
                    <span
                      v-if="
                        Object.prototype.hasOwnProperty.call(clientObj, 'beacon interval') &&
                        clientObj['beacon interval'] !== ''
                      "
                    >
                      {{ clientObj['beacon interval'] }}
                    </span>
                    <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                  </td>

                  <td class="text-left responsive-row">
                    <span
                      v-if="
                        Object.prototype.hasOwnProperty.call(clientObj, 'short slot time') &&
                        clientObj['short slot time'] !== ''
                      "
                    >
                      {{clientsFromCpeConsoleCommandParsedDataStringTranslator.getYesOrNoStringWithTranslation($i18n, clientObj['short slot time'])}}
                    </span>
                    <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                  </td>
                </tr>
                <tr v-if="Object.keys(getCPEClientsListFromCpeCommandResultParsed[wlanName]).length === 0">
                  <td colspan="20" class="no-data-row" style="border: 0;">
                    <span class="text-muted pt-1">{{ $t('aps.cpeClientsListFromCpeNoClients') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--      <pre>{{getCPEWLANSInfoFromCpeCommandResultParsed}}</pre>-->
      <!--      <pre>{{getCPEClientsListFromCpeCommandResultParsed}}</pre>-->
      <div class="d-flex justify-content-center align-items-center" v-if="getCPEClientsListFromCpeCommandResult === ''">
        <div>{{ $t('general.noDataToDisplay') }}</div>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="closeCPEClientsListFromCpeCommandModal"
        >
          {{ $t('general.cancel') }}
        </WButton>
      </div>
    </Modal>

    <Modal @cancel="rebootCpeModal = false" close-btn title="Reboot CPE" v-model="rebootCpeModal" class="modal-info" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.rebootCpe') }}</h4>
      <div v-if="selectedCpesId && selectedCpesId.length">
        {{ $t('aps.rebootCpeConfirm') }}:
        <ul class="list-unstyled">
          <li v-for="cpeId in selectedCpesId" :key="cpeId">
            <strong>{{ cpeObj(cpeId).name || cpeId }}</strong>
          </li>
        </ul>
      </div>
      <div v-else-if="cpeSelectedId">
        {{ $t('aps.rebootCpeConfirm') }}
        <strong v-if="cpeSelectedId">{{ cpeObj(cpeSelectedId).name || cpeSelectedId }}</strong>
        ?
      </div>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="rebootCpeModal = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton info outline
          @click="rebootCPE(selectedCpesId.length ? selectedCpesId : [cpeSelectedId])"
        >
          {{ $t('aps.buttonsYesReboot') }}
        </WButton>
      </div>
    </Modal>

    <Modal close-btn @cancel="resetCpeModal = false" title="Reset CPE" v-model="resetCpeModal" class="modal-info" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.resetCpe') }}</h4>

      <div v-if="selectedCpesId && selectedCpesId.length">
        {{ $t('aps.resetCpeConfirm') }}
        <ul class="list-unstyled">
          <li v-for="cpeId in selectedCpesId" :key="cpeId">
            <strong>{{ cpeObj(cpeId).name || cpeId }}</strong>
          </li>
        </ul>
      </div>
      <div v-else-if="cpeSelectedId">
        {{ $t('aps.resetCpeConfirm') }}
        <strong v-if="cpeSelectedId">{{ cpeObj(cpeSelectedId).name || cpeSelectedId }}</strong>
        ?
      </div>

      <div class="form-group mt-1">
        <div class>
          <label for="reset_broker">{{ $t('aps.modalBrokerAddress') }}</label>
          <input
            type="text"
            :disabled="isDisable"
            name="reset_broker"
            id="reset_broker"
            v-validate="'ipv4'"
            :placeholder="$t('aps.modalBrokerAddressPlaceHolder')"
            class="form-control form-control-sm"
            :class="{ input: true, 'is-danger': errors.has('reset_broker') }"
            v-model="resetBrokerAddress"
          />
          <!--<span v-tooltip.top-center="{content: errors.first(`${config_key}`)}" v-show="errors.has(`${config_key}`)" class="help is-danger category__error"><i class="fa fa-warning text-error"></i></span>-->
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="resetCpeModal = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton info outline
          @click="resetCPE(selectedCpesId.length ? selectedCpesId : [cpeSelectedId])"
        >
          {{ $t('aps.buttonsYesReset') }}
        </WButton>
      </div>
    </Modal>

    <Modal close-btn @cancel="WebAccessModal = false" title="Web/ssh access" v-model="WebAccessModal" effect="fade/zoom" class="modal-info">
      <h4 slot="modal-title" class="modal-title">Web/ssh access</h4>
      <div>
        <p>
          <span>Web access:</span>
          <br />
          <a :href="webAccess" target="_blank">{{ webAccess }}</a>
        </p>
        <p>
          <span>Also you can connect to AP ssh with:</span>
          <br />
          <samp class="font-italic text-muted">{{ sshAccess }}</samp>
        </p>
      </div>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary
          @click="WebAccessModal = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
      </div>
    </Modal>

    <Modal v-if="newTemplateModal" close-btn @cancel="newTemplateModal = false" title="Create Template" v-model="newTemplateModal" large class="modal-success" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.createTemplateFromCpe') }}</h4>
      <div>
        <div class="row" v-if="newTemplateData.template.cpe_config_template">
          <div class="col-lg-6 pl-3">
            <div class="form-group mt-1">
              <span class="h5 mt-1 mb-1 setting-title">{{ $t('template.templateSettings') }}</span>
              <label for="template-name">{{ $t('general.name') }}</label>
              <input
                type="text"
                name="template-name"
                id="template-name"
                :placeholder="$t('aps.modalEnterTemplateNamePlaceHolder')"
                class="form-control"
                v-validate="'required|max:32'"
                :class="{ input: true, 'is-danger': errors.has('template-name') }"
                v-model="newTemplateData.name"
              />
              <span v-show="errors.has('template-name')" class="help is-danger">
                {{ errors.first('template-name') }}
              </span>
            </div>

            <div class="form-group mt-1">
              <label for="template-description">{{ $t('aps.description') }}</label>
              <input
                type="text"
                id="template-description"
                :placeholder="$t('aps.modalTemplateDescriptionPlaceHolder')"
                name="template-description"
                v-validate="'max:200'"
                class="form-control"
                :class="{ input: true, 'is-danger': errors.has('description') }"
                v-model="newTemplateData.description"
              />
              <span v-show="errors.has('description')" class="help is-danger">{{ errors.first('description') }}</span>
            </div>

            <div class="form-group" v-if="areLocationsVisible">
              <label for="template-location">{{ $t('general.baseLocation') }}</label>
              <select
                v-model="newTemplateData.base_location"
                name="template-location"
                class="form-control"
                id="template-location"
              >
                <option v-for="location in locationsList" :value="location.id" :key="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>

            <div class="form-group mb-2 mt-2">
              <Switch-component
                v-model="newTemplateData.is_auto"
                :label="$t('template.autoConfiguration')"
                id="auto-configuration"
              />
            </div>

            <label for="name">{{ $t('aps.modalUUIDOptionalTitle') }}</label>
            <div class="input-group">
              <input
                type="text"
                name="uuid"
                class="form-control input-tag"
                :placeholder="$t('aps.modalAddUUIDPlaceHolder')"
                data-vv-validate-on="input"
                v-validate="'uuid'"
                v-model="newUuid"
                :class="{ input: true, 'is-danger': errors.has('uuid') }"
              />
              <div class="input-group-btn">
                <WButton success
                  customClass="mb-0"
                  @click="addUUIID"
                  :disabled="(this.newUuid == '')"
                >
                  {{ $t('general.add') }}
                </WButton>
              </div>
              <div class="input-group-btn fileUpload ml-1">
                <WButton :danger="fileTypeError || invalidUuid">
                  <i class="fa fa-file-o"></i>
                </WButton>
                <input id="csv" @change="previewUuids" name="csv" type="file" class="upload btn btn-info mb-0" />
              </div>
            </div>
            <span v-if="fileTypeError" class="help is-danger">The field must be a csv file</span>
            <span v-if="invalidUuid" class="help is-danger">Wrong data in file</span>

            <div class="mt-1 mb-1">
              <p
                class="m-0 uuid-block"
                v-if="newTemplateData.cpes.length"
                :data-index="index"
                @click.prevent="removeUuid"
                v-for="(uuid, index) in newTemplateData.cpes"
              >
                <i class="fa fa-remove text-danger"></i>
                <span class="uuid">{{ uuid }}</span>
              </p>
            </div>
          </div>
          <div class="col-lg-6 pl-3 tamplate-config">
            <h5 class="mt-1 mb-1">{{ $t('aps.modalConfigFromTitle') }} {{ updatedCpe.name }}</h5>
            <p class>
              {{ $t('aps.modalModelTitle') }}:
              <span class="text-info">{{ modelObj(newTemplateData.model).description }}</span>
            </p>
            <p class>
              {{ $t('aps.modalLocationTitle') }}:
              <span class="text-info">{{ locationName(newTemplateData.template.location) }}</span>
            </p>
            <div class="mt-1" v-for="radio in newTemplateData.template.cpe_config_template.wifi">
              <h6 class="mt-2 mb-1">{{ $t('template.configFor') }} {{ radio.id }}</h6>
              <p class>
                {{ $t('aps.bandMode') }}:
                <span class="text-info">{{ radio.bandmode }}</span>
              </p>
              <p class>
                {{ $t('aps.bandwidth') }}:
                <span class="text-info">{{ radio.bandwidth }}</span>
              </p>
              <p class>
                {{ $t('aps.channels') }}:
                <span v-for="channel in radio.channels" class="text-info">{{ channel }}&nbsp;</span>
                <span v-if="!radio.channels.length" class="text-info">Auto</span>
              </p>
              <p class>
                {{ $t('aps.txPowerAdjust') }}:
                <span class="text-info" v-if="radio.txpower != '0'">
                  ({{ radio.power.range[0] }} - {{ radio.power.range[1] }}) {{ $t('aps.dbm') }}
                </span>
                <span class="text-info" v-if="radio.txpower == '0'">Auto</span>
              </p>
              <p class>
                {{ $t('aps.country') }}:
                <span class="text-info">{{ radio.country }}</span>
              </p>
              <p class>
                {{ $t('aps.modalWLANsTitle') }}:
                <span class="text-info" v-for="wlan in radio.wlans">
                  <i class="fa fa-wifi"></i>
                  {{ wlanObj(wlan).ssid }}
                </span>
                <span class="text-info" v-if="!radio.wlans.length">{{ $t('aps.modalNoWLANs') }}</span>
              </p>

              <p class>
                {{ $t('aps.maxClients') }}:
                <span class="text-info" v-if="radio.maxclients">{{ radio.maxclients }}</span>
                <span class="text-info" v-else>{{ $t('aps.cpeEditCaptionUnlimited') }}</span>
              </p>
              <p class v-if="radio.scanningconfig">
                {{ $t('aps.backgroundScaning') }}:
                <span class="text-info">
                  {{ radio.scanningconfig.enabled ? $t('aps.modalYes') : $t('aps.modalNo') }}
                </span>
              </p>
            </div>
            <div class="mt-2">
              <p class>
                {{ $t('aps.modalStatTitle') }}:
                <span class="text-info">
                  {{
                    newTemplateData.template.cpe_config_template.stats_config.enabled
                      ? $t('aps.modalEnabled')
                      : $t('aps.modalDisabled')
                  }}
                </span>
              </p>
              <p class>
                {{ $t('aps.modalLogsTitle') }}:
                <span class="text-info">
                  {{
                    newTemplateData.template.cpe_config_template.log_config.enabled
                      ? $t('aps.modalEnabled')
                      : $t('aps.modalDisabled')
                  }}
                </span>
              </p>
              <p class>
                {{ $t('aps.modalWiFiRadarTitle') }}:
                <span class="text-info">
                  {{
                    newTemplateData.template.cpe_config_template.lbs_config.enabled
                      ? $t('aps.modalEnabled')
                      : $t('aps.modalDisabled')
                  }}
                </span>
              </p>
            </div>
            <div class="mt-1">
              <p class>
                {{ $t('aps.modalFirewallTitle') }}:
                <span class="text-info">
                  {{
                    newTemplateData.template.cpe_config_template.firewall.l2_chain
                      ? commonService.firewallNamebyId(newTemplateData.template.cpe_config_template.firewall.l2_chain)
                      : $t('aps.modalDisabled')
                  }}
                </span>
              </p>
              <p class>
                {{ $t('aps.wanBlockAccess') }}:
                <span class="text-info">
                  {{
                    newTemplateData.template.cpe_config_template.firewall.wan_access_block
                      ? $t('aps.modalEnabled')
                      : $t('aps.modalDisabled')
                  }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary @click="newTemplateModal = false">
          {{ $t('general.cancel') }}
        </WButton>
        <WButton success outline @click="createTemplate">
          {{ $t('general.create') }}
        </WButton>
      </div>
    </Modal>

    <Modal close-btn @cancel="closeFWUpgradeDialog" title="Firmware Upgrade" v-model="FWUpgradeModal" class="modal-info" effect="fade/zoom" v-if="updatedCpe">
      <loader-spinner centered v-if="isfirmwareObjectsListFromApiLoading"></loader-spinner>
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.fwUpgrade') }}</h4>
      <div class="mb-1">
        <p>
          <strong>{{ $t('aps.name') }}:</strong>
          <span v-if="updatedCpe.name">{{ updatedCpe.name }}</span>
          <span v-else class="text-muted">{{ $t('misc.no_name') }}</span>
        </p>
        <p>
          <strong>{{ $t('aps.model') }}:</strong>
          {{ updatedCpe.model.name }}
        </p>
        <p>
          <strong>{{ $t('aps.modalCurrentFirmwareVersion') }}:</strong>
          <span v-if="updatedCpe.state.firmware.version.version">{{ updatedCpe.state.firmware.version.version }}</span>
          <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
        </p>
      </div>
      <div>
        <!--<label for="file">Select FW</label>-->
        <!--<a href="#" class="small" @click.prevent="changeFwSource">{{ !fwFromLink ? 'from link' : 'from file'}}</a>-->
        <div class="input-group" v-if="!fwFromLink" style="height: 35px;">
          <select
            v-model="forceFWObjects"
            name="location"
            v-if="cpeModelData.firmwares"
            id="file"
            :class="{ 'select-disabled': isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty') }"
            class="form-control"
            :disabled="isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')"
          >
            <option disabled v-if="cpeModelData.firmwares.length" :value="false">
              {{ $t('aps.fwSelectFirmwareCaption') }}
            </option>
            <option
              v-for="(firmware, index) in cpeModelData.firmwares"
              :key="index"
              v-if="cpeModelData.firmwares.length"
              :value="firmware"
            >
              {{ firmware.version }}
            </option>
            <option v-if="!cpeModelData.firmwares.length" :value="false" disabled>
              {{ $t('aps.modalNoUpdatesAvailable') }}
            </option>
          </select>
          <span class="input-group-btn">
            <div
              class="fileUpload btn btn-info"
              :disabled="uploadFW || isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')"
              :class="{
                disabled: uploadFW || isDisable || (!updatedCpe.connected && updatedCpe.config_status == 'empty')
              }"
            >
              <i class="fa fa-file-o" :class="{ invisible: uploadFW }"></i>
              <span :class="{ invisible: uploadFW }">{{ $t('aps.modalUploadFWfile') }}</span>
              <span v-if="uploadFW" class="loader loader--mini"></span>
              <input
                id="fwfile"
                @change="uploadFWFileToServer"
                name="files"
                type="file"
                v-if="!uploadFW"
                class="upload"
              />
            </div>
          </span>
        </div>
        <div v-if="fwFromLink">
          <div class="form-group m-0 p-0">
            <input
              type="text"
              placeholder="http://10.10.10.1/file_v42.tar"
              class="form-control"
              v-model="forceFWObjects.url"
            />
          </div>
        </div>
        <div class="firmware-info" v-if="forceFWObjects && !fwFromLink">
          <p class="m-0">
            <small>
              <strong>{{ $t('aps.modalFirmwareFileInfo') }}</strong>
            </small>
          </p>
          <p class="m-0">
            <small>
              <strong>MD5:</strong>
              <span v-if="forceFWObjects.md5sum">{{ forceFWObjects.md5sum }}</span>
              <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
            </small>
          </p>
          <p>
            <small>
              <strong>{{ $t('aps.modalFirmwareFileInfoSize') }}:</strong>
              <span v-if="forceFWObjects.filesize">{{ formatBytes(forceFWObjects.filesize) }}</span>
              <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
            </small>
          </p>
        </div>
        <small class="text-danger">
          {{ $t('aps.modalBeAttention') }}
          <br />
          1) {{ $t('aps.modalBeAttentionFirst') }}
          <br />
          2) {{ $t('aps.modalBeAttentionSecond') }}
          <br />
          <!--3) Use <u>http</u> protocol in link-->
        </small>

        <div v-if="forceFWObjects !== false" class="mt-2 form-group d-flex flex-column justify-content-start align-items-start ">
          <div>
            <b>{{$t('aps.cpeFwCustomAddressAndSchema')}}</b>
          </div>
          <div class="d-flex flex-row justify-content-start align-items-start">
            <div>
              <select style="min-width: 70px;" class="form-control" v-model="customUrlAddressWithSchemaForFWUpgrage.schema">
                <option value="http">http</option>
                <option value="https">https</option>
              </select>
            </div>
            <div>
              <input id="custom-url-address-for-fw-upgrage"
                     name="custom-url-address-for-fw-upgrage"
                     :data-vv-as="$t('aps.cpeFwCustomAddress')"
                      data-vv-validate-on="input"
                     v-validate="'required|addressForFWUpgrade'" class="form-control"
                     style="max-width: 300px;" type="text"
                     v-model="customUrlAddressWithSchemaForFWUpgrage.url"/>
                              <span v-show="errors.has('custom-url-address-for-fw-upgrage')" class="help is-danger">
                  {{ errors.first('custom-url-address-for-fw-upgrage') }}
                </span>
            </div>
          </div>
          <div>
          <a href="#" class="ml-q" @click.prevent="setCustomUrlAddressWithSchemaForFWUpgrage('http', updatedCpe.state.l2tp_state.host_addr)">
            <span>{{ $t('general.byDefault') }}</span>
          </a>
        </div>
        </div>

      </div>
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="closeFWUpgradeDialog()"
          :disabled="isfirmwareObjectsListFromApiLoading || uploadFW"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton info
          @click="upgradeFW"
          :disabled="
            !forceFWObjects ||
            !forceFWObjects.url ||
            isDisable ||
            (!updatedCpe.connected && updatedCpe.config_status == 'empty') ||
            uploadFW || errors.has('custom-url-address-for-fw-upgrage')
          "
        >
          {{ $t('aps.buttonsUpgrade') }}
        </WButton>
      </div>
    </Modal>

    <Modal close-btn  @cancel="cpeTagsModal = false" title="Tags" v-model="cpeTagsModal" class="modal-success" effect="fade/zoom" v-if="updatedCpe">
      <h4 slot="modal-title" class="modal-title">{{ $t('general.tags') }}</h4>
      <div class="modal-tags">
        <p class="h5">{{ $t('tags.currentTags') }}:</p>
        <span
          v-for="(tag, index) in updatedCpe.tags"
          class="tag tag--item mr-q"
          @click.prevent="removeTag"
          :data-index="index"
        >
          <i class="fa fa-tag"></i>
          <i class="fa fa-remove"></i>
          {{ tag }}
        </span>
        <div class="input-group mt-2">
          <input
            type="text"
            name="tag"
            class="form-control input-tag"
            :placeholder="$t('aps.modalAddTagPlaceHolder')"
            :disabled="isDisable"
            @keyup.13="addTag"
            v-validate="'max:15'"
            data-vv-validate-on="input"
            v-model="newTag"
            :class="{ input: true, 'is-danger': errors.has('tag') }"
          />
          <span class="input-group-btn">
            <WButton success
              @click="addTag"
              :disabled="(this.newTag == '')"
            >
              {{ $t('general.add') }}
            </WButton>
          </span>
        </div>
        <span v-show="errors.has('tag')" class="help is-danger is-danger__block">{{ errors.first('tag') }}</span>

        <div class="tags__availabel mt-1">
          <p class="h6">{{ $t('tags.availlableTags') }}:</p>
          <span v-for="tag in cpesTagsList" class="tag tag--add mr-q" @click="addExistTag(tag)">
            <i class="fa fa-tag"></i>
            <i class="fa fa-plus"></i>
            {{ tag }}
          </span>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary
          @click="cpeTagsModal = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
      </div>
    </Modal>

    <Modal close-btn @cancel="setTemplateModal = false" title="Set template" v-model="setTemplateModal" class="modal-success" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.assignTemplate') }}</h4>

      <!--<div class="h4 m-0">-->
      <!--{{$t('aps.assignTemplate')}}-->
      <!--</div>-->
      <!--<small class="text-muted">{{$t('aps.assignTemplateHint')}}</small>-->

      <div class="form-group" v-if="updatedCpe">
        <label for="templates">
          {{ $t('aps.templates') }}
          <span v-if="updatedCpe.model.name"></span>
          {{$t('general.for')}}
          <strong>{{ updatedCpe.model.name }}</strong>
        </label>
        <select v-model="setTemplateId" name="templates" id="templates" class="form-control">
          <option :value="''" disabled checked>{{ $t('aps.modalSelectTemplatePlaceHolder') }}</option>
          <option v-for="template in templatesList" :value="template.id">
            {{ commonService.templateNameById(template.id) }}
          </option>
        </select>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="setTemplateModal = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton success
          @click="setTemplatesToCPEs(setTemplateId, updatedCpe.id)"
          :disabled="!setTemplateId || isDisable"
        >
          {{ $t('aps.buttonsApply') }}
        </WButton>
      </div>
    </Modal>
    <Modal
      close-btn
      @cancel="showingMaxinactivityModalInfoDetails = false"
      title="Show Maxinactivity Info"
      class="modal-info"
      v-model="showingMaxinactivityModalInfoDetails"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.maxinactivityAboutModalTitle') }}</h4>
      <MaxInactivityAbout></MaxInactivityAbout>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary
          @click="showingMaxinactivityModalInfoDetails = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
      </div>
    </Modal>
    <Modal
      xlarge
      close-btn
      @cancel="getLogFromCPEModal = false"
      title="Get Logs From Cpe"
      class="modal-info"
      v-model="getLogFromCPEModal"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.getLogsFromCpe') }}</h4>
      <div v-if="getLogFromCPEModal">
        <GetLogFromCPE :cpe-id="updatedCpe.id" :cpe-name="updatedCpe.name"/>
      </div>

      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary
          @click="getLogFromCPEModal = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
      </div>
    </Modal>

    <Modal
      xlarge
      close-btn
      @cancel="closeCurrentCpeStatsModal"
      title="Show Current CPE Stats"
      class="modal-info"
      v-model="showingCurrentCPEStatsModal"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">
        {{ $t('aps.showCurrentStatsModalTitle') }}
        <span>{{ selectedCpeNameForCurrentCpeStat }}</span>
      </h4>
      <div v-if="showingCurrentCPEStatsModal">
        <StatCpeLatest
          :cpe-name="selectedCpeNameForCurrentCpeStat"
          :cpe-id="selectedCpeIdForCurrentCpeStat"
        ></StatCpeLatest>
      </div>

      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary outline
          @click="closeCurrentCpeStatsModal"
        >
          {{ $t('general.cancel') }}
        </WButton>
      </div>
    </Modal>
    <Modal
      xlarge
      close-btn
      v-model="showingCpeConfigModal"
      title="Show Current CPE Stats"
      class="modal-info"
      effect="fade/zoom"
      @cancel="hideShowCpeConfigModal"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.cpeConfigShowTitleHeader') }}</h4>
      <div v-if="showingCpeConfigModal">
        <CpeConfigShow
          :cpe-config="cpeConfigForShowInCpeConfigModal"
          :cpe-names="cpeConfigForShowInCpeConfigModalNameOfCpe"
        ></CpeConfigShow>
      </div>

      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary outline
          @click="hideShowCpeConfigModal"
        >
          {{ $t('general.cancel') }}
        </WButton>
      </div>
    </Modal>
    <Modal
      xlarge
      close-btn
      v-model="showingCpeConfigSetModal"
      title="Show Current CPE Stats"
      class="modal-info"
      effect="fade/zoom"
      @cancel="hideSetCpeConfigModal"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.cpeConfigSetTitleHeader') }}</h4>
      <div v-if="showingCpeConfigSetModal">
        <CpeConfigSet @cpesConfigEmit="cpeConfigSetConfigEmited"></CpeConfigSet>
      </div>

      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary outline
          @click="hideSetCpeConfigModal"
        >
          {{ $t('general.cancel') }}
        </WButton>
      </div>
    </Modal>

    <Change-location
      :type="'cpe'"
      :list="selectedCpesId"
      :isChangeLocationOpen="isChangeLocationOpen"
      :commonService="commonService"
      :massLocationId="massLocationId"
      :locationsList="locationsList"
      :changeLocations="changeLocations"
      :cancelChangeLocation="cancelChangeLocation"
    ></Change-location>
  </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { Multiselect } from 'vue-multiselect';
import VueNotifications from 'vue-notifications';
import VueSlider from 'vue-slider-component';
import { countriesCodesOptionsMixin, intervalUpdateModeMixin, debounceMixin, searchBarMixin, apsAndTemplatesSettingsVisibilityMixin } from '@/mixins';
import { cpeEditCurrentSerialNumberMixin } from './mixins';
import { API_URL } from '@/config';
import Alert from '@/components/Universal/alert/alert.vue';
import Modal from '@/components/Modal.vue';
import rrmService from '@/services/rrmService';
import cpeService from '@/services/cpeService';
import wlanService from '@/services/wlanService';
import helpers from '@/helpers';
import cpePollingService from '@/services/cpePollingService';
import commonService from '@/services/commonService';
import templatesService from '@/services/templatesService';
import firewallService from '@/services/firewallService';
import redirectsService from '@/services/redirectsService';
import 'vue-slider-component/theme/default.css';
import Filters from '@/filters';
import capabilities from '@/services/capabilities';
import { getDefaultTableSettings } from '@/table-settings';
import WiredConfig from '@/components/Aps/wiredConfig.vue';
import statLogLbsConfig from '@/components/Aps/statLogLbsConfig.vue';
import AccessControl from '@/components/Aps/accessControl.vue';
import ChangeLocation from '@/components/general/modal/ChangeLocation.vue';
import EmptyCellValue from '@/components/empty-cell-value.vue';
import WarningLine from '@/components/warning-line.vue';
import SwitchComponent from '@/components/Universal/Switch-component.vue';
import CheckboxComponent from '@/components/Universal/Checkbox-component.vue';
import Info from '@/components/Universal/info-icon.vue';
import MaxInactivityAbout from '@/components/Aps/MaxInactivityAbout.vue';
import DatePicker from '@/components/date-picker.vue';
import marketingService from '@/services/marketingService';
import StatCpeLatest from '@/components/Aps/StatCpeLatest.vue';
import CpeConfigShow from '@/components/Aps/CpeConfigShow.vue';
import GetLogFromCPE from '@/components/Aps/GetLogFromCPE.vue';
import CpeConfigSet from '@/components/Aps/CpeConfigSet.vue';
import locationService from '@/services/locationService';
import SelectComponentForCompanyAndAddressSearch from '@/components/Universal/select/SelectComponentForCompanyAndAddressSearch.vue';
import MapCpe from '@/components/Maps/MapCpe.vue';
import SelectComponent from '@/components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '@/components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import Tunnels from '@/components/Aps/Tunnels.vue';
import { disabledChannelsForCountries, countriesList } from '@/helpers/disabledChannelsForCountries';
import { clientsFromCpeConsoleCommandParsedDataStringTranslator } from './utils';
// const WPA3_TYPES = ['wpa3personal', 'wpa3enterprise', 'wpa23personal', 'wpa23enterprise']

import {
  ApsTable
} from './components';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');
const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

export default {
  name: 'Aps',
  mixins: [countriesCodesOptionsMixin, intervalUpdateModeMixin, debounceMixin, searchBarMixin, apsAndTemplatesSettingsVisibilityMixin, cpeEditCurrentSerialNumberMixin],
  components: {
    MaxInactivityAbout,
    Alert,
    Multiselect,
    Modal,
    VueSlider,
    WiredConfig,
    statLogLbsConfig,
    AccessControl,
    ChangeLocation,
    EmptyCellValue,
    WarningLine,
    SwitchComponent,
    GetLogFromCPE,
    CheckboxComponent,
    Info,
    DatePicker,
    StatCpeLatest,
    CpeConfigShow,
    CpeConfigSet,
    SelectComponentForCompanyAndAddressSearch,
    MapCpe,
    SelectComponent,
    SelectComponentV3ForUseInLocationSelectors,
    Tunnels,
    ApsTable
  },
  props: {
    showDistressed: { type: Boolean, default: false }
  },
  data() {
    return {
      abortLastSearchValueChangingQuery: false,
      showDownloadButton: true,
      showGoBackToClientsButton: false,
      showLocationFilter: true,
      showAdvancedSettingsBlocksInEditPanel: true,
      locationInFiltersData: [],
      showTunnelsBlockInEditPanel: false,
      previouslySelectedLocationsInFilter: [],
      showGoBackToDashboardButton: false,
      showGoBackToCpeStatisticButton: false,
      getLogFromCPEModal: false,
      customUrlAddressWithSchemaForFWUpgrage: {
        url: '',
        schema: ''
      },
      locationForMultiEditPosition: 'Select Location',
      locationForEditPosition: 'Select Location',
      latitudeForMultiEdit: 0,
      longitudeForMultiEdit: 0,
      setPositionSettingsMode: false,
      showingSpinerOverCoordinates: false,
      addressSelectorShowing: true,
      selectedAddressInSelector: {},
      firmwareObjectsListFromApi: [],
      showingCpeConfigSetModal: false,
      showingCpeConfigModal: false,
      selectedCpeIdForCurrentCpeStat: '',
      selectedCpeNameForCurrentCpeStat: '',
      showingCurrentCPEStatsModal: false,
      beforeDestroyHookExecutedFlag: false,
      wlansFullObjectsForSeletedCpe: undefined,
      showingConnectionnOrDisconnectionFiltersBlock: false,
      showingMaxinactivityModalInfoDetails: false,
      limit: 30,
      offset: 0,
      delayTimer: null,
      searchValue: '',
      searchSpinner: false,
      totalResult: false,
      canLoadMore: true,
      commandResult: '',
      setTimeUsingNTPResult: '',
      isCPEClientsListFromCpeCommandModalOpened: false,
      getCPEClientsListFromCpeCommandResult: '',
      getCPEClientsListFromCpeCommandResultParsed: {},
      getCPEWLANSInfoFromCpeCommandResult: '',
      getCPEWLANSInfoFromCpeCommandResultParsed: {},
      showColumnSwitcher: false,
      cpeForDelete: false,
      checkboxValue: [],
      cpeModelData: false,
      groupSettings: true,
      assignCommonWlanMode: false,
      setTemplateMode: false,
      editCpesSettingsMode: false,
      uploadFWfile: false,
      selectFWfile: false,
      fwFromLink: false,
      logSettingsMode: false,
      accessControlMode: false,
      enableSaveChanges: false,
      FWUpgradeModal: false,
      notCorrectCapabilities: false,
      afterUpdate: true,
      isWlanDescription: false,
      interfaceSettings: {
        // DF-49
        // radio0: false,
        // radio1: false,
        // radio2: false
      },
      configFilterList: {
        blacklist: [],
        whitelist: []
      },
      firewallL3List: {
        ip_list: [],
        mac_list: []
      },
      forceFWObjects: false,
      selectedFW: false,
      TXPower: {
        processStyle: {
          backgroundImage: '-webkit-linear-gradient(left, #3498db, #f05b72)'
        }
        // disabled: [true, false]
      },
      rateControl: {
        '11n': {
          data: ['off', 'ht'],
          piecewise: true,
          bgStyle: {
            backgroundColor: '#cccccc'
          },
          processStyle: {
            backgroundColor: '#3498db'
          },
          piecewiseStyle: {
            backgroundColor: '#ccc',
            visibility: 'visible',
            width: '12px',
            height: '12px'
          },
          piecewiseActiveStyle: {
            backgroundColor: '#3498db'
          },
          labelActiveStyle: {
            color: '#3498db'
          },
          piecewiseLabel: true
        },
        '11ac': {
          data: ['off', 'ht', 'vht'],
          piecewise: true,
          bgStyle: {
            backgroundColor: '#cccccc'
          },
          processStyle: {
            backgroundColor: '#3498db'
          },
          piecewiseStyle: {
            backgroundColor: '#ccc',
            visibility: 'visible',
            width: '12px',
            height: '12px'
          },
          piecewiseActiveStyle: {
            backgroundColor: '#3498db'
          },
          labelActiveStyle: {
            color: '#3498db'
          },
          piecewiseLabel: true
        }
      },
      advancedSettings: {
        // DF-49
        // radio0: {
        //   maxClientsEnabled: false,
        //   rateControlEnabled: false
        // },
        // radio1: {
        //   maxClientsEnabled: false,
        //   rateControlEnabled: false
        // },
        // radio2: {
        //   maxClientsEnabled: false,
        //   rateControlEnabled: false
        // }
      },
      rateValue: '',
      maxClientsEnabled: false,
      updateCommonCpesMode: false,
      commonUpdateCpesData: {
        description: '',
        model: '',
        commonSelectedWlans: this.commonSelectedWlans || []
      },
      logSettingsData: {
        stats_config: {
          enabled: true,
          reportperiod: ''
        },
        lbs_config: {
          enabled: true,
          reportperiod: ''
        },
        log_config: {
          enabled: true,
          reportperiod: ''
        }
      },
      commonSelectedWlans: [],
      statLogLbsmodal: false,
      selectedCpesId: [],
      newTags: [],
      newTag: '',
      allChecked: false,
      interfaceSelectedWlans: {
        // DF-49
        // radio0: [],
        // radio1: [],
        // radio2: []
        // сюда будем запихивать ключами id интерфейсов и массивами выбранные wlans из них
      },
      interfaceHiddenWlans: {
        // DF-49
        // radio0: [],
        // radio1: [],
        // radio2: []
      },
      loadingDataForWlanList: {
        // DF-49
        // radio0: false,
        // radio1: false,
        // radio2: false
      },
      wlansListForWlansSearch: {
        // DF-49
        // radio0: [],
        // radio1: [],
        // radio2: []
      },
      timeoutsForListForWlansSearch: {
        // DF-49
        // radio0: undefined,
        // radio1: undefined,
        // radio2: undefined
      },
      timeoutsForListForWlansSearchForMultiedit: undefined,
      wlansListForWlansSearchForMultiedit: [],
      loadingDataForWlanListForMultiedit: false,
      interfaceCapabilities: {},
      searchQuery: '',
      updatedCpe: null,
      filters: {
        query: '',
        interfacesFilter: null,
        radarFilter: null,
        DHCPCaptureFilter: null,
        connectedFilter: null,
        activeFilter: null,
        scanningFilter: null,
        lastError: null,
        location: 'All locations',
        company: 'All companys',
        models: 'All models',
        tag: 'All tags',
        group: 'No RRM filter',
        connectionOrDisconnectionTimeFilter: 'No Connection Or Disconnection Filter',
        connectionOrDisconnectionTimeFilterOrder: 'asc',
        connectionOrDisconnectionTimeFilterByDateRange: false,
        connectionOrDisconnectionTimeFilterDateRange: {
          startDate: moment().startOf('month').valueOf(),
          endDate: moment().endOf('day').valueOf()
        },
        updatingStatusCPEs: null,
        with_childs: false,
      },
      filteredCpes: [],
      hostname: '',
      commonDeletingWlansModal: false,
      commonDeletingTagsModal: false,
      deletingCpeModal: false,
      IPsecModal: false,
      rebootCpeRedundantModal: false,
      rebootCpeAgentModal: false,
      configModal: false,
      setTimeUsingNTPModalShowing: false,
      ntpServerAddressForsetTimeUsingNTP: '',
      rebootCpeModal: false,
      syncTimeModalShowing: false,
      syncTimeCommandResult: undefined,
      syncTimeRequestCancel: undefined,
      syncTimeCommandError: undefined,
      resetCpeModal: false,
      resetBrokerAddress: '',
      commonDeletingCpesModal: false,
      cpeTagsModal: false,
      setTemplateModal: false,
      addTagsModal: false,
      newTemplateModal: false,
      newTemplateData: {
        name: '',
        description: '',
        base_location: '',
        model: '',
        is_auto: false,
        cpes: [],
        template: {
          cpe_config_template: false
        }
      },
      newUuid: '',
      invalidUuid: false,
      fileTypeError: false,
      WebAccessPort: '',
      SshAccessPort: '',
      WebAccessModal: false,
      calculatedCapabilities: {},
      channels40: [],
      channels80: [],
      CpesFwUpgradeMode: false,
      isFWUpgradeShow: false,
      isFirewallShow: false,
      isLatitudeLongitudeBlockShow: false,
      newMacAddress: '',
      newL3MacAddress: '',
      newL3IPAddress: '',
      setTemplateId: '',
      isChangeLocationOpen: false,
      massLocationId: '' || this.$store.state.userData.base_location,
      configCommand: '',
      IPsecOption: ''
    };
  },
  computed: {
    ...brandingMapGetters(['isBeelineBranding']),
    ...featureFlagsMapGetters(['isContentAnalyticsEnabled', 'isExperimentalModeEnabled']),
    ...WLCProductMapGetters(['isWLCProduct']),
    /**
     * Возвращает clientsFromCpeConsoleCommandParsedDataStringTranslator, чтобы его методы можно было
     * вызывать в шаблоне
     *
     * Используется для перевода строк для функции "Показать текущих клиентов (данные с ТД)"
     */
    clientsFromCpeConsoleCommandParsedDataStringTranslator() {
      return clientsFromCpeConsoleCommandParsedDataStringTranslator;
    },
    /**
     * Услиовие для того чтобы спрятать некоторые кнопки в режиме мультиредактирования для WLС сборок
     *
     * Используется чтобы пока скрыть для WLC все кнопки мультиредактирования кроме "Перезагрузить" ТД и "Обновить ПО"
     * При этом в ONE оставляем все как было
     *
     * https://wimark.kaiten.ru/space/327612/card/35692755
     * https://wimark.kaiten.ru/space/327612/card/37710110
     * https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/357793940/FT23.13+GUI+Wimark+WLC+FrontEnd (пункт 9)
     *
     * @return {Boolean}
     */
    isHiddenMultiEditButtonForWLCBuild() {
      return this.isWLCProduct;
    },
    isShowDeleteAPButtonForSingleAPEdit() {
      if (this.isDisable || this.isUpdating || (this.updatedCpe.connected && !this.isRootAdmin)) {
        return false;
      }
      return true;
    },
    isRootAdmin() {
      return this.$store.getters.isRootAdmin;
    },
    disabledChannelsForCountriesComputed() {
      return disabledChannelsForCountries;
    },
    countriesListComputed() {
      return countriesList;
    },
    currentCPEStateTunnels() {
      try {
        if (this.cpeEditCurrent && typeof this.cpeEditCurrent === 'object' && this.cpeEditCurrent !== null &&
          Object.prototype.hasOwnProperty.call(this.cpeEditCurrent, 'state') && typeof this.cpeEditCurrent.state === 'object' &&
          this.cpeEditCurrent.state !== null && Object.prototype.hasOwnProperty.call(this.cpeEditCurrent.state, 'tunnels') &&
          typeof this.cpeEditCurrent.state.tunnels === 'object' && this.cpeEditCurrent.state.tunnels !== null) {
          return JSON.parse(JSON.stringify(this.cpeEditCurrent.state.tunnels));
        }
        return {}
      } catch (e) {
        console.log(e);
        return {}
      }
    },
    // currentCPEStateTunnelsAsArray() {
    //   try {
    //     if (!this.currentCPEStateTunnels || typeof this.currentCPEStateTunnels !== 'object' || this.currentCPEStateTunnels === null) {
    //       return [];
    //     }
    //     const result = [];
    //     const tunnels = Object.keys(this.currentCPEStateTunnels);
    //     tunnels.forEach((tunnel) => {
    //       result.push(this.currentCPEStateTunnels[tunnel])
    //     });
    //     return result;
    //   } catch (e) {
    //     console.log(e);
    //     return [];
    //   }
    // },
    isShowMapsAndLatitudeLongitudeSettings() {
      return this.$store.getters.isShowMapsAndLatitudeLongitudeSettings;
    },
    // downloadPdfColumnWidths() {
    //   try {
    //     const dataForPdf = this.formatToCSV(this.cpesList);
    //     return helpers.createColumnWidthsForPdfFromDownloadExtractV2InputData(dataForPdf);
    //   } catch (e) {
    //     return undefined;
    //   }
    // },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    showWiredColumnInTableAndDownloads() {
      return this.isBeelineBranding;
    },
    showWiredFilter() {
      return this.isBeelineBranding;
    },
    showLocationDescriptionColumnInTableAndDownloads(){
      return true;
    },
    showLocationColumnsInTableAndDownloads(){
      return this.areLocationsVisible
    },
    showWiFiTargetColumnInTableAndDownloads() {
      return this.isContentAnalyticsEnabled;
    },
    showWiFiTargetFilter() {
      return this.isContentAnalyticsEnabled;
    },
    wpa3Types() {
      // return WPA3_TYPES;
      return this.$store.getters.getWPA3Types;
    },
    showPreviouslySelectedLocationsInFilterBlock() {
      try {
        if (Array.isArray(this.previouslySelectedLocationsInFilter) && this.previouslySelectedLocationsInFilter.length) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
        return false
      }
    },
    isCpeModelYuncoreXd6800() {
      // проверяем yuncore,xd6800 ли точка
      return this.checkSelectedCPEModel('yuncore,xd6800');
    },
    disabledSaveButtonForPositionSetBlockInMultiEdit() {
      try {
        if (this.errors.has('latMultiEdit') || this.errors.has('lngMultiEdit')) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isfirmwareObjectsListFromApiLoading() {
      return this.$store.state.getFirmwareListRequestExecuting;
    },
    firmwareObjectsListFromApiWithAddedVersion() {
      // добавляем в поле version имя файла, чтобы блыо что показать при выборе в UI
      try {
        const firmwaresList = JSON.parse(JSON.stringify(this.firmwareObjectsListFromApi));
        for (const item of firmwaresList) {
          const splitedFirmWarePath = item.path.split('/');
          if (splitedFirmWarePath.length > 0) {
            const filename = splitedFirmWarePath[splitedFirmWarePath.length - 1];
            item.version = filename;
          } else {
            item.version = '';
          }
        }
        // возвращаем отсортированый
        return firmwaresList.sort((a, b) => {
          if (a.version < b.version) {
            return -1;
          }
          if (a.version > b.version) {
            return 1;
          }
          if (a.version.length < b.version.length) {
            return -1;
          }
          if (a.version.length > b.version.length) {
            return 1;
          }
          return 0;
        });
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    cpeEditCurrentLastErrorDescriptionAndRecommendation() {
      try {
        if (
          Object.prototype.hasOwnProperty.call(this.cpeEditCurrent, 'last_error') &&
          typeof this.cpeEditCurrent.last_error === 'object'
        ) {
          // console.log('here!');
          const result = { description: '', recommendation: '' };
          if (
            Object.prototype.hasOwnProperty.call(this.cpeEditCurrent.last_error, 'description') &&
            this.cpeEditCurrent.last_error.description !== ''
          ) {
            result.description = this.cpeEditCurrent.last_error.description;
          }
          if (
            Object.prototype.hasOwnProperty.call(this.cpeEditCurrent.last_error, 'recommendation') &&
            this.cpeEditCurrent.last_error.recommendation !== ''
          ) {
            result.recommendation = this.cpeEditCurrent.last_error.recommendation;
          }
          return result;
        }
        return { description: '', recommendation: '' };
      } catch (e) {
        // console.log(e);
        return { description: '', recommendation: '' };
      }
    },
    cpeConfigForShowInCpeConfigModal() {
      try {
        if (this.editVisible && this.cpeEditCurrent && this.selectedCpesId.length === 0) {
          const result = [];
          const { cpesList } = this.$store.state;
          let currentCpe;

          //  Ищем cpe чтобы достать config
          if (cpesList.length) {
            for (const cpe of cpesList) {
              if (cpe.id === this.$store.state.editCpeId || cpe.id === this.selectedCpesId[0]) {
                currentCpe = cpe;
                break;
              }
            }
          }
          // console.log(currentCpe)
          const selectedCpeConfig = JSON.parse(JSON.stringify(currentCpe));
          // удаляем state
          delete selectedCpeConfig.state;
          // преобразуем массивы обратно в объекты (при загрузке списка точек объекты приеобразуются в массивы)
          if (selectedCpeConfig.config.wifi) {
            selectedCpeConfig.config.wifi = helpers.transformArrayToObject(selectedCpeConfig.config.wifi);
          }
          if (selectedCpeConfig.config.wired) {
            selectedCpeConfig.config.wired = helpers.transformArrayToObject(selectedCpeConfig.config.wired);
          }
          result.push(selectedCpeConfig);
          return result;
        } if (this.selectedCpesId.length > 0) {
          const result = [];
          const { cpesList } = this.$store.state;

          // console.log(cpesList)

          //  Ищем все нужные cpe чтобы достать config
          if (cpesList.length) {
            for (const cpe of cpesList) {
              if (this.selectedCpesId.includes(cpe.id)) {
                const cpeForResult = JSON.parse(JSON.stringify(cpe));
                // удаляем state
                delete cpeForResult.state;
                // преобразуем массивы обратно в объекты (при загрузке списка точек объекты приеобразуются в массивы)
                if (cpeForResult.config.wifi) {
                  cpeForResult.config.wifi = helpers.transformArrayToObject(cpeForResult.config.wifi);
                }
                if (cpeForResult.config.wired) {
                  cpeForResult.config.wired = helpers.transformArrayToObject(cpeForResult.config.wired);
                }
                result.push(cpeForResult);
              }
            }
          }
          return result;
        }
      } catch (e) {
        return {};
      }
    },
    cpeConfigForShowInCpeConfigModalNameOfCpe() {
      try {
        if (this.editVisible && this.cpeEditCurrent && this.selectedCpesId.length === 0) {
          const result = [];
          result.push(this.cpeConfigForShowInCpeConfigModal[0].name);
          return result;
        } if (this.selectedCpesId.length > 0) {
          const result = [];
          for (const item of this.cpeConfigForShowInCpeConfigModal) {
            result.push(item.name);
          }
          return result;
        }
        return [];
      } catch (e) {
        return [];
      }
    },
    updatedCpeConfigWifi() {
      try {
        if (this.updatedCpe === null) {
          return null;
        }
        return this.updatedCpe.config.wifi;
      } catch (e) {
        return null;
      }
    },
    editCpeId() {
      return this.$store.state.editCpeId;
    },
    isSetTimeUsingNTPRequestExecuting() {
      return this.$store.state.setTimeUsingNTPRequestExecuting;
    },
    isShowSpinnerOverSyncTimeModal() {
      return this.$store.state.syncTimeRequestExecuting;
    },
    isBlockButtonsInSyncTimeModal(){
      return this.isShowSpinnerOverSyncTimeModal;
    },
    showingConnectionnOrDisconnectionTimeDateRangeFilterButton() {
      if (this.filters.connectionOrDisconnectionTimeFilter !== 'No Connection Or Disconnection Filter') {
        return true;
      }
      return false;
    },
    areCPEErrorsDiscarding() {
      return this.$store.state.discardingCPEsErrors;
    },
    isCpeHasSomeErrors() {
      // cpe.last_error && (cpe.last_error.description || cpe.last_error.type)
      if (this.checkOneCpeHasErrors(this.cpeEditCurrent)) {
        return true;
      }
      return false;
    },
    isCpeEditCurrentIpsecTunnelType() {
      if (this.cpeEditCurrent) {
        if (this.checkIpsecTunnelType(this.cpeEditCurrent.state.l2tp_state.tunnel_type)) {
          return true;
        }
        return false;
      }
      return false;
    },
    sortedInterfaces() {
      const interfaces = [...this.updatedCpe.config.wifi];
      return interfaces.sort((a, b) => a.frequency - b.frequency);
    },
    commonService() {
      return commonService;
    },
    isRootUser() {
      return this.$store.state.baseLocation === this.$store.state.userData.base_location;
    },
    modalOkTranslation() {
      return this.$i18n.t('aps.confirm');
    },
    modalCancelTranslation() {
      return this.$i18n.t('aps.cancel');
    },
    editVisible() {
      return Boolean(this.$store.state.editCpeId) || this.selectedCpesId.length;
    },
    muteEdit() {
      return this.$store.state.muteCpeEdit;
    },
    muteCpesDelete() {
      return this.$store.state.muteCpesDelete;
    },
    muteCpeFilters() {
      return this.$store.state.muteCpeFilters;
    },
    uploadFW() {
      return this.$store.state.uploadFW;
    },
    WebAccess() {
      return this.$store.state.WebAccess;
    },
    isCpesLoading() {
      return this.$store.state.loadingCpes;
    },
    isLoadingAll() {
      return this.isCpesLoading || this.$store.state.loadingCpeModels ||
        this.$store.state.loadingCpeTemplates || this.$store.state.loadingWlans ||
        this.$store.state.loadingRedirects;
    },
    isCpeHasIntefaces() {
      if (!this.updatedCpe.config.wifi || !this.updatedCpe.state.wifi) {
        return false;
      }
      return true;
    },
    isFiltersActive() {
      return (
        this.filters.interfacesFilter ||
        typeof this.filters.activeFilter === 'boolean' ||
        typeof this.filters.connectedFilter === 'boolean' ||
        this.filters.tag !== 'All tags' ||
        this.filters.group !== 'No RRM filter' ||
        this.filters.location !== 'All locations' ||
        this.filters.models !== 'All models' ||
        this.searchValue ||
        this.filters.connectionOrDisconnectionTimeFilter !== 'No Connection Or Disconnection Filter' ||
        this.filters.updatingStatusCPEs !== null ||
        this.filters.DHCPCaptureFilter
      );
    },
    isCpeHasIntefacesView() {
      if (this.selectedCpesId.length > 0) return false;
      return true;
    },
    cpesList: {
      get() {
        const cpesList = this.$store.state.cpesList;

        if (!cpesList) {
          return [];
        }
        return cpesList;
      },
      set() {}
    },
    rrmList() {
      return this.$store.state.rrmList || [];
    },
    cpeModelsList() {
      return this.$store.state.cpeModelsList;
    },
    cpesTagsCurrentList() {
      return this.$store.state.cpesTagsList;
    },
    cpesTagsList() {
      const cpesTagsList = this.$store.state.cpesTagsList.filter((tag) => {
        if (this.updatedCpe.tags.includes(tag)) {
          return false;
        }
        return true;
      });

      return cpesTagsList;
    },
    cpesMultipleTagsList() {
      const cpesTagsList = this.$store.state.cpesTagsList.filter((tag) => {
        if (this.newTags.includes(tag)) {
          return false;
        }
        return true;
      });

      return cpesTagsList;
    },
    locationsList() {
      // return this.$store.state.locationsList.slice().sort((a, b) => {
      //   if (a.name > b.name) {
      //     return 1;
      //   }
      //   if (a.name < b.name) {
      //     return -1;
      //   }
      //   return 0;
      // });
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    locationsListSortedWithoutCompanyName() {
      return this.$store.getters.locationsListSortedWithoutCompanyName;
    },
    companyList() {
      return this.$store.getters.companyListSorted;
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    templatesList() {
      const filteredByModel = this.$store.state.cpeTemplatesList.filter(
        (template) => template.model === this.updatedCpe.model.id
      );
      return filteredByModel;
    },
    locationsItems() {
      return this.$store.state.locationsItems;
    },
    redirectsList() {
      return this.$store.state.redirectsList;
    },
    cpeOperations() {
      return this.$store.state.cpeOperations;
    },
    cpesIdsOperations() {
      return helpers.combineOperationIdsArrayFromObjects(this.$store.state.cpeOperations);
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    wlansItems: {
      get() {
        return this.$store.state.wlansItems;
      },
      set() {}
    },
    hostsList: {
      get() {
        return this.$store.state.hostsList;
      },
      set() {}
    },
    sshAccess() {
      let link;
      if (API_URL) {
        const restApi = API_URL.split(':');
        link = restApi[1].slice(2);
      } else {
        link = window.location.host;
      }
      return `ssh -l root -p ${this.SshAccessPort} ${link}`;
    },
    webAccess() {
      let link;
      if (API_URL) {
        const restApi = API_URL.split(':');
        if (restApi[2]) {
          restApi.splice(2, 1);
          link = restApi.join(':');
        } else {
          link = restApi.join(':');
        }
      } else {
        link = `http://${window.location.host}`;
      }
      return `${link}:${this.WebAccessPort}`;
    },
    cpeEditCurrent() {
      let reInitedUpdatedCpe = false;
      let sameCpe = false;
      const { cpesList } = this.$store.state;
      let currentCpe;

      //  Определяем текущую cpe для редактирования
      if (cpesList.length) {
        for (const cpe of cpesList) {
          if (cpe.id === this.$store.state.editCpeId || cpe.id === this.selectedCpesId[0]) {
            currentCpe = cpe;
            break;
          }
        }
      }

      // Если новая точка, то копируем
      if (
        (this.updatedCpe && this.updatedCpe.id === this.$store.state.editCpeId) ||
        (this.updatedCpe && this.updatedCpe.id === this.selectedCpesId[0])
      ) {
        sameCpe = true;
      }
      if (
        (!sameCpe ||
          this.afterUpdate ||
          (this.updatedCpe &&
            helpers.combineModelIdsArrayFromObjectsArray(this.cpeOperations).includes(this.updatedCpe.id))) &&
        currentCpe
      ) {
        if (this.isReInitUpdatedCpe(currentCpe)) {
          this.updatedCpe = JSON.parse(JSON.stringify(currentCpe));
          reInitedUpdatedCpe = true;
        }
      }
      // Находим capabilities у текущей модели
      if (!this.updatedCpe.config.wired) {
        this.updatedCpe.config.wired = [];
      }
      // заполняем временные white/blacklist
      this.configFilterList.blacklist = this.updatedCpe.config.lbs_config.blacklist;
      this.configFilterList.whitelist = this.updatedCpe.config.lbs_config.whitelist;

      if (this.updatedCpe.config.firewall.l3_filter) {
        if (
          !this.updatedCpe.config.firewall.l3_filter.mac_list ||
          !Array.isArray(this.updatedCpe.config.firewall.l3_filter.mac_list)
        ) {
          this.updatedCpe.config.firewall.l3_filter.mac_list = [];
        }
        this.firewallL3List.mac_list = this.updatedCpe.config.firewall.l3_filter.mac_list.sort();

        if (!Array.isArray(this.updatedCpe.config.firewall.l3_filter.ip_list)) {
          this.updatedCpe.config.firewall.l3_filter.ip_list = [];
        }
        this.firewallL3List.ip_list = this.updatedCpe.config.firewall.l3_filter.ip_list.sort();
      }

      if (!this.updatedCpe.config.log_config.log_port) {
        this.updatedCpe.config.log_config.log_port = '';
      }
      if (!this.updatedCpe.config.stats_config.reportperiod) {
        this.updatedCpe.config.stats_config.reportperiod = '';
      }
      if (!this.updatedCpe.config.lbs_config.reportperiod) {
        this.updatedCpe.config.lbs_config.reportperiod = '';
      }
      if (!this.updatedCpe.config.lbs_config.clienttimeout) {
        this.updatedCpe.config.lbs_config.clienttimeout = '';
      }

      const modelId = this.updatedCpe.model.id;
      const { cpeModelsList } = this.$store.state;

      if (cpeModelsList.length) {
        for (const model of cpeModelsList) {
          if (model.id === modelId) {
            this.cpeModelData = model;
            if (
              this.cpeModelData.name.toLowerCase().includes('cisco') ||
              this.updatedCpe.model.short.toLowerCase().includes('cisco')
            ) {
              this.cpeModelData.cisco = true;
            }

            for (const wiredInterface in this.cpeModelData.caps.wired) {
              if (this.updatedCpe && !helpers.transformArrayToObject(this.updatedCpe.config.wired)[wiredInterface]) {
                this.updatedCpe.config.wired.push({
                  id: wiredInterface,
                  vlans: [],
                  primary_vlan: 0,
                  fake_wlan: ''
                });
              }
              const wiredPorts = this.cpeModelData.caps.wired[wiredInterface].ports;
              const filteredPorts = wiredPorts.sort((curr, prev) => {
                if (curr.index > prev.index) {
                  return 1;
                }
                if (curr.index < prev.index) {
                  return -1;
                }
                return 0;
              });
              this.cpeModelData.caps.wired[wiredInterface].ports = filteredPorts;
            }
            break;
          } else {
            this.cpeModelData = false;
          }
        }
      }


      if (this.updatedCpe &&
        this.updatedCpe.config &&
        this.updatedCpe.config.wifi &&
        Array.isArray(this.updatedCpe.config.wifi)) {
        this.updatedCpe.config.wifi.forEach((configInterface) => {
          // DF-49
          if (!Object.prototype.hasOwnProperty.call(this.advancedSettings, configInterface.id)) {
            // this.advancedSettings[configInterface.id] = {};
            this.$set(this.advancedSettings, configInterface.id, {
              maxClientsEnabled: false,
              rateControlEnabled: false
            })
          }
          if (!Object.prototype.hasOwnProperty.call(this.interfaceSelectedWlans, configInterface.id)) {
            // this.interfaceSelectedWlans[configInterface.id] = [];
            this.$set(this.interfaceSelectedWlans, configInterface.id, []);
          }
          if (!Object.prototype.hasOwnProperty.call(this.interfaceHiddenWlans, configInterface.id)) {
            // this.interfaceHiddenWlans[configInterface.id] = [];
            this.$set(this.interfaceHiddenWlans, configInterface.id, []);
          }
          if (!Object.prototype.hasOwnProperty.call(this.wlansListForWlansSearch, configInterface.id)) {
            // this.wlansListForWlansSearch[configInterface.id] = [];
            this.$set(this.wlansListForWlansSearch, configInterface.id, []);
          }
          if (!Object.prototype.hasOwnProperty.call(this.interfaceSettings, configInterface.id)) {
            // this.interfaceSettings[configInterface.id] = false;
            this.$set(this.interfaceSettings, configInterface.id, false);
          }

          if (!Object.prototype.hasOwnProperty.call(this.loadingDataForWlanList, configInterface.id)) {
            // this.loadingDataForWlanList[configInterface.id] = false;
            this.$set(this.loadingDataForWlanList, configInterface.id, false);
          }
          if (!Object.prototype.hasOwnProperty.call(this.timeoutsForListForWlansSearch, configInterface.id)) {
            // this.timeoutsForListForWlansSearch[configInterface.id] = undefined;
            this.$set(this.timeoutsForListForWlansSearch, configInterface.id, undefined);
          }
        });
      }


      if (
        this.updatedCpe &&
        this.updatedCpe.config &&
        this.updatedCpe.config.wifi &&
        this.updatedCpe.state &&
        this.updatedCpe.state.wifi &&
        this.cpeModelData
      ) {
        this.updatedCpe.config.wifi.forEach((configInterface) => {
          if (!this.cpeModelData.caps.wifi[configInterface.id].txpwrlist.length) {
            return;
          }

          // // DF-49
          // if (!Object.prototype.hasOwnProperty.call(this.advancedSettings, configInterface.id)) {
          //   // this.advancedSettings[configInterface.id] = {};
          //   this.$set(this.advancedSettings, configInterface.id, {
          //     maxClientsEnabled: false,
          //     rateControlEnabled: false
          //   })
          // }
          // if (!Object.prototype.hasOwnProperty.call(this.interfaceSelectedWlans, configInterface.id)) {
          //   // this.interfaceSelectedWlans[configInterface.id] = [];
          //   this.$set(this.interfaceSelectedWlans, configInterface.id, []);
          // }
          // if (!Object.prototype.hasOwnProperty.call(this.interfaceHiddenWlans, configInterface.id)) {
          //   // this.interfaceHiddenWlans[configInterface.id] = [];
          //   this.$set(this.interfaceHiddenWlans, configInterface.id, []);
          // }
          // if (!Object.prototype.hasOwnProperty.call(this.wlansListForWlansSearch, configInterface.id)) {
          //   // this.wlansListForWlansSearch[configInterface.id] = [];
          //   this.$set(this.wlansListForWlansSearch, configInterface.id, []);
          // }
          // if (!Object.prototype.hasOwnProperty.call(this.interfaceSettings, configInterface.id)) {
          //   // this.interfaceSettings[configInterface.id] = false;
          //   this.$set(this.interfaceSettings, configInterface.id, false);
          // }
          //
          // if (!Object.prototype.hasOwnProperty.call(this.loadingDataForWlanList, configInterface.id)) {
          //   // this.loadingDataForWlanList[configInterface.id] = false;
          //   this.$set(this.loadingDataForWlanList, configInterface.id, false);
          // }
          // if (!Object.prototype.hasOwnProperty.call(this.timeoutsForListForWlansSearch, configInterface.id)) {
          //   // this.timeoutsForListForWlansSearch[configInterface.id] = undefined;
          //   this.$set(this.timeoutsForListForWlansSearch, configInterface.id, undefined);
          // }

          const currentWlans = [];
          const hiddenWlans = [];

          const stateInterface = this.updatedCpe.state.wifi[configInterface.id];
          const sortedTxpwrlist = this.cpeModelData.caps.wifi[configInterface.id].txpwrlist.sort(helpers.compareDbm);
          const maxTxPower = sortedTxpwrlist[sortedTxpwrlist.length - 1].dbm;
          const minTxPower = sortedTxpwrlist[0].dbm;

          if (!configInterface.power.range || configInterface.power.range.length !== 2) {
            if (this.cpeModelData && this.cpeModelData.caps.wifi[configInterface.id].txpwrlist) {
              configInterface.power.range = [minTxPower, maxTxPower];
            } else {
              configInterface.power.range = [0, 10];
            }
          }
          if (this.cpeModelData.cisco) {
            configInterface.power.range[0] = minTxPower;
          }

          if (this.wlansList) {
            configInterface.wlans.forEach((id) => {
              if (this.wlansItems[id]) {
                this.wlansItems[id].id = id;
                currentWlans.push(this.wlansItems[id]);
              } else {
                hiddenWlans.push(id);
              }
            });
          }

          if ((!sameCpe || this.afterUpdate) && reInitedUpdatedCpe === true) {
            this.interfaceSelectedWlans[configInterface.id] = JSON.parse(JSON.stringify(currentWlans));
            if (!this.isRootUser) {
              this.interfaceHiddenWlans[configInterface.id] = JSON.parse(JSON.stringify(hiddenWlans));
            }
          }

          configInterface.wlans = helpers.combineIdsArrayFromObjectsArray(currentWlans);

          if (configInterface.maxclients) {
            this.advancedSettings[configInterface.id].maxClientsEnabled = true;
            // console.log(configInterface);
          } else {
            this.advancedSettings[configInterface.id].maxClientsEnabled = false;
          }

          if (stateInterface.bandmode === '11bgn') {
            stateInterface.bandmode = '11n';
          }

          if (!configInterface.bandmode) {
            configInterface.bandmode = stateInterface.bandmode;
          }

          if (!configInterface.bandwidth) {
            configInterface.bandwidth = stateInterface.bandwidth;
          }

          if (!configInterface.bandmode && !stateInterface.bandmode) {
            // проверяем на наличие доступного capabilities
            if (this.cpeModelData.caps.wifi && this.cpeModelData.caps.wifi[configInterface.id]) {
              if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.ac) {
                configInterface.bandmode = '11ac';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.n) {
                configInterface.bandmode = '11n';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.a) {
                configInterface.bandmode = '11a';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.b) {
                configInterface.bandmode = '11b';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.g) {
                configInterface.bandmode = '11g';
              }
            }
          }

          if (!configInterface.frequency) {
            if (
              this.cpeModelData &&
              this.cpeModelData.caps.wifi &&
              this.cpeModelData.caps.wifi[configInterface.id] &&
              this.cpeModelData.caps.wifi[configInterface.id].frequency !== 'Both'
            ) {
              Vue.set(configInterface, 'frequency', this.cpeModelData.caps.wifi[configInterface.id].frequency);
            } else if (stateInterface.frequency !== 'Both') {
              Vue.set(configInterface, 'frequency', stateInterface.frequency);
            } else {
              Vue.set(configInterface, 'frequency', '5');
            }
          }

          if (!configInterface.bandmode && !stateInterface.bandmode) {
            // проверяем на наличие доступного capabilities
            if (this.cpeModelData.caps.wifi && this.cpeModelData.caps.wifi[configInterface.id]) {
              if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.ac) {
                configInterface.bandmode = '11ac';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.n) {
                configInterface.bandmode = '11n';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.a) {
                configInterface.bandmode = '11a';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.b) {
                configInterface.bandmode = '11b';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.g) {
                configInterface.bandmode = '11g';
              }
            }
          }

          if (!configInterface.country) {
            configInterface.country = 'RU';
          }

          if (this.cpeModelData && this.cpeModelData.caps.wifi && this.cpeModelData.caps.wifi[configInterface.id]) {
            this.interfaceCapabilities[configInterface.id] = {
              selected: configInterface,
              capabilities: this.cpeModelData.caps.wifi[configInterface.id]
            };
            // console.log(JSON.parse(JSON.stringify(this.interfaceCapabilities)))
            // изменения для задачи WNE-3314 (необходимо сделать минимальным значением txPower 1 dbm)
            if (typeof this.interfaceCapabilities[configInterface.id].selected === 'object' &&
              this.interfaceCapabilities[configInterface.id].selected !== null &&
              Object.prototype.hasOwnProperty.call(this.interfaceCapabilities[configInterface.id].selected, 'power') &&
              typeof this.interfaceCapabilities[configInterface.id].selected.power === 'object' &&
              this.interfaceCapabilities[configInterface.id].selected.power !== null &&
              Object.prototype.hasOwnProperty.call(this.interfaceCapabilities[configInterface.id].selected.power, 'range') &&
              Array.isArray(this.interfaceCapabilities[configInterface.id].selected.power.range) &&
              this.interfaceCapabilities[configInterface.id].selected.power.range.length &&
              this.interfaceCapabilities[configInterface.id].selected.power.range[0] === 0) {
              // если в настроках интерфеса было установлено txPower min 0, то ставим 1,
              // так как минимальное значение в слайдере теперь 1
              this.interfaceCapabilities[configInterface.id].selected.power.range[0] = 1;
            }
            // console.log(JSON.parse(JSON.stringify(this.interfaceCapabilities)))
            // конец изменений  для WNE-3314

            this.reverseCalculateCapabilities(configInterface, stateInterface);
            this.calculateCapabilities(
              configInterface,
              stateInterface,
              this.cpeModelData.caps.wifi[configInterface.id],
              configInterface.id
            );
          } else {
            this.interfaceCapabilities[configInterface.id] = {
              selected: configInterface
            };
          }
        });
      }

      this.afterUpdate = false;
      return currentCpe;
    },
    cpeSelectedId() {
      return this.$store.state.editCpeId;
    },
    hideCommonEdit() {
      return (
        this.assignCommonWlanMode ||
        this.updateCommonCpesMode ||
        this.logSettingsMode ||
        this.accessControlMode ||
        this.editCpesSettingsMode ||
        this.addTagsModal ||
        this.CpesFwUpgradeMode ||
        this.setTemplateMode ||
        this.setPositionSettingsMode
      );
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator' || this.isUpdating;
    },
    isUpdating() {
      return (
        (this.updatedCpe && this.cpeEditCurrent && this.cpeEditCurrent.config_status == 'updating') ||
        (this.updatedCpe && this.cpeEditCurrent && this.cpeEditCurrent.config_status == 'upgrading') ||
        (this.updatedCpe &&
          helpers.combineModelIdsArrayFromObjectsArray(this.cpeOperations).includes(this.updatedCpe.id)) ||
        (this.updatedCpe && this.isBusy(this.updatedCpe.id, this.updatedCpe.config_status))
      );
    },
    isOperator() {
      return this.$store.state.userData.role == 'operator';
    },
    isCisco() {
      let isCisco = false;
      if (this.cpeModelData && this.cpeModelData.name.toLowerCase().includes('cisco')) {
        isCisco = true;
      }
      if (this.updatedCpe && this.updatedCpe.model.short.toLowerCase().includes('cisco')) {
        isCisco = true;
      }
      return isCisco;
    },
    isAllChecked() {
      let result = true;
      let cpesIdsList;
      let notUpgradingCpes = [];
      if (this.cpesList) {
        notUpgradingCpes = this.cpesList.filter((cpe) => cpe.config_status !== 'updating');
        cpesIdsList = helpers.combineIdsArrayFromObjectsArray(notUpgradingCpes);
      }
      if (cpesIdsList && cpesIdsList.length && this.selectedCpesId && this.selectedCpesId.length) {
        cpesIdsList.forEach((cpe_id) => {
          if (!this.selectedCpesId.includes(cpe_id)) {
            return (result = false);
          }
        });
      } else {
        return (result = false);
      }
      return result;
    },
    userDataLocation() {
      this.filters.base_location = this.$store.state.userData.base_location;
      return this.$store.state.userData.base_location;
    },
    isNoData() {
      return this.filteredCpes.length;
    },
    computedCapabilities() {
      return this.calculatedCapabilities;
    },
    checkGroupSettings() {
      return this.groupSettings;
    },
    tableSettings() {
      return getDefaultTableSettings(this.$i18n).cpe;
    },
    firewallsList() {
      return this.$store.state.firewallsList;
    },
    isSendCPEConfigScriptFromCpesExecuting() {
      return this.$store.state.sendCPEConfigScriptFromCpesExecuting;
    },
    isgetCPEClientsListFromCpeCommandRequestExecuting() {
      return this.$store.state.getCPEClientsListFromCpeCommandRequestExecuting;
    },
    isgetCPEWLANSInfoFromCpeCommandRequestExecuting() {
      return this.$store.state.getCPEWLANSInfoFromCpeCommandRequestExecuting;
    },
    isShowSpinnerOverCPEClientsListFromCpeCommandModal() {
      return (
        this.isgetCPEClientsListFromCpeCommandRequestExecuting || this.isgetCPEWLANSInfoFromCpeCommandRequestExecuting
      );
    }
    // getCPEClientsListFromCpeCommandResultParsed() {
    //   return cpeService.cpeClientsListFromCpeCommandResultParse(this.getCPEClientsListFromCpeCommandResult)
    // }
  },
  watch: {
    searchValue(newSearchQuery) {
      this.debounce(async () => {
        // todo refactor
        // хак чтобы при переходах на страницу с других страниц (dashboard), открывалась панель настройки ТД и отображалась кнопка редиректа на пред. страницу
        if (this.abortLastSearchValueChangingQuery) {
          this.showGoBackToDashboardButton = false;
          this.showGoBackToCpeStatisticButton = false;
          this.showGoBackToDashboardButton = false;
          this.abortLastSearchValueChangingQuery = false;
        }
        if (this.showGoBackToDashboardButton || this.showGoBackToCpeStatisticButton || this.showGoBackToClientsButton) {
          this.abortLastSearchValueChangingQuery = true;

          return;
        }

        // хак закончился

        if (!newSearchQuery) {
          return;
        }

        if (this.setSearchBarQuery) {
          this.setSearchBarQuery(newSearchQuery);
        }

        this.searchInputHandler(newSearchQuery);
      }, 1000);
    },
    forceFWObjects: {
      handler (val, oldVal) {
        if (val === false) {
          this.clearCustomUrlAddressWithSchemaForFWUpgrage()
        }
      },
      deep: true
    },
    updatedCpeConfigWifi(value) {
      // смотрим на список wlan сетей для заданой точки, когда он меняется делаем запрос полных оъектов этих WLAN
      // console.log(value);
      if (value !== null) {
        if (this.editCpeId !== '') {
          this.wlansFullObjectsForSeletedCpe = undefined;
          this.loadSelectedCpeWlansData(this.editCpeId);
        }
      }
    },
    editCpeId(id) {
      // console.log(id);
      this.wlansFullObjectsForSeletedCpe = undefined;
      this.loadSelectedCpeWlansData(id);
      this.hideTunnelsBlockInEditPanel();
      this.hideAdvancedSettingsBlocksInEditPanel()
      this.$nextTick(()=> {
        this.openAdvancedSettingsBlocksInEditPanel();
      })
    },
    cpeOperations() {},
    cpesList() {
      this.afterUpdate = true;
      cpePollingService.startPolling(this);
    },
    updatedCpe: {
      handler(val, oldVal) {
        if (!oldVal) {
          this.enableSaveChanges = false;
        } else if (val && oldVal && val.id !== oldVal.id) {
          this.enableSaveChanges = false;
        } else if (!val) {
          this.enableSaveChanges = false;
        } else {
          this.enableSaveChanges = true;
        }
      },
      deep: true
    },
    isLoadingAll: {
      handler(val, oldVal) {
        try {
          if (oldVal === true && val === false && this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('cpeIdFormCpeStatisic')) {
            // после загрузки списка CPE если ранее  перешили в раздел CPE из статистики по CPE с передачей определной точки для октрытия открываем ее в панели редактироваания
            if (Array.isArray(this.cpesList) && this.cpesList.length > 0 && this.cpesList.find(cpe => {
              return cpe.id === this.$route.params.cpeIdFormCpeStatisic;
            })) {
              this.$nextTick(() => {
                this.$store.commit('setCpesEditIndex', '0');
                this.$store.commit('setCpesEditId', this.$route.params.cpeIdFormCpeStatisic ?? '');
                // и удаляем чтобы этот вотчер больше не отрабатывал если список cpe обновляется (и isCpesLoading меняет состояние)
                delete this.$route.params.cpeIdFormCpeStatisic;
              })
            }
          }
          if (oldVal === true && val === false && this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('cpeIdFormDashboard')) {
            // после загрузки списка CPE если ранее  перешили в раздел CPE из дашборда по CPE с передачей определной точки для октрытия открываем ее в панели редактироваания
            if (Array.isArray(this.cpesList) && this.cpesList.length > 0 && this.cpesList.find(cpe => {
              return cpe.id === this.$route.params.cpeIdFormDashboard;
            })) {
              this.$nextTick(() => {
                this.$store.commit('setCpesEditIndex', '0');
                this.$store.commit('setCpesEditId', this.$route.params.cpeIdFormDashboard ?? '');
                // и удаляем чтобы этот вотчер больше не отрабатывал если список cpe обновляется (и isCpesLoading меняет состояние)
                delete this.$route.params.cpeIdFormDashboard;
              })
            }
          }
          if (oldVal === true && val === false && this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('cpeIdFormClients')) {
            // после загрузки списка CPE если ранее  перешили в раздел CPE из клинетов по CPE с передачей определной точки для октрытия открываем ее в панели редактироваания
            if (Array.isArray(this.cpesList) && this.cpesList.length > 0 && this.cpesList.find(cpe => {
              return cpe.id === this.$route.params.cpeIdFormClients;
            })) {
              this.$nextTick(() => {
                this.$store.commit('setCpesEditIndex', '0');
                this.$store.commit('setCpesEditId', this.$route.params.cpeIdFormClients ?? '');
                // и удаляем чтобы этот вотчер больше не отрабатывал если список cpe обновляется (и isCpesLoading меняет состояние)
                delete this.$route.params.cpeIdFormClients;
              })
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  },
  methods: {
    deleteAP(rowAPData) {
      const { id } = rowAPData;

      this.isFWUpgradeShow = false;
      this.isLatitudeLongitudeBlockShow = false;
      this.isFirewallShow = false;
      this.statLogLbsmodal = false;

      this.openDeletingCpeDialog(id);
    },
    openEditAPPanel(rowAPData) {
      const { originalIndex, nativeData: { id } } = rowAPData;

      this.isFWUpgradeShow = false;
      this.isLatitudeLongitudeBlockShow = false;
      this.isFirewallShow = false;
      this.statLogLbsmodal = false;

      // DF-49
      // this.interfaceSettings.radio0 = false;
      // this.interfaceSettings.radio1 = false;
      // this.interfaceSettings.radio2 = false;

      Object.keys(this.interfaceSettings).forEach((interfaceName)=>{
        this.interfaceSettings[interfaceName] = false;
      });

      this.forceFWObjects = false;
      if (!this.selectedCpesId.length) {
        this.$store.commit('setCpesEditIndex', originalIndex);
        this.$store.commit('setCpesEditId', id);
      }
    },
    handleSelectAPRow(selectedRowsAPData) {
      // todo refactor
      // хак чтобы предотвратить infinite loop при выборе чекбоксов
      // он происходит изз-за того, что что-то триггерит перерендер после выбора
      // но пока не найдено, что
      let isIncludes = false;

      const selectedAPsIds = selectedRowsAPData.map(ap => {
        const apId = ap.nativeData.id;

        if (this.selectedCpesId.includes(apId)) {
          isIncludes = true;
        }
        return apId;
      });

      if (isIncludes && selectedRowsAPData.length === this.selectedCpesId.length) {
        return;
      }
      // хак закончился


      // checkCpe
      if (this.CpesFwUpgradeMode === true) {
        this.CpesFwUpgradeMode = false;
      }
      this.disableSingleEditMode();

      this.selectedCpesId = selectedAPsIds


      // ! legacy
      if (!this.checkCpesModel()) {
        this.resetMultipleEditMode();
      }
      if (!this.checkCpesConnectedState()) {
        this.resetMultipleEditMode();
      }
      let cpeObj;
      if (this.selectedCpesId[0]) {
        cpeObj = JSON.parse(JSON.stringify(commonService.cpeObjbyId(this.selectedCpesId[0])));
        this.updatedCpe = JSON.parse(JSON.stringify(cpeObj));
      } else {
        this.$store.commit('setCpesEditId', null);
        return;
      }

      // Находим capabilities
      const modelId = this.updatedCpe.model.id;
      const { cpeModelsList } = this.$store.state;
      cpeModelsList.forEach((model) => {
        if (model.id === modelId) {
          this.cpeModelData = model;
        } else {
        }
      });

      // Проверяем корректность capabilities
      if (!this.cpeModelData || !this.cpeModelData.caps.wifi || !Object.keys(this.cpeModelData.caps.wifi).length) {
        this.notCorrectCapabilities = true;
      } else {
        this.notCorrectCapabilities = false;
      }

      this.checkRfCollision();
    },
    isShowDeleteAPButtonForMultiAPsEdit() {
      if (this.isRootAdmin) {
        return true;
      }
      return this.checkCpesDisconnectedState();
    },
    changeLocationForCpeHandler() {
      // console.log(JSON.parse(JSON.stringify(this.interfaceSelectedWlans)));
      for (const interfaceName of Object.keys(this.interfaceSelectedWlans)){
        this.interfaceSelectedWlans[interfaceName] = []
      }
    },
    changeCountryHandler(interfaceId) {
      // console.log('this.interfaceCapabilities[interfaceId].selected.channel', this.interfaceCapabilities);
      // this.interfaceCapabilities[interfaceId].selected.channels = [];
      // для задачи WNE-3589
      // если для выбраной страны недоступны какие-то каналы, то чистим от них список выбранных
      this.$nextTick(() => {
        const selectedCountry = this.interfaceCapabilities[interfaceId].selected.country;
        const isDisabledChannelsForSelectedCountry = Object.keys(this.countriesListComputed).includes(selectedCountry);
        let channelsDisabledForSelectedCountry = [];
        // console.log('isDisableChannelsForSelectedCountry', isDisableChannelsForSelectedCountry);
        if (isDisabledChannelsForSelectedCountry) {
          channelsDisabledForSelectedCountry = JSON.parse(JSON.stringify(this.disabledChannelsForCountriesComputed[this.countriesListComputed[selectedCountry]]));
        }
        this.$nextTick(() => {
          if (isDisabledChannelsForSelectedCountry && Array.isArray(this.interfaceCapabilities[interfaceId].selected.channels)) {
            this.interfaceCapabilities[interfaceId].selected.channels =
              this.interfaceCapabilities[interfaceId].selected.channels.filter((channel) => !channelsDisabledForSelectedCountry.includes(channel));
          }
        })
      });
    },
    extractSelectedWlansIdsByInterfaceNames(interfaceSelectedWlans) {
      // console.log(interfaceSelectedWlans)
      const result = {};
      const interfaceNames = Object.keys(interfaceSelectedWlans);
      for (const interfaceName of interfaceNames) {
        if (!Object.prototype.hasOwnProperty.call(result, interfaceName)) {
          result[interfaceName] = [];
        }
        for (const item of interfaceSelectedWlans[interfaceName]) {
          result[interfaceName].push(item.id)
        }
      }
      return result;
    },
    downloadPdfColumnWidths() {
      try {
        // console.log('sdfsdfasdfsadsadffssadfdf')
        const dataForPdf = this.formatToCSV(this.cpesList);
        return helpers.createColumnWidthsForPdfFromDownloadExtractV2InputData(dataForPdf);
      } catch (e) {
        return undefined;
      }
    },
    redrawDownloadButton() {
      this.showDownloadButton = false;
      this.$nextTick(() => {
        this.showDownloadButton = true;
      })
    },
    getCPEIPForDownloadsFiles(cpeObject) {
      try {
        if (typeof cpeObject === 'object' && Object.prototype.hasOwnProperty.call(cpeObject, 'state') &&
          typeof cpeObject.state === 'object' && Object.prototype.hasOwnProperty.call(cpeObject.state, 'network') &&
          cpeObject.state.network.ipaddr) {
          return cpeObject.state.network.ipaddr;
        }
        return '—'
      } catch (e) {
        console.log(e);
        return '—'
      }
    },
    showColumnAdditionalCheck(columnKeyName) {
      // console.log(columnKeyName);
      if (!this.showWiFiTargetColumnInTableAndDownloads && columnKeyName === 'radar') {
        return false;
      }
      if (!this.showWiredColumnInTableAndDownloads && columnKeyName === 'wired') {
        return false;
      }
      if (!this.showLocationColumnsInTableAndDownloads && (columnKeyName === 'locationDescription' || columnKeyName === 'location')) {
        return false;
      }
      if (!this.showLocationDescriptionColumnInTableAndDownloads && columnKeyName === 'locationDescription') {
        return false;
      }
      return true;
    },
    toggleShowTunnelsBlockInEditPanel() {
      this.showTunnelsBlockInEditPanel = !this.showTunnelsBlockInEditPanel
    },
    openTunnelsBlockInEditPanel() {
      this.showTunnelsBlockInEditPanel = true;
    },
    hideTunnelsBlockInEditPanel() {
      this.showTunnelsBlockInEditPanel = false;
    },
    openAdvancedSettingsBlocksInEditPanel() {
      this.showAdvancedSettingsBlocksInEditPanel = true;
    },
    hideAdvancedSettingsBlocksInEditPanel() {
      this.showAdvancedSettingsBlocksInEditPanel = false;
    },
    toggleAdvancedSettingsBlocksInEditPanel(){
      this.showAdvancedSettingsBlocksInEditPanel = !this.showAdvancedSettingsBlocksInEditPanel
    },
    setInterfaceSelectedWlansForInterface(selectedWlansList, interfaceName) {
      // this.interfaceSelectedWlans[interfaceName] = wlansLIst
      Vue.set(this.interfaceSelectedWlans, interfaceName, selectedWlansList);
    },
    setCommonSelectedWlans(selectedWlansList) {
      Vue.set(this, 'commonSelectedWlans', selectedWlansList);
    },
    checkCPEWPA3Capability(cpe) {
      try {
        if (cpe?.state?.firmware?.features && Array.isArray(cpe.state.firmware.features) && cpe.state.firmware.features.length > 0) {
          // console.log(cpe.state.firmware.features)
          if (cpe.state.firmware.features.includes('wpa3')) {
            return true;
          }
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    selectInPreviouslySelectedLocationsInFilter(location) {
      this.selectLocationInFilters(location);
      this.locationInFiltersData = location;
      this.toggleShowLocationFilter();
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = ''
      } else {
        this.filters.location = location.id;
        this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshCpesListQuery();

    },
    changeWithChildsInFilters(){
      this.refreshCpesListQuery();
    },
    addLocationInPreviouslySelectedLocationsInFilter(location) {
      const existingPreviouslySelectedLocationsInFilter = JSON.parse(JSON.stringify(this.previouslySelectedLocationsInFilter));
      const isExistingPassedToFuncLocation = existingPreviouslySelectedLocationsInFilter.find((existingLocation) => {
        return existingLocation.id === location.id
      });
      // console.log(isExistingPassedToFuncLocation)
      if (!isExistingPassedToFuncLocation) {
        console.log('here2');
        existingPreviouslySelectedLocationsInFilter.unshift(location)
        this.previouslySelectedLocationsInFilter = existingPreviouslySelectedLocationsInFilter.splice(0, 3);
      }
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    goBackToDashboard() {
      this.$router.push({name: 'Dashboard'})
    },
    goBackToClients() {
      this.$router.push({name: 'Clients', params: {goBackFromCpes: true}})
    },
    goBackToCpeStatistic() {
        // console.log('go to cpe statistic')
       this.$router.push({name: 'Statcpes', params: {goBackFromCpes: true}})
    },
    // goToEvents() {
    //   this.saveComponentStateDataForRestore()
    //   console.log(this.cpeEditCurrent)
    //   this.$router.push({name: 'Events', query: { id: this.cpeEditCurrent.id, name: this.cpeEditCurrent.name }});
    // },
    goToClients() {
      this.saveComponentStateDataForRestore();
      // console.log(cpe);
      this.$router.push({ name: 'Clients',
        params:
          { cpeId: this.cpeEditCurrent.id,
            cpeName: this.cpeEditCurrent.name,
            locationId: this.cpeEditCurrent.base_location
          }
      });
    },
    goToEvents(cpe) {
      this.saveComponentStateDataForRestore();
      // console.log(cpe);
      this.$router.push({ name: 'Events', query: { id: cpe.id, name: cpe.name } });
    },
    saveComponentStateDataForRestore() {
      // из стора
      const cpesList = JSON.parse(JSON.stringify(this.$store.state.cpesList));
      const editCpeIndex = this.$store.state.editCpeIndex;
      const editCpeId = this.$store.state.editCpeId;
      const muteCpeFilters = this.$store.state.muteCpeFilters;

      // из стейта компонента
      const filters = JSON.parse(JSON.stringify(this.filters));
      const searchQuery = this.searchQuery;
      const totalResult = this.totalResult;
      const searchValue = this.searchValue;
      const limit = this.limit;
      const offset = this.offset;
      const canLoadMore = this.canLoadMore;
      const showingConnectionnOrDisconnectionFiltersBlock = this.showingConnectionnOrDisconnectionFiltersBlock;
      const showGoBackToCpeStatisticButton = this.showGoBackToCpeStatisticButton;
      const showGoBackToDashboardButton = this.showGoBackToDashboardButton;
      const showGoBackToClientsButton = this.showGoBackToClientsButton;
      const locationInFiltersData = JSON.parse(JSON.stringify(this.locationInFiltersData));
      const previouslySelectedLocationsInFilter = JSON.parse(JSON.stringify(this.previouslySelectedLocationsInFilter))

      const cpesDataForRestoreInCpePage = {
        cpesList,
        editCpeIndex,
        editCpeId,
        filters,
        searchQuery,
        totalResult,
        searchValue,
        limit,
        offset,
        canLoadMore,
        muteCpeFilters,
        showingConnectionnOrDisconnectionFiltersBlock,
        showGoBackToCpeStatisticButton,
        showGoBackToDashboardButton,
        showGoBackToClientsButton,
        locationInFiltersData,
        previouslySelectedLocationsInFilter
      }
      localStorage.setItem('cpesComponentStateDataForRestoreInCpePage', JSON.stringify(cpesDataForRestoreInCpePage));
    },
    loadComponentStateDataForRestoreAndRestore() {
      const cpesComponentStateDataForRestoreInCpePage = JSON.parse(localStorage.getItem('cpesComponentStateDataForRestoreInCpePage'));
      // console.log(cpesComponentStateDataForRestoreInCpePage)
      if (cpesComponentStateDataForRestoreInCpePage) {
        // восстанавливаем в стор
        this.$store.commit('setCpesList', cpesComponentStateDataForRestoreInCpePage.cpesList ?? []);
        this.$store.commit('toggleMuteCpeFilters', cpesComponentStateDataForRestoreInCpePage.muteCpeFilters ?? false)

        // восстанавливаем в стейт компонента
        if (!!cpesComponentStateDataForRestoreInCpePage.filters){
          this.$set(this, 'filters', JSON.parse(JSON.stringify(cpesComponentStateDataForRestoreInCpePage.filters)) )
        }
        if (!!cpesComponentStateDataForRestoreInCpePage.locationInFiltersData){
          this.$set(this, 'locationInFiltersData', JSON.parse(JSON.stringify(cpesComponentStateDataForRestoreInCpePage.locationInFiltersData)))
        }

        if (!!cpesComponentStateDataForRestoreInCpePage.previouslySelectedLocationsInFilter){
          this.$set(this, 'previouslySelectedLocationsInFilter', JSON.parse(JSON.stringify(cpesComponentStateDataForRestoreInCpePage.previouslySelectedLocationsInFilter)))
        }

        //  this.filters = this. JSON.parse(JSON.stringify(cpesComponentStateDataForRestoreInCpePage.filters))
        this.searchQuery = cpesComponentStateDataForRestoreInCpePage.searchQuery ?? '';
        this.totalResult = cpesComponentStateDataForRestoreInCpePage.totalResult ?? false;
        this.searchValue = cpesComponentStateDataForRestoreInCpePage.searchValue ?? '';
        this.limit = cpesComponentStateDataForRestoreInCpePage.limit ?? 30;
        this.offset = cpesComponentStateDataForRestoreInCpePage.offset ?? 0;
        this.canLoadMore = cpesComponentStateDataForRestoreInCpePage.canLoadMore ?? true;
        this.showingConnectionnOrDisconnectionFiltersBlock = cpesComponentStateDataForRestoreInCpePage.showingConnectionnOrDisconnectionFiltersBlock ?? false
        this.showGoBackToCpeStatisticButton = cpesComponentStateDataForRestoreInCpePage.showGoBackToCpeStatisticButton ?? false;
        this.showGoBackToDashboardButton = cpesComponentStateDataForRestoreInCpePage.showGoBackToDashboardButton ?? false;
        this.showGoBackToClientsButton = cpesComponentStateDataForRestoreInCpePage.showGoBackToClientsButton ?? false;
        this.$nextTick(() => {
          this.$store.commit('setCpesEditIndex', cpesComponentStateDataForRestoreInCpePage.editCpeIndex ?? false );
          this.$store.commit('setCpesEditId', cpesComponentStateDataForRestoreInCpePage.editCpeId ?? '' );
        })
      }
    },
    removeComponentStateDataForRestore() {
      localStorage.removeItem('cpesComponentStateDataForRestoreInCpePage');
    },
    checkComponentStateDataForRestore(){
      return !!localStorage.getItem('cpesComponentStateDataForRestoreInCpePage');
    },
    checkSelectedCPEModel(modelName) {
      // ждем на вход назавние модели - напирмер "yuncore,xd6800"
      // если текущая точка этой модели - возвращаем true, иначе false
      try {
        if (this.cpeModelData?.name === modelName) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e)
        return false;
      }
    },
    clearCustomUrlAddressWithSchemaForFWUpgrage(){
      this.customUrlAddressWithSchemaForFWUpgrage = {
        url: '',
        schema: ''
      }
    },
    setCustomUrlAddressWithSchemaForFWUpgrage(schema, url){
      this.customUrlAddressWithSchemaForFWUpgrage = {
        url: url,
        schema: schema
      }
    },
    async asyncSearchWlanWhenOpenSelectorList(id) {
      return this.asyncSearchWlan('', id, 0);
    },
    async asyncSearchWlanWhenSearchChange(query, id) {
      return this.asyncSearchWlan(query, id);
    },
    async asyncSearchWlan(query, id, timeout = 1000) {
      if (this.timeoutsForListForWlansSearch[id] !== undefined) {
        clearTimeout(this.timeoutsForListForWlansSearch[id])
      }
      this.loadingDataForWlanList[id] = true;

      let locationForSearch = undefined;
      if (this.updatedCpe.base_location) {
        locationForSearch = this.updatedCpe.base_location
      }

      this.timeoutsForListForWlansSearch[id] = setTimeout(async () => {
        // if (query !== '') {
        // console.log(query);
        // console.log(id);
        // this.loadingDataForWlanList[id] = true;
        try {
          // const response = await wlanService.requestAllCompactWlansWithoutClientsAndWithSearch(query);
          const response = await wlanService.requestAllCompactWlansWithoutClientsAndWithSearch(query, locationForSearch, locationForSearch ? false : true);
          this.wlansListForWlansSearch[id] = response.itemslist;
        } catch (e) {
          console.log(e)
          this.wlansListForWlansSearch[id] = [];
        } finally {
          this.loadingDataForWlanList[id] = false;
        }
        // console.log(this.wlansListForWlansSearch[id]);
        // }
      }, timeout);
    },
    async asyncSearchWlanWhenOpenSelectorListForMultiedit(id) {
      return this.asyncSearchWlanForMultiedit('', 0);
    },
    async asyncSearchWlanWhenSearchChangeForMultiedit(query) {
      return this.asyncSearchWlanForMultiedit(query);
    },
    async asyncSearchWlanForMultiedit(query, timeout = 1000) {
      if (this.timeoutsForListForWlansSearchForMultiedit !== undefined) {
        clearTimeout(this.timeoutsForListForWlansSearchForMultiedit)
      }
      this.loadingDataForWlanListForMultiedit = true;
      this.timeoutsForListForWlansSearchForMultiedit = setTimeout(async () => {

        // console.log(query);
        // console.log(id);
        // this.loadingDataForWlanListForMultiedit = true;
        try {
          const response = await wlanService.requestAllCompactWlansWithoutClientsAndWithSearch(query);
          this.wlansListForWlansSearchForMultiedit = response.itemslist;
        } catch (e) {
          console.log(e)
          this.wlansListForWlansSearchForMultiedit = [];
        } finally {
          this.loadingDataForWlanListForMultiedit = false;

          // console.log(this.wlansListForWlansSearch[id]);
        }
      }, timeout);
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    getCompanyNameForDownloadsFiles(locationId){
      try{
        let companyName = '';
        companyName = this.locationPartyGetField(locationId, 'name');
        if (companyName !== '') {
          return companyName
        }
        return this.$t('general.no').toLowerCase();
      }catch (e) {
        console.log(e);
        return ''
      }
    },
    getCompanyInnForDownloadsFiles(locationId){
      try{
        let companyInn = '';
        companyInn = this.locationPartyGetField(locationId, 'inn');
        if (companyInn !== '') {
          return companyInn
        }
        return this.$t('general.no').toLowerCase();
      }catch (e) {
        console.log(e);
        return ''
      }
    },
    onMarkerDragForSetPositionForSingleEdit(payloadCoords) {
      // console.log(payloadCoords);
      this.updatedCpe.latitude = payloadCoords.lat;
      this.updatedCpe.longitude = payloadCoords.lng;
    },
    onMarkerDragForSetPositionForMultiEdit(payloadCoords) {
      // console.log(payloadCoords);
      this.latitudeForMultiEdit = payloadCoords.lat;
      this.longitudeForMultiEdit = payloadCoords.lng;
    },
    setPositionForMultiEdit() {
      if (!this.disabledSaveButtonForPositionSetBlockInMultiEdit) {
        cpeService.setCpePositionForMultiEdit(this);
      }
      // cpeService.setCpePositionForMultiEdit(this);
    },
    setPositionSettingsShow() {
      if (!this.isShowMapsAndLatitudeLongitudeSettings){
        return;
      }
      this.setPositionSettingsMode = true;
      this.latitudeForMultiEdit = this.updatedCpe.latitude;
      this.longitudeForMultiEdit = this.updatedCpe.longitude;
      this.locationForMultiEditPosition = this.updatedCpe.base_location;
    },
    setPositionSettingsHide() {
      this.setPositionSettingsMode = false;
      this.latitudeForMultiEdit = 0;
      this.longitudeForMultiEdit = 0;
      this.locationForMultiEditPosition = 'Select Location';
    },
    showSpinerOverCoordinates() {
      this.showingSpinerOverCoordinates = true;
    },
    hideSpinerOverCoordinates() {
      this.showingSpinerOverCoordinates = false;
    },
    async requestCoordsByAddress(address) {
      return cpeService.requestCoordsByAddress(address);
    },
    clearSelectedAddressInSelector() {
      this.$set(this, 'selectedAddressInSelector', {});
      this.addressSelectorShowing = false;
      this.$nextTick(() => {
        this.addressSelectorShowing = true;
      });
    },
    selectAddressInSelector() {
      // console.log('selected');
      // console.log(this.selectedAddressInSelector);
      if (
        this.selectedAddressInSelector.hasOwnProperty('lon') &&
        this.selectedAddressInSelector.hasOwnProperty('lat')
      ) {
        this.updatedCpe.latitude = parseFloat(this.selectedAddressInSelector.lat);
        this.updatedCpe.longitude = parseFloat(this.selectedAddressInSelector.lon);
      }
      // if (this.selectedAddressInSelector.hasOwnProperty('display_name')) {
      //   this.location.address = this.selectedAddressInSelector.display_name;
      // }
      this.clearSelectedAddressInSelector();
      this.showSpinerOverCoordinates();
      setTimeout(() => {
        this.hideSpinerOverCoordinates();
      }, 500);
    },
    selectAddressInSelectorForMultiEdit() {
      // console.log('selected');
      // console.log(this.selectedAddressInSelector);
      if (
        this.selectedAddressInSelector.hasOwnProperty('lon') &&
        this.selectedAddressInSelector.hasOwnProperty('lat')
      ) {
        this.latitudeForMultiEdit = parseFloat(this.selectedAddressInSelector.lat);
        this.longitudeForMultiEdit = parseFloat(this.selectedAddressInSelector.lon);
      }
      // if (this.selectedAddressInSelector.hasOwnProperty('display_name')) {
      //   this.location.address = this.selectedAddressInSelector.display_name;
      // }
      this.clearSelectedAddressInSelector();
      this.showSpinerOverCoordinates();
      setTimeout(() => {
        this.hideSpinerOverCoordinates();
      }, 500);
    },
    getLocationNameFromLocationId(locationId) {
      try {
        const id = locationId;
        for (const location of this.locationsListSortedWithoutCompanyName) {
          if (location.id === id) {
            return location.name;
          }
        }
        return '';
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    copyCoordinatesFromLocation() {
      this.showSpinerOverCoordinates();
      setTimeout(() => {
        this.hideSpinerOverCoordinates();
      }, 300);
      const locationId = this.locationForEditPosition;
      // console.log(this.locationsList)
      for (const location of this.locationsList) {
        if (location.id === locationId) {
          this.updatedCpe.latitude = location.coords.lat;
          this.updatedCpe.longitude = location.coords.lng;
          break;
        }
      }
    },
    copyCoordinatesFromLocationForMultiEdit() {
      this.showSpinerOverCoordinates();
      setTimeout(() => {
        this.hideSpinerOverCoordinates();
      }, 300);
      const locationId = this.locationForMultiEditPosition;
      // console.log(this.locationsList)
      for (const location of this.locationsList) {
        if (location.id === locationId) {
          this.latitudeForMultiEdit = location.coords.lat;
          this.longitudeForMultiEdit = location.coords.lng;
          break;
        }
      }
    },
    createFirmwareObjectsListFromApiWithAddedVersionAndModifiedURL(hostAddr) {
      // модифицируем url добавляя адрес хоста и возвращаем массив
      // нужен отсюда this.updatedCpe.state.l2tp_state.host_addr
      try {
        const firmwareObjectsList = JSON.parse(JSON.stringify(this.firmwareObjectsListFromApiWithAddedVersion));
        for (const item of firmwareObjectsList) {
          item.url = `http://${hostAddr}${item.url}`;
        }
        return firmwareObjectsList;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    copyToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.copyToClipboardSuccessShowMsg();
        },
        (err) => {
          this.copyToClipboardErrorShowMsg();
        }
      );
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.copyToClipboardSuccessShowMsg();
      } catch (err) {
        this.copyToClipboardErrorShowMsg();
      }

      document.body.removeChild(textArea);
    },
    copyToClipboardErrorShowMsg() {
      VueNotifications.error({ message: `${this.$t('aps.cpeConfigShowCopyToClipboardError')}` });
    },
    copyToClipboardSuccessShowMsg() {
      VueNotifications.success({ message: `${this.$t('aps.cpeConfigShowCopyToClipboardSuccess')}` });
    },
    isReInitUpdatedCpe(currentCpe) {
      try {
        if (
          (currentCpe && !this.updatedCpe) ||
          (currentCpe && this.updatedCpe && this.updatedCpe.id !== currentCpe.id)
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return true;
      }
    },
    cpeConfigSetConfigEmited(cpesConfig) {
      if (!Array.isArray(cpesConfig) || cpesConfig.length === 0) {
        return;
      }
      // console.log(cpesConfig)
      const configPackedForAPIReq = {};
      for (const configItem of cpesConfig) {
        configPackedForAPIReq[configItem.id] = configItem;
      }
      // console.log(configPackedForAPIReq);
      cpeService.updateCpesConfigFromUploadedConfig(this, configPackedForAPIReq);
    },
    showSetCpeConfigModal() {
      this.showingCpeConfigSetModal = true;
    },
    hideSetCpeConfigModal() {
      this.showingCpeConfigSetModal = false;
    },
    showShowCpeConfigModal() {
      this.showingCpeConfigModal = true;
    },
    hideShowCpeConfigModal() {
      this.showingCpeConfigModal = false;
    },
    openCurrentCpeStatsModal(cpeId, cpeName) {
      // открываем окно с последней статистикой по точкам доступа
      if (!cpeId) {
        return;
      }
      this.selectedCpeIdForCurrentCpeStat = cpeId;
      this.selectedCpeNameForCurrentCpeStat = cpeName;
      this.showingCurrentCPEStatsModal = true;
    },
    closeCurrentCpeStatsModal() {
      // console.log('closed');
      this.showingCurrentCPEStatsModal = false;
      this.selectedCpeIdForCurrentCpeStat = '';
      this.selectedCpeNameForCurrentCpeStat = '';
    },
    isThisArray(variable) {
      // console.log(cpeWired)
      if (Array.isArray(variable)) {
        return true;
      }
      return false;
    },
    wlansNamesForCPE(cpe) {
      // пробегаемся по всем настройкам радиоинтерфейсов на точке, вытаскиваем idшники wlan сетей,
      // и получаем по этим ID названия этих wlan, а далее упаковывваем все названия в строку,
      // для дальнейшего вывода в скачиваемых данных
      try {
        const wlnasIdsForCpe = []; // тут храним вытащенные из точки idшники wlan сетей
        const wlnasNamesForCpe = []; // а тут имена этих wlan
        let resultStringWithWLANSNames = ''; // а тут итоговую строку с их именами, которую вернем из функции
        if (!cpe.config.hasOwnProperty('wifi')) {
          return resultStringWithWLANSNames;
        }
        const cpeConfig = JSON.parse(JSON.stringify(cpe.config.wifi));
        if (cpeConfig.length === 0) {
          // если нет никаких настроек для радиоинтерфейсов сразу возвращаем пустую итоговую строку
          return resultStringWithWLANSNames;
        }
        cpeConfig.forEach((radioInterface) => {
          // вытаскиваем id wlan сетей из всех интерфейсов, если они есть
          // console.log(radioInterface)
          if (
            radioInterface.hasOwnProperty('wlans') &&
            Array.isArray(radioInterface.wlans) &&
            radioInterface.wlans.length !== 0
          ) {
            radioInterface.wlans.forEach((wlan) => {
              wlnasIdsForCpe.push(wlan);
            });
          }
        });
        const wlnasIdsForCpeAsSet = new Set(wlnasIdsForCpe); // преобразовывываем в Set чтобы убрать дубли id'шников, если есть
        const wlnasIdsForCpeFiltered = Array.from(wlnasIdsForCpeAsSet); // и обратно в Array чтобы пользоваться далее как массивом
        if (wlnasIdsForCpeFiltered.length === 0) {
          // если не найдено никаких idшников wlan сетей  возвращаем пустую итоговую строку
          return resultStringWithWLANSNames;
        }
        wlnasIdsForCpeFiltered.forEach((wlanId) => {
          // ищем имена wlan сетей по имеющимся id'шникам
          wlnasNamesForCpe.push(commonService.wlanNamebyId(wlanId));
        });

        if (wlnasNamesForCpe.length === 0) {
          // если в массиве имен wlan ничего нет то возвращаем пустую итоговую строку
          return resultStringWithWLANSNames;
        }

        resultStringWithWLANSNames = wlnasNamesForCpe.join(', '); // формируем итоговою строчку с названиями
        // console.log(resultStringWithWLANSNames);

        return resultStringWithWLANSNames;
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    showExpandedInfoAboutWLAN(wlanId) {
      if (
        this.wlansFullObjectsForSeletedCpe !== undefined &&
        this.wlansFullObjectsForSeletedCpe.hasOwnProperty(wlanId) &&
        this.wlansFullObjectsForSeletedCpe[wlanId].tunneling === true &&
        this.wlansFullObjectsForSeletedCpe[wlanId].hasOwnProperty('proto')
      ) {
        return true;
      }
      return false;
    },
    loadSelectedCpeWlansData(id) {
      // смотим какие там есть wlan сети на выбранной точке и запрашиваем данные этих сетей по их idшникам
      // чтобы дальше вытащить оттуда данные по типу туннеля
      if (id === null) {
        return;
      }
      let selectedCpeObject;
      for (const cpe of this.filterItems(this.cpesList)) {
        if (cpe.id === id) {
          selectedCpeObject = JSON.parse(JSON.stringify(cpe));
          break;
        }
      }
      if (selectedCpeObject === undefined) {
        return;
      }
      const wlansIds = [];
      if (
        selectedCpeObject.hasOwnProperty('config') &&
        selectedCpeObject.config.hasOwnProperty('wifi') &&
        this.isThisArray(selectedCpeObject.config.wifi) &&
        selectedCpeObject.config.wifi.length > 0
      ) {
        for (const wifi of selectedCpeObject.config.wifi) {
          if (wifi.hasOwnProperty('wlans') && Array.isArray(wifi.wlans) && wifi.wlans.length > 0) {
            for (const wlanId of wifi.wlans) {
              wlansIds.push(wlanId);
            }
          }
        }
      }

      if (wlansIds.length <= 0) {
        return;
      }
      // удаляем дублирование id'шников
      const wlansIdsFilterd = wlansIds.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
      // console.log(wlansIdsFilterd);
      wlanService.getWlansWhenSelectCpeInAps(this, wlansIdsFilterd);
    },
    changeCompany() {
      // console.log(val)
      let firstLocationIdForCompany = '';
      if (this.filters.company.hasOwnProperty('locations') && this.filters.company.locations.length > 0) {
        firstLocationIdForCompany = this.filters.company.locations[0];
        // console.log(firstLocationIdForCompany);
      } else {
        firstLocationIdForCompany = 'All locations';
      }
      // this.updatedPage.base_location = firstLocationIdForCompany;
      this.filters.location = firstLocationIdForCompany;

      this.refreshCpesListQuery();
    },
    locationsObjbyIds(ids) {
      return commonService.locationsObjbyIds(ids);
    },
    tooltipForLocationColumn(cpe) {
      try {
        let result = '';
        result += this.locationName(cpe.base_location);
        if (this.locationPartyGetField(cpe.base_location, 'name') !== '') {
          result = `${result}<br>${this.locationPartyGetField(cpe.base_location, 'name')}`;
        }
        if (this.locationPartyGetField(cpe.base_location, 'inn') !== '') {
          result =
            `${result
            }<br>${
              this.$t('location.innCaptionForTable')
            } ${
              this.locationPartyGetField(cpe.base_location, 'inn')}`;
        }
        return result;
      } catch (e) {
        return '';
      }
    },
    syncTimeButtonHandler(cpeIds) {
      // console.log('sync time');
      cpeService.syncTime(this, cpeIds);
    },
    covertSyncTimeTimestampToDateTimeForShow(unixTimestmap) {
      try {
        const timestamp = unixTimestmap;
        const timestampAsDateTime = moment(unixTimestmap * 1000).format(' D MMM YYYY, H:mm:ss [GMT]Z');
        return `${timestampAsDateTime} (${timestamp})`;

      } catch (e) {
        console.log(e);
        return '';
      }
    },
    issueSetTimeUsingNTP(cpeIds, ntpServerAddress) {
      // const command = `ntpd -n -q -p ${ntpServerAddress}`;
      // console.log(command);
      // if (!this.errors.has('ntpServerAddressForsetTimeUsingNTP')){
      //   console.log('send');
      //   //cpeService.issueSetTimeUsingNTP(this, cpeIds, ntpServerAddress);
      // }
      this.$validator.validate('ntpServerAddressForsetTimeUsingNTP').then(
        (result) => {
          if (result) {
            // console.log('send');
            cpeService.issueSetTimeUsingNTP(this, cpeIds, ntpServerAddress);
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    connectionOrDisconnectionTimeFilterSelectorChanged() {
      if (this.filters.connectionOrDisconnectionTimeFilter === 'No Connection Or Disconnection Filter') {
        // если переключили на Нет сортировки, тогда отключаем фильтр по диапазону времени
        this.filters.connectionOrDisconnectionTimeFilterByDateRange = false;
      }
      this.refreshCpesListQuery();
    },
    handleDateChangeForConnectionDisconnectionFilter() {
      // console.log('date changed');
      this.refreshCpesListQuery();
    },
    toggleConnectionnOrDisconnectionFilterByDateRange() {
      // this.filters.connectionOrDisconnectionTimeFilterByDateRange = !this.filters.connectionOrDisconnectionTimeFilterByDateRange;
      this.refreshCpesListQuery();
    },
    toggleConnectionnOrDisconnectionFiltersBlockShowing() {
      if (this.showingConnectionnOrDisconnectionFiltersBlock === true) {
        // если отключаем показ блока с фильтрами по дате подключения\отключения
        // заодно сбрасываем фильтры
        this.filters.connectionOrDisconnectionTimeFilter = 'No Connection Or Disconnection Filter';
        this.filters.connectionOrDisconnectionTimeFilterByDateRange = false;
        this.filters.connectionOrDisconnectionTimeFilterDateRange = {
          startDate: moment().startOf('month').valueOf(),
          endDate: moment().endOf('day').valueOf()
        };
        this.refreshCpesListQuery();
      }
      this.showingConnectionnOrDisconnectionFiltersBlock = !this.showingConnectionnOrDisconnectionFiltersBlock;
    },
    toggleSortModeForconnectionOrDisconnectionTimeFilter() {
      if (this.filters.connectionOrDisconnectionTimeFilterOrder === 'desc') {
        this.filters.connectionOrDisconnectionTimeFilterOrder = 'asc';
      } else {
        this.filters.connectionOrDisconnectionTimeFilterOrder = 'desc';
      }
      this.refreshCpesListQuery();
    },
    covertTimestampToDate(unixTimestmap) {
      return moment(unixTimestmap * 1000).format(' D MMM YYYY, H:mm ');
    },
    checkOneCpeHasErrors(cpeObject) {
      // проверяет содержит ли CPE ошибки
      if (
        cpeObject.hasOwnProperty('last_error') &&
        cpeObject.last_error &&
        ((cpeObject.last_error.hasOwnProperty('description') && cpeObject.last_error.description) ||
          (cpeObject.last_error.hasOwnProperty('type') && cpeObject.last_error.type))
      ) {
        return true;
      }
      return false;
    },
    discardErrorsForMultiEditCPEs() {
      // console.log('multi edit discard errors');
      const cpesWithErrorsIdsList = [];
      this.$store.state.cpesList.forEach((cpe) => {
        // console.log(cpe);
        if (this.selectedCpesId.includes(cpe.id) && this.checkOneCpeHasErrors(cpe)) {
          cpesWithErrorsIdsList.push(cpe.id);
        }
      });
      if (cpesWithErrorsIdsList.length !== 0) {
        cpeService.discardErrorsForCpes(this, cpesWithErrorsIdsList);
      }
    },
    discardErrorsForOneCPE() {
      // console.log(this.cpeEditCurrent);
      if (this.cpeEditCurrent.hasOwnProperty('id') && this.cpeEditCurrent.id !== '') {
        cpeService.discardErrorsForCpes(this, [this.cpeEditCurrent.id]);
      }
    },
    sideEffectOutput(value) {
      // console.log(value);
      return value;
    },
    loadMore() {
      this.offset++;
      cpeService.getPaginatedCpes(this, this.limit, this.offset);
    },
    searchInputHandler(value) {
      this.showGoBackToCpeStatisticButton = false;
      this.showGoBackToDashboardButton = false;
      this.showGoBackToClientsButton = false;
      this.cancelEdit();
      this.searchValue = value;
      this.searchSpinner = true;

      this.refreshCpesListQuery();
    },
    toggleColumnSettings() {
      this.showColumnSwitcher = !this.showColumnSwitcher;
      this.redrawDownloadButton();
    },
    toggleTableColumn(value, key) {
      // deprecated and deleted setTableSettings
      // this.$store.commit('setTableSettings', {
      //   tableName: 'cpe',
      //   key,
      //   value
      // });
      this.redrawDownloadButton();
    },
    // reverseCalculateCapabilities(radioInterface, state) {
    //   const config = radioInterface;
    //
    //   if (config.bandmode === '11g' && config.frequency === '2.4') {
    //     if (config.bandwidth !== 'auto') {
    //       config.bandmode = '11n';
    //     }
    //   }
    //   if (config.bandmode === '11a' && config.frequency === '5') {
    //     if (config.bandwidth === 'auto') {
    //       config.bandmode = '11a';
    //     } else if (config.bandwidth.indexOf('HT') === 0) {
    //       config.bandmode = '11n';
    //     } else if (config.bandwidth.indexOf('VHT') === 0) {
    //       config.bandmode = '11ac';
    //     }
    //   }
    // },

    reverseCalculateCapabilities(radioInterface, state) {
      const config = radioInterface;

      if (config.bandmode === '11g' && config.frequency === '2.4') {
        if (config.bandwidth !== 'auto') {
          if (config.bandwidth.indexOf('HE') === 0) {
            config.bandmode = '11ax';
          } else {
            config.bandmode = '11n';
          }

        }
      }
      if (config.bandmode === '11a' && config.frequency === '5') {
        if (config.bandwidth === 'auto') {
          config.bandmode = '11a';
        } else if (config.bandwidth.indexOf('HT') === 0) {
          config.bandmode = '11n';
        } else if (config.bandwidth.indexOf('VHT') === 0) {
          config.bandmode = '11ac';
        } else if (config.bandwidth.indexOf('HE') === 0) {
          config.bandmode = '11ax';
        }
      }
    },


    ssidWithDescription({ ssid, description }) {
      if (this.isWlanDescription && description) {
        return `${ssid}: ${commonService.cutName(description)}`;
      }
      return `${ssid}`;
    },
    // isModeAllowed(frequency, mode, hwmodelist) {
    //   if (frequency === '2.4') {
    //     const modes24 = {
    //       b: hwmodelist.b,
    //       g: hwmodelist.g,
    //       n: hwmodelist.n
    //       // 'ac': hwmodelist.ac
    //     };
    //     return modes24[mode];
    //   }
    //   const modes5 = {
    //     a: hwmodelist.a,
    //     n: hwmodelist.n,
    //     ac: hwmodelist.ac
    //   };
    //   return modes5[mode];
    // },

    isModeAllowed(frequency, mode, hwmodelist) {
      // console.log('frequency', frequency)
      // console.log('mode', mode)
      // console.log('hwmodelist', hwmodelist)
      if (frequency === '2.4') {
        const modes24 = {
          b: hwmodelist.b,
          g: hwmodelist.g,
          n: hwmodelist.n,
          // 'ac': hwmodelist.ac
          ax: hwmodelist.ax
        };
        return modes24[mode];
      }
      const modes5 = {
        a: hwmodelist.a,
        n: hwmodelist.n,
        ac: hwmodelist.ac,
        ax: hwmodelist.ax
      };
      return modes5[mode];
    },
    handleFrequencyChange(interfaceSelectedData, stateInterface, interfaceCapabilities, interfaceName) {
      if (interfaceSelectedData.frequency === '2.4') {
        if (interfaceCapabilities.hwmodelist.b) {
          interfaceSelectedData.bandmode = '11b';
        } else if (interfaceCapabilities.hwmodelist.g) {
          interfaceSelectedData.bandmode = '11g';
        } else if (interfaceCapabilities.hwmodelist.n) {
          interfaceSelectedData.bandmode = '11n';
        }
      } else if (interfaceSelectedData.frequency === '5') {
        if (interfaceCapabilities.hwmodelist.a) {
          interfaceSelectedData.bandmode = '11a';
        } else if (interfaceCapabilities.hwmodelist.n) {
          interfaceSelectedData.bandmode = '11n';
        } else if (interfaceCapabilities.hwmodelist.ac) {
          interfaceSelectedData.bandmode = '11ac';
        }
      }
      this.calculateCapabilities(interfaceSelectedData, stateInterface, interfaceCapabilities, interfaceName);
      this.clearChanList(interfaceName);
    },
    handleModeChange(interfaceSelectedData, stateInterface, interfaceCapabilities, interfaceName) {
      if (interfaceSelectedData.bandmode != '11ac' || interfaceSelectedData.bandmode != '11n') {
        interfaceSelectedData.require_mode = 'off';
      }
      if (
        interfaceSelectedData.bandmode === '11a' ||
        interfaceSelectedData.bandmode === '11b' ||
        interfaceSelectedData.bandmode === '11g'
      ) {
        interfaceSelectedData.bandwidth = 'auto';
      }
      this.calculateCapabilities(interfaceSelectedData, stateInterface, interfaceCapabilities, interfaceName);
    },
    // calculateCapabilities(configInterface, stateInterface, interfaceCapabilities, interfaceName) {
    //   if (!interfaceCapabilities) {
    //     return;
    //   }
    //
    //   const config = configInterface;
    //
    //   let selectedChannel = null;
    //   if (stateInterface) {
    //     selectedChannel = stateInterface.channel;
    //   } else {
    //     selectedChannel = configInterface.channels[0];
    //   }
    //   if (!interfaceName) return;
    //
    //   this.calculatedCapabilities[interfaceName] = capabilities.getCurrentCapabilities(config, interfaceCapabilities);
    //
    //   if (this.calculatedCapabilities[interfaceName].bandwidth.indexOf(config.bandwidth) === -1) {
    //     config.bandwidth = this.calculatedCapabilities[interfaceName].bandwidth[0];
    //   }
    //
    //   // validate channels for HT40 & 2.4
    //   if (
    //     this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '2.4'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'HT40'
    //     );
    //   }
    //   // end validate
    //
    //   // validate channels for HT40 & 5
    //   if (
    //     (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT40' ||
    //       this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40') &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '5'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'VHT40/HT40'
    //     );
    //   }
    //   // end
    //   // validate channels for HT80 & 5
    //   if (
    //     (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80' ||
    //       this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT80') &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '5'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'VHT80/HT80'
    //     );
    //   }
    //
    //   // end
    // },

    calculateCapabilities(configInterface, stateInterface, interfaceCapabilities, interfaceName) {
      if (!interfaceCapabilities) {
        return;
      }

      const config = configInterface;

      let selectedChannel = null;
      if (stateInterface) {
        selectedChannel = stateInterface.channel;
      } else {
        selectedChannel = configInterface.channels[0];
      }
      if (!interfaceName) return;

      this.calculatedCapabilities[interfaceName] = capabilities.getCurrentCapabilities(config, interfaceCapabilities);

      if (this.calculatedCapabilities[interfaceName].bandwidth.indexOf(config.bandwidth) === -1) {
        config.bandwidth = this.calculatedCapabilities[interfaceName].bandwidth[0];
      }

      // validate channels for HT40 & 2.4
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' ||
        this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE40') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '2.4'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'HT40/HE40'
        );
      }
      // end validate

      // validate channels for HT40 & 5
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT40' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE40') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT40/HT40/HE40'
        );
      }
      // end
      // validate channels for HT80 & 5
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE80') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT80/HT80/HE80'
        );
      }

      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80+80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT160' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE80+80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE160') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT80+80/VHT160/HE80+80/HE160'
        );
      }

      // end
    },

    calculateClientsByFrequency(item, frequency) {
      let result = '';
      const wifiState = item.state.wifi;
      if (!wifiState) {
        return result;
      }
      const ID = Object.entries(wifiState)
        .filter(([key, value]) => value.frequency === frequency)
        .map((it) => it[0])[0];
      if (!ID) {
        return result;
      }
      const active = this.wlansState(item, ID).active.length;
      if (active) {
        result = active;
      }
      return result;
    },
    calculateWiredVLANs(wired) {
      return wired.reduce((acc, wiredInterface) => {
        if (wiredInterface.hasOwnProperty('vlans') && wiredInterface.id.includes('eth')) {
          return (acc += wiredInterface.vlans.length);
        }
        return acc;
      }, 0);
    },
    calculateWiredClients(clients) {
      if (clients === null || clients === undefined || !clients) {
        return '';
      }
      const ethKeys = Object.keys(clients).filter((client) => client.includes('eth'));
      if (ethKeys.length === 0) {
        return '';
      }
      return ethKeys.reduce((acc, current) => clients[current].total + acc, 0);
    },
    clearChanList(id) {
      this.interfaceCapabilities[id].selected.channels = [];
    },
    toggleEdit(e) {
      if (e.target.dataset.action === 'search') {
        return;
      }
      this.isFWUpgradeShow = false;
      this.isLatitudeLongitudeBlockShow = false;
      this.isFirewallShow = false;
      this.statLogLbsmodal = false;
      const selectedNode = e.target.closest('[data-index]');
      const index = parseInt(selectedNode.dataset.index);
      const { id } = selectedNode.dataset;
      const { status } = selectedNode.dataset;
      if (e.target.dataset.action === 'delete') {
        this.openDeletingCpeDialog(id);
        return;
      }

      // DF-49
      // this.interfaceSettings.radio0 = false;
      // this.interfaceSettings.radio1 = false;
      // this.interfaceSettings.radio2 = false;


      Object.keys(this.interfaceSettings).forEach((interfaceName)=>{
        this.interfaceSettings[interfaceName] = false;
      });

      this.forceFWObjects = false;
      if (!this.selectedCpesId.length) {
        this.$store.commit('setCpesEditIndex', index);
        this.$store.commit('setCpesEditId', id);
      }
    },
    changePosition(value) {
      const editBlock = document.querySelector('.cpes-edit .row');
      if (editBlock) {
        editBlock.style.position = value;
      }
    },
    disableSingleEditMode() {
      this.commonUpdateCpesData.description = '';
      this.commonUpdateCpesData.commonSelectedWlans = [];
      this.$store.commit('setCpesEditIndex', null);
      this.$store.commit('setCpesEditId', null);
      this.updatedCpe = null;
      this.newTemplateData = {
        name: '',
        description: '',
        base_location: '',
        model: '',
        is_auto: false,
        cpes: [],
        template: {
          cpe_config_template: false
        }
      };
    },
    // Возвращает к исходному окну
    resetMultipleEditMode() {
      this.setPositionSettingsHide();
      this.commonDeletingWlansModal = false;
      this.commonDeletingTagsModal = false;
      this.commonDeletingCpesModal = false;
      this.editCpesSettingsMode = false;
      this.addTagsModal = false;
      this.assignCommonWlanMode = false;
      this.setTemplateMode = false;
      this.logSettingsMode = false;
      this.accessControlMode = false;
      this.allChecked = false;
      this.updateCommonCpesMode = false;
      this.CpesFwUpgradeMode = false;
      this.commonDeletingTagsModal = false;
      this.commonSelectedWlans = [];
      this.isChangeLocationOpen = false;
    },
    disableMultipleEditMode() {
      this.$store.commit('setCpesEditId', false);
      this.updatedCpe = null;
      this.selectedCpesId = [];
      this.massLocationId = this.$store.state.userData.base_location;
    },
    openCpeWizard() {
      this.$router.push('registerCpe');
    },
    wlansState(cpe, id) {
      const stateOfWlans = {
        active: [],
        notActive: [],
        updating: []
      };
      const cpeConfig = helpers.transformArrayToObject(cpe.config.wifi);
      if (!cpeConfig[id]) {
        return stateOfWlans;
      }
      const wlansConfigArray = cpeConfig[id].wlans;
      if (!cpe.state.wifi) {
        return stateOfWlans;
      }
      const wlanStates = cpe.state.wifi[id].wlanstates;

      wlansConfigArray.forEach((wlanId) => {
        if (wlanStates[wlanId] && wlanStates[wlanId].state === 'ENABLED') {
          if (commonService.wlanNamebyId(wlanId)) {
            stateOfWlans.active.push(commonService.wlanNamebyId(wlanId));
          }
        } else if (!wlanStates[wlanId] || wlanStates[wlanId].state === 'DISABLED') {
          if (commonService.wlanNamebyId(wlanId)) {
            stateOfWlans.notActive.push(commonService.wlanNamebyId(wlanId));
          }
        } else if (commonService.wlanNamebyId(wlanId)) {
          stateOfWlans.updating.push(commonService.wlanNamebyId(wlanId));
        }
      });
      return stateOfWlans;
    },
    isBusy(id, status) {
      return (
        this.cpesIdsOperations.includes(id) ||
        status === 'updating' ||
        status === 'upgrading' ||
        status === 'rebooting' ||
        status === 'resetting'
      );
    },
    // errorStatus(error) {
    //   const erorrDiv = document.createElement('div');
    //   erorrDiv.innerHTML = '<span class="text-danger mb-h"><b>Last error</b></span>';
    //   const erorrList = document.createElement('ul');
    //   erorrDiv.className = 'error-status small';
    //   erorrList.className = 'list-unstyled mb-h';
    //
    //   erorrDiv.appendChild(erorrList);
    //
    //   if (error.type) {
    //     erorrList.innerHTML += `<li><b>Type:</b> ${error.type}</li> `;
    //   }
    //   if (error.module) {
    //     erorrList.innerHTML += `<li><b>Module:</b> ${error.module}</li> `;
    //   }
    //   if (error.module_id) {
    //     erorrList.innerHTML += `<li><b>Module ID:</b> ${error.module_id}</li> `;
    //   }
    //   if (error.description) {
    //     erorrList.innerHTML += `<li class="mt-q">${error.description}</li> `;
    //   }
    //
    //   erorrList.innerHTML += "<li class='mt-q'>Click to watch events</li>";
    //
    //   return erorrDiv;
    // },

    errorStatus(error) {
      const erorrDiv = document.createElement('div');
      erorrDiv.innerHTML = '<span class="text-danger mb-h"><b>Last error</b></span>';
      const erorrList = document.createElement('ul');
      erorrDiv.className = 'error-status small';
      erorrList.className = 'list-unstyled mb-h';

      erorrDiv.appendChild(erorrList);

      if (error.type) {
        erorrList.innerHTML += `<li><b>Type:</b> ${error.type}</li> `;
      }
      if (error.module) {
        erorrList.innerHTML += `<li><b>Module:</b> ${error.module}</li> `;
      }
      if (error.module_id) {
        erorrList.innerHTML += `<li><b>Module ID:</b> ${error.module_id}</li> `;
      }
      if (error.description) {
        erorrList.innerHTML += `<li class="mt-q">${this.$t('aps.errorCaptionInCpeEditPanel')}: ${
          error.description
        }</li> `;
      }
      if (error.recommendation && error.recommendation !== '') {
        erorrList.innerHTML += `<li class="mt-q">${this.$t('aps.recommendationCaptionInCpeEditPanel')}: ${
          error.recommendation
        }</li> `;
      } else {
        erorrList.innerHTML += `<li class="mt-q">${this.$t('aps.recommendationCaptionInCpeEditPanel')}: ${this.$t(
          'general.no'
        ).toLowerCase()}</li> `;
      }
      erorrList.innerHTML += `<li class='mt-q'>${this.$t('aps.clickToWatchEventsCaptionInTooltip')}</li>`;

      return erorrDiv;
    },
    filterItems(items) {
      let filteredCpes = items;

      filteredCpes = Filters.cpeSearchQuery(filteredCpes, this.filters.query);
      this.filteredCpes = filteredCpes;
      return filteredCpes;
    },
    setDHCPCaptureFilter(status) {
      // console.log(status)
      const currentDHCPCaptureFilterStatus = this.filters.DHCPCaptureFilter;
      // console.log('currentDHCPCaptureFilterStatus', currentDHCPCaptureFilterStatus);
      // console.log('status', status)
      if (currentDHCPCaptureFilterStatus === status) {
        return;
      }
      if (status === 'enable') {
        this.filters.DHCPCaptureFilter = 'enable';
      } else if (status === 'disable') {
        this.filters.DHCPCaptureFilter = 'disable';
      }

      this.refreshCpesListQuery();
    },
    toggleDHCPCaptureFilter() {
      if (!this.filters.DHCPCaptureFilter) {
        this.filters.DHCPCaptureFilter = 'enable';
      } else {
        this.filters.DHCPCaptureFilter = null;
      }
      this.refreshCpesListQuery();
    },
    setFilter(filterName, value) {
      if (this.muteCpeFilters) {
        return;
      }
      this.selectedCpesId = [];
      this.allChecked = false;
      const checkAll = document.getElementById('checkAll');
      if (checkAll) {
        checkAll.checked = false;
      }
      this.disableSingleEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
      } else {
        this.filters[filterName] = null;
      }
      this.refreshCpesListQuery();
    },
    refreshCpesListQuery() {
      this.selectedCpesId = [];
      this.allChecked = false;
      this.disableSingleEditMode();

      this.offset = 0;
      this.$store.commit('setCpesEditId', null);
      this.$store.commit('setCpesList', false);
      this.canLoadMore = true;
      // cpeService.refreshCpes(this, 'spinner');

      // console.log(this.$route.name)
      // if (helpers.makeGetCpesRequestAfterCpesComponentDestroy(this.$route.name)) {
      //   // нужно для исправления бага по задачеке WNE-2306
      //   // подробнее в описании функци componentsWithCPEListLoadingList в хелперс
      //   // console.log('make req');
      //   cpeService.refreshCpes(this, 'spinner');
      // } else {
      //   // console.log('not make req');
      // }

      if (this.beforeDestroyHookExecutedFlag === false) {
        // нужно для исправления бага по задачеке WNE-2306
        // console.log('refresh');
        cpeService.refreshCpes(this, 'spinner');
      } else {
        // console.log('beforeDestroy');
      }
    },
    cancelEdit() {
      this.selectedCpesId = [];
      this.allChecked = false;
      const checkAll = document.getElementById('checkAll');
      if (checkAll) {
        checkAll.checked = false;
      }
      this.disableSingleEditMode();
    },
    cancelCommonEdit() {
      this.setPositionSettingsHide();
      this.commonDeletingWlansModal = false;
      this.commonDeletingTagsModal = false;
      this.commonDeletingCpesModal = false;
      this.editCpesSettingsMode = false;
      this.addTagsModal = false;
      this.assignCommonWlanMode = false;
      this.logSettingsMode = false;
      this.accessControlMode = false;
      this.selectedCpesId = [];
      this.allChecked = false;
      const checkAll = document.getElementById('checkAll');
      if (checkAll) {
        checkAll.checked = false;
      }
      this.disableSingleEditMode();
    },
    updateCpe() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.updatedCpe.config.lbs_config.filtermode === 'WhiteList' && this.newMacAddress) {
            if (this.configFilterList.whitelist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.whitelist.push(this.newMacAddress);
              this.newMacAddress = '';
            } else {
              return;
            }
          }

          if (this.updatedCpe.config.lbs_config.filtermode === 'BlackList' && this.newMacAddress) {
            if (this.configFilterList.blacklist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.blacklist.push(this.newMacAddress);
              this.newMacAddress = '';
            } else {
              return;
            }
          }

          if (this.firewallL3List.mac_list.indexOf(this.newL3MacAddress) == -1 && this.newL3MacAddress) {
            this.firewallL3List.mac_list.push(this.newL3MacAddress);
          }
          this.newL3MacAddress = '';

          if (this.firewallL3List.ip_list.indexOf(this.newL3IPAddress) == -1 && this.newL3IPAddress) {
            this.firewallL3List.ip_list.push(this.newL3IPAddress);
          }
          this.newL3IPAddress = '';

          // todo. Появилось после задачи DF-115. Удалить это, если в будущем не потребуется вернуть настройки NTP для ТД назад (15.11.23)
          // // ntp settings
          // // если enable === true, но в списке серверов пусто, выключаем enable и делаем его равым false
          // if (Object.prototype.hasOwnProperty.call(this.updatedCpe.config, 'ntp_config') && typeof this.updatedCpe.config.ntp_config === 'object' &&
          //   this.updatedCpe.config.ntp_config !== null && Object.prototype.hasOwnProperty.call(this.updatedCpe.config.ntp_config, 'enable') &&
          //   Object.prototype.hasOwnProperty.call(this.updatedCpe.config.ntp_config, 'servers') && this.updatedCpe.config.ntp_config.enable === true &&
          //   Array.isArray(this.updatedCpe.config.ntp_config.servers) && this.updatedCpe.config.ntp_config.servers.length === 0) {
          //   // console.log('disable ntp_config ')
          //   this.updatedCpe.config.ntp_config.enable = false;
          // }


          cpeService.updateCpe(this);
        }
      });
    },
    clearLogSettings() {
      this.logSettingsData.lbs_config.enabled = true;
      this.logSettingsData.log_config.enabled = true;
      this.logSettingsData.stats_config.enabled = true;

      this.logSettingsData.lbs_config.reportperiod = '';
      this.logSettingsData.log_config.reportperiod = '';
      this.logSettingsData.stats_config.reportperiod = '';
    },
    assignCommonWlanShow() {
      this.assignCommonWlanMode = !this.assignCommonWlanMode;
    },
    resetMaxClients(val, id) {
      if (!val) {
        this.interfaceCapabilities[id].selected.maxclients = '';
      }
    },
    setTemplate() {
      this.setTemplateMode = !this.setTemplateMode;
    },
    editCpesSettingsShow() {
      if (this.checkCpesModel()) {
        this.editCpesSettingsMode = true;
      } else {
        this.editCpesSettingsMode = false;
      }
    },
    LogSettingsShow() {
      this.logSettingsMode = !this.logSettingsMode;
    },
    acessControlSettingsShow() {
      this.accessControlMode = !this.accessControlMode;
    },
    fwUpgradeShow() {
      this.CpesFwUpgradeMode = !this.CpesFwUpgradeMode;
    },
    // fwUpgradeForMultiEditShow() {
    //   // console.log(this.cpeModelData)
    //   this.forceFWObjects = false;
    //   this.CpesFwUpgradeMode = true;
    //   cpeService.getAllFirmwaresList(this).then(
    //     (response) => {
    //       this.CpesFwUpgradeMode = false;
    //       this.$nextTick(() => {
    //         // console.log('loaded');
    //         this.CpesFwUpgradeMode = true;
    //         this.cpeModelData.firmwares = this.createFirmwareObjectsListFromApiWithAddedVersionAndModifiedURL(this.updatedCpe.state.l2tp_state.host_addr);
    //
    //       });
    //     },
    //     (err) => {
    //       console.log(err);
    //     });
    // },
    fwUpgradeForMultiEditShow() {
      this.cpeModelData.firmwares = []
      this.clearCustomUrlAddressWithSchemaForFWUpgrage()
      // console.log(this.cpeModelData)
      this.forceFWObjects = false;
      this.CpesFwUpgradeMode = true;
      cpeService.getAllFirmwaresList(this).then(
        (response) => {
          this.CpesFwUpgradeMode = false;
          this.$nextTick(() => {
            // console.log('loaded');
            this.CpesFwUpgradeMode = true;
            this.setCustomUrlAddressWithSchemaForFWUpgrage('http', this.updatedCpe.state.l2tp_state.host_addr);
            if (this.cpeModelData.firmwares && this.cpeModelData.firmwares.length !== 0) {
              // если в этом месте было что-то по умолчанию - добавляем
              const firmwareObjectsListFromApiWithAddedVersionAndModifiedURL = this.createFirmwareObjectsListFromApiWithAddedVersionAndModifiedURL(
                this.updatedCpe.state.l2tp_state.host_addr
              );
              for (const item of firmwareObjectsListFromApiWithAddedVersionAndModifiedURL) {
                this.cpeModelData.firmwares.push(item);
              }
            } else {
              // а если нет то просто наполняем
              this.cpeModelData.firmwares = this.createFirmwareObjectsListFromApiWithAddedVersionAndModifiedURL(
                this.updatedCpe.state.l2tp_state.host_addr
              );
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
    },
    fwUpgradeForMultiEditHide() {
      try {
        this.cpeModelData.firmwares = [];
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.CpesFwUpgradeMode = false;
        }, 10);
      }
    },
    assignCommonWlan() {
      cpeService.assignCommonWlan(this);
    },
    assignTags() {
      cpeService.assignTags(this);
    },
    updateCpesRadioSettings() {
      cpeService.updateCpesRadioSettings(this);
    },
    updateCommonCpes() {
      cpeService.updateCommonCpes(this);
    },
    setTemplatesToCPEs(id, sourceObject) {
      cpeService.setTemplatesToCPEs(this, id, sourceObject);
    },
    updateLogSettings() {
      this.$validator
        .validateAll({
          stat_reportperiod: this.logSettingsData.stats_config.reportperiod,
          log_reportperiod: this.logSettingsData.log_config.reportperiod,
          lbs_reportperiod: this.logSettingsData.lbs_config.reportperiod
        })
        .then((result) => {
          if (result) {
            cpeService.updateLogSettings(this);
          }
        });
    },
    updateAccessControl() {
      cpeService.updateAccessControlSettings(this);
    },
    refreshCpesList() {
      // this.cancelCommonEdit();
      cpeService.refreshCpes(this);
      // if (this.editCpeId !== ''){
      //   this.loadSelectedCpeWlansData(this.editCpeId);
      // }
    },
    checkRfCollision() {
      let RF24 = 0;
      let RF50 = 0;
      let NoRF = 0;
      // Плохая производительность этой конструкции на 300+ элементах
      // чтобы хоть как-то ее ускорить юзаем побитовые логические операции
      this.$store.state.cpesList.forEach((cpe) => {
        if (this.selectedCpesId.includes(cpe.id)) {
          if (cpe.state && cpe.state.wifi && cpe.state.wifi.length) {
            cpe.state.wifi.forEach((rf) => {
              if (rf.frequency === '2.4') {
                RF24++;
              } else if (rf.frequency === '5') {
                RF50++;
              }
            });
          } else {
            NoRF++;
          }
        }
      });
      return (RF24 && RF50) || NoRF;
    },
    checkCpesModel() {
      let result = true;
      const modelsArray = [];
      this.$store.state.cpesList.forEach((cpe) => {
        if (this.selectedCpesId.includes(cpe.id)) {
          modelsArray.push(cpe.model.id);
        }
      });
      modelsArray.forEach((model) => {
        if (modelsArray[0] != model) {
          result = false;
        }
      });
      return result;
    },
    checkIpsecTunnelType(tunnelType) {
      if (tunnelType === 'ipsec_noencrypt' || tunnelType === 'ipsec') {
        return true;
      }
      return false;
    },
    checkCpesHasIpsecTunnelType() {
      // возвращаем true если все выбранные точки с типом подключения ipsec_noencrypt или ipsec
      // и false если есть с другим типом
      let result = false;
      const cpesIpsecStatus = [];
      this.$store.state.cpesList.forEach((cpe) => {
        if (this.selectedCpesId.includes(cpe.id)) {
          cpesIpsecStatus.push(this.checkIpsecTunnelType(cpe.state.l2tp_state.tunnel_type));
        }
      });
      // console.log(cpesIpsecStatus)
      // если хотя бы одна из точек не с ipsec ставим результат в false
      if (cpesIpsecStatus.includes(false)) {
        result = false;
      } else {
        result = true;
      }
      return result;
    },
    checkCpesHasEqualHostAddr() {
      // возвращаем true если все выбранные точки с одним адресом брокера
      // и false в другом случае
      try {
        const allEqual = (arr) => arr.every((v) => v === arr[0]);
        let result = false;
        const cpesHostAddresses = [];
        this.$store.state.cpesList.forEach((cpe) => {
          if (this.selectedCpesId.includes(cpe.id)) {
            cpesHostAddresses.push(cpe.state.l2tp_state.host_addr);
          }
        });
        // console.log('cpesHostAddresses', cpesHostAddresses)
        if (allEqual(cpesHostAddresses)) {
          result = true;
        } else {
          result = false;
        }
        return result;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    getHostAddressesAndCpeNamesList() {
      // возвращаем обхект вида
      // {
      // host_addr : [cpename1, cpename2],
      // host_addr2 : [cpename3, cpename4],
      // }
      const result = {}
      try {
        this.$store.state.cpesList.forEach((cpe) => {
          if (this.selectedCpesId.includes(cpe.id)) {
            if (!result[cpe.state.l2tp_state.host_addr]) {
              result[cpe.state.l2tp_state.host_addr] = []
              result[cpe.state.l2tp_state.host_addr].push(cpe.name)
            } else {
              result[cpe.state.l2tp_state.host_addr].push(cpe.name)
            }
          }
        });
        // console.log('result', result)
        return result;
      } catch (e) {
        console.log(e);
        return result;
      }
    },
    checkCpesDisconnectedState() {
      let result = true;
      const modelsArray = [];

      this.$store.state.cpesList.forEach((cpe) => {
        if (this.selectedCpesId.includes(cpe.id) && cpe.connected) {
          result = false;
        }
      });
      return result;
    },
    checkCpesConnectedState() {
      let result = true;
      const modelsArray = [];

      this.$store.state.cpesList.forEach((cpe) => {
        if (this.selectedCpesId.includes(cpe.id) && !cpe.connected) {
          result = false;
        }
      });
      return result;
    },
    checkCpesHasError() {
      // Если хотя бы  одна из CPE имеет ошибку то возвращаем  true
      let result = false;

      this.$store.state.cpesList.forEach((cpe) => {
        // console.log(cpe);
        if (this.selectedCpesId.includes(cpe.id) && this.checkOneCpeHasErrors(cpe)) {
          result = true;
        }
      });
      return result;
    },
    // resetAllFilters() {
    //   this.previouslySelectedLocationsInFilter = [];
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.showGoBackToCpeStatisticButton = false;
    //   this.showGoBackToDashboardButton = false;
    //   this.showGoBackToClientsButton = false;
    //   this.filters = {
    //     query: '',
    //     DHCPCaptureFilter: null,
    //     interfacesFilter: null,
    //     connectedFilter: null,
    //     activeFilter: null,
    //     lastError: null,
    //     location: 'All locations',
    //     company: 'All companys',
    //     models: 'All models',
    //     tag: 'All tags',
    //     group: 'No RRM filter',
    //     connectionOrDisconnectionTimeFilter: 'No Connection Or Disconnection Filter',
    //     connectionOrDisconnectionTimeFilterOrder: 'asc',
    //     connectionOrDisconnectionTimeFilterByDateRange: false,
    //     connectionOrDisconnectionTimeFilterDateRange: {
    //       startDate: moment().startOf('month').valueOf(),
    //       endDate: moment().endOf('day').valueOf()
    //     },
    //     radarFilter: null,
    //     scanningFilter: null,
    //     updatingStatusCPEs: null,
    //     with_childs: false,
    //   };
    //   this.searchValue = '';
    //   this.showingConnectionnOrDisconnectionFiltersBlock = false;
    //   this.refreshCpesListQuery();
    // },
    resetFilterQuery() {
      this.searchInputHandler('');

      if (this.resetSearchBarQuery) {
        this.resetSearchBarQuery();
      }
    },
    resetAllFilters() {
      this.resetFilterQuery()
      // this.previouslySelectedLocationsInFilter = [];
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.showGoBackToCpeStatisticButton = false;
      this.showGoBackToDashboardButton = false;
      this.showGoBackToClientsButton = false;
      this.filters = {
        query: '',
        DHCPCaptureFilter: null,
        interfacesFilter: null,
        connectedFilter: null,
        activeFilter: null,
        lastError: null,
        // location: 'All locations',
        company: 'All companys',
        models: 'All models',
        tag: 'All tags',
        group: 'No RRM filter',
        connectionOrDisconnectionTimeFilter: 'No Connection Or Disconnection Filter',
        connectionOrDisconnectionTimeFilterOrder: 'asc',
        connectionOrDisconnectionTimeFilterByDateRange: false,
        connectionOrDisconnectionTimeFilterDateRange: {
          startDate: moment().startOf('month').valueOf(),
          endDate: moment().endOf('day').valueOf()
        },
        radarFilter: null,
        scanningFilter: null,
        updatingStatusCPEs: null,
        // with_childs: false,
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
        this.previouslySelectedLocationsInFilter = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this);
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();

      this.searchValue = '';
      this.showingConnectionnOrDisconnectionFiltersBlock = false;
      this.refreshCpesListQuery();
    },
    // showNewTemplate() {
    //   this.newTemplateData.template.cpe_config_template = JSON.parse(JSON.stringify(this.updatedCpe.config));
    //   this.newTemplateData.template.location = this.updatedCpe.base_location;
    //   this.newTemplateData.base_location = this.$store.state.userData.base_location;
    //   this.newTemplateData.model = this.updatedCpe.model.id;
    //   this.newTemplateModal = true;
    // },

    showNewTemplate() {
      const updatedCpeConfigCopy = JSON.parse(JSON.stringify(this.updatedCpe.config));
      const extractedSelectedWlansIdsByInterfaceNames = this.extractSelectedWlansIdsByInterfaceNames(JSON.parse(JSON.stringify(this.interfaceSelectedWlans)));
      // console.log('extractedSelectedWlansIdsByInterfaceNames', extractedSelectedWlansIdsByInterfaceNames);
      // console.log('updatedCpeConfigCopy before changes', JSON.parse(JSON.stringify(updatedCpeConfigCopy)))
      for (const wifiInterfeceIndex in updatedCpeConfigCopy.wifi) {
        updatedCpeConfigCopy.wifi[wifiInterfeceIndex].wlans = extractedSelectedWlansIdsByInterfaceNames[updatedCpeConfigCopy.wifi[wifiInterfeceIndex].id]
      }
      // console.log('updatedCpeConfigCopy after changes', JSON.parse(JSON.stringify(updatedCpeConfigCopy)))
      this.newTemplateData.name = '';
      this.newTemplateData.description = '';
      this.newTemplateData.template.cpe_config_template = updatedCpeConfigCopy;
      this.newTemplateData.template.location = this.updatedCpe.base_location;
      this.newTemplateData.base_location = this.$store.state.userData.base_location;
      this.newTemplateData.model = this.updatedCpe.model.id;
      this.newTemplateModal = true;
    },

    // updateCpeInterface() {
    //   // this.wlansListForWlansSearch = {
    //   //   radio0: [],
    //   //   radio1: [],
    //   //   radio2: []
    //   // }
    //   // this.wlansListForWlansSearchForMultiedit = [];
    //   this.enableSaveChanges = true;
    //   // multiselect depends on this method, do not delete it
    // },
    // updateCpeInterface(selectesWlans, selectorId) {
    //   this.enableSaveChanges = true;
    //   // multiselect depends on this method, do not delete it
    //   // console.log('selectesWlans', selectesWlans)
    //   // console.log(this.cpeEditCurrent)
    //   // если это не выбрано в селекторе при мультиредактировании и выбраны какие-нибудь сети
    //   if (selectorId !== 'multiEditWlanSelector' && selectesWlans.length) {
    //     // ищем среди добавленых WLAN'ы c c типами WPA3 и создаем массив с их ID
    //     const wpa3WLANsInSelected = selectesWlans.filter((wlan) => {
    //       if (wlan.security?.type && this.wpa3Types.includes(wlan.security.type)) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     });
    //     const wpa3WLANsInSelectedIDs = [];
    //     wpa3WLANsInSelected.forEach((wlan) => {
    //       if (wlan.id){
    //         wpa3WLANsInSelectedIDs.push(wlan.id);
    //       }
    //     })
    //     // console.log('wpa3WLANsInSelected', wpa3WLANsInSelected);
    //     // console.log('wpa3WLANsInSelectedIDs', wpa3WLANsInSelectedIDs);
    //
    //     // если точка не может в WPA3, и при этом есть в добавляемом WPA3 сеть, выдаем предупреждение
    //     // и удаляем из выбраного эту WPA3 сеть
    //     if (!this.checkCPEWPA3Capability(this.cpeEditCurrent) && wpa3WLANsInSelectedIDs.length) {
    //       // console.log('SHOW ERROR');
    //       VueNotifications.warn({ message: `${this.$t('aps.WPA3AddToNoWPA3CPEError')}`});
    //       // отфильтровываем из выбраных WPA3 сети (оставляем только не wpa3, чтобы удалить ненужное из выбраных)
    //       const selectedWlansFilteredFromWPA3 = selectesWlans.filter((wlan) => {
    //         if (wlan.security?.type && this.wpa3Types.includes(wlan.security.type)) {
    //           return false;
    //         } else {
    //           return true;
    //         }
    //       });
    //       this.setInterfaceSelectedWlansForInterface(selectedWlansFilteredFromWPA3, selectorId);
    //       // console.log('selectesWlansFiltered',selectedWlansFilteredFromWPA3)
    //     }
    //   }
    // },
    updateCpeInterface(selectesWlans, selectorId) {
      this.enableSaveChanges = true;
      // multiselect depends on this method, do not delete it

      // console.log('selectesWlans', selectesWlans)
      // console.log(this.cpeEditCurrent)

      // смотрим выбраны ли вообще какин-нибудь wlan'ы
      const wpa3WLANsInSelectedIDs = [];
      if (Array.isArray(selectesWlans) && selectesWlans.length) {
        // ищем среди добавленых WLAN'ы c c типами WPA3 и пополняем массив с их ID
        const wpa3WLANsInSelected = selectesWlans.filter((wlan) => {
          if (wlan.security?.type && this.wpa3Types.includes(wlan.security.type)) {
            return true;
          } else {
            return false;
          }
        });
        wpa3WLANsInSelected.forEach((wlan) => {
          if (wlan.id) {
            wpa3WLANsInSelectedIDs.push(wlan.id);
          }
        })
      }

      if (selectorId !== 'multiEditWlanSelector') {
        // если единичное редактирование (не мульти)

        // если точка не может в WPA3, и при этом есть в выбраном WPA3 сеть, выдаем предупреждение
        // и удаляем из выбраного эту WPA3 сеть
        if (!this.checkCPEWPA3Capability(this.cpeEditCurrent) && wpa3WLANsInSelectedIDs.length) {
          // console.log('SHOW ERROR');
          VueNotifications.warn({message: `${this.$t('aps.WPA3AddToNoWPA3CPEError')}`});
          // отфильтровываем из выбраных WPA3 сети (оставляем только не wpa3)
          const selectedWlansFilteredFromWPA3 = selectesWlans.filter((wlan) => {
            if (wlan.security?.type && this.wpa3Types.includes(wlan.security.type)) {
              return false;
            } else {
              return true;
            }
          });
          this.setInterfaceSelectedWlansForInterface(selectedWlansFilteredFromWPA3, selectorId);
          // console.log('selectesWlansFiltered',selectedWlansFilteredFromWPA3)
        }
      } else if (selectorId === 'multiEditWlanSelector') {
        // если мультиредактирование
        // смотрим все выбраные точки
        // если хотя-бы точка не может в WPA3, и при этом в выбраных WPA3 сеть, выдаем предупреждение
        // и удаляем из выбраного эту WPA3 сеть
        let hasCpeWithNoWPA3CapabilityInSelected = false;
        this.$store.state.cpesList.forEach((cpe) => {
          // console.log(cpe);
          if (this.selectedCpesId.includes(cpe.id) && !this.checkCPEWPA3Capability(cpe)) {
            hasCpeWithNoWPA3CapabilityInSelected = true;
          }
        });
        if (hasCpeWithNoWPA3CapabilityInSelected && wpa3WLANsInSelectedIDs.length) {
          // console.log('SHOW ERROR');
          VueNotifications.warn({message: `${this.$t('aps.WPA3AddToNoWPA3CPEMultiEditError')}`});
          // отфильтровываем из выбраных WPA3 сети (оставляем только не wpa3)
          const selectedWlansFilteredFromWPA3 = selectesWlans.filter((wlan) => {
            if (wlan.security?.type && this.wpa3Types.includes(wlan.security.type)) {
              return false;
            } else {
              return true;
            }
          });
          this.setCommonSelectedWlans(selectedWlansFilteredFromWPA3);
        }
      }
    },
    showId(e) {
      const selectedNode = e.target.closest('[data-id]');
      const { id } = selectedNode.dataset;
      if (!this.cpeModelData || !this.cpeModelData.caps.wifi || !this.cpeModelData.caps.wifi[id]) {
        return;
      }
      this.interfaceSettings[selectedNode.dataset.id] = !this.interfaceSettings[selectedNode.dataset.id];
    },
    showLogStatLbs() {
      this.statLogLbsmodal = !this.statLogLbsmodal;
    },
    openCommonDeletingWlansDialog() {
      this.commonDeletingWlansModal = true;
    },
    openCommonDeletingTagsDialog() {
      this.commonDeletingTagsModal = true;
    },
    openIPsecModal() {
      this.IPsecModal = true;
      this.IPsecOption = '';
    },
    openRebootCpeRedundantModal() {
      this.rebootCpeRedundantModal = true;
    },
    openRebootCpeAgentModal() {
      this.rebootCpeAgentModal = true;
    },
    openConfigModal() {
      this.configModal = true;
      this.configCommand = '';
    },
    openGetLogFromCPEModal() {
      this.getLogFromCPEModal = true;
    },
    closeGetLogFromCPEModal() {
      this.getLogFromCPEModal = false;
    },
    openSetTimeUsingNTPModal() {
      this.setTimeUsingNTPModalShowing = true;
      this.ntpServerAddressForsetTimeUsingNTP = '';
    },
    openSyncTimeModal() {
      this.syncTimeCommandResult = undefined;
      this.syncTimeCommandError = undefined;
      this.syncTimeModalShowing = true;
    },
    openRebootCpeModal() {
      this.rebootCpeModal = true;
    },
    openResetCpeModal() {
      this.resetCpeModal = true;
    },
    openCommonDeletingCpesDialog() {
      this.commonDeletingCpesModal = true;
    },
    openDeletingCpeDialog(id) {
      this.cpeForDelete = id;
      this.deletingCpeModal = true;
    },
    openAddTagsDialog() {
      this.addTagsModal = true;
    },
    // openFWUpgradeDialog() {
    //   this.FWUpgradeModal = true;
    // },
    // openFWUpgradeDialog() {
    //   this.forceFWObjects = false;
    //   this.FWUpgradeModal = true;
    //   cpeService.getAllFirmwaresList(this).then(
    //     (response) => {
    //       this.FWUpgradeModal = false;
    //       this.$nextTick(() => {
    //         // console.log('loaded');
    //         this.FWUpgradeModal = true;
    //
    //          this.cpeModelData.firmwares = this.createFirmwareObjectsListFromApiWithAddedVersionAndModifiedURL(this.updatedCpe.state.l2tp_state.host_addr);
    //
    //       });
    //     },
    //     (err) => {
    //       console.log(err);
    //     });
    // },

    openFWUpgradeDialog() {
      this.forceFWObjects = false;
      this.FWUpgradeModal = true;
      this.clearCustomUrlAddressWithSchemaForFWUpgrage();
      cpeService.getAllFirmwaresList(this).then(
        (response) => {
          this.FWUpgradeModal = false;
          this.$nextTick(() => {
            // console.log('loaded');
            this.FWUpgradeModal = true;
            this.setCustomUrlAddressWithSchemaForFWUpgrage('http', this.updatedCpe.state.l2tp_state.host_addr);
            if (this.cpeModelData.firmwares && this.cpeModelData.firmwares.length !== 0) {
              // если в этом месте было что-то по умолчанию - добавляем
              const firmwareObjectsListFromApiWithAddedVersionAndModifiedURL = this.createFirmwareObjectsListFromApiWithAddedVersionAndModifiedURL(
                this.updatedCpe.state.l2tp_state.host_addr
              );
              for (const item of firmwareObjectsListFromApiWithAddedVersionAndModifiedURL) {
                this.cpeModelData.firmwares.push(item);
              }
            } else {
              // а если нет то просто наполняем
              this.cpeModelData.firmwares = this.createFirmwareObjectsListFromApiWithAddedVersionAndModifiedURL(
                this.updatedCpe.state.l2tp_state.host_addr
              );
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
    },
    closeFWUpgradeDialog() {
      try {
        // this.FWUpgradeModal = false;
        this.cpeModelData.firmwares = [];
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.FWUpgradeModal = false;
        }, 10);
      }

      // cpeService.getAllFirmwaresList(this);
    },
    getWebAccess() {
      cpeService.getWebAccess(this);
    },
    deleteCpe() {
      cpeService.deleteCpe(this);
    },
    deleteCommonWlans() {
      cpeService.deleteCommonWlans(this);
    },
    deleteCommonTags() {
      cpeService.deleteCommonTags(this);
    },
    deleteCommonCpes() {
      cpeService.deleteCommonCpes(this);
    },
    rebootCpeAgent(cpeIds) {
      cpeService.rebootCpeAgent(this, cpeIds);
    },
    rebootCpeRedundant(cpeIds) {
      cpeService.rebootCpeRedundant(this, cpeIds);
    },
    updateCPEIPsec(cpeIds, IPsecOption) {
      cpeService.updateCPEIPsec(this, cpeIds, IPsecOption);
    },
    issueCPECommand(cpeIds, command) {
      cpeService.issueCPECommand(this, cpeIds, command);
    },
    rebootCPE(cpeIds) {
      cpeService.rebootCPE(this, cpeIds);
    },
    changeLocations(list, id) {
      cpeService.changeCpesLocations(this, list, id);
    },
    resetCPE(ids, broker_ip) {
      cpeService.resetCPE(this, ids, broker_ip);
    },
    createTemplate() {
      this.$validator
        .validateAll({
          'template-name': this.newTemplateData.name
        })
        .then((result) => {
          if (result) {
            cpeService.createTemplateFromCpe(this);
          }
        });
    },
    cancelDeleteCommonWlans() {
      this.commonDeletingWlansModal = false;
    },
    cancelDeleteCommonTags() {
      this.commonDeletingTagsModal = false;
    },
    cancelChangeLocation() {
      this.isChangeLocationOpen = false;
    },
    cancelDeleteCommonCpes() {
      this.commonDeletingCpesModal = false;
    },
    cancelDeleteCpe() {
      this.deletingCpeModal = false;
    },
    checkCpeLoading(id) {
      this.cpeOperations.forEach((operation) => {
        if (operation.model_id === id) {
          return true;
        }
      });
    },
    wlanObj(id) {
      const wlanObj = commonService.wlanObjbyId(id);
      return wlanObj;
    },
    cpeObj(id) {
      const cpeObj = commonService.cpeObjbyId(id);
      return cpeObj;
    },
    modelObj(id) {
      const modelObj = commonService.modelObjbyId(id);
      return modelObj;
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },

    locationDescription(id) {
      const showLocationFromId = commonService.showLocationDescriptionFromId(id);
      return showLocationFromId;
    },

    locationPartyGetField(id, field) {
      try {
        const showLocationPartyFromId = commonService.showLocationPartyFromLocationId(id);
        return showLocationPartyFromId[field];
      } catch (e) {
        // console.log(e)
        return '';
      }
    },
    firewallName(id) {
      const showFirewallFromId = commonService.firewallNamebyId(id);
      return showFirewallFromId;
    },
    cutName(name) {
      const cutName = commonService.cutName(name);
      return cutName;
    },
    formatBytes(bytes, decimals) {
      const formatBytes = commonService.formatBytes(bytes, decimals);
      return formatBytes;
    },
    activeChannel(channelList, currChannel) {
      let result;
      channelList.forEach((channel) => {
        if (channel === currChannel) {
          result = true;
        }
      });
      return result;
    },
    addMacAddress() {
      this.$validator.validateAll({ mac: this.newMacAddress }).then((result) => {
        if (result) {
          if (this.updatedCpe.config.lbs_config.filtermode === 'WhiteList' && this.newMacAddress) {
            if (this.configFilterList.whitelist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.whitelist.push(this.newMacAddress);
            } else {
              return;
            }
          }
          if (this.updatedCpe.config.lbs_config.filtermode === 'BlackList' && this.newMacAddress) {
            if (this.configFilterList.blacklist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.blacklist.push(this.newMacAddress);
            } else {
              return;
            }
          }
          this.newMacAddress = '';
          this.$validator.reset();
        }
      });
    },
    addTag() {
      this.$validator
        .validateAll({
          tag: this.newTag
        })
        .then((result) => {
          if (result && this.newTag) {
            if (this.updatedCpe.tags.indexOf(this.newTag) == -1) {
              this.updatedCpe.tags.push(this.newTag);
              this.newTag = '';
            } else {
              return;
            }
            this.newTag = '';
          }
        });
    },
    addTagMultiple() {
      this.$validator
        .validateAll({
          tags: this.newTag
        })
        .then((result) => {
          if (result && this.newTag) {
            this.newTags.push(this.newTag);
            this.newTag = '';
          }
        });
    },
    searchTag(tag) {
      this.filters.tag = tag;
      this.refreshCpesListQuery();
    },
    addExistTag(tag) {
      if (this.updatedCpe.tags.indexOf(tag) == -1) {
        this.updatedCpe.tags.push(tag);
      } else {
      }
    },
    addExistTagMultiple(tag) {
      this.newTags.push(tag);
    },
    removeTag(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.updatedCpe.tags.splice(index, 1);
    },
    removeTagMultiple(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.newTags.splice(index, 1);
    },
    removeMac(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { list } = selectedNode.dataset;
      if (list === 'whitelist') {
        this.configFilterList.whitelist.splice(index, 1);
      }
      if (list === 'blacklist') {
        this.configFilterList.blacklist.splice(index, 1);
      }
    },

    showFWUpgrade() {
      this.isFWUpgradeShow = !this.isFWUpgradeShow;
    },
    showLatitudeLongitudeBlock() {
      if (!this.isShowMapsAndLatitudeLongitudeSettings){
        return;
      }
      if (this.isLatitudeLongitudeBlockShow === false) {
        this.locationForEditPosition = this.updatedCpe.base_location;
      } else {
        this.locationForEditPosition = 'Select Location';
      }
      this.isLatitudeLongitudeBlockShow = !this.isLatitudeLongitudeBlockShow;
    },
    showFirewall() {
      this.isFirewallShow = !this.isFirewallShow;
    },
    previewUuids(event) {
      const that = this;
      function showCsv() {
        const input = event.target;
        if (input.files && input.files[0] && input.files[0].type === 'text/csv') {
          const reader = new FileReader();
          reader.onload = (e) => {
            const result = JSON.stringify(e.target.result).replace(/["/]*/g, '');
            const uuids = result.split('\\n');
            uuids.forEach((id) => {
              if (id.length != 36) {
                that.invalidUuid = true;
              }
            });
            if (that.invalidUuid) return;
            const newUuidArray = that.newTemplateData.cpes.concat(uuids);
            that.newTemplateData.cpes = newUuidArray;
          };
          reader.readAsText(input.files[0]);
          input.value = '';
          that.invalidUuid = false;
          that.fileTypeError = false;
        } else {
          that.invalidUuid = false;
          that.fileTypeError = true;
        }
      }
      showCsv();
    },
    addUUIID() {
      this.$validator
        .validateAll({
          uuid: this.newUuid
        })
        .then((result) => {
          if (result) {
            if (this.newTemplateData.cpes.indexOf(this.newUuid) == -1) {
              this.newTemplateData.cpes.unshift(this.newUuid);
              this.newUuid = '';
            } else {
              this.newUuid = '';
            }
          }
        });
    },
    removeUuid(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.newTemplateData.cpes.splice(index, 1);
    },
    getHostname() {
      if (API_URL) {
        const restApi = API_URL.split(':');
        if (restApi[2]) {
          restApi.splice(2, 1);
          this.hostname = restApi.join(':');
        } else {
          this.hostname = restApi.join(':');
        }
      } else {
        this.hostname = `http://${window.location.host}`;
      }
    },
    changeFwSource() {
      this.fwFromLink = !this.fwFromLink;
      if (this.fwFromLink) {
        this.forceFWObjects = { url: '' };
      } else {
        this.forceFWObjects = false;
      }
    },
    uploadFWFileToServer() {
      const fwfile = document.querySelector('#fwfile');
      if (!fwfile.files.length) {
        return;
      }
      const formData = new FormData();
      formData.append(fwfile.name, fwfile.files[0]);
      this.$store.commit('toggleUploadingFW', true);
      this.axios.post(`${API_URL || ''}/api-static/firmwares/upload`, formData).then(
        (response) => {
          if (response.data.status === 'success') {
            this.uploadFWfile = response.data.data.items;
            // this.uploadFWfile.version = `${fwfile.files[0].name} ( ${this.$t('aps.fwFileUploadedCaption')} ) `;
            this.uploadFWfile.version = `(${this.$t('aps.fwFileUploadedCaption')}) ${fwfile.files[0].name}`;
            // this.updatedCpe.config.firmware.file = JSON.parse(JSON.stringify(this.uploadFWfile.url));
            this.cpeModelData.firmwares.push(this.uploadFWfile);
            this.forceFWObjects = this.uploadFWfile;
            this.forceFWObjects.url = `http://${this.updatedCpe.state.l2tp_state.host_addr}${this.forceFWObjects.url}`;
            VueNotifications.success({ message: this.$t('aps.fwFileUploadSuccessfullyMsgCaption') });
            this.$store.commit('toggleUploadingFW', false);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            this.$store.commit('toggleUploadingFW', false);
          }
        },
        (err) => {
          this.$store.commit('toggleUploadingFW', false);
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    upgradeFW() {
      const { body } = document;
      body.classList.remove('modal-open');
      this.FWUpgradeModal = false;
      this.closeFWUpgradeDialog();
      cpeService.upgradeFW(this);
    },
    handlerChannels(isCisco, channels, channel, isActive, id) {
      if (!isCisco || !isActive) {
        return;
      }
      this.interfaceCapabilities[id].selected.channels = [channel];
    },
    formatToCSV(data) {
      if (!data) {
        return [];
      }
      return data.map(this.formatItemToCSV);
    },
    convertToNewExcelExportModuleFormat(inputDataForOldExcelModule, nameForExcelList){
      return helpers.convertFromOldExcelModuleFormatToNewExcelModuleFormat(inputDataForOldExcelModule, nameForExcelList)
    },
    // formatItemToCSV(item) {
    //   // console.log(item)
    //   const returnedObject = {};
    //   returnedObject[this.$t('aps.tableTab_name')] = item.name;
    //   returnedObject[this.$t('aps.tableTab_model')] = item.model.name;
    //   returnedObject[this.$t('aps.tableTab_status')] = item.config_status;
    //   // returnedObject['IP'] = (item.state.network && item.state.network.ipaddr) ? item.state.network.ipaddr : '—';
    //   returnedObject['IP'] = this.getCPEIPForDownloadsFiles(item);
    //   returnedObject['2.4 Ghz'] = this.calculateClientsByFrequency(item, '2.4');
    //   returnedObject['5 Ghz'] = this.calculateClientsByFrequency(item, '5');
    //   returnedObject[this.$t('aps.tableTab_wired')] = this.calculateWiredClients(item.clients);
    //   returnedObject[this.$t('aps.tableTab_radar')] = item.config.lbs_config.enabled ? this.$t('general.enabled').toLowerCase() : this.$t('general.disabled').toLowerCase();
    //   returnedObject[this.$t('aps.location')] = item.base_location ? this.locationName(item.base_location) : '';
    //   returnedObject[this.$t('location.companyCaptionForTable')] = this.getCompanyNameForDownloadsFiles(item.base_location);
    //   returnedObject[this.$t('location.innCaptionForTable')] = this.getCompanyInnForDownloadsFiles(item.base_location);
    //   returnedObject[this.$t('aps.tableTab_tags')] = item.tags.join(' ');
    //   returnedObject.MAC = item.state.network.macaddr;
    //   if (this.isBeelineBranding) {
    //     if (item.config.hasOwnProperty('beeline_config') && item.config.beeline_config.hasOwnProperty('nas_ip')) {
    //       returnedObject[this.$t('aps.nasIp')] = item.config.beeline_config.nas_ip;
    //     } else {
    //       returnedObject[this.$t('aps.nasIp')] = '';
    //     }
    //   }
    //   if (this.isBeelineBranding) {
    //     if (item.config.hasOwnProperty('wifi') && Array.isArray(item.config.wifi)) {
    //       returnedObject[this.$t('wlans.wlans')] = this.wlansNamesForCPE(item);
    //     } else {
    //       returnedObject[this.$t('wlans.wlans')] = '';
    //     }
    //   }
    //   // returnedObject.Firmware = item.state.firmware.version.version;
    //   returnedObject.Firmware = item.state.firmware ? item.state.firmware.version.version : '';
    //   returnedObject[this.$t('aps.tableTab_firstConnection')] = (item.first_connection && item.first_connection !== 0) ?
    //     this.covertTimestampToDate(item.first_connection) : '';
    //   returnedObject[this.$t('aps.tableTab_lastConnection')] = (item.last_connection && item.last_connection !== 0) ?
    //     this.covertTimestampToDate(item.last_connection) : '';
    //   returnedObject[this.$t('aps.tableTab_lastDisconnection')] = (item.last_disconnection && item.last_disconnection !== 0) ?
    //     this.covertTimestampToDate(item.last_disconnection) : '';
    //   return returnedObject;
    //
    //   // return {
    //   //   name: item.name,
    //   //   Model: item.model.name,
    //   //   Status: item.config_status,
    //   //   '2.4 Ghz Clients': this.calculateClientsByFrequency(item, '2.4'),
    //   //   '5 Ghz Clients': this.calculateClientsByFrequency(item, '5'),
    //   //   'Wired Clients': this.calculateWiredClients(item.clients),
    //   //   Radar: item.config.lbs_config.enabled ? 'enabled' : '',
    //   //   Location: this.locationName(item.base_location),
    //   //   Company: this.locationPartyGetField(item.base_location, 'name'),
    //   //   Inn: this.locationPartyGetField(item.base_location, 'inn'),
    //   //   Tags: item.tags.join(' '),
    //   //   MAC: item.state.network.macaddr,
    //   //   Firmware: item.state.firmware.version.version
    //   // };
    // },
    formatItemToCSV(item) {
      // console.log('formatItemToCSV')
      // console.log(item)
      const returnedObject = {};
      returnedObject[this.$t('aps.tableTab_name')] = item.name;
      returnedObject[this.$t('aps.tableTab_model')] = item.model.name;
      returnedObject[this.$t('aps.tableTab_status')] = item.config_status;
      // returnedObject['IP'] = (item.state.network && item.state.network.ipaddr) ? item.state.network.ipaddr : '—';
      returnedObject['IP'] = this.getCPEIPForDownloadsFiles(item);
      returnedObject['2.4 Ghz'] = this.calculateClientsByFrequency(item, '2.4');
      returnedObject['5 Ghz'] = this.calculateClientsByFrequency(item, '5');
      if (this.showWiredColumnInTableAndDownloads) {
        returnedObject[this.$t('aps.tableTab_wired')] = this.calculateWiredClients(item.clients);
      }
      if (this.showWiFiTargetColumnInTableAndDownloads) {
        if (item.config.lbs_config.enabled === true) {
          returnedObject[this.$t('aps.tableTab_radar')] = this.$t('general.enabled').toLowerCase()
        } else {
          returnedObject[this.$t('aps.tableTab_radar')] = this.$t('general.disabled').toLowerCase();
        }
        // returnedObject[this.$t('aps.tableTab_radar')] = item.config.lbs_config.enabled ? this.$t('general.enabled').toLowerCase() : this.$t('general.disabled').toLowerCase();
      }
      // returnedObject[this.$t('aps.location')] = item.base_location ? this.locationName(item.base_location) : '';
      //
      // if (this.showLocationDescriptionColumnInTableAndDownloads) {
      //   returnedObject[this.$t('location.locationDescription')] = item.base_location ? this.locationDescription(item.base_location) : '';
      // }
      //
      //
      // returnedObject[this.$t('location.companyCaptionForTable')] = this.getCompanyNameForDownloadsFiles(item.base_location);
      // returnedObject[this.$t('location.innCaptionForTable')] = this.getCompanyInnForDownloadsFiles(item.base_location);

      if (this.showLocationColumnsInTableAndDownloads) {
        returnedObject[this.$t('aps.location')] = item.base_location ? this.locationName(item.base_location) : '';

        if (this.showLocationDescriptionColumnInTableAndDownloads) {
          returnedObject[this.$t('location.locationDescription')] = item.base_location ? this.locationDescription(item.base_location) : '';
        }
        returnedObject[this.$t('location.companyCaptionForTable')] = this.getCompanyNameForDownloadsFiles(item.base_location);
        returnedObject[this.$t('location.innCaptionForTable')] = this.getCompanyInnForDownloadsFiles(item.base_location);
      }


      // returnedObject[this.$t('aps.tableTab_tags')] = item.tags.join(' ');

      if (this.tableSettings.tags.value === true) {
        returnedObject[this.$t('aps.tableTab_tags')] = item.tags.join(' ');
      }

      returnedObject.MAC = item.state.network.macaddr;
      if (this.isBeelineBranding) {
        if (item.config.hasOwnProperty('beeline_config') && item.config.beeline_config.hasOwnProperty('nas_ip')) {
          returnedObject[this.$t('aps.nasIp')] = item.config.beeline_config.nas_ip;
        } else {
          returnedObject[this.$t('aps.nasIp')] = '';
        }
      }
      if (this.isBeelineBranding) {
        if (item.config.hasOwnProperty('wifi') && Array.isArray(item.config.wifi)) {
          returnedObject[this.$t('wlans.wlans')] = this.wlansNamesForCPE(item);
        } else {
          returnedObject[this.$t('wlans.wlans')] = '';
        }
      }
      // returnedObject.Firmware = item.state.firmware.version.version;
      returnedObject.Firmware = item.state.firmware ? item.state.firmware.version.version : '';
      returnedObject[this.$t('aps.tableTab_firstConnection')] = (item.first_connection && item.first_connection !== 0) ?
        this.covertTimestampToDate(item.first_connection) : '';
      returnedObject[this.$t('aps.tableTab_lastConnection')] = (item.last_connection && item.last_connection !== 0) ?
        this.covertTimestampToDate(item.last_connection) : '';
      returnedObject[this.$t('aps.tableTab_lastDisconnection')] = (item.last_disconnection && item.last_disconnection !== 0) ?
        this.covertTimestampToDate(item.last_disconnection) : '';
      return returnedObject;

      // return {
      //   name: item.name,
      //   Model: item.model.name,
      //   Status: item.config_status,
      //   '2.4 Ghz Clients': this.calculateClientsByFrequency(item, '2.4'),
      //   '5 Ghz Clients': this.calculateClientsByFrequency(item, '5'),
      //   'Wired Clients': this.calculateWiredClients(item.clients),
      //   Radar: item.config.lbs_config.enabled ? 'enabled' : '',
      //   Location: this.locationName(item.base_location),
      //   Company: this.locationPartyGetField(item.base_location, 'name'),
      //   Inn: this.locationPartyGetField(item.base_location, 'inn'),
      //   Tags: item.tags.join(' '),
      //   MAC: item.state.network.macaddr,
      //   Firmware: item.state.firmware.version.version
      // };
    },
    handleCloseConfigScript() {
      this.configModal = false;
      this.commandResult = '';
    },
    handleCloseSetTimeUsingNTPModal() {
      this.setTimeUsingNTPModalShowing = false;
      this.ntpServerAddressForsetTimeUsingNTP = '';
      this.setTimeUsingNTPResult = '';
    },
    handleCloseSyncTimeModal() {
      if (this.syncTimeRequestCancel) {
        this.syncTimeRequestCancel.cancel();
        this.syncTimeRequestCancel = undefined;
      }
      this.syncTimeCommandResult = undefined;
      this.syncTimeCommandError = undefined;
      this.syncTimeModalShowing = false;
    },
    showMaxinactivityModalInfoDetails() {
      // console.log('clicked');
      this.showingMaxinactivityModalInfoDetails = true;
    },
    onSubmitConfigScriptForm(event) {
      // Тут отменяем действие браузера по умолчанию для события onSubmit в form модалки "Отпарвить команду настройки".
      // Событие возникает при нажатии Enter когда мы находимся на каком-нибудь поле внутри form
      // и по умолчанию перезагружает страницу
      // Из-за этого и перезагружалась вся страница после ввода команды и нажатия Enter

      // console.log(event);
      // console.log('prevent default for form onSubmit');
      event.preventDefault(); // отменяем действие браузера по умолчанию для события
    },
    onSubmitSetTimeUsingNTPForm(event) {
      // console.log('prevent default for form onSubmit');
      event.preventDefault(); // отменяем действие браузера по умолчанию для события onSubmit (перезагрузка страницы)
    },
    onEnterKeyUpOnConfigScriptForm(event) {
      // вызываем когда нажали Enter в моделке "Отпарвить команду настройки"
      event.preventDefault(); // отменяем действие браузера по умолчанию
      // console.log(event);
      // console.log ('Enter Pressed')
      if (this.configCommand && !this.isSendCPEConfigScriptFromCpesExecuting) {
        // console.log('send')
        // если введена какая-нибудь команда в поле и запрос не находится в процессе выполнения - отправляем запрос
        this.issueCPECommand(
          this.selectedCpesId.length ? this.selectedCpesId : [this.cpeSelectedId],
          this.configCommand
        );
      }
    },
    getCPEClientsListFromCpeCommand(cpeIds) {
      cpeService.getCPEClientsListFromCpeCommand(this, cpeIds);
    },
    openCPEClientsListFromCpeCommandModal(cpeIds) {
      // console.log(cpeIds)
      this.getCPEClientsListFromCpeCommandResult = '';
      this.getCPEClientsListFromCpeCommandResultParsed = {};
      this.getCPEWLANSInfoFromCpeCommandResult = '';
      this.getCPEWLANSInfoFromCpeCommandResultParsed = {};
      this.getCPEClientsListFromCpeCommand(cpeIds);
      this.isCPEClientsListFromCpeCommandModalOpened = true;
    },
    closeCPEClientsListFromCpeCommandModal() {
      this.getCPEClientsListFromCpeCommandResult = '';
      this.getCPEClientsListFromCpeCommandResultParsed = {};
      this.getCPEWLANSInfoFromCpeCommandResult = '';
      this.getCPEWLANSInfoFromCpeCommandResultParsed = {};
      this.isCPEClientsListFromCpeCommandModalOpened = false;
      if (document.body.style.paddingRight !== null) {
        document.body.style.paddingRight = null; // чтобы после закрытия модалки вставл на место скролбар
      }
    },
    getWlanInfoForClientsListFromCpeCommand(wlan) {
      try {
        if (Object.prototype.hasOwnProperty.call(this.getCPEWLANSInfoFromCpeCommandResultParsed, wlan)) {
          return this.getCPEWLANSInfoFromCpeCommandResultParsed[wlan];
        }
        return undefined;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    tooltipForWlanInfoForClientsListFromCpeCommand(wlanInfo) {
      try {
        const outputDiv = document.createElement('div');
        outputDiv.innerHTML = `<span class="mb-h"><b>${this.$t('aps.cpeClientsListFromCpeTooltipHeader')}</b></span>`;
        const propsList = document.createElement('ul');
        outputDiv.className = 'error-status small';
        propsList.className = 'list-unstyled mb-h';
        outputDiv.appendChild(propsList);
        for (const key in wlanInfo) {
          // result = result + '<br>' + key + ': ' + wlanInfo[key];
          propsList.innerHTML += `<li><b>${key}:</b> ${wlanInfo[key]}</li> `;
        }
        return outputDiv;
      } catch (e) {
        return '';
      }
    },
    makeMACForDisplayInClientsListFromCpeCommandTable(mac) {
      try {
        return mac.toUpperCase();
      } catch (e) {
        console.log(e);
        return mac;
      }
    }
  },
  mounted() {
    // начальная загрузка данных по точкам
    function initFilterQueryHandler(newSearchBarQuery) {
      if (newSearchBarQuery) {
        this.searchValue = newSearchBarQuery;
      } else {
        cpeService.getPaginatedCpes(this, this.limit, this.offset);
      }
    }

    // обновление данных по точкам при изменении searchQuery параметра
    function updateFilterQueryHandler(newSearchBarQuery) {
      this.searchValue = newSearchBarQuery;
    }

    // подписки на изменение searchQuery
    this.subscribeOnSearchBarInit(initFilterQueryHandler);
    this.subscribeOnSearchBarUpdate(updateFilterQueryHandler);

    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      if ((this.editVisible && this.cpeEditCurrent.config_status !== 'updating') || this.editCpesSettingsMode) {
        return;
      }

      cpeService.refreshCpes(this)
    });
    this.$nextTick(function () {
      this.checkCpeLoading();
    });
  },
  beforeDestroy() {
    if (!this.showGoBackToClientsButton) {
      // если показана кнопка  "обратно в клиентов"
      // если идем обратно в клиентов не стоит сбрасывать  фильтры, так как это сбрсывает список ТД
      // а он нужен в клиентах,
      this.beforeDestroyHookExecutedFlag = true;
      this.resetAllFilters();
    }
  },
    created() {
    let inHeaderLocationSelectedApplying = true;
    const hasLocalStorageDataForRestoring = this.checkComponentStateDataForRestore();
    const goBackFromEvents = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromEvents')
    const goBackFromClients = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromClients')
    const needRestoreState = hasLocalStorageDataForRestoring && (goBackFromEvents || goBackFromClients)
    if (needRestoreState) {
      this.loadComponentStateDataForRestoreAndRestore();
      this.removeComponentStateDataForRestore();
    }
    if (!needRestoreState && hasLocalStorageDataForRestoring) {
      this.removeComponentStateDataForRestore();
    }
    if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('registeredCpeId')) {
      inHeaderLocationSelectedApplying = false;
      // если переходим cюда из комопнента RegisterMyCPEPlease после регистрации точки,
      // срзау делаем поиск по id этой точке
      this.searchValue = this.$route.params.registeredCpeId;
      //  и удалем этот параметр
      delete this.$route.params.registeredCpeId;
      this.$store.commit('userRegistersTheCpeHimselfRequestEnd');
    }
    if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('cpeIdFormCpeStatisic')) {
      inHeaderLocationSelectedApplying = false;
      if (this.isSelectedDefaultLocationForRequests) {
        inHeaderLocationSelectedApplying = true;
      }
      // если переходим cюда из комопнента Ststistic - CPE
      // срзау делаем поиск по id этой точке
      this.searchValue = this.$route.params.cpeIdFormCpeStatisic;
      this.showGoBackToCpeStatisticButton = true;
      // а далее логика  в вотчере isLoadingAll
    }

    // если переходим на эту страницу с Dashboard -> Виджет Самые нагруженные ТД => ссылка Настройки ТД
    if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('cpeIdFormDashboard')) {
      inHeaderLocationSelectedApplying = false;
      if (this.isSelectedDefaultLocationForRequests) {
        inHeaderLocationSelectedApplying = true;
      }
      // если переходим cюда из комопнента Dashboard
      // срзау делаем поиск по id этой точке
      this.searchValue = this.$route.params.cpeIdFormDashboard;
      this.showGoBackToDashboardButton = true;
      // а далее логика  в вотчере isLoadingAll
    }
    if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('cpeIdFormClients')) {
      inHeaderLocationSelectedApplying = false;
      if (this.isSelectedDefaultLocationForRequests) {
        inHeaderLocationSelectedApplying = true;
      }
      // если переходим cюда из комопнента Clients
      // срзау делаем поиск по id этой точке
      this.searchValue = this.$route.params.cpeIdFormClients;
      this.showGoBackToClientsButton = true;
      // а далее логика  в вотчере isLoadingAll
    }
    if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('locationSelectedFromDashboard')) {
      inHeaderLocationSelectedApplying = false;
      if (this.isSelectedDefaultLocationForRequests) {
        inHeaderLocationSelectedApplying = true;
      }
      // если переходим cюда из дашборда кликнов по ссылку View CPES в блоке

      if (this.$route.params.locationSelectedFromDashboard) {
        this.filters.location = this.$route.params.locationSelectedFromDashboard.locationId;
        this.locationInFiltersData = this.$route.params.locationSelectedFromDashboard.locationObject
        this.showGoBackToDashboardButton = true;
      }

    }
    if (this.showDistressed) {
      this.setFilter('lastError', true);
      // this.filters.lastError = true;
    }
    this.disableSingleEditMode();
    if (!needRestoreState) {
      this.$store.commit('setCpesList', false);

      if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = []
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    }
    if (!String.prototype.format) {
      String.prototype.format = function () {
        const args = arguments;
        return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
      };
    }
    rrmService.getRRMs(this);
    templatesService.getCpeModels(this);
    templatesService.getCpeTemplates(this);
    cpeService.getCpesTags(this);
    // wlanService.getAllWlans(this);
    // wlanService.getAllCompactWlansWithDataRemainingWithoutClients(this);
    wlanService.getAllCompactWlansFromaAllLocationsWithDataRemainingWithoutClients(this);
    wlanService.getHosts(this);
    redirectsService.getRedirectsRule(this);
    this.getHostname();
    // cpeService.getAllFirmwaresList(this);
  }
};
</script>
<style lang="scss">
.cpes {
  display: flex;
}

.cpes-page {
  min-width: 900px;
}

.cpe-logo {
  vertical-align: baseline;
  height: 15px;
}

.cpes-list,
.cpes-edit {
  transition: width 0.2s;
}

.cpes-list {
  display: inline-block;
  width: 100%;
}

.cpes-edit {
  display: inline-block;
  width: 0%;
  word-wrap: break-word;
}

.cpes-edit .card-header {
  border: 0px;
}

.cpes-edit .btn {
  white-space: normal;
}

/*Edit frame for CPE with interface*/
.edit-visible .cpes-list {
  transition: width 0.2s;
  margin-right: 10px;
  width: 60%;
}

.edit-visible .cpes-edit {
  transition: width 0.2s;
  width: 40%;
  min-width: 500px;
}

/* Edit frame for CPE without interface */
.edit-visible.interface-visible .cpes-list {
  transition: width 0.2s;
  width: 45%;
}

.edit-visible.interface-visible .cpes-edit {
  transition: width 0.2s;
  width: 55%;
}

/*Table style*/
.cpe-row {
  transition: background 0.15s;
  cursor: pointer;
}

.cpe-row.selected {
  background: #d9e3ec;
}

.cpe-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.cpe-row-nodata {
  text-align: center;
}

.cpe-action {
  transition: transform 0.2s;
}

.channel-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  /*padding: 0px;*/
  list-style: none;
  margin-left: 0.5rem;

  font-size: 80%;
}

.channel-list input[type='checkbox'] {
  display: none;
}

.channel-list label {
  width: 100%;
  cursor: pointer;
}

.channel-list-item {
  padding: 0px 0px;
  transition: border-bottom-color 0.5s;
  cursor: pointer;
}

/*CPE settings */
.cpe-settings {
  display: flex;
  justify-content: flex-start;
}

.cpe-buttons {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.main-settings {
  flex-basis: auto;
  margin-right: 0rem;
}

.interface-block {
  border-radius: 10px;
  padding: 10px;
  background: rgba(236, 236, 236, 0.45);
  -webkit-box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
  -moz-box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
  box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
}

.interface-block-header {
  cursor: pointer;
}

.interface-block-icon {
  position: relative;
  bottom: 1px;
  display: inline-block;
  margin-right: 0.3rem;
  height: 15px;
}

.frequency-title {
  font-size: 0.7rem;
  position: relative;
  bottom: 2px;
}

.interface-visible .main-settings {
  flex-basis: 55%;
  margin-right: 2rem;
}

.interface-channel-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0rem 2rem;
}

.interface-channel {
  line-height: 2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50% !important;
  margin-right: 0.5rem;
}

.interface-channel-checkbox {
  display: none;
}

.interface-wlan-state {
  white-space: nowrap;
}

.interface-channel-trapezoid {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 20%;
  box-sizing: border-box;
  min-width: 30px;
  max-width: 70px;
  margin: 6px -1px;
  height: 0;
  border: 10px solid rgba(0, 0, 0, 0);
  border-top: 0 solid;
  border-bottom: 25px solid #bcc5d0;
  text-align: center;
  line-height: 25px;
  color: #fff;
  cursor: pointer;
  transition: border-bottom-color 0.5s;
}

.interface-channel-trapezoid__ht40 {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 25%;
  box-sizing: border-box;
  min-width: 50px;
  max-width: 100px;
  margin: 6px -1px;
  height: 0;
  border: 10px solid rgba(0, 0, 0, 0);
  border-top: 0 solid;
  border-bottom: 30px solid #bcc5d0;
  text-align: center;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
  transition: border-bottom-color 0.5s;
}

.interface-channel-trapezoid__ht80 {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 50%;
  box-sizing: border-box;
  min-width: 105px;
  max-width: 150px;
  height: 0;
  margin: 6px -1px;
  border: 10px solid rgba(0, 0, 0, 0);
  border-top: 0 solid;
  border-bottom: 40px solid #bcc5d0;
  border-radius: 1px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  cursor: pointer;
  transition: border-bottom-color 0.5s;
  z-index: 10;
}

.interface-channel-trapezoid__ht160 {
  font-size: 1em;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 25%;
  box-sizing: border-box;
  min-width: 210px;
  max-width: 300px;
  margin: 6px -1px;
  height: 0;
  border: 10px solid rgba(0, 0, 0, 0);
  border-top: 0 solid;
  border-bottom: 30px solid #bcc5d0;
  text-align: center;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
  transition: border-bottom-color 0.5s;
}

.interface-channel-trapezoid__ht160:hover {
  border-bottom-color: #8cd4a5;
}

.active.interface-channel-trapezoid__ht160 {
  border-bottom-color: #4ebd75;
}

.interface-channel-trapezoid__ht160.disabled {
  cursor: not-allowed;
}


.interface-channel-trapezoid:hover {
  border-bottom-color: #8cd4a5;
}

.interface-channel-trapezoid__ht40:hover {
  border-bottom-color: #8cd4a5;
}

.interface-channel-trapezoid__ht80:hover {
  border-bottom-color: #8cd4a5;
}

.active.interface-channel-trapezoid {
  border-bottom-color: #4ebd75;
}

.active.interface-channel-trapezoid__ht40 {
  border-bottom-color: #4ebd75;
}

.active.interface-channel-trapezoid__ht80 {
  border-bottom-color: #4ebd75;
}

.interface-channel-trapezoid.disabled {
  cursor: not-allowed;
}

.interface-channel-trapezoid__ht40.disabled {
  cursor: not-allowed;
}

.interface-channel-trapezoid__ht80.disabled {
  cursor: not-allowed;
}

.label-disabled {
  cursor: not-allowed !important;
}

.interface-enter-active {
  transition: all 0.7s;
}

.interface-leave-active {
  transition: all 0.5s;
}

.interface-enter, .interface-leave-to /* .list-leave-active для <2.1.8 */ {
  opacity: 0;
  transform: translateY(-25px);
}

/*Style for filters*/
/*.btn.btn-outline-info:hover {*/
/*background-color: #82cee5;*/
/*}*/

/*.btn.btn-outline-info.active {*/
/*background-color: #63c2de;*/
/*}*/

.cpe-updating {
  opacity: 0.9;
  background: rgba(236, 236, 236, 0.45);
  /*cursor: not-allowed;*/
}

.cpe-wlans-state {
  position: relative;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.multiselect__content-wrapper {
  z-index: 1000 !important;
}

.vue-slider-dot {
  z-index: 10 !important;
}

.cpes-edit .btn-block.btn:disabled {
  margin-bottom: 0;
}

.cpes-edit .btn-block.btn-outline-success:disabled {
  margin-bottom: 4px;
}

.cpes-edit .btn:disabled + .text-danger {
  display: block;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.btn-link__disabled {
  cursor: pointer;
  color: #bcc5d0;
}

.btn-link__disabled:hover {
  cursor: pointer;
  color: #bcc5d0;
}

.tamplate-config p {
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
}

.edit-header__buttons .badge-upgrade {
  position: absolute;
  top: -6px;
  right: -5px;
}

.badge-new-update {
  text-decoration: underline dashed;
  cursor: pointer;
  transition: background-color 0.2s;
}

.badge-new-update:hover {
  background-color: #4dbd74;
}

.cpes-page .filters__multiple .btn-group .btn {
  padding: 0.25rem 1rem;
}

.cpe-name {
  word-break: break-all;
}

.form-control__vlan {
  width: 55px;
}

// setting block header
.setting-block-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.setting-block-buttons {
  align-self: center;
  height: 100%;
}

.setting-block-buttons .icon-arrow-left:before,
.setting-block-buttons .icon-arrow-down:before {
  font-size: 20px;
}

.error-status {
  display: flex;
  flex-direction: column;

  padding: 4px;

  text-align: left;
}
</style>

<style lang="scss" scoped>
  .errorMessageNoAvailableCapabilities-wrapper{
    margin-top: 1em;
    overflow-wrap: normal;
  }
  .location-description-wrapper {
    max-width: 250px !important;
    word-break: break-all !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }

.filters__multiple .btn-group .btn {
  padding: 0.25rem 1rem;
}

.wlans-title {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  width: 100%;
}

.cpe-info {
  font-size: 80%;

  & .text-muted {
    color: #94a0b2 !important;
  }

  &--stats li:first-child {
    display: block;
  }

  &--stats li {
    display: inline-block;
    vertical-align: middle;

    margin-right: 5px;
  }
}

.cisco-icon {
  height: 20px;
  vertical-align: bottom;
}

.cpe-status {
  white-space: nowrap;
}

.cpe-edit-name {
  position: absolute;
  bottom: -17px;
  left: -8px;
}

.badge-status--cpe:after {
  top: 12px;
}

/* Moves button to top on laptop screens */
/* 1) Allows wrapping */
.filters-wrap {
  @media (max-width: 1440px) {
    flex-wrap: wrap;
  }
}

/* 2) Move button to top-right corner */
.filters-add {
  @media (max-width: 1440px) {
    order: -1;
    margin-left: auto;
    margin-bottom: 1rem;
  }
}

.custom-control {
  display: block;
}
</style>

<style lang="scss">
  #DHCPCaptureFilter-on-button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin-right: 0 !important;
    border-right: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-left: 0 !important;
    border-left: 0 !important;
  }
  #DHCPCaptureFilter-on-button.active {
    border-left: 1px solid rgba(255,255,255, 0.25) !important;
  }

  #DHCPCaptureFilter-off-button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-left: 0 !important;
  }

  #DHCPCaptureFilter-button-when-filter-enabled {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin-right: 0 !important;
    border-right: 0 !important;
  }

  .input-group-for-location-select-in-cpe--filters {
    // min-height: 27px !important;
    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }

  .select-location-in-cpe--filters {
    // min-height: 27px !important;
    width: 100%;
  }

  .select-location-in-cpe--filters .select__control {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    max-height: 300px !important;
    min-height: 27px !important;
    height: 22px !important;
    align-items: center;
  }

  .select-location-in-cpe--filters .select__input {
    // min-height: 27px !important;
    margin-bottom: 0px !important;
  }

  .select-location-in-cpe--filters .arrow {
    min-height: 27px !important;
  }

  .select-location-in-cpe--filters .select__control {
    max-width: 100% !important;
  }

  .select-location-in-cpe--filters .select__tag  {
    margin-bottom: 0px;
    height: 22px !important;
    max-width: 100% !important;
  }

  .select-location-in-cpe--filters .select__tag > span {
    max-width: 90px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .select-location-in-cpe--filters .select__input {
    width: 100% !important;
    text-overflow: ellipsis !important;
    padding-right: 0 !important
  }

  .select-location-in-cpe--filters .select__list-container {
    width: 400px;
  }


.fadeinconndisconnfiltersinaps-enter-active,
.fadeinconndisconnfiltersinaps-leave-active {
  transition: opacity 0.7s;
}
.fadeinconndisconnfiltersinaps-enter, .fadeinconndisconnfiltersinaps-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.dhcpcapturefiltertransitioninaps-enter-active,
.dhcpcapturefiltertransitioninaps-leave-active {
  transition: all 0.5s;
}
.dhcpcapturefiltertransitioninaps-enter, .dhcpcapturefiltertransitioninaps-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.filters__multiple-in-aps {
  flex-wrap: wrap;
  gap: 0.2em 1em;
}

.filters__multiple-in-aps div {
  margin: 0 !important;
}
.switcher-conn-disconn-date-range-filter-in-aps label {
  height: 27px !important;
  margin: 0px !important;
}

.switcher-conn-disconn-date-range-filter-in-aps span.switch-handle,
.switcher-conn-disconn-date-range-filter-in-aps span.switch-label {
  height: 27px !important;
}
.datepicker-conn-disconn-date-range-filter-in-aps .mx-input-wrapper {
  max-height: 27px !important;
  overflow: hidden;
}
.datepicker-conn-disconn-date-range-filter-in-aps .mx-input-wrapper input {
  padding-top: 0px !important;
}

.loader-backdrop-aps:after {
  position: absolute;
  top: 30px;
}
.no-scroll-bar {
  overflow-y: auto;

  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scroll-bar ::-webkit-scrollbar {
  /* chrome based */
  width: 0px; /* ширина scrollbar'a */
}
</style>

<style lang="scss" scoped>
.table-with-clients-from-cpe-command {
  font-size: 0.7em;
}
.table-with-clients-from-cpe-command-with-combined-columns {
  font-size: 0.85em;
}
.table-with-clients-from-cpe-command-with-combined-columns-nowrap-column {
  white-space: nowrap;
}
.wlan-ssid-in-CPEClientsListFromCpeCommandModal {
  /*text-decoration: underline;*/
  /*text-decoration-style: dashed;*/
  cursor: help;
}
.command-result-tab-margin {
  margin-left: 1em;
}
.divs-in-location-column {
  text-overflow: ellipsis !important;
  width: 149px !important;
  white-space: nowrap !important;
  overflow: hidden;
}
.max-width-in-cpe-table {
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}
.filters-in-aps {
  align-items: flex-end;
  /*display: flex;*/
  /*flex-direction: row;*/
  /*margin-bottom: 0.65rem;*/

  .conn-disconn-sort-in-aps {
    /*width: 100%;*/
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
  }

  /*& input {*/
  /*  padding-right: 25px !important;*/
  /*}*/
}
.edit-header__buttons-in-cpes {
  width: 100% !important;
  gap: 1rem;
}

.edit-header__buttons-in-cpes > div:first-of-type {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.info-tooltip-in-aps {
  margin-left: 0.2em;
}
.output-row {
  grid-column-start: 1;
  grid-column-end: 3;
}
.connection-time {
  font-size: 0.7em;
}

.select-channels-less-or-more-in-interface-settings {
  text-align: center;
  font-size: 1.2em !important;
}

.text-prewrap {
  white-space: pre-wrap;
}
</style>
