/* eslint-disable camelcase */
import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {Object} FieldConfig
 * @property {boolean} isDisabled - Указывает, отключено ли поле.
 * @property {string|boolean|number} defaultValue - Значение по умолчанию для поля.
 * @property {boolean} isRequired - Указывает, обязательно ли для заполнения.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 */

/**
 * @typedef {Object} DefaultValues
 * @property {Object} general - Общие настройки.
 * @property {FieldConfig} general.name
 * @property {FieldConfig} general.wlan_id
 * @property {FieldConfig} general.ssid
 * @property {FieldConfig} general.broadcast_ssid_enabled
 * @property {FieldConfig} general.status_24ghz_enabled
 * @property {FieldConfig} general.status_5ghz_enabled
 * @property {FieldConfig} general.status_6ghz_enabled
 * @property {FieldConfig} general.status_ssid_enabled
 * @property {Object} security - Настройки безопасности.
 * @property {Object} security.layer2 - Настройки безопасности - уровень 2.
 * @property {FieldConfig} security.layer2.security_type
 * @property {FieldConfig} security.layer2.encryption_type
 * @property {FieldConfig} security.layer2.support_pmf
 * @property {FieldConfig} security.layer2.param_80211w_query_attempts
 * @property {FieldConfig} security.layer2.param_80211w_retry_timeout
 * @property {FieldConfig} security.layer2.fast_roaming_enabled
 * @property {FieldConfig} security.layer2.ft_over_ds_enabled
 * @property {FieldConfig} security.layer2.param_80211r_reassociation_timeout
 * @property {FieldConfig} security.layer2.mab_radius_profile
 * @property {FieldConfig} security.layer2.psk_enabled
 * @property {FieldConfig} security.layer2.psk_256_enabled
 * @property {FieldConfig} security.layer2.param_8021x_enabled
 * @property {FieldConfig} security.layer2.param_8021x_256_enabled
 * @property {FieldConfig} security.layer2.sae_enabled
 * @property {FieldConfig} security.layer2.ft_sae_enabled
 * @property {FieldConfig} security.layer2.ft_8021x_enabled
 * @property {FieldConfig} security.layer2.ft_psk_enabled
 * @property {FieldConfig} security.layer2.suite_b_192_enabled
 * @property {FieldConfig} security.layer2.owe_enabled
 * @property {FieldConfig} security.layer2.psk_format
 * @property {FieldConfig} security.layer2.psk_type
 * @property {FieldConfig} security.layer2.psk_key
 * @property {FieldConfig} security.layer2.owe_wlan_id
 * @property {FieldConfig} security.layer2.sae_anti_clogging_threshold
 * @property {FieldConfig} security.layer2.sae_max_retries
 * @property {FieldConfig} security.layer2.sae_password_element
 * @property {Object} security.layer3 - Настройки безопасности - уровень 3.
 * @property {FieldConfig} security.layer3.web_auth_profile
 * @property {Object} security.aaa - Настройки безопасности - aaa.
 * @property {FieldConfig} security.aaa.radius_8021x_profile
 * @property {FieldConfig} security.aaa.radius_accounting_profile
 * @property {Object} advanced - Дополнительные настройки.
 * @property {FieldConfig} advanced.max_connection_radio_wlan
 * @property {FieldConfig} advanced.dtim_interval_5ghz
 * @property {FieldConfig} advanced.dtim_interval_24ghz
 * @property {FieldConfig} advanced.ofdma_downlink_enabled
 * @property {FieldConfig} advanced.ofdma_uplink_enabled
 * @property {FieldConfig} advanced.mumimo_downlink_enabled
 * @property {FieldConfig} advanced.mumimo_uplink_enabled
 * @property {FieldConfig} advanced.bss_twt_enabled
 * @property {FieldConfig} advanced.advertise_ap_name_enabled
 * @property {FieldConfig} advanced.p2p_block_action
 * @property {FieldConfig} advanced.deny_laa_rcm_enabled
 * @property {FieldConfig} advanced.wmm_policy
 * @property {FieldConfig} advanced.okc_enabled
 * @property {FieldConfig} advanced.param_80211ax_enabled
 * @property {FieldConfig} advanced.neighbor_report_enabled
 * @property {FieldConfig} advanced.bss_fast_transition_enabled
 */

/** @type {DefaultValues} */
const defaultValues = {
  general: {
    name: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    wlan_id: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    ssid: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    broadcast_ssid_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    status_24ghz_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    status_5ghz_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    status_6ghz_enabled: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    status_ssid_enabled: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    }
  },
  security: {
    layer2: {
      security_type: {
        isDisabled: false,
        defaultValue: 'wpa2',
        isRequired: true
      },
      encryption_type: {
        isDisabled: false,
        defaultValue: 'ccmp',
        isRequired: true
      },
      support_pmf: {
        isDisabled: false,
        defaultValue: 'disabled',
        isRequired: false
      },
      param_80211w_query_attempts: {
        isDisabled: false,
        defaultValue: 5,
        isRequired: true
      },
      param_80211w_retry_timeout: {
        isDisabled: false,
        defaultValue: 200,
        isRequired: true
      },
      fast_roaming_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      ft_over_ds_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      param_80211r_reassociation_timeout: {
        isDisabled: false,
        defaultValue: 20,
        isRequired: true
      },
      isMabEnabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      mab_radius_profile: {
        isDisabled: false,
        defaultValue: 'not_configured',
        isRequired: true
      },
      psk_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      psk_256_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      param_8021x_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      param_8021x_256_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      sae_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      ft_sae_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      ft_8021x_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      ft_psk_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      suite_b_192_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      owe_enabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      psk_format: {
        isDisabled: false,
        defaultValue: 'ascii',
        isRequired: false
      },
      psk_type: {
        isDisabled: false,
        defaultValue: 'open',
        isRequired: false
      },
      psk_key: {
        isDisabled: false,
        defaultValue: '',
        isRequired: false // Параметр обязателен для заполнения при добавлении нового профиля.
      },
      owe_wlan_id: {
        isDisabled: false,
        defaultValue: 0,
        isRequired: true
      },
      sae_anti_clogging_threshold: {
        isDisabled: false,
        defaultValue: 1500,
        isRequired: true
      },
      sae_max_retries: {
        isDisabled: false,
        defaultValue: 5,
        isRequired: true
      },
      sae_password_element: {
        isDisabled: false,
        defaultValue: 'both-h2e-hnp',
        isRequired: false
      }
    },
    layer3: {
      isWebAuthProfileEnabled: {
        isDisabled: false,
        defaultValue: false,
        isRequired: false
      },
      web_auth_profile: {
        isDisabled: false,
        defaultValue: 'not_configured',
        isRequired: true
      }
    },
    aaa: {
      radius_8021x_profile: {
        isDisabled: false,
        defaultValue: 'not_configured',
        isRequired: false
      },
      radius_accounting_profile: {
        isDisabled: false,
        defaultValue: 'not_configured',
        isRequired: false
      }
    }
  },
  advanced: {
    max_connection_radio_wlan: {
      isDisabled: false,
      defaultValue: 200,
      isRequired: true
    },
    dtim_interval_5ghz: {
      isDisabled: false,
      defaultValue: 1,
      isRequired: true
    },
    dtim_interval_24ghz: {
      isDisabled: false,
      defaultValue: 1,
      isRequired: true
    },
    ofdma_downlink_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    ofdma_uplink_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    mumimo_downlink_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    mumimo_uplink_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    bss_twt_enabled: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    advertise_ap_name_enabled: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    p2p_block_action: {
      isDisabled: false,
      defaultValue: 'disabled',
      isRequired: false
    },
    deny_laa_rcm_enabled: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    wmm_policy: {
      isDisabled: false,
      defaultValue: 'allowed',
      isRequired: false
    },
    okc_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    param_80211ax_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    neighbor_report_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    bss_fast_transition_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    }
  }
};

/**
 * Глубокое слияние двух объектов.
 *
 * @template T
 * @param {T} target - Целевой объект.
 * @param {Partial<T>} source - Исходный объект.
 * @returns {T} - Слитый объект.
 */

function mergeDeep(target, source) {
  const result = { ...target };

  Object.entries(source).forEach(([key, value]) => {
    // Проверяем, если это массив, заменяем массив целиком
    if (Array.isArray(value)) {
      result[key] = value;
    } else if (value instanceof Object && key in target) {
      // Рекурсивно обрабатываем объекты
      result[key] = mergeDeep(target[key], value);
    } else {
      // Заменяем значение для простых типов
      result[key] = value;
    }
  });

  return result;
}

/**
 * @typedef {Object} SelectOption
 * @property {string} display - Отображаемое значение опции.
 * @property {string} data - Данные опции.
 */

/**
 * @typedef {Object} Input
 * @property {string} id - Уникальный идентификатор для поля ввода.
 * @property {string} label - Метка для поля ввода.
 * @property {string} name - Имя поля ввода.
 * @property {string} inputType - Тип поля ввода.
 * @property {string} validateRules - Правила валидации для поля ввода.
 * @property {boolean} isDisabled - Отключено ли поле ввода.
 * @property {string} placeholder - Плейсхолдер для поля ввода.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 * @property {string|number|boolean|undefined} defaultValue - Значение по умолчанию для поля ввода.
 * @property {string|number|boolean} [radioValue] - value для radio инпута.
 */

/**
 * @typedef {Object} ContentGroup
 * @property {string} [id] - Уникальный идентификатор для группы содержимого.
 * @property {string} label - Метка для группы содержимого.
 * @property {string} [name] - Имя для группы содержимого.
 * @property {boolean} [withBorder] - будет ли граница у группы содержимого.
 * @property {Input[]} inputs - Поля ввода в группе содержимого.
 */

/**
 * @typedef {Object} TabConfig
 * @property {string} id - Уникальный идентификатор для вкладки.
 * @property {string} label - Метка для вкладки.
 * @property {string} name - Имя вкладки.
 * @property {ContentGroup[]} content - Группы содержимого во вкладке.
 * @property {TabConfig[]} childrenTabs - Вложенные вкладки.
 */

/**
 * Генерирует конфигурацию формы на основе заданных параметров.
 *
 * @param {Object} params - Параметры для генерации конфигурации формы.
 * @param {Object} params.i18nInstance - Экземпляр i18n для локализации.
 * @param {Object} [params.initialValues] - Начальные значения и настройки полей формы.
 * @param {Object} [params.initialValues.general] - Начальные значения и настройки для общей вкладки.
 * @param {Object} [params.initialValues.security] - Начальные значения и настройки для вкладки безопасности.
 * @param {Object} [params.initialValues.advanced] - Начальные значения и настройки для вкладки дополнительные.
 * @returns {TabConfig[]} Конфигурация вкладок формы.
 */

function generateFormConfig(params) {
  const {
    i18nInstance,
    initialValues = {}
  } = params;

  const $t = i18nInstance.t.bind(i18nInstance);
  const mergedValues = mergeDeep(defaultValues, initialValues);

  const {
    general: {
      name,
      wlan_id,
      ssid,
      broadcast_ssid_enabled,
      status_24ghz_enabled,
      status_5ghz_enabled,
      status_6ghz_enabled,
      status_ssid_enabled,
    },
    security: {
      layer2,
      layer3,
      aaa
    },
    advanced: {
      max_connection_radio_wlan,
      dtim_interval_5ghz,
      dtim_interval_24ghz,
      ofdma_downlink_enabled,
      ofdma_uplink_enabled,
      mumimo_downlink_enabled,
      mumimo_uplink_enabled,
      bss_twt_enabled,
      advertise_ap_name_enabled,
      p2p_block_action,
      deny_laa_rcm_enabled,
      wmm_policy,
      okc_enabled,
      param_80211ax_enabled,
      neighbor_report_enabled,
      bss_fast_transition_enabled
    }
  } = mergedValues;

  const {
    security_type,
    encryption_type,
    isMabEnabled,
    mab_radius_profile,
    support_pmf,
    param_80211w_query_attempts,
    param_80211w_retry_timeout,
    fast_roaming_enabled,
    ft_over_ds_enabled,
    param_80211r_reassociation_timeout,
    psk_enabled,
    psk_256_enabled,
    param_8021x_enabled,
    param_8021x_256_enabled,
    sae_enabled,
    ft_sae_enabled,
    ft_8021x_enabled,
    ft_psk_enabled,
    suite_b_192_enabled,
    owe_enabled,
    psk_format,
    psk_type,
    psk_key,
    owe_wlan_id,
    sae_anti_clogging_threshold,
    sae_max_retries,
    sae_password_element
  } = layer2;

  const { isWebAuthProfileEnabled, web_auth_profile } = layer3;
  const { radius_8021x_profile, radius_accounting_profile } = aaa;

  /**
  * @type {TabConfig[]}
  */
  const formConfig = [
    {
      id: uuidv4(),
      label: $t('wlan.tabLabel.general'),
      name: 'general',
      content: [
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.name'),
              name: 'general.name',
              inputType: 'text',
              validateRules: `${name.isRequired ? 'required|' : ''}uniqueProfileName|profileNameMask|max:120`,
              isDisabled: name.isDisabled,
              placeholder: '',
              defaultValue: name.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.wlan_id'),
              name: 'general.wlan_id',
              inputType: 'number',
              validateRules: `${wlan_id.isRequired ? 'required|' : ''}integer|uniqueWlanId|between:1,4096`,
              isDisabled: wlan_id.isDisabled,
              placeholder: '',
              defaultValue: wlan_id.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.ssid'),
              name: 'general.ssid',
              inputType: 'text',
              validateRules: `${ssid.isRequired ? 'required|' : ''}ssidMask|max:32`,
              isDisabled: ssid.isDisabled,
              placeholder: '',
              defaultValue: ssid.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.status_ssid_enabled'),
              name: 'general.status_ssid_enabled',
              inputType: 'checkbox',
              validateRules: `${status_ssid_enabled.isRequired ? 'required|' : ''}`,
              isDisabled: status_ssid_enabled.isDisabled,
              placeholder: '',
              defaultValue: status_ssid_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.broadcast_ssid_enabled'),
              name: 'general.broadcast_ssid_enabled',
              inputType: 'checkbox',
              validateRules: `${broadcast_ssid_enabled.isRequired ? 'required|' : ''}`,
              isDisabled: broadcast_ssid_enabled.isDisabled,
              placeholder: '',
              defaultValue: broadcast_ssid_enabled.defaultValue
            }
          ]
        },
        {
          id: uuidv4(),
          label: '',
          name: 'general.ghzStatuses',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.status_24ghz_enabled'),
              name: 'general.status_24ghz_enabled',
              inputType: 'checkbox',
              validateRules: `${status_24ghz_enabled.isRequired ? 'required|' : ''}`,
              isDisabled: status_24ghz_enabled.isDisabled,
              placeholder: '',
              defaultValue: status_24ghz_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.status_5ghz_enabled'),
              name: 'general.status_5ghz_enabled',
              inputType: 'checkbox',
              validateRules: `${status_5ghz_enabled.isRequired ? 'required|' : ''}`,
              isDisabled: status_5ghz_enabled.isDisabled,
              placeholder: '',
              defaultValue: status_5ghz_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.status_6ghz_enabled'),
              name: 'general.status_6ghz_enabled',
              inputType: 'checkbox',
              validateRules: `${status_6ghz_enabled.isRequired ? 'required|' : ''}`,
              isDisabled: status_6ghz_enabled.isDisabled,
              placeholder: '',
              defaultValue: status_6ghz_enabled.defaultValue
            }
          ]
        },
      ],
      childrenTabs: []
    },
    {
      id: uuidv4(),
      label: $t('wlan.tabLabel.security'),
      name: 'security',
      content: [],
      childrenTabs: [
        {
          id: uuidv4(),
          label: $t('wlan.tabLabel.layer2'),
          name: 'layer2',
          content: [
            {
              label: $t('wlan.tabSectionLabel.securityType'),
              name: 'security.layer2.security_type',
              inputs: [
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.security_type.open'),
                  name: 'security.layer2.security_type',
                  inputType: 'radio',
                  validateRules: `${security_type.isRequired ? 'required|' : ''}`,
                  isDisabled: security_type.isDisabled,
                  placeholder: '',
                  defaultValue: security_type.defaultValue,
                  radioValue: 'open'
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.security_type.wpa2'),
                  name: 'security.layer2.security_type',
                  inputType: 'radio',
                  validateRules: `${security_type.isRequired ? 'required|' : ''}`,
                  isDisabled: security_type.isDisabled,
                  placeholder: '',
                  defaultValue: security_type.defaultValue,
                  radioValue: 'wpa2'
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.security_type.wpa2+wpa3'),
                  name: 'security.layer2.security_type',
                  inputType: 'radio',
                  validateRules: `${security_type.isRequired ? 'required|' : ''}`,
                  isDisabled: security_type.isDisabled,
                  placeholder: '',
                  defaultValue: security_type.defaultValue,
                  radioValue: 'wpa2+wpa3'
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.security_type.wpa3'),
                  name: 'security.layer2.security_type',
                  inputType: 'radio',
                  validateRules: `${security_type.isRequired ? 'required|' : ''}`,
                  isDisabled: security_type.isDisabled,
                  placeholder: '',
                  defaultValue: security_type.defaultValue,
                  radioValue: 'wpa3'
                }
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlan.tabSectionLabel.encryptionType'),
              name: 'security.layer2.encryption_type',
              inputs: [
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.encryption_type.ccmp'),
                  name: 'security.layer2.encryption_type',
                  inputType: 'radio',
                  validateRules: `${encryption_type.isRequired ? 'required|' : ''}`,
                  isDisabled: encryption_type.isDisabled,
                  placeholder: '',
                  defaultValue: encryption_type.defaultValue,
                  radioValue: 'ccmp'
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.encryption_type.ccmp256'),
                  name: 'security.layer2.encryption_type',
                  inputType: 'radio',
                  validateRules: `${encryption_type.isRequired ? 'required|' : ''}`,
                  isDisabled: encryption_type.isDisabled,
                  placeholder: '',
                  defaultValue: encryption_type.defaultValue,
                  radioValue: 'ccmp256'
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.encryption_type.gcmp256'),
                  name: 'security.layer2.encryption_type',
                  inputType: 'radio',
                  validateRules: `${encryption_type.isRequired ? 'required|' : ''}`,
                  isDisabled: encryption_type.isDisabled,
                  placeholder: '',
                  defaultValue: encryption_type.defaultValue,
                  radioValue: 'gcmp256'
                }
              ]
            },
            {
              id: uuidv4(),
              label: '',
              name: 'security.layer2.mab',
              inputs: [
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.isMabEnabled'),
                  name: 'security.layer2.isMabEnabled',
                  inputType: 'checkbox',
                  validateRules: `${isMabEnabled.isRequired ? 'required' : ''}`,
                  isDisabled: isMabEnabled.isDisabled,
                  placeholder: '',
                  defaultValue: isMabEnabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.mab_radius_profile'),
                  name: 'security.layer2.mab_radius_profile',
                  inputType: 'select',
                  validateRules: `${mab_radius_profile.isRequired ? 'required' : ''}`,
                  isDisabled: mab_radius_profile.isDisabled,
                  placeholder: '',
                  defaultValue: mab_radius_profile.defaultValue,
                  selectOptions: [
                    // список селектов с бэка
                    ...mab_radius_profile.selectOptions
                  ]
                }
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlan.tabSectionLabel.80211r'),
              name: 'security.layer2.80211r',
              inputs: [
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.fast_roaming_enabled'),
                  name: 'security.layer2.fast_roaming_enabled',
                  inputType: 'checkbox',
                  validateRules: `${fast_roaming_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: fast_roaming_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: fast_roaming_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.ft_over_ds_enabled'),
                  name: 'security.layer2.ft_over_ds_enabled',
                  inputType: 'checkbox',
                  validateRules: `${ft_over_ds_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: ft_over_ds_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: ft_over_ds_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.param_80211r_reassociation_timeout'),
                  name: 'security.layer2.param_80211r_reassociation_timeout',
                  inputType: 'number',
                  validateRules: `${param_80211r_reassociation_timeout.isRequired ? 'required|' : ''}integer|between:1,100`,
                  isDisabled: param_80211r_reassociation_timeout.isDisabled,
                  placeholder: '',
                  defaultValue: param_80211r_reassociation_timeout.defaultValue
                }
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlan.tabSectionLabel.80211w'),
              name: 'security.layer2.80211w',
              inputs: [
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.support_pmf'),
                  name: 'security.layer2.support_pmf',
                  inputType: 'select',
                  validateRules: `${support_pmf.isRequired ? 'required' : ''}`,
                  isDisabled: support_pmf.isDisabled,
                  placeholder: '',
                  defaultValue: support_pmf.defaultValue,
                  selectOptions: [
                    { display: $t('wlan.inputLabel.support_pmf.selectOption.disabled'), data: 'disabled' },
                    { display: $t('wlan.inputLabel.support_pmf.selectOption.optional'), data: 'optional' },
                    { display: $t('wlan.inputLabel.support_pmf.selectOption.mandatory'), data: 'mandatory' }
                  ]
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.param_80211w_query_attempts'),
                  name: 'security.layer2.param_80211w_query_attempts',
                  inputType: 'number',
                  validateRules: `${param_80211w_query_attempts.isRequired ? 'required|' : ''}integer|between:1,10`,
                  isDisabled: param_80211w_query_attempts.isDisabled,
                  placeholder: '',
                  defaultValue: param_80211w_query_attempts.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.param_80211w_retry_timeout'),
                  name: 'security.layer2.param_80211w_retry_timeout',
                  inputType: 'number',
                  validateRules: `${param_80211w_retry_timeout.isRequired ? 'required|' : ''}integer|between:100,2000`,
                  isDisabled: param_80211w_retry_timeout.isDisabled,
                  placeholder: '',
                  defaultValue: param_80211w_retry_timeout.defaultValue
                }
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlan.tabSectionLabel.authenticationKeyManagement'),
              name: 'security.layer2.authenticationKeyManagement',
              inputs: [
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.psk_enabled'),
                  name: 'security.layer2.psk_enabled',
                  inputType: 'checkbox',
                  validateRules: `${psk_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: psk_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: psk_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.psk_256_enabled'),
                  name: 'security.layer2.psk_256_enabled',
                  inputType: 'checkbox',
                  validateRules: `${psk_256_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: psk_256_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: psk_256_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.param_8021x_enabled'),
                  name: 'security.layer2.param_8021x_enabled',
                  inputType: 'checkbox',
                  validateRules: `${param_8021x_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: param_8021x_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: param_8021x_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.param_8021x_256_enabled'),
                  name: 'security.layer2.param_8021x_256_enabled',
                  inputType: 'checkbox',
                  validateRules: `${param_8021x_256_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: param_8021x_256_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: param_8021x_256_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.sae_enabled'),
                  name: 'security.layer2.sae_enabled',
                  inputType: 'checkbox',
                  validateRules: `${sae_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: sae_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: sae_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.ft_sae_enabled'),
                  name: 'security.layer2.ft_sae_enabled',
                  inputType: 'checkbox',
                  validateRules: `${ft_sae_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: ft_sae_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: ft_sae_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.ft_8021x_enabled'),
                  name: 'security.layer2.ft_8021x_enabled',
                  inputType: 'checkbox',
                  validateRules: `${ft_8021x_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: ft_8021x_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: ft_8021x_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.ft_psk_enabled'),
                  name: 'security.layer2.ft_psk_enabled',
                  inputType: 'checkbox',
                  validateRules: `${ft_psk_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: ft_psk_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: ft_psk_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.suite_b_192_enabled'),
                  name: 'security.layer2.suite_b_192_enabled',
                  inputType: 'checkbox',
                  validateRules: `${suite_b_192_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: suite_b_192_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: suite_b_192_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.owe_enabled'),
                  name: 'security.layer2.owe_enabled',
                  inputType: 'checkbox',
                  validateRules: `${owe_enabled.isRequired ? 'required' : ''}`,
                  isDisabled: owe_enabled.isDisabled,
                  placeholder: '',
                  defaultValue: owe_enabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.psk_format'),
                  name: 'security.layer2.psk_format',
                  inputType: 'select',
                  validateRules: `${psk_format.isRequired ? 'required' : ''}`,
                  isDisabled: psk_format.isDisabled,
                  placeholder: '',
                  defaultValue: psk_format.defaultValue,
                  selectOptions: [
                    { display: 'ASCII', data: 'ascii' },
                    { display: 'HEX', data: 'hex' }
                  ]
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.psk_type'),
                  name: 'security.layer2.psk_type',
                  inputType: 'select',
                  validateRules: `${psk_type.isRequired ? 'required' : ''}`,
                  isDisabled: psk_type.isDisabled,
                  placeholder: '',
                  defaultValue: psk_type.defaultValue,
                  selectOptions: [
                    { display: $t('wlan.inputLabel.psk_type.selectOption.open'), data: 'open' },
                    { display: $t('wlan.inputLabel.psk_type.selectOption.encrypted'), data: 'encrypted' }
                  ]
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.psk_key'),
                  name: 'security.layer2.psk_key',
                  inputType: 'text',
                  validateRules: `${psk_key.isRequired ? 'required|' : ''}`,
                  isDisabled: psk_key.isDisabled,
                  placeholder: '',
                  defaultValue: psk_key.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.owe_wlan_id'),
                  name: 'security.layer2.owe_wlan_id',
                  inputType: 'number',
                  validateRules: `${owe_wlan_id.isRequired ? 'required|' : ''}integer|between:0,4096`,
                  isDisabled: owe_wlan_id.isDisabled,
                  placeholder: '',
                  defaultValue: owe_wlan_id.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.sae_anti_clogging_threshold'),
                  name: 'security.layer2.sae_anti_clogging_threshold',
                  inputType: 'number',
                  validateRules: `${sae_anti_clogging_threshold.isRequired ? 'required|' : ''}integer|between:0,3000`,
                  isDisabled: sae_anti_clogging_threshold.isDisabled,
                  placeholder: '',
                  defaultValue: sae_anti_clogging_threshold.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.sae_max_retries'),
                  name: 'security.layer2.sae_max_retries',
                  inputType: 'number',
                  validateRules: `${sae_max_retries.isRequired ? 'required|' : ''}integer|between:1,10`,
                  isDisabled: sae_max_retries.isDisabled,
                  placeholder: '',
                  defaultValue: sae_max_retries.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.sae_password_element'),
                  name: 'security.layer2.sae_password_element',
                  inputType: 'select',
                  validateRules: `${sae_password_element.isRequired ? 'required' : ''}`,
                  isDisabled: sae_password_element.isDisabled,
                  placeholder: '',
                  defaultValue: sae_password_element.defaultValue,
                  selectOptions: [
                    { display: $t('wlan.inputLabel.sae_password_element.selectOption.hnp'), data: 'hnp' },
                    { display: $t('wlan.inputLabel.sae_password_element.selectOption.h2e'), data: 'h2e' },
                    { display: $t('wlan.inputLabel.sae_password_element.selectOption.both-h2e-hnp'), data: 'both-h2e-hnp' }
                  ]
                }
              ]
            }
          ],
          childrenTabs: []
        },
        {
          id: uuidv4(),
          label: $t('wlan.tabLabel.layer3'),
          name: 'layer3',
          content: [
            {
              id: uuidv4(),
              label: '',
              name: 'security.layer3.webAuthRule',
              inputs: [
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.isWebAuthProfileEnabled'),
                  name: 'security.layer3.isWebAuthProfileEnabled',
                  inputType: 'checkbox',
                  validateRules: `${isWebAuthProfileEnabled.isRequired ? 'required' : ''}`,
                  isDisabled: isWebAuthProfileEnabled.isDisabled,
                  placeholder: '',
                  defaultValue: isWebAuthProfileEnabled.defaultValue
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.web_auth_profile'),
                  name: 'security.layer3.web_auth_profile',
                  inputType: 'select',
                  validateRules: `${web_auth_profile.isRequired ? 'required' : ''}`,
                  isDisabled: web_auth_profile.isDisabled,
                  placeholder: '',
                  defaultValue: web_auth_profile.defaultValue,
                  selectOptions: [
                    // список селектов с бэка
                    ...web_auth_profile.selectOptions
                  ]
                }
              ]
            }
          ],
          childrenTabs: []
        },
        {
          id: uuidv4(),
          label: $t('wlan.tabLabel.aaa'),
          name: 'aaa',
          content: [
            {
              id: uuidv4(),
              label: '',
              name: 'security.aaa.radius',
              inputs: [
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.radius_8021x_profile'),
                  name: 'security.aaa.radius_8021x_profile',
                  inputType: 'select',
                  validateRules: `${radius_8021x_profile.isRequired ? 'required' : ''}`,
                  isDisabled: radius_8021x_profile.isDisabled,
                  placeholder: '',
                  defaultValue: radius_8021x_profile.defaultValue,
                  selectOptions: [
                    { display: $t('wlan.inputLabel.radius_accounting_profile.selectOption.notConfigured'), data: 'not_configured' },
                    // список селектов с бэка
                    ...radius_8021x_profile.selectOptions
                  ]
                },
                {
                  id: uuidv4(),
                  label: $t('wlan.inputLabel.radius_accounting_profile'),
                  name: 'security.aaa.radius_accounting_profile',
                  inputType: 'select',
                  validateRules: `${radius_accounting_profile.isRequired ? 'required' : ''}`,
                  isDisabled: radius_accounting_profile.isDisabled,
                  placeholder: '',
                  defaultValue: radius_accounting_profile.defaultValue,
                  selectOptions: [
                    { display: $t('wlan.inputLabel.radius_accounting_profile.selectOption.notConfigured'), data: 'not_configured' },
                    // список селектов с бэка
                    ...radius_accounting_profile.selectOptions
                  ]
                }
              ]
            }
          ],
          childrenTabs: []
        }
      ]
    },
    {
      id: uuidv4(),
      label: $t('wlan.tabLabel.advanced'),
      name: 'advanced',
      content: [
        {
          id: uuidv4(),
          label: '',
          name: 'advanced.general',
          withBorder: false,
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.advertise_ap_name_enabled'),
              name: 'advanced.advertise_ap_name_enabled',
              inputType: 'checkbox',
              validateRules: `${advertise_ap_name_enabled.isRequired ? 'required' : ''}`,
              isDisabled: advertise_ap_name_enabled.isDisabled,
              placeholder: '',
              defaultValue: advertise_ap_name_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.deny_laa_rcm_enabled'),
              name: 'advanced.deny_laa_rcm_enabled',
              inputType: 'checkbox',
              validateRules: `${deny_laa_rcm_enabled.isRequired ? 'required' : ''}`,
              isDisabled: deny_laa_rcm_enabled.isDisabled,
              placeholder: '',
              defaultValue: deny_laa_rcm_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.okc_enabled'),
              name: 'advanced.okc_enabled',
              inputType: 'checkbox',
              validateRules: `${okc_enabled.isRequired ? 'required' : ''}`,
              isDisabled: okc_enabled.isDisabled,
              placeholder: '',
              defaultValue: okc_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.p2p_block_action'),
              name: 'advanced.p2p_block_action',
              inputType: 'select',
              validateRules: `${p2p_block_action.isRequired ? 'required' : ''}`,
              isDisabled: p2p_block_action.isDisabled,
              placeholder: '',
              defaultValue: p2p_block_action.defaultValue,
              selectOptions: [
                { display: $t('wlan.inputLabel.p2p_block_action.selectOption.disabled'), data: 'disabled' },
                { display: $t('wlan.inputLabel.p2p_block_action.selectOption.drop'), data: 'drop' }
                // список селектов с бэка
                // ...p2p_block_action.selectOptions
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.wmm_policy'),
              name: 'advanced.wmm_policy',
              inputType: 'select',
              validateRules: `${wmm_policy.isRequired ? 'required' : ''}`,
              isDisabled: wmm_policy.isDisabled,
              placeholder: '',
              defaultValue: wmm_policy.defaultValue,
              selectOptions: [
                { display: $t('wlan.inputLabel.wmm_policy.selectOption.disabled'), data: 'disabled' },
                { display: $t('wlan.inputLabel.p2p_block_action.selectOption.allowed'), data: 'allowed' },
                { display: $t('wlan.inputLabel.p2p_block_action.selectOption.require'), data: 'require' }
                // список селектов с бэка
                // ...wmm_policy.selectOptions
              ]
            }
          ]
        },
        {
          id: uuidv4(),
          label: $t('wlan.tabSectionLabel.maxClientsConnections'),
          name: 'advanced.maxClientsConnections',
          withBorder: true,
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.max_connection_radio_wlan'),
              name: 'advanced.max_connection_radio_wlan',
              inputType: 'number',
              validateRules: `${max_connection_radio_wlan.isRequired ? 'required|' : ''}integer|between:0,500`,
              isDisabled: max_connection_radio_wlan.isDisabled,
              placeholder: '',
              defaultValue: max_connection_radio_wlan.defaultValue
            }
          ]
        },
        {
          id: uuidv4(),
          label: $t('wlan.tabSectionLabel.dtimPeriod'),
          name: 'advanced.dtimPeriod',
          withBorder: true,
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.dtim_interval_5ghz'),
              name: 'advanced.dtim_interval_5ghz',
              inputType: 'number',
              validateRules: `${dtim_interval_5ghz.isRequired ? 'required|' : ''}integer|between:1,255`,
              isDisabled: dtim_interval_5ghz.isDisabled,
              placeholder: '',
              defaultValue: dtim_interval_5ghz.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.dtim_interval_24ghz'),
              name: 'advanced.dtim_interval_24ghz',
              inputType: 'number',
              validateRules: `${dtim_interval_24ghz.isRequired ? 'required|' : ''}integer|between:1,255`,
              isDisabled: dtim_interval_24ghz.isDisabled,
              placeholder: '',
              defaultValue: dtim_interval_24ghz.defaultValue
            }
          ]
        },
        {
          id: uuidv4(),
          label: $t('wlan.tabSectionLabel.80211k'),
          name: 'advanced.80211k',
          withBorder: true,
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.neighbor_report_enabled'),
              name: 'advanced.neighbor_report_enabled',
              inputType: 'checkbox',
              validateRules: `${neighbor_report_enabled.isRequired ? 'required' : ''}`,
              isDisabled: neighbor_report_enabled.isDisabled,
              placeholder: '',
              defaultValue: neighbor_report_enabled.defaultValue
            }
          ]
        },
        {
          id: uuidv4(),
          label: $t('wlan.tabSectionLabel.80211ax'),
          name: 'advanced.80211ax',
          withBorder: true,
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.param_80211ax_enabled'),
              name: 'advanced.param_80211ax_enabled',
              inputType: 'checkbox',
              validateRules: `${param_80211ax_enabled.isRequired ? 'required' : ''}`,
              isDisabled: param_80211ax_enabled.isDisabled,
              placeholder: '',
              defaultValue: param_80211ax_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.ofdma_downlink_enabled'),
              name: 'advanced.ofdma_downlink_enabled',
              inputType: 'checkbox',
              validateRules: `${ofdma_downlink_enabled.isRequired ? 'required' : ''}`,
              isDisabled: ofdma_downlink_enabled.isDisabled,
              placeholder: '',
              defaultValue: ofdma_downlink_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.ofdma_uplink_enabled'),
              name: 'advanced.ofdma_uplink_enabled',
              inputType: 'checkbox',
              validateRules: `${ofdma_uplink_enabled.isRequired ? 'required' : ''}`,
              isDisabled: ofdma_uplink_enabled.isDisabled,
              placeholder: '',
              defaultValue: ofdma_uplink_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.mumimo_downlink_enabled'),
              name: 'advanced.mumimo_downlink_enabled',
              inputType: 'checkbox',
              validateRules: `${mumimo_downlink_enabled.isRequired ? 'required' : ''}`,
              isDisabled: mumimo_downlink_enabled.isDisabled,
              placeholder: '',
              defaultValue: mumimo_downlink_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.mumimo_uplink_enabled'),
              name: 'advanced.mumimo_uplink_enabled',
              inputType: 'checkbox',
              validateRules: `${mumimo_uplink_enabled.isRequired ? 'required' : ''}`,
              isDisabled: mumimo_uplink_enabled.isDisabled,
              placeholder: '',
              defaultValue: mumimo_uplink_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.bss_twt_enabled'),
              name: 'advanced.bss_twt_enabled',
              inputType: 'checkbox',
              validateRules: `${bss_twt_enabled.isRequired ? 'required' : ''}`,
              isDisabled: bss_twt_enabled.isDisabled,
              placeholder: '',
              defaultValue: bss_twt_enabled.defaultValue
            }
          ]
        },
        {
          id: uuidv4(),
          label: $t('wlan.tabSectionLabel.80211v'),
          name: 'advanced.80211v',
          withBorder: true,
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlan.inputLabel.bss_fast_transition_enabled'),
              name: 'advanced.bss_fast_transition_enabled',
              inputType: 'checkbox',
              validateRules: `${bss_fast_transition_enabled.isRequired ? 'required' : ''}`,
              isDisabled: bss_fast_transition_enabled.isDisabled,
              placeholder: '',
              defaultValue: bss_fast_transition_enabled.defaultValue
            }
          ]
        }
      ],
      childrenTabs: []
    }
  ];

  return formConfig;
}

export default generateFormConfig;
